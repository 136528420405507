// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import * as Yup from "yup";

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes:any
}

interface S {
  otp:string;
  alertMessage:string;
  alertType:string;
  Loading:boolean,
  showAlert:boolean
}

interface SS {
  id: any;
}

export default class MobileAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  twofactorApi:any;
  verifyTxt:string;
  twoFactorAuthenticationTxt:string;
  enterOtpLabel: string;
  rolesPermisionApi:string="";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];
    let data={ primary_color: "#E57727",secondary_color: "#E57727",}
    localStorage.setItem("themeColor",JSON.stringify(data))

    this.state = {
      otp:"",
      alertMessage:"",
      alertType:"",
      Loading:false,
      showAlert:false
    };
    this.verifyTxt=configJSON.verifyTxt;
    this.twoFactorAuthenticationTxt=configJSON.twoFactorAuthenticationTxt;
    this.enterOtpLabel=configJSON.enterOtpLabel;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.rolesPermision()
    if(localStorage.getItem("loginData")==null){
      this.loginPage();
    }
  }
  async componentDidUpdate() {
    if(this.state.showAlert){
        setTimeout(()=>{
            this.setState({showAlert:false})
        },10000)
    }
  }

  loginPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToHomePage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomePageMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async receive(from: string, message: Message) {
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
  
        if (apiRequestCallId === this.twofactorApi) {
          this.setState({Loading:true})
          if (responseJson.meta && !responseJson.error) {
           
                this.goToHomePage()
          } else {
            this.setState({alertMessage: responseJson.error})
            this.setState({showAlert: true,alertType:"error",Loading:false})
          }
  
          this.parseApiCatchErrorResponse(errorReponse);
        }

        if (apiRequestCallId === this.rolesPermisionApi) {
          if (responseJson && !responseJson.error) {
           localStorage.setItem("rolesPermission",JSON.stringify(responseJson))
          } else {
            this.setState({alertMessage: responseJson.error})
            this.setState({showAlert: true,alertType:"error"})
          }
  
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
  }

   handleChange=(e:any)=>{
    this.setState({otp:e})
  }
  forgotSchema = () => {
    const schema = {
        Otp: Yup.string()
          .required('OTP is required')
          .matches(/^\d{5}$/, 'OTP must be a 5-digit number')
        }
          return schema
    }
  twofactorverify=async (value:any)=>{
    const user = JSON.parse(localStorage.getItem('loginData') || '{}');
    const userToken=user.token;
    this.twofactorApi=await this.apiTFCall({
        contentType: 'application/json',
        method: 'POST',
        endPoint: '/account_block/accounts/verify_two_factor_otp',
        body:{
          "data": 
          {
              "otp": value
          }
      },
        token:userToken
      })
  }

  rolesPermision=async ()=>{
    const user = JSON.parse(localStorage.getItem('loginData') || '{}');
    const userToken=user.token;
    this.rolesPermisionApi=await this.apiTFCall({
        method: 'GET',
        endPoint: '/bx_block_roles_permissions/role_permissions/get_role_permission',
        token:userToken
      })
  }
  apiTFCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestTFMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestTFMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestTFMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestTFMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestTFMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestTFMessage.id, requestTFMessage);
    return requestTFMessage.messageId;
  };
}
// Customizable Area End
