import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import moment from 'moment';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Audittrail2Controller, {
  Props,
  configJSON,
} from "./Audittrail2Controller";
import { Autocomplete,Pagination } from "@material-ui/lab";

export default class Audittrail2 extends Audittrail2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.getUserList();
    this.fetchAuditTrailsAttachments();
  }
  // Customizable Area End

  render() {
    const { pageNumber, dataPerPage, auditTrailsAttachments } = this.state;
    const startIndex = (pageNumber - 1) * dataPerPage;
    const endIndex = startIndex + dataPerPage;
    const totalItems = auditTrailsAttachments.length;
    const totalPages = Math.ceil(totalItems / dataPerPage);
    const currentData = auditTrailsAttachments.slice(startIndex, endIndex);

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"}>
          <Box sx={webStyle.mainWrapper}>
          <Box sx={webStyle.headSectionAuto}>
              <Typography variant="h6">Select user</Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={this.state.selectedUserId !== null ? this.state.userList.find((user:any) => user.id === this.state.selectedUserId) : null}
                options={this.state.userList}
                getOptionLabel={(user) => `${user.name} | ${user.email}`}
                onChange={(event, newValue) => {
                  this.setState({ selectedUserId: newValue ? newValue.id : "" });
                }}
                renderInput={(params) => <TextField {...params} label="" />}
              />
            </Box>
            <Box sx={webStyle.headSection}>
              <Typography variant="h6">Select items types</Typography>
              <Select
                name="users"
                data-test-id="users"
                style={webStyle.selectStyle}
                variant="outlined"
                label="All record types"
                value={this.state.selectedItemType}
                onChange={this.itemTypeChange}
              >
                {Object.entries({ 'create': 'Create', 'change': 'Change', 'delete': 'Delete' }).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={webStyle.headSection}>
              <Typography variant="h6">From Date</Typography>
              <TextField
                type="date"
                style={{ width: '100%' }}
                id="outlined-basic"
                variant="outlined"
                name="fromDate"
                value={this.state.fromDate}
                onChange={this.updateFromDate}
              />
            </Box>
            <Box sx={webStyle.headSection}>
              <Typography variant="h6"> To Date </Typography>
              <TextField
                type="date"
                style={{ width: '100%' }}
                id="outlined-basic"
                variant="outlined"
                name="toDate"
                value={this.state.toDate}
                onChange={this.updateFromDate}
              />
            </Box>
            <Box sx={webStyle.headSection}>
              {/* @ts-ignore */}
              <Button style={webStyle.buttonStyle}
                onClick={this.fetchAuditTrails}
              >
                Run report
              </Button>
            </Box>
          </Box>
          <Box sx={webStyle.tableStyle}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Event</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { }
                  {
                    currentData?.length > 0 && currentData?.map((item: any, index: any) => {
                      const { attributes: { audit_trail: { date_time, changes_done_by_name, action, details } } } = item;
                      const formattedDate = moment(date_time).format('DD/MM/YYYY');
                      const formattedTime = moment(date_time).format('hh:mm A');
                      return (
                        <TableRow>
                          <TableCell> {formattedDate}  </TableCell>
                          <TableCell>{formattedTime}</TableCell>
                          <TableCell>{changes_done_by_name}</TableCell>
                          <TableCell>{action}</TableCell>
                          <TableCell>{details}</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={webStyle.paginationMain}>
              <Box sx={webStyle.pagginationWrapper}>
                <Box sx={webStyle.paginatnBox}>
                  {/* <Typography style={webStyle.paginatnText}>{currentData.length} Per Page</Typography> */}
                  <select
                    value={dataPerPage}
                    onChange={this.handleItemsPerPageChange}
                    style={{ border: "none", background: "#fff" }}
                    data-test-id="selectDocPerPage"   >
                    {this.dataPerPageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option} per page
                      </option>
                    ))}
                  </select>
                  <Pagination
                    //@ts-ignore
                    style={webStyle.paginatnText}
                    size="small"
                    count={totalPages}
                    onChange={(event, page) => this.setState({ pageNumber: page })}
                    page={pageNumber}
                    data-test-id="pagination"
                  />
                  {/* @ts-ignore */}
                  <Typography style={webStyle.RightPagintnText}>
                    {Math.min(startIndex + 1, auditTrailsAttachments.length)} - {endIndex} of {auditTrailsAttachments.length} results
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  paginationMain: {
    position: 'relative',
  },
  pagginationWrapper: {
    borderTop: '1px solid #E2E8F0',
    background: '#fff',
    height: "4rem",
    marginTop: '40px',
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  RightPagintnText: {
    color: "#64748B",
    textAlign: "right",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px"
  },
  paginatnBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '0px 16px'
  },
  paginatnText: {
    display: "flex",
    height: "32px",
    padding: "5px 0px",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    flexShrink: "0",
    borderRadius: "100px",
    background: "#FFF",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    alignItems: "flex-end",
    paddingBottom: "30px",
    background: "#fff",
    gap: 15,
    color: '#334155',
    margin: '20px 0',
  },
  headSection: {
    display: "flex",
    flexDirection: "column",
    width: '20%',
    gap: '4px',
    '& h6': {
      fontSize: '14px',
      fontWeight: 700,
    },
    '& input': {
      padding: '18px 8px',
      borderRadius: '8px',
      border: '1px solid #CBD5E1',
      color: '#64748B',
      fontSize: '16px',
    },
    '& fieldset': {
      border: 'none',
    }
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    padding: "15px 16px",
    border: "none",
    color: 'rgba(255, 255, 255',
    fontSize: '16px',
    fontWeight: "700",
    textTransform: 'capitalize',
    backgroundColor: "#E57727",
  },
  selectStyle: {
    height: '56px',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    color: '#64748B',
    fontSize: '16px',
  },
  headSectionAuto: {
    display: "flex",
    flexDirection: "column",
    width: '20%',
    gap: '4px',
    '& h6': {
      fontSize: '14px',
      fontWeight: 700,
    },
    '& .MuiAutocomplete-root': {
      padding: '0 14px',
      height: '56px',
      border: '1px solid #CBD5E1',
      borderRadius: '8px',
      color: '#64748B',
      fontSize: '16px',
      '& ::before': {
        borderBottom: 'none !important',
      },
      '& ::after': {
        borderBottom: 'none !important',
      },
      '& + div':{
        marginTop: '88px',
        width: '18% !important',
      }
    },
    '& input': {
      padding: '18.5px 0 !important',
    }
  },
  selectStyleField: {
    border: 'none',
    background: 'red',
    margin: 0,
    '& input': {
      border: 'none',
    }
  },
  tableStyle: {
    border: '1px solid #E2E8F0',
    borderRadius: '8px',
    fontSize: '14px',
    '& tr': {
      '& th': {
        color: '#64748B',
        fontWeight: '700',
        padding: '20px 24px',
        '&:first-child': {
          minWidth: '240px',
        },
      },
      '& td': {
        color: '#0F172A',
        fontWeight: '400',
        padding: '20px 24px',
        borderBottom: '1px solid #E2E8F0',
        '&:last-child': {
          fontWeight: 500,
          color: '#000',
        }
      },
      '&:last-child': {
        '&:th': {
          borderBottom: 'none'
        }
      },
      '& .MuiAutocomplete-paper': {
        marginTop: "83px"
      }
    },

  }
};
// Customizable Area End
