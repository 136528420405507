import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { ModelContext } from "../../../components/src/Context";
import { getThemeColor,getSecondaryThemeColor } from "../../../components/src/CommonPlatte";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userName: string;
  dataLoaded: boolean;
  userData: boolean;
  isDrawerOpen: boolean;
  showModel: boolean;
  workspaceData: any;
  WorkspaceCatelogData: any;
  showMOdal: any;
  selectedItem: any;
  workspaceCatalogsid: any;
  boardName: any;
  primaryColor:string;
  secondaryColor:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
    details:string;
    titleWelcome:string;
    constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      userName: "",
      dataLoaded: false,
      userData: false,
      isDrawerOpen: false,
      showModel: false,
      primaryColor:getThemeColor(),
      secondaryColor:getSecondaryThemeColor(),
      workspaceData: [],
      WorkspaceCatelogData: [],
      showMOdal: false,
      selectedItem: "",
      workspaceCatalogsid: "",
      boardName: ""
    };

    this.details = configJSON.details;
    this.titleWelcome = configJSON.titleWelcome;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('loginData') || '{}'); 
    this.setState({userName:user.full_name})
    const savedData = localStorage.getItem('loginData');
    if (savedData) {
      this.setState({ dataLoaded: true});
    }else{
      this.setState({ userData:true});
      window.location.href = '/EmailLogin';
    }
  }

  async componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.userData !== this.state.userData) {
      console.log("DIDUpdate");
      
      window.location.href = '/EmailLogin';
    }
  }

  // Customizable Area Start

  static contextType: React.Context<any | undefined> = ModelContext;
  token: string = "";
  createWorkspaceApiCallId: string = "";
  getWorkspaceApiCallId: string = "";
  updateWorkspaceApiCallId: string = "";
  deleteWorkspaceApiCallId: string = "";
  createWorkspaceCatalogsApiCallId: string = "";
  getWorkspaceCatalogsApiCallId: string = "";

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.createWorkspaceApiCallId) {
       this.createWorkspaceApiCallIdSQ(responseJson)
      }
      else if (apiRequestCallId === this.getWorkspaceApiCallId) {
        this.getWorkspaceApiCallIdSQ(responseJson)
      }
      else if (apiRequestCallId === this.updateWorkspaceApiCallId) {
        this.updateWorkspaceApiCallIdSQ(responseJson);
       
      }
      else if (apiRequestCallId === this.deleteWorkspaceApiCallId) {
        this.deleteWorkspaceApiCallIdSQ(responseJson);

      }
      else if (apiRequestCallId === this.createWorkspaceCatalogsApiCallId) {
        this.createWorkspaceCatalogsApiCallIdSQ(responseJson)
      }

      else if (apiRequestCallId === this.getWorkspaceCatalogsApiCallId) {
        this.getWorkspaceCatalogsApiCallIdSQ(responseJson)
       
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleLogout = () => {
    localStorage.clear();
    window.location.href = '/EmailLogin';
  }

  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false });
  };
  handleDrawerOpen = (id: any, name: any) => {
    this.setState({
      isDrawerOpen: true,
      workspaceCatalogsid: id,
      boardName: name
    },
      () => {
        this.getWorkspaceCatalogs(this.state.workspaceCatalogsid)
      });
  };

  handleClick = (event:any ,item: any) => {
    this.setState({
       showMOdal: !this.state.showMOdal,
       selectedItem: item 
      })
  }

  updateWorkspaceApiCallIdSQ = (responseJson: any) => {
    const { closeModel } = this.context;
    if (responseJson && responseJson.data !== null && responseJson.data !== undefined) {
       this.setState({ showMOdal: false, selectedItem: "" }, () => this.getWorkspace())
       closeModel('model1');
    }
  }
  deleteWorkspaceApiCallIdSQ = (responseJson: any) => {
    if (responseJson && responseJson.meta !== null && responseJson.meta !== undefined) {
      this.setState({ showMOdal: false, selectedItem: "" }, () => this.getWorkspace())
    }
  }
  createWorkspaceCatalogsApiCallIdSQ = (responseJson: any) => {
    const { closeModel } = this.context;
    if (responseJson && responseJson.data !== null && responseJson.data !== undefined) {
        this.setState({ showMOdal: false, selectedItem: "" }, () => this.getWorkspaceCatalogs(this.state.workspaceCatalogsid))
        closeModel('model2');
    }
  }
  getWorkspaceCatalogsApiCallIdSQ =(responseJson:any)=>{
    if (responseJson && responseJson.data !== null && responseJson.data !== undefined) {
      this.setState({ WorkspaceCatelogData: responseJson.data });
    }
  }
 createWorkspaceApiCallIdSQ = (responseJson:any)=>{
  const { closeModel } = this.context;
  if (responseJson && responseJson.data !== null && responseJson.data !== undefined) {
    this.setState(
      { selectedItem: "", showMOdal: false },
      () => {
        closeModel('model1');
        this.getWorkspace();
      }
    );
  }
 }


  editWorkspace = async (id: any, item: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.updateWorkspaceApiCallId = await this.getUserAccountapiCall({
      // contentType: 'application/json',
      methods: "PATCH",
      endPoint: `bx_block_profile/workspaces/${id}`,
      token: this.token,
      body: { "workspace": { "name": item.Workspace_Name, "description": item.Description } }
    });
  }

  getWorkspaceApiCallIdSQ = (responseJson:any)=>{
    if (responseJson && responseJson.data !== null && responseJson.data !== undefined) {
      this.setState({ workspaceData: responseJson?.data });
    }
  }

  deleteWorkspace = async (id: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.deleteWorkspaceApiCallId = await this.getUserAccountapiCall({
      contentType: 'application/json',
      methods: "DELETE",
      endPoint: `bx_block_profile/workspaces/${id}`,
      token: this.token,
    });
  }

  getWorkspace = async () => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.getWorkspaceApiCallId = await this.getUserAccountapiCall({
      contentType: 'application/json',
      methods: "Get",
      endPoint: "bx_block_profile/workspaces",
      token: this.token,
    });
  }

  createWorkspace = async (value: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.createWorkspaceApiCallId = await this.getUserAccountapiCall({
      contentType: 'application/json',
      methods: "POST",
      endPoint: "bx_block_profile/workspaces",
      token: this.token,
      body: { "workspace": { "name": value.Workspace_Name, "description": value.Description } },
    });
  }

  //Workspace Catelog 
  createWorkspaceCatalogs = async (id: any, value: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.createWorkspaceCatalogsApiCallId = await this.getUserAccountapiCall({
      contentType: 'application/json',
      methods: "POST",
      endPoint: `bx_block_profile/workspaces/${id}/workspace_catalogs`,
      token: this.token,
      body: { "workspace_catalog": { "name": value.Board_name, "description": "description" } },
    });
  }

  getWorkspaceCatalogs = async (id: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.getWorkspaceCatalogsApiCallId = await this.getUserAccountapiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: `bx_block_profile/workspaces/${Number(id)}/workspace_catalogs`,
      token: this.token,
    });
  }

  gotoWorkspace = ({catelogid, boardName, workspace_name}:any) => {
    localStorage.setItem('boardName', boardName);
    localStorage.setItem('workspace_name', workspace_name);
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Projectnotes");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationProjectnotesMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationProjectnotesMessage),
    {
      workspace_catalogs: catelogid,
      workspaces: this.state.workspaceCatalogsid,
      boardName:boardName,
      workspace_name:workspace_name
    });
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg);
  }
  closeDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen })
  }

  getUserAccountapiCall = async (data: any) => {
    const { methods, endPoint, token, body } = data;
    const Header = {
      "Content-Type": 'application/json',
      token,
    };
    const apiRequestMessageforAccountPeopleweb = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforAccountPeopleweb.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforAccountPeopleweb.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforAccountPeopleweb.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    body &&
      apiRequestMessageforAccountPeopleweb.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(
      apiRequestMessageforAccountPeopleweb.id,
      apiRequestMessageforAccountPeopleweb
    );
    return apiRequestMessageforAccountPeopleweb.messageId;
  }

  // Customizable Area End
}
