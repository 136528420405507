import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  messages:any,
  newMessage:string,
  showProfile:boolean;
  selectedFile: any,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  scrollViewRef:React.RefObject<any>;
  fileInput: React.RefObject<any>;
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.scrollViewRef = React.createRef();
    this.fileInput = React.createRef();
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      messages: [],
      newMessage: '',
      showProfile:false,
      selectedFile: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.scrollToEnd();
  }

  async componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.messages.length !== this.state.messages.length) {
      this.scrollToEnd();
    }
  }


  showModal = () => {
    this.setState({ isVisibleModal: true });
  };



 


  scrollToEnd = () => {
    if (this.scrollViewRef.current) {
      this.scrollViewRef.current.scrollTop = this.scrollViewRef.current.scrollHeight;
    }
  };

  
 
  sendMessage = () => {
    const { newMessage, messages, selectedFile } = this.state;
  
    if (newMessage.trim() === '' && !selectedFile) return;
  
    let messageType = 'text';
  
    if (selectedFile) {
      if (selectedFile?.type?.startsWith('image/')) {
        messageType = 'image';
      } else if (selectedFile.type === 'application/pdf') {
        messageType = 'application/pdf';
      } else if (selectedFile?.type?.startsWith('application/')) {
        messageType = 'document';
      }
    }
  
    const newMessageObj = {
      id: messages.length + 1,
      content: newMessage,
      sender: 'user',
      timestamp: new Date(),
      type: messageType,
      file: selectedFile,
    };
  
    this.setState((prevState) => ({
      messages: [...prevState.messages, newMessageObj],
      newMessage: '',
      selectedFile: null,
    }));
  
    // Reset the file input
    if (this.fileInput.current) {
      this.fileInput.current.value = null;
    }
  };

  handleFileChange = (event:any) => {
    const file = event?.target?.files[0];
    this.setState({ selectedFile: file });
  };



  formatAMPM = (date:any) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours || 12; // Handle midnight

    return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  };
 
  handleShowProfile=()=>{
    this.setState({showProfile:true,isVisibleModal:false})
  }
  handleView=()=>{
    this.setState({showProfile:false})
  }
}
