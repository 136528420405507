// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  handleOpen:()=>void;
  classes:any;
  List?:string[]
}
interface Task {
  id: number;
  text: string;
}
interface S {
  openProject:boolean;
  model:boolean;
  value:string;
  modeTypeSelect:boolean;
  emailTypeSelect:boolean;
  token:string;
  projectList:string[];
  emailList:string[];
  openDropDown:boolean;
  usersEmailData:string;
  isTemplateOpen:boolean;
  showAlertPop:boolean;
  alertMessage:string;
  createFolder:boolean;
  createSubFolder:boolean;
  showCreateFolderModal:boolean;
  openFolderTemplate:boolean;
  toggle:boolean;
  //folder code
  folders:string[];
  showDialog:boolean;
  selectedDoc:any;
  showREnameBox:boolean;
  renameFolderName:string;
  isSubFolder:boolean;
}

interface SS {
  id: any;

}

export default class ProjectTemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  showDataAPI:string="";
  addSubTaskAPI:string="";
  emailFilterAccountByApi:string="";
  createEmailTemplateApi:string="";
  createContractTemplateApi:string="";
  createTaskTemplateApi:string="";
  showTaskListDataAPI:string="";
  showEmailListDataAPI:string="";
  createfolderTemplateApi:string="";
  createsubfolderTemplateApi:string="";
  showfolder:string="";
  showsubfolder:string="";
  folderList:string="";
  deleteFolderApiCallId:string="";
  renameFolderApiCallId:string=""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
  
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    
    ];
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);

    this.state = {
      token:Parsed?.token,
      value:"Invoices",
      model:false,
      projectList:[],
      emailList:[],
      modeTypeSelect:false,
      emailTypeSelect:false,
      usersEmailData:"",
      createSubFolder:false,
      createFolder:false,
      showCreateFolderModal:false,
      openDropDown:false,
      openProject:false,
      //folder code
      showREnameBox:false,
      folders:[],
      isSubFolder:false,
      showDialog:false,
      selectedDoc:null,
      renameFolderName:"",
      //folder code
      isTemplateOpen:false,
      showAlertPop:false,
      alertMessage:"",
      openFolderTemplate:false,
      toggle:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if(apiRequestCallId === this.showDataAPI){
         const newArray = responseJson.data.map((obj:any, index:any) => {
            const name = obj.attributes.full_name ;
            const email = obj.attributes.email ;
        
            return {
                value: index+1,
                label: name,
                labelValue: email
            };
        });
        newArray.unshift({
          value: null,
          label: "Select member",
          labelValue: ""
      });
        this.setState({ usersEmailData: newArray });
      }
      if (apiRequestCallId === this.createTaskTemplateApi) {
        this.setState({showAlertPop:true,alertMessage:responseJson.messages})
        this.showTaskListData();
      }
      if (apiRequestCallId === this.createEmailTemplateApi) {
        this.setState({showAlertPop:true,alertMessage:responseJson.message})
        this.showEmailListData();
      }
      if(apiRequestCallId === this.showTaskListDataAPI){
         this.setState({projectList:responseJson.data})
      }
      if(apiRequestCallId === this.showEmailListDataAPI){
        this.setState({emailList:responseJson.data})
      }
      if(apiRequestCallId === this.createContractTemplateApi){
        this.setState({showAlertPop:true,alertMessage:responseJson.message})        
      }
      if(apiRequestCallId === this.folderList){
        this.setState({folders:responseJson.data})        
      }
    }
  
  
  }

  async componentDidMount() {
    this.showTaskListData();
    this.showClientData();
    this.showEmailListData();
  }
  async componentDidUpdate(prevProps:any, prevState:S) {
    if (this.state.showAlertPop) {
      setTimeout(() => {
        this.setState({ showAlertPop: false });
      }, 5000);
    }
  }

  handleChangeToggle=()=>{
    this.setState({toggle:!this.state.toggle})
  }
  OpenDropDown=()=>{
    this.setState({openDropDown:this.state.openDropDown})
  }
  handleMode=()=>{
    this.setState({modeTypeSelect:!this.state.modeTypeSelect})
  }
  handleEmailType=()=>{
    this.setState({emailTypeSelect:!this.state.emailTypeSelect})
  }
  emailFormData = (values:any) => {
    const formData = new FormData();
  
  
        formData.append(`email_template[template_name]`,values.template_name )
        formData.append(`email_template[mode]`,values.mode )
        formData.append(`email_template[email_to]`,values.to )
        formData.append(`email_template[subject]`,values.subject )
        formData.append(`email_template[email_format]`,values.format )
        formData.append(`email_template[description]`,values.description )
        
     return formData;
  }
  taskFormData = (values:any) => {
    const formData = new FormData();  
  
        formData.append(`project_task[status]`,values.status )
        formData.append(`project_task[start_date]`,values.start_date )
        formData.append(`project_task[due_date]`,values.due_date )
        formData.append(`project_task[priority]`,values.priority )
        formData.append(`project_task[task_assignee]`,values.task_assignee )
        formData.append(`project_task[template_name]`,values.template_name )
        formData.append(`project_task[description]`,values.description )
        
     return formData;
  }
  createEmailTemplate = async (data:any) => {
    const formData=this.emailFormData(data)

    this.createEmailTemplateApi=await this.projectApiCall({
      method: 'POST',
      endPoint: `/bx_block_projecttemplates/email_templates`,
      body:formData,
      token:this.state.token
    })
    this.setState({isTemplateOpen:false});
  };
  createTaskTemplate=async(values:any) =>{
    const formData=this.taskFormData(values)

    this.createTaskTemplateApi=await this.projectApiCall({
      method: 'POST',
      endPoint: `/bx_block_projecttemplates/project_tasks`,
      body:formData,
      token:this.state.token
    })
    this.setState({openProject:false})
  }
  contractFormData = (values:any) => {
    const formData = new FormData();
  
  
        formData.append(`contract[wysiwyg]`,values.format )
        formData.append(`contract[send_reminder_to_client]`,values.send_reminder_to_client )
        formData.append(`contract[email]`,values.account_name )
        formData.append(`contract[description]`,values.description )
        
     return formData;
  }
  createContractTemplate = async (data:any) => {
    const formData=this.contractFormData(data)

    this.createContractTemplateApi=await this.projectApiCall({
      method: 'POST',
      endPoint: `/bx_block_projecttemplates/contracts`,
      body:formData,
      token:this.state.token
    })
  };
  handleEmailOpen = () => {
    this.setState({isTemplateOpen:true});
  };
  handleCancel = () => {
    this.setState({isTemplateOpen:false});
  };
  handleChange = (event:any, newValue:any) => {
    this.setState({ value: newValue });
  };

handleTaskOpen=()=>{
  this.setState({
    openProject: true,
  })
}
  createOk=()=>{
    this.setState({
      openProject: false,
    })
  }
  cancelBtn= async()=>{
    this.setState({
      openProject: false,
    })
  }
  showClientData = async () => {
    this.showDataAPI = await this.projectApiCall({
      method: "GET",
      endPoint: "/account_block/account_creations/people_list_by_filter?user_type=vincertax_employee",
      token: this.state.token,
    });
  };
  showTaskListData = async () => {
    this.showTaskListDataAPI = await this.projectApiCall({
      method: "GET",
      endPoint: "/bx_block_projecttemplates/project_tasks",
      token: this.state.token,
    });
  };
  showEmailListData=async() =>{
    this.showEmailListDataAPI=await this.projectApiCall({
      method: 'GET',
      endPoint: `/bx_block_projecttemplates/email_templates`,
      token:this.state.token
    })
  }
  projectApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const templateRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    templateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    templateRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    templateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      templateRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(templateRequestMessage.id, templateRequestMessage);
    return templateRequestMessage.messageId;
  };

}
// Customizable Area End