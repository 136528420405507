import React from 'react';
import { Typography, } from "@material-ui/core";

const TabPanel = ({ children, value, index, ...other }:any) => {
    return (
        <div
            role="tabpanel"
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className="tabs-panel"
            {...other}
        >
            {value === index && (
                <Typography>{children}</Typography>
            )}
        </div>
    );
};

export default TabPanel;
