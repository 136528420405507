import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, makeStyles, CircularProgress, OutlinedInput, Grid, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, TextField, Typography, } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Formik } from 'formik';
import * as Yup from "yup";


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            borderRadius: '8px',
            width: '600px',
            height:"346px",
            overflowY:"hidden",
        }
    },
    headingStyle: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    paraStyle:{
        textAlign: 'center',
    },
    btnWrapper:{
        justifyContent: 'end',
        padding: "24px 24px 24px 456px",
        borderTop: "1px solid #E2E6F0",
    
        '& button': {
            width: '120px',
            padding: "16px 41.5px 16px 39.5px",
            borderColor: '#E57727',
            borderRadius: '8px',
            fontWeight:700
        }
    },
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: '#E57727',
    },
      "text-transform": "none",
      "color": "white"
    },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 40px",
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    reasonsSection: {
        display:"flex",
        flexDirection:"column",
    },
    downloaIcon: {
        cursor: "pointer"
    },
    pushBackBtn: {
      border: '1px solid primary'
    },
    errorInputStyle:{
      border: '1px solid red'
    }
})

const initialValues={ fieldTitle: ""}

type AddFieldProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText?: string,
    btnOkText: string,
    handleOk?: any;
    handleCancel:any;
    // handleInsertValue?:any;
    // insertValue?:string[];
    // btnLoading?:boolean;
    // inputDisable?: boolean;
    // columnNames?: string[];
    // testIDProps?: string;
    subHeading?: string;
    showDialogBtns?: boolean;
    // pushBackOptions?:any;
    // textId?:string;
    // setSelectedPushBackTo?: any;
    showCrossBtn?: boolean;
    // stepValue?: any;
    // typeSelected?:any;
    // handleRadio?:any;
    // handleFindText?:any;
    // dialogFind?:any;'
    primaryColor:string;
    secondaryColor:string;
    passwordSchema?:any;
}




const AddFeild = (props:AddFieldProps) => {
    const classes = useStyles();
    
    const vaildate = Yup.object().shape ({
        
      });
      
  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                    <span className={classes.subHeading}>{props.subHeading}</span>
                </div>
               <Close
                    className={classes.downloaIcon}
                    onClick={() => {
                      props?.handleCancel()
                    }} 
                />
           </div>
        </DialogTitle>
        <Formik
                initialValues={initialValues}
                validationSchema={vaildate}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                // props.handleOk(values);
                props.handleCancel()
                actions.resetForm();
                }}
              >
                {({
                  handleSubmit,
                  touched,
                  handleBlur,
                  errors,
                  values,
                  handleChange,
                }) => (
                  <form style={{margin:0}} onSubmit={handleSubmit}>

       <DialogContent style={{ padding: "40px"}}>

          <Grid className={classes.reasonsSection} item xs={12}>
          
               {/* <LabelWithIcon label={'Reason'} /> */}
               <label style={{fontSize:"14px",color: "#64748B",fontWeight:700,marginBottom:"5px"}}>Feild title</label>
               <OutlinedInput
                    required
                    fullWidth
                    name="fieldTitle"
                    id="fieldTitle"
                    value={values.fieldTitle}
                    onChange={(e)=>{handleChange(e);

                    }}
                    onBlur={handleBlur}
                    placeholder="Eg. Clients Name"
                    error={touched.fieldTitle && Boolean(errors.fieldTitle)}
                />
                    {touched.fieldTitle && errors.fieldTitle && (
                    <Typography color="error" >{errors.fieldTitle}</Typography>
                    )}
                
          </Grid>

       </DialogContent>
        
    {/* <DialogActions className={classes.btnWrapper}> */}

<Grid className={classes.btnWrapper}>
<Button variant="contained"  className={classes.primaryBtnStyle} style={{background:props.primaryColor}} type='submit'>
                            {props.btnOkText}
                        </Button>
    </Grid>          
        
{/* 
        </DialogActions> */}
        </form>
                )}
        </Formik>

      </Dialog>
    </div>
  )
}

export default AddFeild

