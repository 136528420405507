// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {getThemeColor,getSecondaryThemeColor} from "../../../components/src/CommonPlatte";
import * as Yup from "yup";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes:any

}

interface S {
  weeklyUpdates:boolean;
  fullName:string;
  address:string;
  specialOffers:boolean;
  password:string;
  email:string;
  themePrimaryColor:string;
  themeSecondaryColor:string;
  passwordSchemaWeb:any;
  showDeleteDialog:boolean;
  showLogoutDialog:boolean;
  showChangeDialog:boolean;
  showEmailUpdateBox:boolean;
  alertMessage: string;
  showAlert:boolean,
  currentToken:string;
  userType:string
}

interface SS {
  id: any;

}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserDataApi:any;
  updateUserDataApi:any;
  deleteUserAccountApi:any

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage)
    
    ];
    let passwordSchemaWeb = {
      oldPassword: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(8, "Password must be at least 8 characters"),
      newPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],"password must Match"
          )
        })
    };
    this.state = {
      weeklyUpdates:false,
      fullName:"",
      address:"",
      specialOffers:false,
      password:"",
      email:"",
      themePrimaryColor:getThemeColor(),
      themeSecondaryColor:getSecondaryThemeColor(),
      currentToken:"",
      userType:"",
      showChangeDialog:false,
      alertMessage: "",
     showAlert:false,
     showEmailUpdateBox:false,
      showDeleteDialog:false,
      showLogoutDialog:false,
      passwordSchemaWeb:passwordSchemaWeb,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getUserDataApi &&
      this.getUserDataApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const userDetails = await responseJson.data.attributes;

        this.setState({
          fullName:userDetails.full_name,
          address:userDetails.address,
          weeklyUpdates:userDetails.weekely_update,
          specialOffers:userDetails.special_offer,
          password:userDetails.password,
          email:userDetails.email
        });
      }
    } 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateUserDataApi &&
      this.updateUserDataApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const userDetails = await responseJson.data.attributes;

        this.setState({
          fullName:userDetails.full_name,
          address:userDetails.address,
          weeklyUpdates:userDetails.weekely_update,
          specialOffers:userDetails.special_offer,
          password:userDetails.password,
          email:userDetails.email
        });
        
      }
      if(responseJson.meta.message === "email has changed successfully"){
       this.setState({showEmailUpdateBox:true})
      }else{
        this.setState({alertMessage:responseJson.meta.message,showAlert: true})
      }
    } 
      if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.deleteUserAccountApi &&
        this.deleteUserAccountApi ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );   
        if(responseJson){
            localStorage.clear();
            sessionStorage.clear();
            this.logoutUser();
        }
      }
  }

  async componentDidMount() {
    const userData=JSON.parse(localStorage.getItem("loginData") || "{}");
    const userToken= userData.token
    const userType= userData.user_type
    this.setState({currentToken:userToken,userType:userType})
    this.getUserData(userToken)
  } 
  async componentDidUpdate() {
    // this.setState({themePrimaryColor:getThemeColor()})
    if(this.state.showAlert){
      setTimeout(()=>{
      this.setState({showAlert:false})
    },5000)}
  } 

  handleWeeklyUpdates = () => {
    this.setState({ weeklyUpdates: !this.state.weeklyUpdates });
  };
  handleSpecialOffer = () => {
    this.setState({ specialOffers: !this.state.specialOffers });
  };
  getUserData= async(userToken:string)=>{
    this.getUserDataApi=await this.settingsApiCall({
      method: 'GET',
      endPoint: `/account_block/accounts/show`,
      token:userToken
    })
  }
  changeOK=(values:any)=>{
    this.setState({
      showChangeDialog: false,
    })
    console.log(values,"change Password");
    
    this.changePasswordApiCall(values)
  }
  changePasswordApiCall= async(values:any)=>{
    this.updateUserDataApi=await this.settingsApiCall({
      method: 'PUT',
      endPoint: `/account_block/accounts/account?old_password=${values.oldPassword}&new_password=${values.newPassword}`,
      token:this.state.currentToken
    })
  }
  keepOK=()=>{
    this.setState({
      showDeleteDialog: false,
    })
  }
  deleteOK= async()=>{
    this.setState({
      showDeleteDialog: false,
    })
    this.deleteUserAccountApi=await this.settingsApiCall({
      method: 'DELETE',
      endPoint: `/account_block/accounts/delete`,
      token:this.state.currentToken
    })
  }

  ChangePassword=()=>{
    this.setState({
      showChangeDialog: false,
    })
  }
  logoutOK=()=>{
    this.setState({
      showLogoutDialog: false,
    })
    localStorage.clear();
    sessionStorage.clear();
    this.logoutUser();
  }
  cancelLogout=()=>{
    this.setState({
      showLogoutDialog: false,
    })
  }
  logoutUser() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  closeUpdate=()=>{
    this.setState({
      showEmailUpdateBox: false,
    })
  }
  goToThemes=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationThemeMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToTemplates=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTemplatesMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  buttonClicked=(type:string)=>{
    const key = `show${type.charAt(0).toUpperCase()}${type.slice(1)}Dialog`;
    this.setState({
      ...this.state,
      [key]: true
    });
  }

  SettingFormSchema = () => {
    const schema = {
        fullName: Yup.string()
          .min(1, "Full name is too short")
          .max(20,"Full name is too long")
          .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Full name is invalid')
          .required("Full name is required."),
        
        email: Yup.string()
          .min(1,"Email is too short")
          .max(50,"Email is too long")
          .email().required("Email is required."),
          
        location: Yup.string()
          .min(1, "Location is too short")
          .max(20,"Location is too long")
          .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Location is invalid')
          .required("Location is required."),
      }
      return schema
}
formDataFormat = (values:any) => {
  const formData = new FormData();


      formData.append(`account[full_name]`,values.fullName )
      formData.append(`account[address]`,values.location )
      formData.append(`account[weekely_update]`,this.state.weeklyUpdates?"true":"false" )
      formData.append(`account[special_offer]`,this.state.specialOffers?"true":"false"  )
      if(this.state.email !== values.email){
      formData.append(`account[email]`,values.email )
      }
   return formData;
}

  updateUserData= async(values:any)=>{
    const formData=this.formDataFormat(values)

    this.updateUserDataApi=await this.settingsUpdateApiCall({
      method: 'PUT',
      endPoint: `/account_block/accounts/update`,
      body:formData,
      token:this.state.currentToken
    })
  }
  settingsUpdateApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const updatesettingApiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    updatesettingApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    updatesettingApiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    updatesettingApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    updatesettingApiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
        );
    runEngine.sendMessage(updatesettingApiRequestMessage.id, updatesettingApiRequestMessage);
    return updatesettingApiRequestMessage.messageId;
  };
  settingsApiCall = async (data: any) => {
      const { contentType, method, endPoint, body, token } = data;
      const header = {
        "Content-Type": contentType,
        token,
      };
      const settingApiRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      settingApiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      settingApiRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      settingApiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      body &&
      settingApiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
          );
      runEngine.sendMessage(settingApiRequestMessage.id, settingApiRequestMessage);
      return settingApiRequestMessage.messageId;
    };
}
// Customizable Area End