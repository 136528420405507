// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

interface CardData {
  id: string;
  last4Digits: string;
}
import * as Yup from "yup";


export const configJSON = require("./config");



export interface Props {
  navigation: object;
  id: string;
  classes?:any;
}
interface S {
  loading: boolean;
  
  taxRate:number;
  dueDate:boolean;
  token:string;
  recurring:boolean;
  openInvoiceForm:boolean;
  paymentToggle:boolean;
  discountRate:number;
  cardsData:CardData[];
  InvoiceTemplateList:object[];
  serviceList:string;
  teamEmails:string,
  TypeSelect:boolean;
  alertMessage:string,
  showAlert:boolean;
  manualValues:any;
  showLoader:boolean;
  selectedInvoiceId:string;
  payOnline:boolean;
  addCard:boolean;
  anchorEl:any;
  showInvoiceData:any;
  openManualForm:boolean;
}

interface SS {
  id: string | number;
}

export default class InvoiceDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  showInvoiceApi:string="";
  getServiceListAPI:string="";
   

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
  
      getName(MessageEnum.NavigationInvoiceDetailsMessage)
  
    ];

    this.state = {
  
      loading: false,
      payOnline:false,
      InvoiceTemplateList:[
        {
          value: 0,
          label: "Select",
          labelValue: ""
      },
       ],
       manualValues:"",
      addCard:false,
      anchorEl:null,
      openInvoiceForm:false,
      token:Parsed?.token,
      dueDate:false,
      recurring:false,
      paymentToggle:false,
      taxRate:12,
      alertMessage:"",
      showAlert:false,
      showLoader:false,
      serviceList:"",
      teamEmails:"",
      TypeSelect:false,
      discountRate:0,
      selectedInvoiceId:"",
      openManualForm:false,
      cardsData:[{id:"0",last4Digits:"8369"},{id:"1",last4Digits:"7564"},
    ],
      showInvoiceData:{
            "id": "15",
            "type": "invoice",
            "attributes": {
                "account_data": "vincertax_employee2@yopmail.com",
                "invoice_date": "2024-01-01",
                "payment_method": "card",
                "total_amount": 616.0,
                "currency": "usd",
                "team_member": "",
                "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took ",
                "pay_invoice": false,
                "message_for_client": "this is message for user2 to fill the invoice.",
                "recurring": false,
                "invoice_number": "1FD41A00-0012",
                "stripe_invoice_id": "in_1OhuFFSHtHMGSAFfZVgoAxtk",
                "client_id": 5,
                "tax": 12,
                "status": "open",
                "offline_payment": false,
                "promotinal_credit": false,
                "send_email_notification_to_client": false,
                "client_name": "raj test",
                "invoice_pdf": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBFUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--329b3ed17d7eb377984d76f74175fa113cb16833/1FD41A00-0012_Invoice.pdf",
                "due_date": "2024-01-01",
                "invoice_items": [
                    {
                        "id": 25,
                        "item_id": null,
                        "invoice_id": 15,
                        "created_at": "2024-02-09T13:35:57.890Z",
                        "updated_at": "2024-02-09T13:35:57.890Z",
                        "price": 300.0,
                        "stripe_id": "cus_PWx9gwA4RMqizX",
                        "stripe_price_id": "price_1OhtEzSHtHMGSAFfMKVyIdwC",
                        "stripe_invoice_id": "in_1OhuFFSHtHMGSAFfZVgoAxtk",
                        "name": "Vinceretax Default price 5"
                    },
                    {
                        "id": 26,
                        "item_id": null,
                        "invoice_id": 15,
                        "created_at": "2024-02-09T13:35:58.230Z",
                        "updated_at": "2024-02-09T13:35:58.230Z",
                        "price": 250.0,
                        "stripe_id": "cus_PWx9gwA4RMqizX",
                        "stripe_price_id": "price_1OhtFCSHtHMGSAFfVsqd77mb",
                        "stripe_invoice_id": "in_1OhuFFSHtHMGSAFfZVgoAxtk",
                        "name": "Vinceretax Default price 6"
                    }
                ]
            }
        },
  
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }


async receive(from: string, message: Message) {
  runEngine.debugLog("Message Recived", message);
  
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    runEngine.debugLog("API Message Recived", message);


      if(apiRequestCallId === this.showInvoiceApi){
        this.setState({alertMessage:responseJson.data ? responseJson.messages:responseJson.meta.errors,showAlert: true})
        this.setState({showInvoiceData:responseJson.data})
      }

    if(apiRequestCallId === this.getServiceListAPI){
      const newArray = responseJson.data.map((obj:any, index:any) => {
        const name = obj.attributes.name;
    
        return {
            value: index,
            label: name,
            labelValue: name
        };
    });

    this.setState({ serviceList: newArray });
    }
  }
      if (getName(MessageEnum.NavigationInvoiceDetailsMessage) === message.id) {
      const params = message.getData(
        getName(MessageEnum.NavigationInvoiceDetailsMessage)
      );
          this.showInvoice(params)          
    }
   
}


  async componentDidMount(){
    this.getServiceList()
    const invoiceId= localStorage.getItem("invoiceId");
    let Parsed = invoiceId && JSON.parse(invoiceId);
    this.showInvoice(Parsed)
  }

  handleManualForm=()=>{
    this.setState({openManualForm:!this.state.openManualForm,anchorEl:null})
  }
  handleType=()=>{
    this.setState({TypeSelect:!this.state.TypeSelect})
  }
  handleOpen=(event:React.MouseEvent<HTMLButtonElement>)=>{
    this.setState({anchorEl:event.currentTarget})
  }
  handleClose=()=>{
    this.setState({anchorEl:null})
  }
  handleCreditPay=()=>{
    this.setState({payOnline:!this.state.payOnline,anchorEl:null})
  }
  addCard=()=>{
    this.setState({addCard:!this.state.addCard})
  }
  handleTaxChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({taxRate:event.target.value as number});
  };
  handleDiscountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({discountRate:event.target.value as number});
  };
  handleChangeToggle=()=>{
    this.setState({paymentToggle:!this.state.paymentToggle})
  }
  handleChangeRecurring=()=>{
    this.setState({recurring:!this.state.recurring})
  }
  handleChangeDuedate=()=>{
    this.setState({dueDate:!this.state.dueDate})
  }

  showInvoice = async (id:any) => {
    this.showInvoiceApi=await this.invoiceDetailsApi({
      method: 'GET',
      endPoint: `/bx_block_invoice/invoice/${id}`,
      token:this.state.token
    })
  };

  cardDetailsSchema = () => {
    const schema = {
        cardNumber: Yup.string()
        .matches(/^\d{4} \d{4} \d{4} \d{4}$/, 'Invalid card number')
        .required('Card number is required.'),
        date: Yup.string()
        .matches(/^(0[1-9]|1[0-2])\/((2[2-9])|([3-9]\d))$/,"Invalid date")
        .required('Date is required.'),
        cvv: Yup.string()
        .matches(/^\d*$/, 'Invalid cvv')
        .required('Cvv is required.'),
        nameOnCard: Yup.string()
        .min(1, "Full name is too short")
        .max(30,"Full name is too long")
        .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Full name is invalid')
        .required("Full name is required."),
    };
    return schema;
};

  getServiceList = async () => {
    this.getServiceListAPI = await this.invoiceDetailsApi({
      method: "GET",
      endPoint: "/bx_block_catalogue/catalogues",
      token: this.state.token,
    });
  };
  manualPayment=(values:any)=>{
    this.setState({manualValues:values})
  }

  invoiceDetailsApi = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const invoicepaymentRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    invoicepaymentRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    invoicepaymentRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    invoicepaymentRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      invoicepaymentRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(invoicepaymentRequestMessage.id, invoicepaymentRequestMessage);
    return invoicepaymentRequestMessage.messageId;
  };

}
// Customizable Area End