// Customizable Area Start
import React,{ChangeEvent} from "react";

import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,

} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { Close} from "@material-ui/icons";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import XmlcsvtemplatebuilderController, {
  Props,
} from "./XmlcsvtemplatebuilderController";

const enumXmlCsv={
    XMLCHECK:"xml",
    CSVCHECK:"csv"
}
const StyleDialog = styled(Dialog)({
  '& [role="dialog"]': {
    padding: '30px',
    borderRadius: '16px',
    width: '300px',
  },
});
const StyledButton = styled(Button)({
  '&.primaryBtnStyle': {
    background: "#25FA1",
    textTransform: "none",
    color: "white",
    borderRadius: "8px",
  },
  '&.disableBtns': {
    color: "#fff",
    borderRadius: '8px',
    background: '#757575',
  },
  "&:hover":{
    backgroundColor:"#25FA1"
  }
});
const StyleDialogActions = styled(DialogActions)({
  justifyContent: 'space-around',
  '& button': {
    padding: '13px 0px',
    width: '100px',
    borderColor: '#3b8da2',
    textTransform:"capitalize" as const,
    borderRadius: '15px',
  },
});
export default class Columndialogbox extends XmlcsvtemplatebuilderController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    
  
return (
    <div>
        
      <StyleDialog
        data-test-Id="DialogMain"
        open={this.props.openDialog}
        onClose={()=>this.props.handleCancel("backdropClick")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
      >
        
        <DialogTitle id="alert-dialog-title">
           <div style={useStyles.dialogTitle}>
           {!this.state.next ? (<div>
                    <span style={useStyles.headingStyle}>{this.props.headingText}</span>
                    <span style={useStyles.subHeading}>{this.props.subHeading}</span>
                </div>):(<div>
                    <span style={useStyles.headingStyle}>Enter columns name</span>
                    <span style={useStyles.subHeading}>{this.props.subHeading}</span>
                </div>) }
                 <Close
                    style={useStyles.downloaIcon}
                    id="closeBtn"
                    onClick={() => {
                        this.props.handleCancel('backdropClick');
                        this.reset()
                    }} 
                />
           </div>
        </DialogTitle>
        {!this.state.next && <DialogContent>
          
          <Grid style={useStyles.reasonsSection} item xs={12}>
               <label style={{marginBottom:"7px"}}>Enter the number of columns</label>
               <OutlinedInput
                   style={useStyles.textInputStyle}
                   id="txtInput"
                   value={this.state.dialogReason ==0 ? "" :this.state.dialogReason}
                   type="number"
                   placeholder='Enter number of columns'
                   fullWidth
                   color='primary'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const inputValue = event.target.value;
                    this.handleChangeReason(inputValue);
                  }}
               />
          </Grid>

          <Grid>
           <label>Select the type of file:-</label>
          <FormControl>
               <RadioGroup row aria-labelledby="demo-row-radiobutton-group-label" name={"typeSlected"}
                   defaultValue={this.props.typeSelected ? enumXmlCsv.XMLCHECK : enumXmlCsv.CSVCHECK}
                   value={this.props.typeSelected ? enumXmlCsv.XMLCHECK : enumXmlCsv.CSVCHECK}
                   onChange={(event:ChangeEvent<HTMLInputElement>) => {this.props.handleRadio?.(event.target.value);
                }}
               >
                   <FormControlLabel checked={this.props.typeSelected === true } value={enumXmlCsv.XMLCHECK} control={<Radio color="primary" />} label="XML" />
                   <FormControlLabel checked={this.props.typeSelected === false } value={enumXmlCsv.CSVCHECK} control={<Radio color="primary" />} label="CSV" />
               </RadioGroup>
           </FormControl>
          </Grid>
       
       </DialogContent>}

       {this.state.next && <DialogContent>
          
          <Grid id="renderInput" style={useStyles.reasonsSection} item xs={12}>
        {Array.from({ length: this.state.dialogReason }, (values, index) => (
          <div key={index} >
            <label style={{ fontSize: "16px", fontWeight: 700 }}>Column {index + 1} name</label>
            <OutlinedInput
              id={`input-${index}`}
              value={this.props.inputValue ? this.props.inputValue[index] : ''}
              style={{
                marginTop: "10px",
                marginBottom: "20px"
              }}
              type="text"
              fullWidth
              placeholder={`Enter column ${index + 1} name`}
              color='primary'
              onChange={(event:ChangeEvent<HTMLInputElement>) => {
                this.props.handleInputChange?.(event, index);
              }}
            />
          </div>
        ))}
          </Grid>       
       </DialogContent>} 
        
        {!this.state.next && <StyleDialogActions>
          <Button variant="outlined" id="backbtnClicked" style={{color: '#757575', borderRadius: "8px", textTransform:"capitalize"}} 
          onClick={() => {
            this.props.handleCancel('backdropClick')
            this.reset()
          }} 
          >
            {this.props.btnCancelText}
          </Button>
          
          <StyledButton variant="contained" 
            id="nextClicked"
            disabled={this.state.dialogReason == 0} 
            style={{borderRadius: "8px",textTransform:"capitalize"}} 
            className={this.state.dialogReason !== 0 ? "primaryBtnStyle":"disableBtn"}
            onClick={() => {
              this.onNext();
            }} 
            color="primary" 
            autoFocus
          >
            next
          </StyledButton>
        </StyleDialogActions>}
        {this.state.next && <StyleDialogActions>
          <Button variant="outlined" id="nextbtnClicked" style={{color: '#757575', borderRadius: "8px", textTransform:"capitalize"}} 
          onClick={() => {
            this.onNext();
          }} 
          >
            back
          </Button>
          
          <StyledButton variant="contained" 
          id="submitbtnClicked"
            disabled={ this.props.inputValue?.length == 0} 
            style={{borderRadius: "8px",textTransform:"capitalize"}} 
            className={(this.state.dialogReason !== 0) ? "primaryBtnStyle":"disableBtn"}
            onClick={() => {
              this.props.handleOk()
              this.reset()
            }} 
            color="primary" 
            autoFocus
          >
            Submit
          </StyledButton>
        </StyleDialogActions>}
      </StyleDialog>
    </div>
  )
 }
}

const useStyles = {
    headingStyle: {
      '& h2': {
          fontWeight: 700,
          
      },
      fontSize: "16px"
  },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    reasonsSection: {
        margin: '10px 0'
    },
    downloaIcon: {
        cursor: "pointer"
    },
    pushBackBtn: {
      border: '1px solid primary'
    }
}

  // Customizable Area End