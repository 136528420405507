import React, { Component, useRef } from 'react';
import { Paper, Button } from '@material-ui/core';
/*@ts-ignore*/
// import Carousel from 'react-material-ui-carousel';
import Carousel from 'react-elastic-carousel';
// import './YourCarouselStyles.css'; // You can create this file for custom styles
type MyProps = { item:any };



export default class MyCarousel extends Component<MyProps> {
    
    constructor(props: any) {
        super(props);
    
        this.state = {
          item: this.props.item
        };
      }
    render() {
        const items=this.props.item;



      return (
        <Carousel pagination={false}  isRTL={true} autoPlaySpeed={4000} enableAutoPlay>
          {items.map((item:any, index:any) => (
            // <Paper key={index} style={useStyles.carouselItem}>
              <img src={item.image} alt={item.name} style={useStyles.carouselImage} />
            //   <h2>{item.name}</h2>
            //   <p>{item.description}</p>
            // </Paper>

          ))}
        </Carousel>
      );
    }
  }

  const useStyles = ({
    carouselItem: {
        display: "flex",
        // flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: 'cover',
        backgroundPosition: 'center', // Adjust the image position
        height: '100%', // Ensure the image fills the slide
        width: '100%',
        padding: "20px",
        // textAlign: "center",
        heigth:"500px",
        background: 'var(--light, #F6F2EF)',
      },
      
      carouselImage: {
        maxWidth: "100%",
        height: "100%",
      }
      
});
  