
import React from "react";

// Customizable Area Start
import {
  // Customizable Area Start
  Container,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area End

import ProjectTemplatesController, {
  Props,
} from "./ProjectTemplatesController.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


export default class TaskTemplate extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={"xl"}>
  <div style={{display:"grid",gap:"30px"}}>
  <div style={{display:"flex",gap:"10px"}}>
  <Button id="openTaskTemplate" style={taskStyle.createBtn} onClick={() => this.props.handleOpen()}>Create Template</Button>
  <Button style={taskStyle.copyBtn}>Copy from liberary</Button>

  </div>

<Table>
      <TableHead>
        <TableRow
          style={{
            height: "2.43rem",
            borderRadius: " 0.375rem",
            border: "1px solid #CBD5E1",
          }}
        >
          <TableCell />
          <TableCell
            // style={webStyle.tabelCell}
          >
            Projects Name
          </TableCell>
        </TableRow>
      </TableHead>
      {/* <br /> */}
      {this.props.List !== undefined ? (
        <TableBody>
          {this.props.List.map((item: any, index: number) => (
            <React.Fragment key={item?.id}>
              <TableRow
                style={{
                  borderRadius: "0.375rem",
                  border: "1px solid #CBD5E1",
                  padding: "0.625rem",
                  height: "1.5rem",
                }}
                key={item?.id}
              >
                <TableCell style={{
                    width: "4%",
                    padding:"0px 10px "
                  }}>
          <IconButton aria-label="expand row" size="small" onClick={() => this.OpenDropDown()}>
            {this.state.openDropDown ? <KeyboardArrowDownIcon />:<KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
                <TableCell
                  data-test-id={`project-${index}`}
                  style={{
                    width: "96%",
                    padding:"10px",
                    color: "#396178",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  {item.attributes.template_name}
                </TableCell>
               
              </TableRow>

              {/* <div style={{ marginBottom: "0.8rem" }}></div> */}
              
            </React.Fragment>
          ))}
        </TableBody>
      ) : (
        <div
          style={{
            fontSize: "18px",
            fontWeight: 700,
            color: "#e87424",
          }}
        >
          Project not found
        </div>
      )}
    </Table>
</div>
      </Container>
      // Customizable Area End
    );
  }
}
const taskStyle={
  createBtn:{display: "flex",
  width: "210px",
  height: "50px",
  padding: "10px 16px",
  justifyContent: "center",
  textTransform:"none" as const,
  borderRadius: "50px",
  background: "#396178",
  alignItems: "center",
  color: "#FFF",
  textAlign: "center" as const,
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
  gap: "8px",
  flexShrink: 0,},
  copyBtn:{display: "flex",
  width: "210px",
  height: "50px",
  padding: "10px 16px",
  justifyContent: "center",
  textTransform:"none" as const,
  borderRadius: "50px",
  border: "1px solid #396178",
  alignItems: "center",
  color: "#396178",
  textAlign: "center" as const,
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
  gap: "8px",
  flexShrink: 0,}
}
// Customizable Area End