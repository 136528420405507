import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ModelContext } from "../../../components/src/Context";
import {getThemeColor, getSecondaryThemeColor} from "../../../components/src/CommonPlatte"

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  open: boolean,
  opensecond: boolean,
  model: boolean,
  openthird: boolean
  data: any,
  openStates: any,
  selectedAges: any,
  isDrawerOpen: boolean,
  project_id: any;
  task: any;
  catelogId: any;
  workspaceId: any;
  groupData: any;
  primaryColor:string;
  secondaryColor:string;
  projectOpenStates: any;
  anchorEl: any,
  selectedItems: any;
  objectByName: any;
  group_id: any;
  Task_Id: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectnotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType: React.Context<any | undefined> = ModelContext;
  getshowGroupApiCallId: string = "";
  showSubTaskApiCallId: any;
  createGroupAPI: string = "";
  createColumnAPI: string = "";
  getColumnApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationProjectnotesMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open: false,
      opensecond: false,
      model: false,
      openthird: false,
      openStates: [],
      selectedAges: [],
      data: [],
      primaryColor:getThemeColor(),
      secondaryColor:getSecondaryThemeColor(),
      isDrawerOpen: false,
      project_id: 0,
      task: [],
      catelogId: 0,
      workspaceId: 0,
      groupData: [],
      projectOpenStates: [],
      selectedItems: [],
      anchorEl: null,
      objectByName: {},
      group_id: "",
      Task_Id: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
     
      let responsejson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.createGroupAPI) {
        this.createGroupAPISQ(responsejson);
      }

      if (apiRequestCallId === this.getshowGroupApiCallId) {
        this.getshowGroupApiCallIdSQ(responsejson);
      }
      if (apiRequestCallId === this.getColumnApiCallId) {
        this.getColumnApiCallIdSQ(responsejson);
      }
      if (apiRequestCallId === this.createColumnAPI) {
        this.createColumnAPISQ(responsejson);
      }



      this.parseApiCatchErrorResponse(errorReponse);
    }
    if (getName(MessageEnum.NavigationProjectnotesMessage) === message.id) {
      const params = message.getData(getName(MessageEnum.NavigationProjectnotesMessage));
      this.setState({ workspaceId: params.workspaces, catelogId: params.workspace_catalogs, group_id: params.projects }, () => { this.showGroup() });
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  createGroupAPISQ = (responsejson: any) => {
    const { closeModel } = this.context;
    if (responsejson && responsejson.data !== null && responsejson.data !== undefined) {
      this.showGroup();
      closeModel("model3");
    }
  }

  getshowGroupApiCallIdSQ = (responsejson:any) => {
    if (responsejson && responsejson.data !== null && responsejson.data !== undefined) {
      this.setState({ groupData: responsejson?.data },
        () => {
          const openStates = Array(this.state.groupData.length).fill(false);
          const projectOpenStates = this.state.groupData.map((group: any) => {
            if (group.attributes.groups.projects && group.attributes.groups.projects > 0) {
              return Array(group.attributes.groups.projects.length).fill(false);
            } else { return [] }
          });
          this.setState({ openStates, projectOpenStates });
        }
      )
    }
  }
  getColumnApiCallIdSQ = (responsejson: any) => {
    const objectByName: any = {};
    responsejson && responsejson.data && responsejson.data.forEach((item: any) => {
      objectByName[item.attributes.name] = item.attributes.name;
    });
    this.setState({ objectByName });
  }

  rowData = {
    title: "Need to complete",
    subItems: 23,
    paymentStatus: 50,
    date: "Your Date",
    companyName: "Your Company Name",
    team: "Your Team",
    status: 50,
  };

  toggleGroupCollapse = (index: any) => {
    this.setState((prevState) => {
      const newOpenStates = [...prevState.openStates];
      newOpenStates[index] = !newOpenStates[index];
      return { openStates: newOpenStates };
    });
  };

  toggleProjectCollapse = (groupIndex: any, projectIndex: any) => {
    this.setState((prevState) => {
      const newProjectOpenStates = [...prevState.projectOpenStates];
      newProjectOpenStates[groupIndex][projectIndex] = !newProjectOpenStates[groupIndex][projectIndex];
      return { projectOpenStates: newProjectOpenStates };
    });
  };

  handleDrawer = (task_id: any , projectId:any ,name:any) => {
    if(name){ localStorage.setItem('projectName',name); }
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen , Task_Id:task_id , project_id:projectId});
  };

  gotoWorkspacePage = (project_id: any, sign: any, group_id: any = null) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CreateprojectPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationCreateProjectMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationCreateProjectMessage),
      {
        workspaces: this.state.workspaceId,
        workspace_catalogs: this.state.catelogId,
        projects: project_id,
        toggleForm: sign,
        group_id: group_id
      }
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  token: string = "";
  showGroup = async () => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.getshowGroupApiCallId = await this.getAccountapiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: `bx_block_profile/workspaces/${this.state.workspaceId}/workspace_catalogs/${this.state.catelogId}/groups`,
      token: this.token,
    });
  };
  getAccountapiCall = async (data: any) => {
    const { methods, endPoint, token, body } = data;
    const Header = {
      "Content-Type": "application/json",
      token,
    };
    const apiRequestMessageforProjectnotes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforProjectnotes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforProjectnotes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    body &&
    apiRequestMessageforProjectnotes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    apiRequestMessageforProjectnotes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    runEngine.sendMessage(
      apiRequestMessageforProjectnotes.id,
      apiRequestMessageforProjectnotes
    );
    return apiRequestMessageforProjectnotes.messageId;
  }

  createGroup = async (value: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;


    this.createGroupAPI = await this.getAccountapiCall({
      contentType: 'application/json',
      methods: "POST",
      endPoint: `bx_block_profile/workspaces/${this.state.workspaceId}/workspace_catalogs/${this.state.catelogId}/groups`,
      token: this.token,
      body: {
        "group": {
          "name": value.group_name,
          "color": value.primary_color
        }
      }
    })
  }

  openPopover = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  columnItemSelection = (item: any) => {
    const { selectedItems } = this.state;
    const selectedIndex = selectedItems.indexOf(item);
    const newSelected = [...selectedItems];

    if (selectedIndex === -1) {
      newSelected.push(item);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    this.setState({ selectedItems: newSelected });
  }

  createColumn = async () => {
    const { selectedItems } = this.state;
    const selectedData = selectedItems.map((item: any) => item.toLowerCase());
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.createColumnAPI = await this.getAccountapiCall({
      contentType: 'application/json',
      methods: "POST",
      endPoint: `bx_block_profile/custom_columns`,
      token: this.token,
      body: { "custom_columns": { "name": selectedData } }
    })
  }

  createColumnAPISQ = (responsejson: any) => {
    if (responsejson && responsejson.data !== null && responsejson.data !== undefined) {
      this.getColumn();
    }
  }

  getColumn = async () => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.getColumnApiCallId = await this.getAccountapiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: "/bx_block_profile/custom_columns",
      token: this.token,
    });
  }

  // Customizable Area End
}
