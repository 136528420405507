// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  List,
  ListItem, 
  ListItemText, 
} from "@material-ui/core";
import { AddCircleOutline } from '@material-ui/icons';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

import ImportExportDataController, {
  Props,
} from "./ImportExportDataController.web";
import ImportDialog from "../../../components/src/ImportMapping";
import FileUploader from "../../../components/src/ImportCsvXml";
import ExportDialog from "../../../components/src/ExportFile";
import { imgExport, imgImport } from "./assets";

export class ImportExport extends ImportExportDataController {
  constructor(props: Props) {
    super(props);

  }


  render() {
    const {classes} = this.props

    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"}>
        <Box style={{ display: "flex", flexDirection: "column", 
                    width: "calc(100vw - 135px)", position: 'fixed', top: "0px", right: "0px", alignItems:"flex-start" ,background: "#F1F4F9",
                    height:"100vh"}}>
                        <Box style={{
                            "display": "flex",background:"#fff", "height": "32px", "padding": "20px 1154px 20px 48px", "justifyContent": "space-between",
                            "alignItems": "center", "flexShrink": 0, "borderBottom": "1px solid #CBD5E1", width: "calc(100vw - 193px)", position: "sticky"
                        }}>

                          <Typography key="3" className={classes.redirectStyle} style={{color:"#0F172A",textTransform:"none",}}>
                                  Documents
                                </Typography>
                            <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "gap": "12px", height: "44px", paddingRight: "48px" }}>
                            
                            <Button 
                                onClick={this.openImortData}
                                  data-test-id="importCsv" style={{
                                    "display": "flex", "height": "2.75rem", "padding": "10px 8px", "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
                                    "borderRadius": ".5rem", "background": "#FFF"

                                }}><img src={imgImport} style={{marginRight:"8px",width:"24px",height:"24px"}}/>
                                  <Typography style={{ "color": "#E57727", "fontFamily": "Inter", "fontSize": "1rem", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "1.5rem", textTransform: "none", }
                                } >Import</Typography></Button>


                              <Button 
                                onClick={this.openExportData}
                                  data-test-id="exportCsv" style={{
                                    "display": "flex", "height": "2.75rem", "padding": "10px 20px 10px 8px", "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
                                    "borderRadius": ".5rem", "background": "#FFF"

                                }}><img src={imgExport} style={{marginRight:"8px"}}/>
                                  <Typography style={{ "color": "#E57727", "fontFamily": "Inter", "fontSize": "1rem", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "1.5rem", textTransform: "none", }
                                } >Export</Typography></Button>


                              <Button 
                                  data-test-id="searchbtn" style={{
                                    "display": "flex", "height": "2.75rem", "padding": ".625rem 1rem", "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
                                    "borderRadius": ".5rem", "background": "#E57727",

                                }}> <AddCircleOutline style={{color:"#FFF",marginRight:"8px"}}/>
                                  <Typography style={{ "color": "#FFF", "fontFamily": "Inter", "fontSize": "1rem", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "1.5rem", textTransform: "none", }
                                } >Create file</Typography></Button>


                            </div>

                        </Box>
         
          <Box sx={webStyle.mainWrapper}>
          <div style={{display:"flex",gap:"15px",flexDirection:"column",background:"#F1F4F9",paddingBottom:"20px"}}>
          <Typography style={{fontSize: "24px",
          fontWeight: 700,
          fontFamily: "Inter",}}>Import files</Typography>
        <Typography component="div">
          Files must be formatted as a .CSV or .XML file.
        </Typography>
          </div>
          <div style={{ width: "calc(100vw - 233px)", padding: '20px' }}>
        <List>
          <ListItem>
            <ListItemText className={classes.listItemText}>
            <Typography className={classes.indexStyle}>1</Typography>
            <Typography>Import CSV or XML file</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.listItemText}>
            <Typography className={classes.indexStyle}>2</Typography>
            <Typography>Copy and paste your client details into the spreadsheet</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.listItemText}>
            <Typography className={classes.indexStyle}>3</Typography>
            <Typography>Remove any categories you don't require</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.listItemText}>
            <Typography className={classes.indexStyle}>4</Typography>
            <Typography>Save and re-upload your file</Typography>
            </ListItemText>
          </ListItem>
        </List>
        <div>
          
        <input
          type="file"
          onChange={this.handleFileChange}
          style={{ display: 'none' }}
          id="fileInput"
          accept=".csv, text/csv, .xml, application/xml, text/xml"
        />

      <label
        htmlFor="fileInput"
        data-test-id="upload"
        tabIndex={0}
        onKeyPress={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            const fileInput = document.getElementById('fileInput');
            if (fileInput) fileInput.click();
          }
        }}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
        style={{
          display: 'flex', 
          border: '2px dashed #5678B3',
          padding: '20px',
          cursor: 'pointer',
          width:"95%",
          height:"100px",
          alignItems:"center",
          justifyContent:"center",
          borderRadius: '8px', 
        }}
        aria-label="Drag and drop file upload area"
      >
        {this.state.selectedFile ? <p>{this.state.selectedFile.name}</p> : <Typography style={{color:"#5678B3",fontSize:"16px"}}>Click here or drag to upload</Typography>}
      </label>
    </div>
      </div>
          </Box>
          <ImportDialog
            openDialog={this.state.mapping}
            headingText= "Check before importing"
            handleCancel={this.handleMapping}
          />
                <FileUploader onFileUpload={this.handleImportFileUpload} 
                accept=".csv, application/vnd.ms-excel, text/csv, application/xml, text/xml"
                openDialog={this.state.importData}
                headingText="Import Data"
                handleCancel={this.openImortData}
                />
                <ExportDialog
                 openDialog={this.state.exportData}
                 headingText="Export data"
                 handleCancel={this.openExportData}
                 handleExportAs={this.handleExportAs}
                 handleExportPage={this.handleExportPage}
                 exportAsType={this.state.exportAsType}
                 exportPageType={this.state.exportPageType}
                 
                />
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column" as const,
    marginTop:"30px",
    marginLeft:"30px",
    background: "#fff",
    borderRadius:"10px"
  },
  listItemText:{"& .MuiTypography-displayBlock":{display:"flex"}},
  buttonStyle: {
    marginTop: "30px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  redirectStyle:{
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px"
  },
  indexStyle:{
    marginRight: "10px",
    width: "25px",
    border: "1px solid gray",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: "5px",
  }
};

export default withStyles(webStyle) (ImportExport)
// Customizable Area End
