// Customizable Area Start
import React from "react";
import * as Yup from "yup";
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Typography, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import { Close } from "@material-ui/icons";
const initialValues = { termCondition: false }

import TermsConditionsController, {
  Props,
} from "./TermsConditionsController.web";

export class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props?.openDialog}
          onClose={this.props?.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          disableBackdropClick={true}
          className={classes.dialogWrapper}
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
            <div className={classes.dialogTitle}>
              <div>
                <span className={classes.headingStyles}>{this.props?.headingText}</span>
              </div>
              <Close
                className={classes.downloaIcon}
                onClick={() => {
                  this.props?.handleCancel()
                }}
              />
            </div>
          </DialogTitle>
          <Formik
            data-test-id="TermsConditionForm"
            initialValues={initialValues}
            validationSchema={Yup.object().shape(this.TermsSchema())}
            validateOnChange={false}
            validateOnMount={true}
            validateOnBlur={true}
            onSubmit={(values, actions) => {
              this.props.handleOk();
              this.handleCheckbox()
            }}
          >
            {({
              handleSubmit,
              touched,
              errors,
              values,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent style={{ padding: "40px" }}>

                  <Grid className={classes.reasonsSection} item xs={12}>
                    <Typography className={classes.para}>
                    <div className={classes.paragraph} dangerouslySetInnerHTML={{ __html: this.props.txtTermsData || "" }} />
                    </Typography>

                    <div style={compStyles.termsStyle}>
                      <FormControlLabel
                        color="primary"
                        labelPlacement="end"
                        data-testId="termsCheckbox"
                        style={{ alignItems: "flex-start" }}
                        control={<Checkbox color="primary" style={{ padding: "0px 9px" }} onChange={handleChange("termCondition")} />}
                        label={<label className={classes.termsgetDetails}>I have read and agree to these Terms and Conditions</label>}
                      />
                      {touched.termCondition && errors.termCondition && (
                        <Typography color="error" className={classes.ErrorStyle} >{errors.termCondition}</Typography>
                      )}
                    </div>

                  </Grid>
                </DialogContent>

                <Grid className={classes.buttonWrapper}>
                  <Button variant="outlined"
                    className={classes.cancelBtn}
                    style={{ textTransform: "none" }}
                    onClick={() => { this.props.handleCancel() }}
                  >
                    {this.props.btnCancelText}
                  </Button>

                  <Button variant="contained"
                    data-test-id="btnSubmit"
                    className={classes.primaryStyle}
                    type='submit'>
                    {this.props.btnOkText}
                  </Button>
                </Grid>
              </form>
            )}
          </Formik>

        </Dialog>
      </div>
    );


  }
}

const compStyles = {
  dialogWrapper: {
    "& [role='dialog']": {
      // gap: '40px',
      borderRadius: '8px',
      width: '600px'
    }
  },
  headingStyles: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  para: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  ErrorStyle: {
    color: "#DC2626",
    marginTop:"6px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: 'end',
    gap: "15px",
    padding: "24px 24px 24px 320px",
    borderTop: "1px solid #E2E6F0",

    '& button': {
      padding: '16px 20px',
      width: '182px',
      borderColor: '#E57727',
      fontWeight: 700,
      borderRadius: '8px',
    }
  },
  termsgetDetails: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  primaryStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    "text-transform": "none",
    "color": "white",
    cursor: "pointer"
  },
  termsStyle: {
    marginRight: "15px",
    // marginTop: "20px"
  },
  cancelBtn:{
    border:"none",
    color:"#E57727",
    background: "#F6F2EF",
  },
  paragraph: {
    '& p:first-child': {
      margin: 0,
    },
    '& p:nth-child(2)': {
      marginTop: "20px",
      marginBottom: "20px",
    },
    '& p:last-child': {
      margin: 0,
    },
  },
  dialogTitle: {
    // textAlign: "left",
    fontSize: "16px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 16px 24px 40px",
  },
  reasonsSection: {
    // marginBottom: '25px !important',
    display: "flex",
    flexDirection: "column" as const,
    gap: "32px"
  },
  downloaIcon: {
    cursor: "pointer"
  },
}
export default withStyles(compStyles)(TermsConditions)
// Customizable Area End