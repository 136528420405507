import React, { createContext, useState, ReactNode } from 'react';

interface ModelContextProps {
  modelStates: { [key: string]: boolean };
  openModel: (modelName: string) => void;
  closeModel: (modelName: string) => void;
}

const ModelContext = createContext<ModelContextProps | undefined>(undefined);

interface ModelProviderProps {
  children: ReactNode;
}

const ModelProvider: React.FC<ModelProviderProps> = ({ children }) => {
  const [modelStates, setModelStates] = useState<{ [key: string]: boolean }>({
    model1: false,
    model2: false,
    model3: false,
  });

  const openModel = (modelName: string) => {
    setModelStates((prevStates) => ({
      ...prevStates,
      [modelName]: true,
    }));
  };

  const closeModel = (modelName: string) => {
    setModelStates((prevStates) => ({
      ...prevStates,
      [modelName]: false,
    }));
  };

  return (
    <ModelContext.Provider value={{ modelStates, openModel, closeModel }}>
      {children}
    </ModelContext.Provider>
  );
};

export { ModelProvider, ModelContext };
