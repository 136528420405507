import { Grid, LinearProgress, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import { FileHeader } from './FileHeader';
const useStyles = makeStyles({
    toggle: {
      width: '64px',
      height: '4px',
      backgroundColor: '#E2E8F0',
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#DC2626',
      },
    },
    progressComplete: {
        width: '64px',
        height: '4px',
        // backgroundColor: '#E2E8F0',
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#059669',
        },
      },
  });

export interface SingleFileUploadWithProgressProps {
  file: File;
  onDelete: (file: File) => void;
  onUpload: (file: File, url: string) => void;
}

export function SingleFileUploadWithProgress({
  file,
  onDelete,
  onUpload,
}: SingleFileUploadWithProgressProps) {
  const [progress, setProgress] = useState(0);
  const [hide,setHide] = useState(false)
  const classes = useStyles();

  useEffect(() => {
    async function upload() {
      const url = await uploadFile(file, setProgress);
      onUpload(file, url);
    }

    upload();
  }, []);
  useEffect(()=>{
    if(progress==100){
      setTimeout(()=>{
        setHide(true)
      },3000)   
    }
  })

  const formatFileSize = (sizeInBytes:number) => {
    const KB = 1024;
    const MB = KB * 1024;
  
    if (sizeInBytes < KB) {
      return `${sizeInBytes} B`;
    } else if (sizeInBytes < MB) {
      return `${(sizeInBytes / KB).toFixed(2)} KB`;
    } else {
      return `${(sizeInBytes / MB).toFixed(2)} MB`;
    }
  };
 

  return (
    <Grid item  style={{display: "flex",
    gap: "16px",
    alignItems: "center"}}>
      {/* <FileHeader file={file} onDelete={onDelete} /> */}
      <img src={require("../../web/public/assets/image_84.png")}></img>
      <div>
        <p style={{margin:0 ,color: "#0F172A",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "24px"}}>{file.name}</p>
        <p style={{margin:0 ,color: "#64748B",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "24px"}}>{formatFileSize(file.size)}</p>
      </div>
      {!hide && <div style={{marginLeft: 'auto'}}>
       <LinearProgress variant="determinate" className={progress<100 ?classes.toggle:classes.progressComplete} value={progress} />

      {progress<100 ? <p style={{margin:0,color:"#DC2626"}}>{progress}%</p>:<p style={{margin:0,color:"#059669"}}>{progress}%</p>}
      </div>}
      
      {!hide ? <img src={require("../../web/public/assets/image_close.svg")} onClick={()=>onDelete(file)}></img>: <img style={{marginLeft:"auto"}} src={require("../../web/public/assets/image_delete.svg")} onClick={()=>onDelete(file)}></img>}

    </Grid>
  );
}

function uploadFile(file: File, onProgress: (percentage: number) => void) {
  const url = 'https://vinceretax-350762-ruby.b350762.dev.eastus.az.svc.builder.cafe/bulk_uploading/attachments';

  return new Promise<string>((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);

    xhr.onload = () => {
      const resp = JSON.parse(xhr.responseText);
      res(resp.secure_url);
    };
    xhr.onerror = (evt) => rej(evt);
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentage));
      }
    };

    const formData = new FormData();
    formData.append('file', file);
    xhr.send(formData);
  });
}