import React, { ChangeEvent } from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Dialog,
  Card,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Close, DeleteOutlined, Edit, FileCopyOutlined, Replay } from "@material-ui/icons";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const enumXmlCsv={
  XMLCHECK:"xml",
  CSVCHECK:"csv"
}
// Customizable Area End

import Ocropticalcharacterrecognition2Controller, {
  Props,
  configJSON,
} from "./Ocropticalcharacterrecognition2Controller";


export default class Ocropticalcharacterrecognition2 extends Ocropticalcharacterrecognition2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={{ margin: "auto", width: "803px", padding: "20px 20px" }}>
          <Box sx={webStyle.mainWrapper}>

            <Dialog
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                boxShadow:
                  " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
              }}
              open={this.state.showOcrscreen}
             
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Card
                style={{
                  maxWidth: "37.5rem",
                  overflowY:"scroll",
                  borderRadius: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "2rem",
                    padding: "1.5rem 1rem 1.5rem 2.5rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderBottom:"1px solid rgba(0, 0, 0, 0.12)"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: 700,
                      color: "#000000",
                    }}
                  >

                    Scanned text
                  </Typography>
                  <Close style={webStyle.cross} onClick={this.handleCloseOcrMOdal} data-test-id="closeModal"/>

                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "1.5rem 1.5rem",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "1rem",
                    alignSelf: "stretch",
                    width: "32.5rem",
                    // height: "11.3rem",
                  }}
                >
                  <div>
                    <Typography>Choose an Image</Typography>
                    <input
                      type="file"
                      name=""
                      id=""
                      onChange={this.handleImageChange}
                      accept="image/*"
                      data-test-id="imageInput"
                    />

                    {this.state.imageData &&<img src={this.state.imageData} alt="Selected" style={{ width: "520px", height: '220px' }} />}
                    {this.state.showloader?<div style={{display:"flex",width:"100%",marginTop:"4%",alignItems:"center",justifyContent:"center"}}> <CircularProgress style={{color:"#90CAF9"}}/> </div> :
                    (<>{!this.state.isEditing && this.state.editableOcr &&<> 
                    <Typography style={webStyle.ocrTextStyle}>{this.renderText()}</Typography>
                    <Typography style={webStyle.readText} onClick={this.handleText}>
                    {this.state.isExpanded ? 'Read less' : 'Read full text'}
                  </Typography></>}</>)
                    }
                   
                    {this.state.isEditing && <textarea
                      data-test-id="editOcrText"
                      value={this.state.editableOcr}
                      onChange={this.handleOcrEdit}
                      style={{...webStyle.ocrText, resize: 'vertical'}}
                   
                    />}
                    


                  </div>
                  <label style={{
fontFamily: "Inter",
fontSize: "14px",
fontWeight: 700,
lineHeight: "22px",
letterSpacing: "0em",
textAlign: "left",
}}>Select File Format for Saving</label>
                  <FormControl>
               <RadioGroup aria-labelledby="demo-radiobutton-group-label" name={"typeSlected"} data-test-id = "radioButton"
                   defaultValue={this.state.typeSelected ? enumXmlCsv.XMLCHECK : enumXmlCsv.CSVCHECK}
                   value={this.state.typeSelected ? enumXmlCsv.XMLCHECK : enumXmlCsv.CSVCHECK}
                   onChange={(event:ChangeEvent<HTMLInputElement>) => {this.handleRadio?.(event.target.value);
                }}
               >
                   <FormControlLabel checked={this.state.typeSelected === false } value={enumXmlCsv.XMLCHECK} control={<Radio style={{color:"#E57757"}} />} label="XML" />
                   <FormControlLabel checked={this.state.typeSelected === true } value={enumXmlCsv.CSVCHECK} control={<Radio style={{color:"#E57757"}} />} label="CSV" />
               </RadioGroup>
           </FormControl>
                </div>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    // height: "6.5rem",
                    padding: "1.5rem 1.5rem 1.5rem 28.5rem",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignSelf: "stretch",
                    gap: "1rem"
                  }}
                >

                  <Button style={webStyle.iconbtn} onClick={this.handleDeleteText} data-test-id="delete">
                    <DeleteOutlined style={{ color: "#DC2626" }} />
                  </Button>

                  <Button style={webStyle.iconbtn} onClick={this.handleRevertEdit} data-test-id="revert">
                    <Replay />
                  </Button>

                  <Button style={webStyle.iconbtn} onClick={this.handleOcrEditor} data-test-id="edit">
                    <Edit />
                  </Button>
                  <Button style={webStyle.iconbtn} onClick={this.handleCopyText} data-test-id="copy">
                    <FileCopyOutlined />
                  </Button>
                  <Button
                    onClick={this.createOCR}
                    data-test-id="savebtn"
                    variant="contained"
                    style={{
                      display: "flex",
                      width: "7.5rem",
                      height: "3.5rem",
                      padding: "1rem 2.59375rem 1rem 2.46875rem",
                      borderRadius: "0.5rem",
                      backgroundColor: "#E57727",
                      color: "#FFF",
                      fontWeight: 700,
                      textTransform:"none",
                      fontSize: "1rem",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Card>
            </Dialog>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    "display": "flex",
    "width": "803px",
    "height": "870px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "10px",
    "flexShrink": "0",
    "borderRadius": "8px",
    "border": "1px solid  #CBD5E1"
  },
 
  iconbtn: {
    "display": "flex",
    "width": "56px",
    "height": "56px",
    "padding": "16px",
    "justifyContent": "center",
    "alignItems": "center",
    "borderRadius": "8px",
    "background": "#F1F5F9"
  },
  ocrTextStyle:{
    display:"flex",
    fontFamily:"Inter",
    fontSize:"14px",
    padding:"0 0 0 20px",
    lineHeight:"22px",
    fontWeight:400,
    alignItems:"center",
    justifyContent:"center",
    marginTop:"10px",
    color:"#0F172A"
  },
  cross:{
    "display": "flex",
    "width": "32px",
    "height": "32px",
    "padding": "4px",
    "justifyContent": "center",
    "alignItems": "center",
    cursor:"pointer"
    
  },
  readText:{
    display:"flex",
    fontFamily:"Inter",
    fontSize:"14px",
    lineHeight:"22px",
    fontWeight:700,
    alignItems:"center",
    justifyContent:"center",
    marginTop:"10px",
    color:"#6200EA"
  },
  ocrText:{
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": 400,
    "lineHeight": "22px",
     width:"100%",
     height:"100px",
     maxWidth: '100%',
     marginTop:"15px",
  }
};
// Customizable Area End
