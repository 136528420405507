Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.FullName="Full name"
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
exports.titleWelcome="Welcome to Vincere Tax Portal: Your All-in-One Tax Solution"
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.btnTxtCreateAccount=" Create account"
exports.checkTitle="Your password must contain"
exports.subCheckOneCapital="At least one capital letter"
exports.subCheckOneLowerCase="At least one lowercase letter"
exports.subCheckOneNumber="At least one number"
exports.subCheckPasswordMustbe="Password must be 8-20 characters long."
exports.errorPasswordNotValid = "Password not valid.";
exports.mainHeader="Welcome to VINCERE TAX";
exports.subHeader="To get started, please create your account";

exports.txtTerms="You have to agree with Privacy Policy and Terms and Conditions to Sign up"
exports.errorTitle = "Error";
exports.details = "Are you ready to take control of your taxes like never before? Look no further – the Vincere Tax Portal is here to simplify your tax journey and empower you with unmatched convenience and expertise. Whether you're a valued tax client, a dedicated Vincere Tax Employee, or a trusted White Label Financial Advisor, our cutting-edge platform is designed to bring you closer to your financial goals.";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.Clientdata ="1001290560661-99l0ms9e8pteiaaecc98u60mpgrnmr97.apps.googleusercontent.com"

// Customizable Area End
