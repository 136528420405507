import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  googleChartData: any;
  visualData: any,
  selectedDate: any,
  selectedWeek: any,
  selectedMonthYear: any,
  selectedView: string,
  forcePickerOpen: boolean,
  formattedDate: any,
  selectedYear: any
  datePickerRef: React.RefObject<any>;

  setCustomers: string,
  setSelect: string,
  setEmployeeName: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      setCustomers: "",
      setSelect: "",
      setEmployeeName: "",
      visualData: null,
      selectedDate: null,
      selectedWeek: null,
      selectedMonthYear: null,
      selectedView: 'day',
      forcePickerOpen: false,
      formattedDate: "2024-02-16",
      selectedYear: "",
      datePickerRef:React.createRef()
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestId === this.apiGetDataCallId) {
          if (responseJson.data !== null) {

              this.setState({ visualData: responseJson })
           

          } else {
              console.log(responseJson?.data, "error");
          }
      }


      this.parseApiCatchErrorResponse(errorReponse);
  }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getVisualData()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start



  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.forcePickerOpen !== this.state.forcePickerOpen && this.state.forcePickerOpen) {
        this.state.datePickerRef.current?.setOpen(true);
        this.setState({ forcePickerOpen: false });
    }
}



handleDateChange = (date: any) => {

    const formattedDate: any =  date.toISOString().slice(0, 10);
    this.setState({
        selectedDate: date,
        selectedMonthYear: null,
        selectedWeek: null,
        formattedDate
    }, () => this.getVisualData());

};

handleWeekChange = (date: any) => {
    const formattedDate = date.toISOString().slice(0, 10);

    this.setState({
        selectedWeek: date,
        selectedDate: null,
        selectedMonthYear: null,
        formattedDate
    }, () => this.getVisualDatabyWeek());
};

handleMonthYearChange = (date: Date) => {
    const nextMonthDate = new Date(date);
    nextMonthDate.setMonth(date.getMonth());
    const formattedDate = nextMonthDate.getMonth() + 1


    this.setState(
        {
            selectedMonthYear: date,
            selectedDate: null,
            selectedWeek: null,
            selectedYear: nextMonthDate.getFullYear(),
            formattedDate
        },
        () => this.getVisualDatabyMonth()
    );
};

handleViewChange = (view: any) => {
    this.setState({
        selectedView: view,
        forcePickerOpen: true,
    });
};

token = ""
getVisualData = async () => {
    let userData = localStorage.getItem("loginData");


    let someDataParsed = userData && JSON.parse(userData);
    this.token = someDataParsed?.token;



    this.apiGetDataCallId = await this.VisualDataApicall({


        methods: "GET",
        endPoint: `bx_block_visual_analytics/users_data?dates=day&date=${this.state.formattedDate}`,

        token: this.token,
    });



};


getVisualDatabyWeek = async () => {

    this.apiGetDataCallId = await this.VisualDataApicall({


        methods: "GET",
        endPoint: `bx_block_visual_analytics/users_data?dates=week&date=${this.state.formattedDate}`,

        token: this.token,
    });



};

getVisualDatabyMonth = async () => {

    this.apiGetDataCallId = await this.VisualDataApicall({


        methods: "GET",
        endPoint: `bx_block_visual_analytics/users_data?dates=month&month_no=${this.state.formattedDate}&year=${this.state.selectedYear}`,

        token: this.token,
    });



};




VisualDataApicall = async (data: any) => {
    const { ContentType, methods, endPoint, token} = data;
    const Header = {
        "Content-Type": ContentType,
        token,
    };
    const apiRequestMessageforFilesInFolder = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforFilesInFolder.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(Header)
    );
    apiRequestMessageforFilesInFolder.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    apiRequestMessageforFilesInFolder.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methods
    );
    
    runEngine.sendMessage(
        apiRequestMessageforFilesInFolder.id,
        apiRequestMessageforFilesInFolder
    );
    return apiRequestMessageforFilesInFolder.messageId;
};

 
  // Customizable Area End
}
