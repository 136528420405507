import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { CirclePicker, ChromePicker, ColorResult } from 'react-color';

interface ColorPickerProps {
  onChange: (color: string) => void;
  name:string
}

const useStyles = makeStyles({
  circle: {
    width: "280px !important",
    gap:"12px !important",
    display: "flex",
    flexWrap: "wrap" as const,
    marginRight: "-14px",
    marginBottom: "-14px",
  },
  imageContainer: {
    position: 'relative',
    cursor: 'pointer',
  },
  chromePickerContainer: {
    position: 'absolute',
    top: '50px', // Adjust the positioning as needed
    zIndex: 1000,
  },
  popImage: {
    border: "2px solid blue",
    borderRadius: "50px",
    transition: "transform 0.1s ease-in-out",
    '&:hover':{
      transform: "scale(1.2)",
    }
  },
});

const ColorPicker: React.FC<ColorPickerProps> = ({ name,onChange }) => {
  const [color, setColor] = useState('#000000'); // Initial color is black
  const [showChromePicker, setShowChromePicker] = useState(false);
  const classes = useStyles();

  const customColors = ['#EF346E', '#FF723A', '#FFB32E', '#095BA6', '#9B50E5'];

  const handleColorChange = (newColor: ColorResult) => {
    const newC = newColor.hex;
    setColor(newC);
    onChange(newC);
  };

  const handleImageClick = () => {
    setShowChromePicker(!showChromePicker);
  };

  return (
    <div style={{ display: "flex", marginLeft: "10px", marginTop: "10px" }}>
      <CirclePicker className={classes.circle} colors={customColors} color={color} onChange={handleColorChange} />

      <div className={classes.imageContainer} onClick={handleImageClick}>
        <img className={classes.popImage} src="/assets/color_lens.svg" alt="Color Lens" />
        {showChromePicker && (
          <div className={classes.chromePickerContainer}>
            <ChromePicker color={color} onChange={handleColorChange} />
          </div>
        )}
      </div>

      {/* <div style={{ marginTop: '20px' }}>
        <p>Selected Color: {color}</p>
      </div> */}
    </div>
  );
};

export default ColorPicker;

