
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getThemeColor, getSecondaryThemeColor } from "../../../components/src/CommonPlatte";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface UserData {
  name?: string,
  email?: string,
  tags?: string,
  template?: string,
  location?: string,
  ein?: string,
  btype?: string,
  selectedOption: string,
  teammembers: any,
  password:string,
 
}
export interface S {
  // Customizable Area Start

  userdata: UserData
  teamdata: unknown
  fieldError: boolean
  invalidEmail: boolean
  apiError: any,
  inValidPassword: boolean,
  isValidAccountName: boolean,
  LoggedUserType: string,
  fieldName: boolean,
  fieldEmail: boolean,
  fieldPassword: boolean

  btnColor: string;
  textColor: string;
  errors: any;
  customField: any;
  addfieldName: string;
  addfieldDescr: any;
  addfieldError: boolean;
  addfieldDesError: boolean;
  fieldCustom: boolean;
  addFieildModal: boolean;
  editDataModal: boolean;
  newFieldKey: any;
  newFieldValue: any;
  requiredfields: any;
  duplicatekeyError: any
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}



export default class AccountCreationController extends BlockComponent<
  Props,
  S,
  SS

> {
  // Customizable Area Start
  createAccountAPI: any;
  CurrentUserAPI: any;
  intitialValues = { email: "" }
  addFielddataAPI: any;
  showUserProfileData: any;
  deletefieldApicall: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      userdata: { selectedOption: "", name: "", email: "", password: "", tags: "", ein: "", btype: "", location: "", template: "", teammembers: [] },
      teamdata: [],
      fieldError: false,
      inValidPassword: false,
      invalidEmail: false,
      isValidAccountName: false,
      btnColor: getThemeColor(),
      textColor: getSecondaryThemeColor(),
      apiError: {},
      LoggedUserType: "",
      fieldName: false,
      fieldPassword: false,
      fieldEmail: false,
      errors: {},
      customField: [],
      addfieldDescr: "",
      addfieldName: "",
      addfieldDesError: false,
      addfieldError: false,
      fieldCustom: false,
      addFieildModal: false,
      editDataModal: false,
      newFieldKey: [],
      newFieldValue: [],
      requiredfields: [],
      duplicatekeyError: ""
      // Customizable Area End
    };

    // Customizable Area Start


    // Customizable Area End
  }



  // Customizable Area Start

  async componentDidMount() {


    this.getCurrentLogUser()


  }
  formDataFormat = (values: any) => {
    const formData: any = new FormData();




    formData.append(`account[first_name]`, this.state.userdata?.name)
    formData.append(`account[full_name]`, this.state.userdata?.name)
    formData.append(`account[email]`, this.state.userdata?.email)
    formData.append(`account[password]`, this.state.userdata?.password)
    formData.append(`account[password_confirmation]`, this.state.userdata?.password)
    formData.append(`account[user_type]`, this.state.userdata?.selectedOption)
    formData.append(`account[activated]`, true)
    formData.append(`account[tags]`, this.state.userdata?.tags)
    formData.append(`account[buniness_type]`, this.state.userdata?.btype)
    formData.append(`account[buniness_ein]`, this.state.userdata?.ein)
    formData.append(`new_column_name`, this.state.newFieldKey)
    formData.append(`new_value`, this.state.newFieldValue)
    return formData;
  }

  token: string = "";

  createAccount = async () => {


    let tokenData = localStorage.getItem("loginData");
    let tokenDataParsed = tokenData && JSON.parse(tokenData);
    this.token = tokenDataParsed?.token;

    const formData = this.formDataFormat('values')
    const data = {
      "account": {
        "first_name": this.state.userdata.name,
        "email": this.state.userdata.email,
        "password": this.state.userdata.password,
        "password_confirmation": this.state.userdata.password,
        "activated": true,
        "user_type": this.state.userdata.selectedOption,
        "tags": this.state.userdata.tags,
        "folder_template": "wrong",
        "buniness_type": this.state.userdata.btype,
        "buniness_ein": this.state.userdata.ein,
        "team_members": this.state.userdata.teammembers
      },
      "new_column_name": this.state.newFieldKey,
      "new_value": this.state.newFieldValue
    }

    this.createAccountAPI = await this.createAccountapiCall({


      ContentType: 'application/json',
      methods: 'POST',
      endPoint: 'account_block/account_creations',

      Body: data,
      token: this.token

    })
  }

  getCurrentLogUser = async () => {
    let tokenData = localStorage.getItem("loginData");
    let tokenDataParsed = tokenData && JSON.parse(tokenData);
    this.token = tokenDataParsed?.token;

    this.CurrentUserAPI = await this.createAccountapiCall({
      methods: "GET",
      endPoint: `account_block/accounts/show`,

      token: this.token,
    }
    );

  }
  createAccountapiCall = async (data: any) => {
    const { ContentType, methods, endPoint, Body, token } = data;
    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestMessageforAccountCreation = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforAccountCreation.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforAccountCreation.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforAccountCreation.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    Body &&
      apiRequestMessageforAccountCreation.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),

        JSON.stringify(Body)
      );
    runEngine.sendMessage(apiRequestMessageforAccountCreation.id, apiRequestMessageforAccountCreation);
    return apiRequestMessageforAccountCreation.messageId;
  };



  handleChange = async (e: any) => {
    const { name, value, type } = e.target;

    // Validate the individual field and update the errors state
    try {
      const fieldSchema = Yup.reach(this.SignUpFormSchema(), name);
      await fieldSchema.validate(type === 'checkbox' ? value === 'true' : value);
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [name]: undefined,
        },
      }));
    } catch (error: any) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [name]: error.message,
        },
      }));
    }

    this.setState((prevState) => ({
      userdata: {
        ...prevState.userdata,
        [name]: type === 'checkbox' ? value === 'true' : value,
      },
    }));
  };



  validateForm = () => {
    const schema = this.SignUpFormSchema();
    schema
      .validate(this.state.userdata, { abortEarly: false })
      .then(() => {
        this.createAccount()

      })
      .catch((validationError) => {
        const newErrors: Record<string, string> = {};
        validationError.inner.forEach((error: any) => {
          newErrors[error.path] = error.message;
        });
        this.setState({
          errors: newErrors,
        });
      });
  };

  SignUpFormSchema = () => {
    return Yup.object().shape({
      name: Yup.string()
        .min(1, 'Full name is too short')
        .max(20, 'Full name is too long')
        .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Full name is invalid')
        .required('Full name is required.'),

      email: Yup.string()
        .min(1, 'Email is too short')
        .max(50, 'Email is too long')
        .email().required("Email is required."),

      password: Yup.string()

        .min(8, 'Password must be at least 8 characters')
        .max(20, 'Password should not be more than 20 characters')
        .matches(/^(?=.*[a-z])/, 'Password must contain lowercase character')
        .matches(/^(?=.*[A-Z])/, 'Password must contain uppercase character')
        .matches(/^(?=.*\d)/, 'Password must contain at least one number')
        .matches(/^(?=.*[!@#$%^&*(),.?":{}|<>])/, 'Password must contain at least one special character')
        .required('Please Enter Password'),

      selectedOption: Yup.string()
        .required('Client Type is required.'),
    });
  };

  handleFieldError = () => {
    const { addfieldName, addfieldDescr } = this.state;

    const addfieldError = addfieldName.trim() === "";
    const addfieldDesError = addfieldDescr.trim() === "";

    let duplicateError = false;

    if (!addfieldError && !addfieldDesError) {
      const { newFieldKey } = this.state;
      if (newFieldKey.includes(addfieldName)) {
        duplicateError = true;
      }
    }

    this.setState({
      addfieldError,
      addfieldDesError,
      duplicatekeyError: duplicateError ? 'Duplicate field name. Please choose a different field name.' : '',

    });

    if (!addfieldError && !addfieldDesError && !duplicateError) {
      this.handleSubmitkeyValue();
    }
  };

  handleSubmitkeyValue = () => {
    const { addfieldDescr, addfieldName, newFieldKey, newFieldValue, requiredfields } = this.state;
  
    const trimmedFieldName = addfieldName.trim(); 
  
    if (newFieldKey.some((key:any) => key.trim() === trimmedFieldName)) {
      this.setState({ duplicatekeyError: 'Duplicate field name. Please choose a different field name.' });
      return;
    }
  
    this.setState({
      requiredfields: [...requiredfields, { addfieldName: trimmedFieldName, addfieldDescr }],
      newFieldKey: [...newFieldKey, trimmedFieldName],
      newFieldValue: [...newFieldValue, addfieldDescr],
      addfieldName: '',
      addfieldDescr: '',
      duplicatekeyError: '',
      addFieildModal: false
    });
  };
  


  

  handleDelete = (index:any) => {
    const { newFieldKey, newFieldValue, requiredfields } = this.state;
  
    const updatedKeyField = [...newFieldKey];
    const updatedValueField = [...newFieldValue];
    const updatedRequiredFields = [...requiredfields];

    updatedKeyField.splice(index, 1);
    updatedValueField.splice(index, 1);
  
    updatedRequiredFields.splice(index, 1);
  
    this.setState({
      newFieldKey: updatedKeyField,
      newFieldValue: updatedValueField,
      requiredfields: updatedRequiredFields,
    });
  };
  



  handleChangeAddFieldDescr = (event: any) => {
    this.setState({ addfieldDescr: event?.target?.value })
  }

  handleChangeAddFieldname = (event: any) => {
    this.setState({ addfieldName: event?.target?.value })
  }

  handlecloseFieldModal1 = () => {
    this.setState({ addFieildModal: !this.state.addFieildModal, addfieldDesError: false, addfieldError: false, addfieldName: "", addfieldDescr: "", duplicatekeyError: "" });
  };
  handleaddFieldModal = () => {
    this.setState({ addFieildModal: !this.state.addFieildModal });
  };

  handleSubmit = (e: any) => {
    e.preventDefault()
    // console.log("clicked the button")
    this.state.userdata.teammembers = this.state.teamdata
    // this.setState({userdata:{teammembers:this.state.teamdata}})
    this.validateForm()


  }

  handleCancel = () => {
    this.setState({
      userdata: { selectedOption: "", name: "", email: "", password: "", tags: "", ein: "", btype: "", location: "", template: "", teammembers: [] },
      teamdata: [], apiError: "", fieldError: false, invalidEmail: false, inValidPassword: false, isValidAccountName: false, fieldName: false, fieldEmail: false, fieldPassword: false, errors: {}
    })

  }

  handleTeam = (event: React.ChangeEvent<{
    name: string | undefined;
    value: unknown;
  }>) => {
    const {
      target: { value },
    } = event;
    this.setState({

      teamdata:
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value,

    });
  };


  NavigateToPeopleWeb() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPeopleWebMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );



      if (apiRequestId === this.createAccountAPI) {
        if (responseJson.data && !responseJson.errors) {


          this.NavigateToPeopleWeb()
        }
        else if (responseJson.errors) {

          this.setState({ apiError: responseJson.errors, fieldError: false })
        }

      }
      if (apiRequestId === this.CurrentUserAPI) {

        this.setState({ LoggedUserType: responseJson?.data?.attributes?.user_type })

      }



    }

    this.parseApiCatchErrorResponse(errorReponse);







    // Customizable Area End
  }



  // Customizable Area End
}
