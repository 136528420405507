import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import DocumentsWebAdapter from "../../blocks/adapters/src/DocumentsWebAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailLogInAdapter from "../../blocks/adapters/src/EmailLoginAdaptar";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import ProfileClientAdapter from "../../blocks/adapters/src/ProfileClientAdapter";
import TwoFactorAuthenticationAdapter from "../../blocks/adapters/src/TwoFactorAuthenticationAdapter";
import HomePageAdapter from "../../blocks/adapters/src/HomePageAdapter";
import ThemeScreenAdapter from "../../blocks/adapters/src/ThemeScreenAdapter";
import PeopleWebAdapter from "../../blocks/adapters/src/PeopleWebAdapter";
import CreateProjectAdapter from "../../blocks/adapters/src/CreateProjectAdapter";
import PdfEditorWrapperAdapter from "../../blocks/adapters/src/PdfEditWrapperAdapter"
import UserGroupWebAdapter from '../../blocks/adapters/src/UserGroupWebAdapter';
import PdfDocumentAdapter from "../../blocks/adapters/src/PdfDocumentAdapter";
import PdfEditWebAdapter from "../../blocks/adapters/src/PdfEditWebAdapter"
import InvoiceDetailsAdapter from "../../blocks/adapters/src/InvoiceDetailsAdapter";
import DocumentUploadAdapter from "../../blocks/adapters/src/DocumentUploadAdapter";
import TemplatesAdapter from "../../blocks/adapters/src/TemplatesAdapter";
import ProjectnotesAdapter from "../../blocks/adapters/src/ProjectnotesAdapter";
import UserLiberaryAdapter from "../../blocks/adapters/src/UserLiberaryAdapter";
import SubFolderAdapter from "../../blocks/adapters/src/SubFolderAdapter";
import NotificationAdapter from "../../blocks/adapters/src/NotificationAdapter";
import NotificationDetailsAdapter from "../../blocks/adapters/src/NotificationDetailsAdapter";
import CreateThemeAdapter from "../../blocks/adapters/src/CreateThemeAdapter";
import ScannedOcrAdapter from "../../blocks/adapters/src/ScannedOcrAdapter";
import OcrRecognationAdapter from "../../blocks/adapters/src/OcrRecognationAdapter";

//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailLogInAdapter=new EmailLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();

//Assembler generated adapters end


const twoFactorAuthentication= new TwoFactorAuthenticationAdapter();
const homePage= new HomePageAdapter();
const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const themeScreenAdapter = new ThemeScreenAdapter();
const documents= new DocumentsWebAdapter();
const profileClientAdapter=new ProfileClientAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
const createProjectAdapter = new CreateProjectAdapter();
const peopleWebAdapter = new PeopleWebAdapter();
const pdfEditorWrapperAdapter = new PdfEditorWrapperAdapter()
// const userGroupWebAdapter = new UserGroupWebAdapter();
const pdfDocumentAdapter = new PdfDocumentAdapter
const userGroupWebAdapter = new UserGroupWebAdapter();
const pdfEditWebAdapter = new PdfEditWebAdapter()
const invoiceDetailsAdapter= new InvoiceDetailsAdapter()
const documentUploadWebAdapter = new DocumentUploadAdapter()
const templatesAdapter = new TemplatesAdapter();
const projectnotesAdapter = new ProjectnotesAdapter();
const createThemeAdapter = new CreateThemeAdapter();
const userLiberaryAdapter = new UserLiberaryAdapter();
const subFolderAdapter = new SubFolderAdapter();
const scannedOcrAdapter = new ScannedOcrAdapter();
const ocrRecognationAdapter =new OcrRecognationAdapter();
const notificationAdapter = new NotificationAdapter();
const notificationDetailsAdapter = new NotificationDetailsAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to VincereTax!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'SocialMediaAccountRegistrationScreen'}  onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'social-media-account'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'TwoFactorAuthentication'}  onPress={() => navigation.navigate("TwoFactorAuthentication")} />
<CustomTextItem content={'BulkUploading'}  onPress={() => navigation.navigate("BulkUploading")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'multipageforms'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'projecttemplates'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Tasks'}  onPress={() => navigation.navigate("Tasks")} />
<CustomTextItem content={'AccountGroups'}  onPress={() => navigation.navigate("AccountGroups")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'MobileAccountLoginBlock'}  onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
<CustomTextItem content={'CameraAccess'}  onPress={() => navigation.navigate("CameraAccess")} />
<CustomTextItem content={'PdfEdit'}  onPress={() => navigation.navigate("PdfEdit")} />
<CustomTextItem content={'VisualAnalytics'}  onPress={() => navigation.navigate("VisualAnalytics")} />
<CustomTextItem content={'ThemeBlock'}  onPress={() => navigation.navigate("ThemeBlock")} />
<CustomTextItem content={'LandingPage'}  onPress={() => navigation.navigate("LandingPage")} />
<CustomTextItem content={'NavigationMenu'}  onPress={() => navigation.navigate("NavigationMenu")} />
<CustomTextItem content={'StripePayments'}  onPress={() => navigation.navigate("StripePayments")} />
<CustomTextItem content={'customisableuserprofiles'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'TermsConditions'}  onPress={() => navigation.navigate("TermsConditions")} />
<CustomTextItem content={'ImportExportData'}  onPress={() => navigation.navigate("ImportExportData")} />
<CustomTextItem content={'SaveAsPdf'}  onPress={() => navigation.navigate("SaveAsPdf")} />
<CustomTextItem content={'DigitalSignatures'}  onPress={() => navigation.navigate("DigitalSignatures")} />
<CustomTextItem content={'Search'}  onPress={() => navigation.navigate("Search")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'Pushnotifications'}  onPress={() => navigation.navigate("Pushnotifications")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'Analytics'}  onPress={() => navigation.navigate("Analytics")} />
<CustomTextItem content={'InvoiceBilling'}  onPress={() => navigation.navigate("InvoiceBilling")} />
<CustomTextItem content={'Rolesandpermissions'}  onPress={() => navigation.navigate("Rolesandpermissions")} />
<CustomTextItem content={'Ocropticalcharacterrecognition2'}  onPress={() => navigation.navigate("Ocropticalcharacterrecognition2")} />
<CustomTextItem content={'Projectnotes'}  onPress={() => navigation.navigate("Projectnotes")} />
<CustomTextItem content={'Repeatingtasks2'}  onPress={() => navigation.navigate("Repeatingtasks2")} />
<CustomTextItem content={'Cfconditionalquestionanswerforms'}  onPress={() => navigation.navigate("Cfconditionalquestionanswerforms")} />
<CustomTextItem content={'Cfsureprepapiintegrationtodigestreceiptsandtaxforms'}  onPress={() => navigation.navigate("Cfsureprepapiintegrationtodigestreceiptsandtaxforms")} />
<CustomTextItem content={'Cfprefillformswitheditconfirmationlogic2'}  onPress={() => navigation.navigate("Cfprefillformswitheditconfirmationlogic2")} />
<CustomTextItem content={'Salesreporting'}  onPress={() => navigation.navigate("Salesreporting")} />
<CustomTextItem content={'Settings2'}  onPress={() => navigation.navigate("Settings2")} />
<CustomTextItem content={'Adminconsole2'}  onPress={() => navigation.navigate("Adminconsole2")} />
<CustomTextItem content={'Breadcrumbnavigation2'}  onPress={() => navigation.navigate("Breadcrumbnavigation2")} />
<CustomTextItem content={'Audittrail2'}  onPress={() => navigation.navigate("Audittrail2")} />
<CustomTextItem content={'Emailnotifications2'}  onPress={() => navigation.navigate("Emailnotifications2")} />
<CustomTextItem content={'Paymentadmin2'}  onPress={() => navigation.navigate("Paymentadmin2")} />
<CustomTextItem content={'Xmlcsvtemplatebuilder'}  onPress={() => navigation.navigate("Xmlcsvtemplatebuilder")} />

<CustomTextItem content={'breadcrumbnavigation'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />

<CustomTextItem content={'ocropticalcharacterrecognition'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />

<CustomTextItem content={'repeatingtasks'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Rolesandpermissions2'}  onPress={() => navigation.navigate("Rolesandpermissions2")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;