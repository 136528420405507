import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChartData } from "react-native-chart-kit/dist/HelperTypes";
import moment, { Moment, unitOfTime } from "moment";

interface Reporting {
  overview: { unit: number, revenue: number };
  sales: { [label: string]: number };
  salesCumulative: { [label: string]: number };
  revenueBreakdown: { [label: string]: number };
  salesComparison: { [label: string]: number };
  currency: string;
}
interface RevenueBreakdownUnit {
  name: string;
  value: number;
  color: string;
  legendFontColor: string;
  legendFontSize: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  selectedPeriod: { pkey: string, value: string };
  selectedDate: { from: Moment, through: Moment };
  selectedFrame: string;
  totalSaleUnit: number;
  totalSaleRevenue: number;
  revenueBreakdown: RevenueBreakdownUnit[];
  mobileSalesData: ChartData;
  mobileSalesCumulativeData: { labels: string[], data: number[] };
  webSalesData: (string | number)[][];
  webSalesCumulativeData: (string | number)[][];
  webRevenueBreakdown: (string | number)[][];
  currency: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SalesReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  salesReportApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];
    const chartData = this.prepareChartData(configJSON.sampleReporting)

    this.state = {
      token: "",
      // Customizable Area Start
      loading: false,
      selectedPeriod: configJSON.periods[1],
      selectedDate: { from: moment().startOf('M'), through: moment().endOf('M') },
      selectedFrame: 'week',
      totalSaleUnit: chartData.totalSaleUnit,
      totalSaleRevenue: chartData.totalSaleRevenue,
      revenueBreakdown: chartData.revenueBreakdown,
      mobileSalesData: chartData.mobileSalesData,
      mobileSalesCumulativeData: chartData.mobileSalesCumulativeData,
      webSalesData: chartData.webSalesData,
      webSalesCumulativeData: chartData.webSalesCumulativeData,
      webRevenueBreakdown: chartData.webRevenueBreakdown,
      currency: chartData.currency,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const getSalesReportResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", getSalesReportResponse);

      if (getSalesReportResponse && getSalesReportResponse.overview) {
        const chartData = this.prepareChartData(getSalesReportResponse);
        this.setState({ ...chartData, loading: false });
      } else {
        this.showAlert("Alert", "API Error", "");
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getSalesReport();
    // Customizable Area End
  }

  setSelectedPeriod = (period: { pkey: string, value: string }) => {
    let momentKey: unitOfTime.StartOf;
    switch (period.pkey) {
      case 'day':
        momentKey = 'week'
        break;
      case 'week':
        momentKey = 'month'
        break;
      case 'month':
        momentKey = 'year'
        break;
      default:
        momentKey = 'day'
    }
    const start = moment().startOf(momentKey);
    const ends = moment().endOf(momentKey);
    this.setState({ selectedFrame: period.pkey, selectedDate: { from: start, through: ends } });
    this.getSalesReport(this.state.token, period.pkey, start.valueOf(), ends.valueOf());
  }

  setSelectedDate = (direction: string = 'left') => {
    let momentKey: unitOfTime.StartOf;
    switch (this.state.selectedFrame) {
      case 'day':
        momentKey = 'week'
        break;
      case 'week':
        momentKey = 'month'
        break;
      case 'month':
        momentKey = 'year'
        break;
      default:
        momentKey = 'day'
    }

    const start = direction === 'left' ?
      this.state.selectedDate.from.subtract(1, momentKey) :
      this.state.selectedDate.from.add(1, momentKey);
    const ends = direction === 'left' ?
      this.state.selectedDate.through.subtract(1, momentKey) :
      this.state.selectedDate.through.add(1, momentKey);

    this.setState({ selectedDate: { from: start, through: ends } });
    this.getSalesReport(this.state.token, this.state.selectedFrame, start.valueOf(), ends.valueOf());
  };

  getSalesReport = (
    token: string = "",
    frame: string = this.state.selectedFrame,
    start: number = this.state.selectedDate.from.valueOf(),
    ends: number = this.state.selectedDate.through.valueOf()) => {
    const header = {
      "Content-Type": configJSON.getSalesReportApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let params = new URLSearchParams();
    params.append('frame', frame);
    params.append('start', start.toString());
    params.append('end', ends.toString());

    this.salesReportApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSalesReportApiEndPoint}?${params.toString()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    this.setState({ loading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  decideLabels = () => {
    const frameKey = this.state?.selectedFrame || 'week';
    let labels: string[] = [];
    if (frameKey !== 'week') {
      labels = configJSON.chartLabels[this.state?.selectedFrame]
    }

    if (labels.length === 0) {
      const from = this.state?.selectedDate.from || moment().startOf('month');
      const ends = this.state?.selectedDate.through || moment().endOf('month');
      let tempDate = moment(this.state?.selectedDate.from).startOf('week');
      while (tempDate.isBefore(ends) || tempDate.isSame(ends)) {
        if (tempDate.isAfter(from) || tempDate.isSame(from)) {
          labels.push(tempDate.format('DD.MM.YYYY'))
        }
        tempDate = moment(tempDate.add(1, 'w'));
      }
    }
    return labels;
  }

  prepareChartData = (reporting: Reporting) => {
    let labels: string[] = this.decideLabels();

    let hourLabels: string[] = [];

    const mobileSalesData: number[] = []
    const mobileSalesCumulativeData: number[] = []
    const webSalesData: (string | number)[][] = [configJSON.webSalesChartDataLabels]
    const webSalesCumulativeData: (string | number)[][] = [configJSON.webSalesChartDataLabels]
    
    let sums = 0;
    let hourGroupSum = 0;
    labels.forEach((label: string, l_index: number) => {
      sums += reporting.sales[label] || 0;
      if (this.state?.selectedFrame === 'hour' && l_index % 2 === 0) {
        hourLabels.push(label)
        hourGroupSum += reporting.sales[label] || 0;
        mobileSalesData.push(hourGroupSum);
        mobileSalesCumulativeData.push(sums)
        hourGroupSum = 0;
      }
      else if (this.state?.selectedFrame === 'hour' && l_index % 2 !== 0) {
        hourGroupSum += reporting.sales[label] || 0;
      }
      else {
        mobileSalesData.push(reporting.sales[label] || 0);
        mobileSalesCumulativeData.push(sums)
      }

      webSalesData.push([label, reporting.sales[label] || 0])
      webSalesCumulativeData.push([label, sums])
    })


    let _revenueBreakdown: RevenueBreakdownUnit[] = []
    Object.keys(reporting.revenueBreakdown).forEach((revenue, r_index) => {
      _revenueBreakdown.push({
        name: revenue,
        value: reporting.revenueBreakdown[revenue],
        color: configJSON.chartColors[r_index % configJSON.chartColors.length],
        legendFontColor: "#7F7F7F",
        legendFontSize: 15
      })
    })

    return {
      currency: reporting.currency,
      totalSaleUnit: reporting.overview.unit,
      totalSaleRevenue: reporting.overview.revenue,
      mobileSalesData: {
        labels: hourLabels.length === 0 ? labels : hourLabels,
        datasets: [
          {
            data: mobileSalesData
          }
        ]
      },
      mobileSalesCumulativeData: { labels: hourLabels.length === 0 ? labels : hourLabels, data: mobileSalesCumulativeData },
      revenueBreakdown: _revenueBreakdown,
      webSalesData,
      webSalesCumulativeData,
      webRevenueBreakdown: [configJSON.webRevenueBreakdownLabels, ...Object.keys(reporting.revenueBreakdown).map((report) => [report, reporting.revenueBreakdown[report]])],
    }
  }
  // Customizable Area End
}
