// Customizable Area Start
import React from "react";
import {
  Container,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography
} from "@material-ui/core";
import InvoiceTemplateController, {
  Props,
} from "./InvoiceTemplateController.web";
import { imgPdf, imgSuccess, upload } from "./assets";
import { MoreVert, DeleteOutlineOutlined } from '@material-ui/icons';
import InvoiceTemplateForm from "../../../components/src/InvoiceTemplateForm";
import { downloadFile } from "../../../components/src/CommonPlatte";
import { Alert } from "@material-ui/lab";
import Loader from "../../../components/src/Loader.web";


export default class InvoiceTemplate extends InvoiceTemplateController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const {teamEmails}=this.props
    
    return (
  
      <Container maxWidth={"xl"}>
        <Loader loading={this.state.showLoader}/>
        <div style={{ display: "grid", gap: "30px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button id="openInvoiceTemplate" style={taskStyle.createBtn}
              onClick={() => this.openForm()}
            >Create Template</Button>
          </div>

          <Table>
            {this.state.InvoiceList !== undefined ? (
              <TableBody>
                {this.state.InvoiceList.map((item: any, index: number) => (
                  <React.Fragment key={item.id}>
                    <TableRow
                      style={{
                        borderRadius: "0.375rem",
                        borderBottom: "1px solid #E2E8F0",
                        padding: "0.625rem",
                        height: "1.5rem",
                      }}
                      key={item.id}
                    >
                      <TableCell style={{
                        width: "4%",
                        padding: "0px 10px "
                      }}>
                        <IconButton aria-label="expand row" size="small">
                          <img src={imgPdf}></img>
                        </IconButton>
                      </TableCell>
                      <TableCell
                        data-test-id={`project-${index}`}
                        style={{
                          width: "25%",
                          padding: "18px 12px",

                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        {item.attributes.invoice_items[0].name}
                      </TableCell>
                      <TableCell
                        data-test-id={`project-${index}`}
                        style={{
                          width: "15%",
                          padding: "12px",

                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        {item.attributes.invoice_number}
                      </TableCell>
                      <TableCell
                        data-test-id={`project-${index}`}
                        style={{
                          width: "25%",
                          padding: "12px",

                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        {this.formatDate(item.attributes.invoice_date)}
                      </TableCell>
                      <TableCell
                        data-test-id={`project-${index}`}
                        style={{
                          width: "15%",
                          padding: "12px",

                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        ${item.attributes.invoice_items[0].price}
                      </TableCell>
                      <TableCell
                        data-test-id={`project-${index}`}
                        style={{
                          width: "30%",
                          padding: "12px",
                          color: "green",
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        {item.attributes.status}
                      </TableCell>
                      <TableCell
                        data-test-id={`project-${index}`}
                        style={{
                          width: "10%",
                          padding: "12px",

                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        <MoreVert id="showDialog" onClick={(e) => this.handleShowProfile(e, item.id)} />

                      </TableCell>
                    </TableRow>
                    {this.state.selectedProjectId === item?.id && this.state.isVisibleModal && (
                      <div style={{
                        ...taskStyle.boxMOdal,
                        position: "absolute",
                        top: this.state.modalPosition.top + "px",
                        left: this.state.modalPosition.left + "px",
                      }}>
                        <Button style={taskStyle.btnModal} data-test-id="viewProfilebtn" onClick={()=>{downloadFile(item.attributes.invoice_pdf.url,item.attributes.invoice_pdf.filename)}}> <img src={upload} style={{ marginRight: "8px" }}></img><Typography style={taskStyle.btnmodalText}>Download file</Typography></Button>
                        <Button style={taskStyle.btnModal} id="deteteInvoice" onClick={()=>this.deleteInvoiceById(item.id)}><DeleteOutlineOutlined style={{ marginRight: "8px" }} /> <Typography style={taskStyle.btnmodalText}>Delete file</Typography></Button>
                      </div>
                    )}

                  </React.Fragment>
                ))}
              </TableBody>
            ) : (
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: 700,
                  color: "#e87424",
                }}
              >
                Invoices not found
              </div>
            )}
          </Table>
        </div>
        {this.state.showAlert &&
              <Alert style={{ ...taskStyle.alert, position: "absolute" }} icon={false} severity="success">
                <div style={taskStyle.message} >
                  <img src={imgSuccess} style={{ verticalAlign: "bottom" }} />
                  <Typography variant="body1">{this.state.alertMessage}</Typography>
                </div>
              </Alert>}
        <InvoiceTemplateForm
          openDialog={this.state.openInvoiceForm}
          headingText="Create Template"
          btnOkText="Generate Invoice"
          btnCancelText="Cancel"
          handleOk={this.createInvoiceTemplate}
          teamEmails={teamEmails}
          handleCancel={this.closeForm}
          templateList={this.state.InvoiceTemplateList}
          serviceList={this.state.serviceList}
          invoiceBilling={false}
        />
      </Container>
  
    );
  }
}
const taskStyle = {
  createBtn: {
    display: "flex",
    width: "210px",
    height: "50px",
    padding: "10px 16px",
    justifyContent: "center",
    textTransform: "none" as const,
    borderRadius: "50px",
    background: "#396178",
    alignItems: "center",
    color: "#FFF",
    textAlign: "center" as const,
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    gap: "8px",
    flexShrink: 0,
  },
  boxMOdal: {
    display: "flex",
    width: "194px",
    padding: "5px",
    flexDirection: "column" as const,
    alignItems: "center" as const,
    gap: "5px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    background: "#FFF",
    position: "absolute" as const,
    right: '103px',
    top: '50%'
  },
  message: { display: "flex", gap: "8px" },
  alert: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    transform: "translate(-50%, 50%)",
    top: "80%",
    left: "50%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  btnModal: {
    display: "flex",
    height: "36px",
    padding: "9px 20px 9px 16px",
    alignItems: "center" as const,
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: "#FFF",
    justifyContent: 'flex-start'
  },
  btnmodalText: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform: 'none' as const
  },
}
// Customizable Area End