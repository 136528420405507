import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    userName: string;
    dataLoaded: boolean;
    userData: boolean;
    model: boolean;
    toggleForm: boolean;
    id: any;
    params: any;
    form: any;
    namesArray: any;


    // Customizable Area End
}

interface SS {
    id: any;
}

export default class CreateProjectController extends BlockComponent<Props, S, SS> {
    details: string;
    titleWelcome: string;
    createProjectsAPI: any
    createSubTaskAPI: any;
    getColumnInCreateProjectApiCallId: any;
    editProjectApiCallId: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationCreateProjectMessage)
        ];
        this.titleWelcome = configJSON.titleWelcome;
        this.details = configJSON.details;

        this.state = {
            model: true,
            userName: "",
            dataLoaded: false,
            userData: false,
            toggleForm: false,
            id: "",
            params: {},
            form: "",
            namesArray: []
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    // Customizable Area End
    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestId === this.createProjectsAPI) {
                this.createProjectsAPISQ(responseJson);
            }

            if (apiRequestId === this.createSubTaskAPI) {
                this.createSubTaskAPISQ(responseJson);
            }
            if (apiRequestId === this.getColumnInCreateProjectApiCallId) {
                this.getColumnInCreateProjectApiCallIdAPISQ(responseJson);
            }
            if (apiRequestId === this.editProjectApiCallId) {
                this.editProjectApiCallIdSQ(responseJson);
            }

            this.parseApiCatchErrorResponse(errorReponse);
        }

        if (getName(MessageEnum.NavigationCreateProjectMessage) === message.id) {
            const params = message.getData(getName(MessageEnum.NavigationCreateProjectMessage));
            this.setState({ params: params });
            this.navigationCreateProjectMessageSQ(params);
        }

        // Customizable Area End
    }

    getColumnInCreateProjectApiCallIdAPISQ(responseJson: any) {
        const namesArray: any = [];
        responseJson && responseJson.data && responseJson.data.forEach((item: any) => {
            const name = item.attributes.name;
            namesArray.push(name);
        });
        this.setState({ namesArray });
    }

    createProjectsAPISQ(responseJson: any) {
        if (responseJson && responseJson.data !== null) {
            this.setState({ userName: responseJson.data })
            this.gotoWOrkspacePage()
        }
    }

    createSubTaskAPISQ(responseJson: any) {
        if (responseJson && responseJson.data !== null) {
            this.gotoWOrkspacePage()
        }
    }

    navigationCreateProjectMessageSQ(params: any) {
        if (params.toggleForm === "TASK") {
            this.setState({ toggleForm: true, id: params, form: "TASK" });
        }
        else if (params.toggleForm === "EDIT_PROJECT") {
            this.setState({ toggleForm: false, id: params, form: "EDIT_PROJECT" });
        } else {
            this.setState({ toggleForm: false, id: "" })
        }
    }

    token: string = "";
    gotoWOrkspacePage() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "Projectnotes");
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationProjectnotesMessage)
        );
        raiseMessage.addData(
            getName(MessageEnum.NavigationProjectnotesMessage),
            {
                workspaces: this.state.params.workspaces,
                workspace_catalogs: this.state.params.workspace_catalogs,
                projects: this.state.params.projects 
            }
        );
        msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(msg);
    }

    handleClose = () => {
        this.setState({ model: !this.state.model });
        this.gotoWOrkspacePage();
    }

    createSubTask = async (value: any) => {
        let someData = localStorage.getItem("loginData");
        let Parsed = someData && JSON.parse(someData);
        this.token = Parsed?.token;

        this.createSubTaskAPI = await this.getUserAccountapiCall({
            contentType: 'application/json',
            methods: "POST",
            endPoint: "bx_block_tasks/tasks",
            token: this.token,
            body: {
                "task":
                {
                    "project_id": this.state.params.projects,
                    "title": value.project_name,
                    "start_date": value.start_date,
                    "due_date": value.end_date,
                    "status": value.status,
                    "description": "description",
                    "assignee_email": value.assignee_email,
                    "frequency": value.frequency
                }
            },
        })
    }


    createProjects = async (value: any) => {
        let someData = localStorage.getItem("loginData");
        let Parsed = someData && JSON.parse(someData);
        this.token = Parsed?.token;

        this.createProjectsAPI = await this.getUserAccountapiCall({
            contentType: 'application/json',
            methods: "POST",
            endPoint: `bx_block_profile/workspaces/${this.state.params.workspaces}/workspace_catalogs/${this.state.params.workspace_catalogs}/groups/${this.state.params.projects}/projects`,
            token: this.token,
            body: {
                "project":
                {
                    "project_name": value.project_name,
                    "start_date": value.start_date,
                    "end_date": value.end_date,
                    "status": value.status,
                    "description": "",
                    "assignee_email": value.assignee_email,
                    "frequency": value.frequency
                }
            },
        });
    };

    getUserAccountapiCall = async (data: any) => {
        const { methods, endPoint, token, body, } = data;
        const Header = {
            "Content-Type": "application/json",
            token,
        };
        const apiRequestMessageforCreateProjectweb = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        apiRequestMessageforCreateProjectweb.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(Header)
        );
        apiRequestMessageforCreateProjectweb.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        apiRequestMessageforCreateProjectweb.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methods
        );
        body &&
            apiRequestMessageforCreateProjectweb.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

        runEngine.sendMessage(
            apiRequestMessageforCreateProjectweb.id,
            apiRequestMessageforCreateProjectweb
        );
        return apiRequestMessageforCreateProjectweb.messageId;
    }

    getColumnInCreateProject = async () => {
        let someData = localStorage.getItem("loginData");
        let Parsed = someData && JSON.parse(someData);
        this.token = Parsed?.token;

        this.getColumnInCreateProjectApiCallId = await this.getUserAccountapiCall({
            contentType: 'application/json',
            methods: "GET",
            endPoint: "/bx_block_profile/custom_columns",
            token: this.token,
        });
    }

    editProject = async (value: any) => {
        let someData = localStorage.getItem("loginData");
        let Parsed = someData && JSON.parse(someData);
        this.token = Parsed?.token;

        this.editProjectApiCallId = await this.getUserAccountapiCall({
            contentType: 'application/json',
            methods: "PATCH",
            endPoint: `bx_block_profile/workspaces/${this.state.params.workspaces}/workspace_catalogs/${this.state.params.workspace_catalogs}/groups/${this.state.params.group_id}/projects/${this.state.params.projects}`,
            token: this.token,
            body: {
                "project": {
                    "project_name": value.project_name,
                    "start_date": value.start_date,
                    "end_date": value.end_date,
                    "status": value.status,
                    "description": value.description,
                    "assignee_email": value.assignee_email,
                    "frequency": value.frequency,
                    "custom_columns": {
                        "text": value.custom_columns.text,
                        "email": value.custom_columns.email,
                        "numbers": value.custom_columns.numbers,
                        "phone": value.custom_columns.phone,
                        "date": value.custom_columns.date,
                    }
                }
            }
        });
    }
    editProjectApiCallIdSQ(responseJson: any) {
        this.gotoWOrkspacePage()
    }
    // Customizable Area End
}
