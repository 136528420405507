import React from 'react'
import { Dialog, Button, makeStyles, FormControl, TextField, Typography, Card, Divider, FormLabel, MenuItem, } from '@material-ui/core';

import Select from "react-select";
import { Close } from '@material-ui/icons';


const folderStyle= makeStyles({
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: '#E57727',
    },
    padding: '16px',
    width: '118px',
    fontWeight:700,
    borderRadius: '8px',
      "text-transform": "none",
      "color": "white"
    },
    formStyle:{
      marginBottom: "0.94rem",
      display: "flex", width: "32.5rem",
      height: "5.13rem", flexDirection: "column" as const,
      justifyContent: "center",
      alignItems: "flex-start",
  
    },
    cancelBtn: {
      color: '#E57727',
      background: "#F6F2EF",
      width:"105px !important",
      height:"56px",
      borderColor: '#F6F2EF',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      textTransform: "capitalize" as const
  },
    divStyle:{display: "flex",
    padding: "2.5rem 2.5rem",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    gap: "1rem", alignSelf: "stretch",
    width: "32.5rem"},
    dialogBox:{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow:
        " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    },
      rowField: {
      display: "flex",
      width: "1149px",
      padding: "10px 10px",
  
      alignItems: "center",
      gap: "10px",
      borderRadius: "6px 6px 0px 0px",
      border: "0.5px solid  #CBD5E1",
      height: "36px",
      justifyContent: 'space-between',
      position: "relative" as const
    },
    cancelbtn: {
      display: "flex",
      width: "120px",
      height: "56px",
      padding: "16px 37.5px 16px 37.5px",
      justifyContent: "flex-end",
      alignItems: "center",
      flexShrink: 0,
      borderRadius: "8px",
      background: "#F6F2EF",
      marginRight: "1rem"
    },
    cancelbtnText: {
      color: "#E57727",
      textAlign: "center" as const,
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none" as const
    },
    closeMOdalCross: {
      display: "flex",
      width: "32px",
      height: "32px",
      padding: "4px",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      cursor: 'pointer'
    },
  })
type FoldersTemplateProps = {
    openDialog:boolean,
    handleOk: any;
    folderNameErr:any;
    errorMessage:any;
    handleCancel:any;
    showDialogBtns?: boolean;
    showCrossBtn?: boolean;
    createFolder?:boolean;
}

const FoldersTemplate = (props:FoldersTemplateProps) => {
  console.log(props,"Folder");
  
    const classes = folderStyle();   
      
  return (
    <div>
<Dialog
className={classes.dialogBox}
data-test-id="editmodal"
open={props.openDialog}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
<Card
  style={{
    maxWidth: "37.5rem",

    borderRadius: "0.5rem",


  }}
>

  <div
    style={{
      display: "flex",
      height: "2rem", padding: "1.5rem 1rem 1.5rem 2.5rem",
      justifyContent: "space-between",
      alignItems: "center", alignSelf: "stretch",

    }}
  >
    <p
      style={{
        fontSize: "1.5rem",
        fontWeight: 700, color: "#000000",
      }}
    >
      {!props.createFolder ? "Create new folder" : "Create sub folder"}

    </p>
    <Close className={classes.closeMOdalCross} onClick={()=>props.handleCancel()} data-test-id="closefolder" />

  </div>
  <Divider />
  <div
  className={classes.divStyle}
    style={{
       height: !props.createFolder ? "5.13rem" : "11.25rem",
    }}
  >
    {!props.createFolder ?
      <FormControl
        className={classes.formStyle}
      >
        <FormLabel style={{
          paddingBottom: "10px", fontWeight: 700,
          color: "#64748B", fontSize: '14px',
          lineHeight: '22px'
        }}

        >
          Folder name
        </FormLabel>
        <TextField type="text" variant="outlined"
          style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
          name="date"
          color="primary"
          InputProps={{

            style: {
              color: "#64748B", height: '56px',
              padding: '10px 8px'
            },
            placeholder: "Untitled folder"
          }}
          fullWidth
        />
          {props.folderNameErr && (
                  <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                    {props.errorMessage}
                  </Typography>
                )}
      </FormControl> : <div>
        <FormControl style={{ width: "100%", marginBottom: "20px" }}>
          <FormLabel id="template-type1" style={{
            fontWeight: 700,
            color: "#64748B", fontSize: '14px',
            lineHeight: '22px'
          }}

          >
            Select Folder
          </FormLabel>

          <Select
            id="template-type1"
            variant="outlined"
            name="template"
            displayEmpty
            placeholder="Select Option"
            // value={userdata && userdata.template}
            style={{ backgroundColor: "#ffffff", "border": "1px solid #CBD5E1" }}
            MenuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' }, getContentAnchorEl: null
            }}
          // onChange={this.handleChange}
          >
            <MenuItem value="" disabled  >
              <span style={{ opacity: 0.5, color: "#64748B" }}>Select template</span>
            </MenuItem>
            <MenuItem value={'Folder1'}>Folder1</MenuItem>
            <MenuItem value={'Folder12'}>Folder2</MenuItem>
            <MenuItem value={'Folder3'}>Folder3</MenuItem>
          </Select>


        </FormControl>
        <FormControl
          style={{
            marginBottom: "0.94rem",
            display: "flex", width: "32.5rem",
            height: "5.13rem", flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",

          }}
        >
          <FormLabel style={{
            fontWeight: 700,
            color: "#64748B", fontSize: '14px',
            lineHeight: '22px'
          }}

          >
            Sub folder name
          </FormLabel>
          <TextField type="text" variant="outlined"
            style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
            name="date"
            color="primary"
            InputProps={{

              style: {
                color: "#64748B", height: '56px',
                padding: '10px 8px'
              },
              placeholder: "Untitled folder"
            }}
            fullWidth
          />
        </FormControl>
      </div>}

      
  </div>
  <Divider />
  <div
    style={{
      display: "flex",
      padding: "16px 35.5px 16px 35.5px",
      justifyContent: "flex-end",
      alignItems: "center", alignSelf: "stretch",
    }}
  >
    <Button className={classes.cancelbtn} onClick={()=>props.handleCancel()}><Typography
      className={classes.cancelbtnText}>Cancel</Typography></Button>
    <Button

      data-test-id="cancelbtn" variant="contained" style={{
        display: "flex", width: "7.5rem", height: "3.5rem",
        padding: "1rem 0",
        borderRadius: "0.5rem", backgroundColor: "#E57727",
        color: "#FFF", fontWeight: 700,
        fontSize: "1rem",
      }}
      onClick={()=>props.handleOk()}
    >
      Ok
    </Button>
  </div>

</Card>
</Dialog>

    </div>
  )
}

export default FoldersTemplate

