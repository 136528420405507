import React, { useRef, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, OutlinedInput, Grid, FormControlLabel, FormControl, RadioGroup, Radio, Typography, TextareaAutosize, } from '@material-ui/core';
import Select from "react-select";
import { AddCircleOutline, Close } from '@material-ui/icons';
import { Formik,useField} from 'formik';
import * as Yup from "yup";


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            margin: '0',
            borderRadius: '8px',
            width: '624px',

        }
    },
    headingStyle: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    btnWrapper:{
        justifyContent: 'flex-end',
        padding: "24px 24px 24px 350px",
        borderTop: "1px solid #E2E6F0",
        display: "inline-flex",
        alignItems: "flex-start",
        gap: "16px",
    
        '& button': {
            padding: '16px',
            width: '105px',
            fontWeight:700,
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: '#E57727',
    },
      "text-transform": "none",
      "color": "white"
    },
    cancelBtn: {
      color: '#000',
      background: "#F6F2EF",
      width:"105px !important",
      height:"56px",
      borderColor: '#F6F2EF',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      textTransform: "capitalize"
  },
    textInputStyle: {
      margin: "0 !important",
      "& .MuiOutlinedInput-input": {
        textTransform: "uppercase",
      },
      "& .css-yk16xz-control": {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        padding:"8px"
      },
      "& .css-1pahdxg-control": {
        // height: "56px",
        padding:"8px",
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
      },
      "& .css-26l3qy-menu": {
        height: "106px",
      },
      "&:hover .css-1pahdxg-control": {
        // height: "56px",
        borderColor: "#2d6f8f"
      }
    },
    textInputStyle1: {
      margin: "0 !important",
      "& .MuiOutlinedInput-input": {
        textTransform: "uppercase",
      },
      "& .css-yk16xz-control": {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        padding:"8px"
      },
      "& .css-1pahdxg-control": {
        // height: "56px",
        padding:"8px",
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
      },
      "& .css-26l3qy-menu": {
        height: "156px",
        top:"-156px"
      },
      "&:hover .css-1pahdxg-control": {
        // height: "56px",
        borderColor: "#2d6f8f"
      }
    },
    labelStyle:{fontSize:"14px",fontWeight:700,lineHeight:"22px",color:"#334155"},
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 40px",
    },
    multiline:{
        width: '520px', height:"98px",padding:"9px",resize: 'vertical', fontSize:"16px",fontWeight:400,borderRadius:"8px" 
        },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    reasonsSection: {
        // marginBottom: '25px !important',
        display:"flex",
        flexDirection:"column",
        gap:"10px",
        width:"520px"
    },
    radioH:{
        "& .MuiTypography-body1": {
          fontSize: "16px !important",
          color: "#0F172A",
          fontFamily: "Inter",
          lineHeight: "24px",     
      },
      '& .MuiRadio-root.Mui-checked': {
        color: '#e87242',
      },
      '& .MuiRadio-root:not(.Mui-checked)': {
        color: 'grey !important',
      },
    },
    downloaIcon: {
        cursor: "pointer"
    },
})
const enumType={
    RECCURING:"reccuring",
    ONETIME:"onetime"
}

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1, "Name is too short")
  .max(20,"Name is too long")
  .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Name is invalid')
  .required("Name is required."),
  description: Yup.string().min(1, "Description name is too short")
  .max(20,"Description name is too long")
  .required('Description name is required'),
  amount: Yup.string()
  .matches(/^(?!0\d*$)\d+$/, 'Invalid amount')
  .required('Amount is required'),
  billing:Yup.string()
  .required('Billing period is required')
  .oneOf(['yearly', 'monthly',"quatarly"], 'Invalid period'),
  currency: Yup.string()
  .required('Currency is required')
  .oneOf(['usd'], 'Invalid currency'),
  date: Yup.date()
  .required('Date is required'),
});
type AddServiceProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText?: string,
    btnOkText: string,
    handleOk: any;
    handleCancel:any;
    primaryColor:string;
    secondaryColor:string;
    subHeading?: string;
    showDialogBtns?: boolean;
    handleType?: any;
    TypeSelect?:any;
}




const AddService = (props:AddServiceProps) => {
    const classes = useStyles();
    const [isFile,setFile]=useState("")
    const formikRef = useRef<any>(null);
    const fileInput:any=React.createRef()


  const handleButtonClick = () => {
    formikRef?.current?.submitForm();
  };
  const handleImageChange = (e:any) => {
    if (fileInput.current.files.length > 0) {
        const file = fileInput.current.files[0];
        if (file) {
            setFile(file);
        }
    }
  };
  const handleInputButtonClick = () => {
    
    fileInput.current.click();
  };
  const currencyList=[
    {
      value: null,
      label: "Select currency type",
      labelValue: ""
    },
    {
      value: 1,
      label: "USD",
      labelValue: "usd"
    },
  ]
  const billingList=[
    {
      value: null,
      label: "Select billing type",
      labelValue: ""
    },
    {
      value: 1,
      label: "Monthly",
      labelValue: "monthly"
    },
    {
      value: 2,
      label: "Quatarly",
      labelValue: "quatarly"
    },
    {
      value: 3,
      label: "Yearly",
      labelValue: "yearly"
    },
  ]
      
  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                    <span className={classes.subHeading}>{props.subHeading}</span>
                </div>
                <Close
                    className={classes.downloaIcon}
                    // onClick={props?.handleCancel}
                    onClick={() => {
                      props?.handleCancel()
                    }} 
                />
           </div>
        </DialogTitle>
        <DialogContent style={{ display:"grid",padding: "24px 40px 8px"}}>
        <Formik
                innerRef={formikRef}
                initialValues={{ name: "", description: "",date:"",amount:"", currency:"" ,image:isFile,billing:"" }}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                props.handleOk(values);
                actions.setSubmitting(false)
                actions.resetForm();
                }}
              >
                {({
                  handleSubmit,
                  touched,
                  handleBlur,
                  errors,
                  values,
                  setFieldValue,
                  handleChange,
                }) => (
                  <form style={{margin:"0",gap:"16px",display:"grid"}}onSubmit={handleSubmit}>



          <Grid className={classes.reasonsSection} item xs={12}>
          
               <label className={classes.labelStyle}>Name</label>
               <OutlinedInput
                  
                    fullWidth
                    name="name"
                    id="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter name"
                />
                    {touched.name && errors.name && (
                    <Typography color="error" >{errors.name}</Typography>
                    )}
                
          </Grid>

          <Grid className={classes.reasonsSection} item xs={12}>

            <label className={classes.labelStyle}>Description</label>
            <TextareaAutosize
            maxRows={5}
            className={classes.multiline}
            style={{border: touched.description && errors.description ? '1px solid red' : "1px solid #CBD5E1",height:"98px",minHeight:"50px"}} 
            name="description"
            id="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            placeholder="Type here"
            />
            {touched.description && errors.description && (
            <Typography color="error">{errors.description}</Typography>
            )}
            </Grid>

            <Grid className={classes.reasonsSection} style={{alignItems:"center", border:"2px dashed grey",borderRadius:"8px"}} item xs={12}>
            <input
              type="file"
              name="image"
              accept=".png, .svg, .jpg, image/png, image/svg+xml, image/jpeg"
              onBlur={handleBlur}
              onChange={(e) => { handleImageChange(e); handleChange(e) }}
              style={{ display: 'none' }}
              ref={fileInput}
            />
            <Button variant="outlined" style={{color:props.secondaryColor,background:"#fff",width:"180px !important"}}
                        onClick={() => {
                          handleInputButtonClick()
                        }}
                    >
                      <AddCircleOutline style={{marginRight:"8px"}}/>  Service image
                    </Button>
              
            </Grid>
          <Grid className={classes.reasonsSection} item xs={12}>

            <label className={classes.labelStyle}>Date</label>
            <TextFieldControl
            data-test-id={`date`}
            id={`date`}
            className={classes.textInputStyle}
            name={`date`}
            type={"date"}
            onBlur={handleBlur}
            placeholder="Start Date"
            style={{textTransform:"uppercase"}}
            onChange={handleChange}
            inputProps={{
            min: new Date().toISOString().split('T')[0],
            }}
            />
            {touched.date && errors.date && (
            <Typography color="error" >{errors.date}</Typography>
            )}

            </Grid>

     <Grid className={classes.reasonsSection} item xs={12}>
                <FormControl>
                <RadioGroup aria-labelledby="demo-row-radiobutton-group-label" name={"format"}
                className={classes.radioH}
                data-test-id="radioBtn"
                  defaultValue={props.TypeSelect ? enumType.RECCURING : enumType.ONETIME}
                  value={props.TypeSelect ? enumType.RECCURING : enumType.ONETIME}
                  onChange={(event: any) => {
                    handleChange(event);
                    props.handleType()
                  }}
                >
    <FormControlLabel checked={props.TypeSelect === true} value={enumType.RECCURING} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1" style={{
              fontFamily: "Inter",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: "left",
              fontWeight: 400 }}>
              Recurring
            </Typography>
          } />
    <FormControlLabel checked={props.TypeSelect === false} value={enumType.ONETIME} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1" style={{ fontFamily: "Inter",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "left",fontWeight: 400 }}>
              One-Time
            </Typography>
          } />
  </RadioGroup>
</FormControl>

     </Grid>

     <Grid style={{display:"flex",gap:"24px"}} item xs={12} >
          <Grid item style={{display: "grid",gap: "5px",width:"248px"}} >
          <label className={classes.labelStyle}>Amount</label>
          <OutlinedInput
              
               fullWidth
               name="amount"
               id="amount"
               value={values.amount}
               onBlur={handleBlur}
               onChange={handleChange}
               placeholder="Enter amount"
           />
               {touched.amount && errors.amount && (
               <Typography color="error" >{errors.amount}</Typography>
               )}
     </Grid>
     <Grid item style={{display: "grid",gap: "5px",width:"248px"}} >
     <label className={classes.labelStyle}>Currency</label>
          
     <Select
                    name="currency"
                    data-test-id="currency"
                    className={classes.textInputStyle}
                    options={currencyList}
                    value={currencyList.find((option: any) => option.value === Number(values.currency))}
                    onChange={(option: any) =>
                      setFieldValue('currency', option?.labelValue)
                    }
                    placeholder="Select"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontWeight: state.data.value === null ? 400 : 700,
                        color: state.data.value === null ? "#64748B" : provided.color,
                      }),
                    }}
                  />
                  {touched.currency && errors.currency && (
                    <Typography color="error" >{errors.currency}</Typography>
                  )}
      </Grid>
      </Grid>
      <Grid item className={classes.reasonsSection} style={{marginBottom:"40px"}} xs={12}>
      <label className={classes.labelStyle}>Billing period</label>
          
          <Select
                         name="billing"
                         data-test-id="billing"
                         className={classes.textInputStyle1}
                         options={billingList}
                         value={billingList.find((option: any) => option.value === Number(values.billing))}
                         onChange={(option: any) =>
                           setFieldValue('billing', option?.labelValue)
                         }
                         placeholder="Select"
                         styles={{
                           option: (provided, state) => ({
                             ...provided,
                             fontWeight: state.data.value === null ? 400 : 700,
                             color: state.data.value === null ? "#64748B" : provided.color,
                           }),
                         }}
                       />
                       {touched.billing && errors.billing && (
                         <Typography color="error" >{errors.billing}</Typography>
                       )}
      </Grid>
      </form>
                )}
        </Formik>
      </DialogContent>
        
    <DialogActions className={classes.btnWrapper}>
<Button variant="outlined" className={classes.cancelBtn} style={{color:props.secondaryColor}}
                        onClick={() => {
                            props.handleCancel()
                        }}
                    >
                        {props.btnCancelText}
                    </Button>
<Button variant="contained"  className={classes.primaryBtnStyle} style={{background:props.primaryColor}}
 type='submit' onClick={handleButtonClick}>
                            {props.btnOkText}
                        </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const TextFieldControl = (props: any) => {
  const [field, meta] = useField(props);

  return (
      <OutlinedInput
          fullWidth
          type={props.type}
          {...field}
          {...props}
          error={meta.error && meta.touched}
      />
  );
};

export default AddService

