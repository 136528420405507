// Customizable Area Start
import React from "react";
import {
  Container,
  Button,
  Typography,
  Grid,
  OutlinedInput,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons"
import * as Yup from "yup";
import ProjectTemplatesController, {
  Props,
} from "./ProjectTemplatesController.web";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Formik } from "formik";
import Toggle from "../../../components/src/Toggle.web";
import { Alert } from "@material-ui/lab";
import { imgSuccess } from "./assets";

const enumType = {
  WYSIWYG: "wysiwyg",
  HTML: "html"
}

export class ContractTemplate extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const { classes } = this.props
    const modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        [{ undo: true, redo: true }],
        ['clean'],
        ['copy'],
      ],
    };

    const formats = [
      'header',
      'bold', 'italic', 'underline', 'strike',
      'align',
      'blockquote', 'code-block',
      'list', 'bullet',
      'link', 'image', 'video',
    ];
    const validationSchema = Yup.object().shape({
      account_name: Yup.string().min(1, "Field is too short")
        .max(30, "Field is too long")
        .required("This field is required."),
      description:Yup.string().min(20, "Description is too short, min 20 words required")
      .required("Description is required."),
    });
    return (

      <Container maxWidth={"xl"}>
        <div style={{ display: "grid", gap: "30px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography style={contractStyle.createStyle}>Create Contract Template</Typography>
            <div style={{ display: "flex", gap: "12px" }}>
              <Typography style={contractStyle.btnTxt}>
                <AddCircleOutline style={{ marginRight: "8px" }} />
                Add Invoice
              </Typography>
              <Typography style={contractStyle.btnTxt}>
                <AddCircleOutline style={{ marginRight: "8px" }} />
                Add Signature
              </Typography>
              <Typography style={contractStyle.btnTxt}>
                <AddCircleOutline style={{ marginRight: "8px" }} />
                Add Service
              </Typography>
            </div>


          </div>
          <Formik
            data-testId="ContractForm"
            initialValues={{ account_name: "", description: "", format: false, send_reminder_to_client: false }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnMount={true}
            validateOnBlur={true}
            onSubmit={(values, actions) => {
              this.createContractTemplate(values)
              actions.setSubmitting(false)
              actions.resetForm();
            }}
          >
            {({
              handleSubmit,
              touched,
              handleBlur,
              errors,
              values,
              setFieldValue,
              handleChange,
            }) => (
              <form style={{ display: "grid", margin: "0", gap: "20px" }} onSubmit={handleSubmit}>
                <Grid style={{display:"grid",gap:"5px"}} item xs={12}>

                  <label style={contractStyle.label}>Account name, id or Email</label>
                  <OutlinedInput
                    fullWidth
                    name="account_name"
                    id="account_name"
                    onBlur={handleBlur}
                    value={values?.account_name}
                    onChange={handleChange}
                    placeholder="Enter your template name"
                  />
                  {touched.account_name && errors.account_name && (
                    <Typography color="error" >{errors.account_name}</Typography>
                  )}

                </Grid>
                <Grid item xs={12} >

                  <FormControl>
                    <RadioGroup row aria-labelledby="demo-row-radiobutton-group-label" name={"format"}
                      data-test-id="radioBtn"
                      className={classes.radioBtn}
                      defaultValue={this.state.emailTypeSelect ? enumType.WYSIWYG : enumType.HTML}
                      value={this.state.emailTypeSelect ? enumType.WYSIWYG : enumType.HTML}
                      onChange={(event: any) => {
                        handleChange(event);
                        this.handleEmailType()
                      }}
                    >
                      <FormControlLabel checked={this.state.emailTypeSelect === true} value={enumType.WYSIWYG} control={<Radio style={{ color: "#e87424" }} />} label={
                        <Typography variant="body1" style={{ fontWeight: this.state.emailTypeSelect ? 700 : 400 }}>
                          WYSIWYG
                        </Typography>
                      } />
                      <FormControlLabel checked={this.state.emailTypeSelect === false} value={enumType.HTML} control={<Radio style={{ color: "#e87424" }} />} label={
                        <Typography variant="body1" style={{ fontWeight: this.state.emailTypeSelect === false ? 700 : 400 }}>
                          HTML
                        </Typography>
                      } />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {this.state.emailTypeSelect ?
                  <Grid item >
                  <ReactQuill
                    data-test-id="txtInputQuill"
                    className={classes.editor}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={values?.description}
                    onChange={(content) => {
                      setFieldValue("description", content);
                    }}
                  />
                  {touched.description && errors.description && (
                    <Typography color="error" >{errors.description}</Typography>
                  )}

                </Grid>
                            : <Grid>
                                <OutlinedInput
                                data-test-id="txtInputAccountName"
                                fullWidth
                                multiline
                                rows={5}
                                name="description"
                                id="description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.description}
                                placeholder="Type here"
                                error={touched.description && Boolean(errors.description)}
                              />
                              {touched.description && errors.description && (
                                <Typography color="error">{errors.description}</Typography>
                              )}
                              </Grid>}
                <Grid style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  <Toggle
                    name="send_reminder_to_client"
                    data-test-id="reminder-customer"
                    style={contractStyle.toggleBtnss}
                    isChecked={this.state.toggle}
                    color="#E57727"
                    handleToggle={this.handleChangeToggle}
                  />
                  <Typography style={{
                    color: "#0F172A",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}>Send reminders to client</Typography>
                </Grid>

                <Button id="btnContract" variant="contained" style={contractStyle.primaryBtnStyle} type='submit'>
                  Create
                </Button>
              </form>
            )}
          </Formik>
        </div>
        {this.state.showAlertPop &&
              <Alert style={{ ...contractStyle.alertDivs, position: "absolute" }} icon={false} severity="success">
                <div style={contractStyle.messageDivs} >
                  <img src={imgSuccess} style={{ verticalAlign: "bottom" }} />
                  {console.log(this.state.alertMessage,"contract web web")}
                  <Typography variant="body1">{this.state.alertMessage}</Typography>
                </div>
              </Alert>}
      </Container>

    );
  }
}
const contractStyle = {
  createStyle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px",
  },
  primaryBtnStyle: {
    padding: '16px',
    width: '118px',
    fontWeight: 700,
    borderRadius: '8px',
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    "text-transform": "none",
    "color": "white"
  },
  toggleBtnss: {
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      background: "#E57727",
    },
  },
  label:{
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px", 
  },
  messageDivs: { display: "flex", gap: "8px" },
  alertDivs: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    transform: "translate(-50%, 50%)",
    top: "80%",
    left: "50%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  btnTxt:{ display: "flex", alignItems: "center", justifyContent: "center", color: "#396178" }
}
export default withStyles(contractStyle)(ContractTemplate)
// Customizable Area End