// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Box,
  IconButton,
  Grid,
  Button
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LandingPageController, {
  Props,
} from "./LandingPageController.web";
import Loader from "../../../components/src/Loader.web";
import "./index.css"
import { MoreVert as MoreVertIcon, Toc as TocIcon, Reply, DeleteOutline } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { BoardWorkspaceModel, CreateWorkspaceModel } from "../../../components/src/WorkspaceModel";
import { ModelContext } from "../../../components/src/Context";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

const componentStyles = {
  mainDiv: {
    width: "100%",
    height: "100%",
    background: "#fff",
  },
  centerContent: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 30px"
  },
  logoImage: {
    height: "100%",
    width: '10%'
  },
  txt: {
    fontSize: "3rem",
    marginTop: "20%"
  },
  label: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px", /* 150% */
  },
  subTitle: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px", /* 144.444% */
  },
  textInputStyle: { marginTop: "10px" },
  labelInputStyle: {
    color: "#000",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px"
  },
  sublabel: { color: "#0F172A", marginTop: "5px", fontWeight: 400, lineHeight: "24px", fontSize: '16px' },
  submitBtnStyle: {
    borderRadius: "8px",
    textTransform: "initial",
    background: "#E57727",
    color: "#fff",
    padding: '8px 16px',
    fontSize: "16px",
    fontweight: 700,
  },
  displayFlex: {
    display: "flex"
  },
}

export default class Home extends LandingPageController {
  static contextType: React.Context<any | undefined> = ModelContext;
  constructor(props: Props) {
    super(props);
  }
  componentDidMount(): any {
    this.getWorkspace();
  }
  render() {
    const { isDrawerOpen } = this.state;
    const { openModel } = this.context;

    return (
      // Required for all blocks 
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl" className="cardmain">
          <Box className="cardsize" >
            <Grid container spacing={3} data-test-id="map">
              {this.state.workspaceData.length > 0 && this.state.workspaceData.map((item: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={6} md={6} lg={4} >
                      <Card className="cardwrap" data-test-id="card_wrap_index" onClick={(e) => { this.handleDrawerOpen(item.id, item.attributes.name) }}>
                        <CardContent className="cardbox">
                          <Box className="cardicon">
                            <Box className="cardiconbg">
                              <TocIcon />
                            </Box>
                            <Box>
                              <Typography variant="h6">
                                {item && item.attributes && item.attributes.name}
                              </Typography>
                              <Typography variant="body1">{item?.attributes?.description}</Typography>
                            </Box>
                          </Box>
                          <IconButton data-test-id="iconbutton" onClick={(e) => { e?.stopPropagation(); this.handleClick(e ,item); }}>
                            <MoreVertIcon />
                          </IconButton>
                          {this.state.showMOdal && this.state.selectedItem == item ?
                            <Box style={useStyles.actionModalBox}>
                              <Button style={useStyles.repltybtn} data-test-id="editButton" onClick={(e) => { e?.stopPropagation(); openModel('model1') }} > <Reply /><Typography style={useStyles.replyText} >Edit</Typography></Button>
                              <Button style={useStyles.deletebtn} data-test-id="deleteButton" onClick={(e) => { e?.stopPropagation(); this.deleteWorkspace(item.id) }}> <DeleteOutline style={{ color: "#DC2626" }} /><Typography style={useStyles.deleteText}>Delete Workspace</Typography></Button>
                            </Box> : null}
                        </CardContent>
                      </Card>
                    </Grid>
                  </React.Fragment>
                )
              })}
            </Grid>
          </Box>
          <Box>
            <Drawer
              data-test-id="Drawer_component"
              anchor="right"
              open={isDrawerOpen}
              onClose={() => this.setState({ isDrawerOpen: !this.state.isDrawerOpen })}
            >
              <div style={useStyles.statusModalHeader} id="alert-dialog-title">
                <span style={{ width: '90%' }}>{this.state.boardName}  </span>
                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Button style={{...useStyles.addBoardsBtn,background:this.state.primaryColor}}
                    onClick={() => { openModel('model2') }}
                    data-test-id="addButton"
                  >
                    Add boards
                  </Button>
                  <CloseIcon onClick={() => this.setState({ isDrawerOpen: !this.state.isDrawerOpen })} />
                </Box>
              </div>

              <div style={useStyles.TaxesBody} >
                <Grid container spacing={5}>
                  {this.state.WorkspaceCatelogData.length > 0 && this.state.WorkspaceCatelogData.map((item: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                       <Grid item xs={6}
                          onClick={() => {
                            const catelogid = item && item.id;
                            const boardName = item && item.attributes && item.attributes.name;
                            const workspaceName = item && item.attributes && item.attributes.workspace && item.attributes.workspace.attributes && item.attributes.workspace.attributes.name;
                            this.gotoWorkspace({
                              catelogid: catelogid,
                              boardName: boardName,
                              workspace_name: workspaceName
                            })
                          }}
                          data-test-id="goto-workspace-test-id"
                        >
                          <Typography style={useStyles.textCard}>
                            {item?.attributes?.name}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )
                  })}
                </Grid>
              </div>
            </Drawer>
          </Box>

          <CreateWorkspaceModel
            createWorkspace={this.createWorkspace}
            editWorkspace={this.editWorkspace}
            selectedItem={this.state.selectedItem}
            primaryColor={this.state.primaryColor}
            secondaryColor={this.state.secondaryColor}
          />
          <BoardWorkspaceModel
            createWorkspaceCatalogs={this.createWorkspaceCatalogs}
            workspaceCatalogsid={this.state.workspaceCatalogsid}
            primaryColor={this.state.primaryColor}
            secondaryColor={this.state.secondaryColor}
          />
        
        </Container>
      </ThemeProvider>
    );
  }
}
const useStyles = ({
  actionModalBox: {
    "display": "flex",
    "width": "192px",
    "height": "75px",
    "padding": "4px",
    "flexDirection": "column",
    "justifyContent": "center",
    "alignItems": "flex-start",
    "gap": "2px",
    "flexShrink": "0",
    "borderRadius": "8px",
    "border": "1px solid #E2E8F0",
    "background": "#FFF",
    "boxShadow": "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    position: 'absolute',
    top: '44px',
    right: "24px",
    zIndex: 1
  },
  statusModalHeader: {
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    color: "#0F172A",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px",
    borderBottom: "1px solid #E2E8F0", background: "#FFF",
    minWidth: '500px',
    alignItems: 'center',
  },
  TaxesBody: {
    padding: '24px 40px',

  },
  addBoardsBtn: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    padding: '10px 16px',
    borderRadius: '8px',
    background: '#E57727',
    color: "#fff",
    width: '150px',
    textTransform: "none"
  },
  textCard: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "6px",
    borderLeft: "4px solid #E57727",
    background: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    padding: '16px 20px'

  },
  inboxbtn: {
    "display": "flex",
    "height": "36px",
    "padding": "7px 56px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "flexShrink": "0",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "background": "#FFF",
    "justifyContent": "flex-start",
  },
  deletebtn: {
    "display": "flex",
    "height": "36px",
    "padding": "7px 16px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "flexShrink": "0",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "background": "#FFF",
    "justifyContent": "flex-start",
  },
  replyText: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "22px",
    textTransform: "none"
  },

  deleteText: {
    "color": "#DC2626",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "22px",
    textTransform: "none"
  },
  repltybtn: {
    "display": "flex",
    "height": "36px",
    "padding": "7px 105px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "flexShrink": "0",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "background": "#F0E5FF",
    "justifyContent": "flex-start",
  },

});

// Customizable Area End