import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react"
// Customizable Area Start
import { defaultImage } from "./assets"
import { getThemeColor,getSecondaryThemeColor } from "../../../components/src/CommonPlatte";
export interface UserProfileData {
  attributes: {
    full_name: string;
    email: string;
    address: string;
    bio: string;
    profile:any;
    custom_field:any
    
  };
}

export interface FormDataFields {
  'account[first_name]': string;
  'account[email]': string;
  'account[address]': string;
  'account[bio]': string;
  'account[profile]': File;
'account[custome_field]':boolean
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addFieildModal: boolean;
  editDataModal: boolean;
  userProfileData: UserProfileData;
  customField:any;
  showUploadCard: boolean;
  addfieldName:string;
  addfieldDescr:any;
  addfieldError:boolean;
  addfieldDesError:boolean;
  fieldCustom:boolean
  primaryColor:string;
  secondaryColor:string;
  selectedFile: File | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  showUserProfileData: any
  upDateProfileApi: any
  addFielddataAPI:any
  fileInput: React.RefObject<any>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileInput = React.createRef();
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start

      addFieildModal: false,
      editDataModal: false,
      userProfileData: {
        attributes: {
          full_name: "",
          email: "",
          address: "",
          bio: "",
          profile:{},
          custom_field:[]
        }
      },
      primaryColor:getThemeColor(),
      secondaryColor:getSecondaryThemeColor(),
      showUploadCard: false,
      selectedFile: null,
      addfieldDescr:"",
      addfieldName:"",
      addfieldDesError:false,
      addfieldError:false,
      fieldCustom:false,
      customField:[]
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start

    this.getUserProfile()
    // Customizable Area End
  }

  // Customizable Area Start



  token: string = "";
  handleChangeAddFieldname=(event:any)=>{
    this.setState({addfieldName:event?.target?.value})
   }
  
  

   handleChangeAddFieldDescr=(event:any)=>{
     this.setState({addfieldDescr:event?.target?.value})
    }
    handleFieldError = () => {
      const { addfieldName, addfieldDescr } = this.state;
    
      const addfieldError = addfieldName.trim() === "";
      const addfieldDesError = addfieldDescr.trim() === "";
    
      this.setState({
        addfieldError,
        addfieldDesError,
        fieldCustom: true,
      });
    
      if (!addfieldError && !addfieldDesError) {
        this.addFielddata();
      }
    };
    
   fieldsToExclude: string[] = ["id", "account_id", "created_at", "updated_at"];

    filterFields(originalObject:any, fieldsToExclude:any) {
      const filteredObject:any = {};
      for (const key in originalObject) {
          if (!fieldsToExclude.includes(key)) {
              filteredObject[key] = originalObject[key];
          }
      }
      return filteredObject;
  }

  handleInputChange = (key: string, newValue: any) => {
    this.setState((prevState) => ({
      customField: {
        ...prevState.customField,
        [key]: newValue
      }
    }));
  };

  handleaddFieldModal1 = () => {
    this.setState({ addFieildModal: !this.state.addFieildModal,addfieldDesError:false,addfieldError:false,addfieldName:"",addfieldDescr:""});
  };


  getUserProfile = async () => {
    let userData = localStorage.getItem("loginData");
  
    
    let someDataParsed = userData && JSON.parse(userData);
    this.token = someDataParsed?.token;


    this.showUserProfileData = await this.userProfileAPIcall({
      methods: "GET",
      endPoint: `account_block/accounts/show`,

      token: this.token,
    });

  };

  formDataCall = () => {
    const formdata = new FormData();

    if(this?.state?.userProfileData?.attributes !== undefined)
{
     this?.state?.userProfileData?.attributes['full_name'] !== "" && formdata.append('account[full_name]', this.state.userProfileData.attributes.full_name);
     this?.state?.userProfileData?.attributes['email'] !== "" && formdata.append('account[email]', this.state.userProfileData.attributes.email);
     this?.state?.userProfileData?.attributes['address'] !== null && formdata.append('account[address]', `${this.state.userProfileData.attributes.address}`);
     this?.state?.userProfileData?.attributes['bio'] !== null && formdata.append('account[bio]', `${this.state.userProfileData.attributes.bio}`);
    this?.state?.selectedFile &&
    formdata.append('account[profile]', this.state.selectedFile)

    if (this.state.customField) {
      for (const key in this.state.customField) {
        const value = this.state.customField[key];
        formdata.append(`custom_fields[${key}]`, value);
      }
    }

   
  

    return formdata;}
  }

  formDataAddFieldCall = () => {
    const formdata = new FormData();

    formdata.append('new_column_name', this.state.addfieldName)
    formdata.append('new_data', this.state.addfieldDescr)
    return formdata;
    }

  updateProfile = async () => {
    const formdata = this.formDataCall();
    this.upDateProfileApi = await this.userProfileAPIcall({

      methods: 'PUT',
      endPoint: `account_block/accounts/update`,
      Body: formdata,
      token: this.token,
     

    })
    this.setState({ showUploadCard: false })
  }


  addFielddata = async () => {
    const formdata = this.formDataAddFieldCall();
    this.addFielddataAPI = await this.userProfileAPIcall({

      methods: 'POST',
      endPoint: `account_block/custom_fields`,
      Body: formdata,
      token: this.token,
     

    })
    this.setState({addFieildModal:false},()=>this.getUserProfile())
  
  }

  userProfileAPIcall = async (data: any) => {
    const { ContentType, methods, endPoint, token, Body } = data;
    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestMessageforUserProfile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforUserProfile.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforUserProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforUserProfile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    Body &&
      apiRequestMessageforUserProfile.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),

        Body
      );

    runEngine.sendMessage(
      apiRequestMessageforUserProfile.id,
      apiRequestMessageforUserProfile
    );
    return apiRequestMessageforUserProfile.messageId;
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestId === this.showUserProfileData) {
        if (responseJson.data !== null) {
          this.setState({ userProfileData: responseJson.data });
          const newCustomField: any = {
            "custom_field": this.filterFields(responseJson.data.attributes.custom_field, this.fieldsToExclude)
            
        };
        this.setState({customField:newCustomField.custom_field})
          console.log(this.state.customField,"::::::::::::::::::::::::::::")
        } else {
          console.log(responseJson.data, "error");
        }
      }

      if (apiRequestId === this.upDateProfileApi) {
        if (responseJson.data !== null) {
          console.log(responseJson, "::::::::::::::data")
        }
        else {
          console.log("error", responseJson)
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Customizable Area End
  }





  handleChange = (event: any) => {

    const updatedUserAccountData: any = { ...this.state.userProfileData};

    if (event?.target) {
      console.log("hey here is updated user account data,", updatedUserAccountData)
      console.log("hey here is updated user account data in copy before,", this.state.userProfileData)


      const { name, value } = event.target;
      if (name !== undefined && value !== undefined) {
        updatedUserAccountData.attributes[name] = value;
        this.setState({ userProfileData: updatedUserAccountData});
      
      }
    }


  }
  handleaddFieldModal = () => {
    this.setState({ addFieildModal: !this.state.addFieildModal });
  };

  handleshowUploadCard = () => {
    this.setState({ showUploadCard: !this.state.showUploadCard })
  }


  handleeditDataModal = () => {
    this.setState({ editDataModal: !this.state.editDataModal });
  };


  handleFileChange = (e: any) => {
    const fileInput = e.target;
    const selectedFile = fileInput.files && fileInput.files[0];

    if (selectedFile) {

      this.setState({ selectedFile,showUploadCard:false });
    }
  };

  openFileInput = () => {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  };

   file:any
  removeFile = () => {
    
    fetch('/static/media/button_editpicture.ec7d593d.png')
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.blob();
  })
  .then(blob => {
     this.file= new File([blob], "button_editpicture.png", { type: blob.type });
  
    this.setState({ selectedFile:this.file,showUploadCard:false});
  })
  .catch(error => {
    console.error('There has been a problem with your fetch operation:', error);
  });
  };
 
 
  
  handelCancelChanges = () => {
  
    this.getUserProfile()
    this.setState({showUploadCard:false,selectedFile:null})


  }


  selectProfile = () => {
    let profileImageSrc;
  
    if (this.state.selectedFile) {
      profileImageSrc = URL.createObjectURL(this.state.selectedFile);
    } else if (this.state.userProfileData?.attributes?.profile !== null) {
      profileImageSrc = this.state?.userProfileData?.attributes?.profile?.url;
    } else {
     
      profileImageSrc = defaultImage; 
    }
  


  
 
    
    
  
    return profileImageSrc; 
  };
  

  // web events

  // Customizable Area End
}
