import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Container,
  Typography,
  Divider
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ChatBubbleOutline, Close, DeleteOutline, Edit, Gesture, PictureAsPdf, Save } from "@material-ui/icons";
import PdfEditController, { Props } from "./PdfEditController";
import { download, upload } from "./assets";
import PDFTronWrapper1 from "../../../components/src/PDFTronWrapper1";
import { Link as Redirect } from "react-router-dom";
import LogoutBox from "../../../components/src/LogoutBox";






// Customizable Area End



const theme = createTheme({
  palette: {
    primary: {
      main: "#396178",
      contrastText: "#fff",
    },
  },
});

export default class PdfEdit1 extends PdfEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.getFilesInFolderlist();
    let userData = localStorage.getItem("loginData");
    let someDataParsed = userData && JSON.parse(userData);
    const data = someDataParsed?.user_type;
    this.setState({userType:data });
  }

  // Customizable Area End

  render() {
    const { isEditable,themePrimaryColor,themeSecondaryColor } = this.state
    const PDFURL = this.state.FilesInFolder;
    const fileUrl = PDFURL[0]?.file_url;
    const fileName = PDFURL[0]?.file_name
    return (
      /* Customizable Area Start */
      <ThemeProvider theme={theme}>
        <Container style={{ "width": "1284px", display: "flex", justifyContent: "center", padding: "36px 20px", gap: "31px", margin: 'auto', alignItems: "center", }}>

          <Box sx={webStyle.leftBox}>
            <Typography style={webStyle.leftBoxHeading}>{fileName}</Typography>
            {fileUrl && <PDFTronWrapper1
              document={fileUrl}
              fileName = {fileName}
              callChildFunction ={this.callChildFunction}
            />}

          </Box>
          <Box sx={webStyle.RightBox}>
            <Box sx={webStyle.rightHead}>
              <Typography style={webStyle.rightBoxHeading}>PDF Options</Typography>
            </Box>
            <Box sx={webStyle.functionalBox}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
                <Box style={{ display: "flex", alignItems: "center", gap: "12px", opacity: isEditable ? "1" : "0.4" }}>
                  <PictureAsPdf style={{ width: "1.5rem", height: "1.5rem", color: "#64748B" }} />
                  <Typography style={webStyle.signDocText}>{fileName}</Typography>
                </Box>
              </Box>
              <Divider />
              <Box sx={isEditable ? webStyle.commonFunctionBoxEdit : webStyle.commonFunctionBox}>
              <Button style={webStyle.btnFunction} disabled={!isEditable} onClick={()=>{this.handleConvertAndUploadPDF()}}><Save /></Button>
                <Typography style={webStyle.btnFuncText}>Save</Typography>
              </Box>
              <Box sx={isEditable ? webStyle.commonFunctionBoxEdit : webStyle.commonFunctionBox}>
                <Button data-testid="Delete" onClick={this.buttonClicked} style={webStyle.btnFunction} disabled={!isEditable}><DeleteOutline style={{ color: "#DC2626", }} /></Button>
                <Typography style={webStyle.btnFuncText}>Delete</Typography>
              </Box>
              <Button style={webStyle.checkOutBox} onClick={this.handleEditable} data-test-id="checkOut">
                <Typography style={webStyle.checkOutText}> {isEditable ? "Check in" : "Check Out"}</Typography>
              </Button>
              {this.state.userType === "vincertax_employee"&&<Redirect style={{ ...(isEditable ? webStyle.editauditText : webStyle.auditText), textDecoration: "none" }} to="/Audittrail2">
               <Button style={{...(isEditable? webStyle.editauditTrail:webStyle.auditTrail) , color: '#fff', width:"100%", fontWeight: 700, textTransform: 'capitalize', fontFamily: 'Inter', fontSize: '16px'}} disabled={!isEditable}>
                  Audit trail
                </Button>
              </Redirect>}
            </Box>
          </Box>

        </Container>
        <LogoutBox
            btnCancelText='Cancel'
            btnOkText='Delete'
            openDialog={this.state.showDeleteDialog}
            headingText={'Are you sure you want to Delete?'}
            handleOk={() => this.deleteOK()}
            handleCancel={() => this.cancelLogout()}
            showDialogBtns={true}
            showCrossBtn={true}
            btnColor={themePrimaryColor}
            textColor={themeSecondaryColor}
          />
      </ThemeProvider>
      /* Customizable Area End */
    );
  }
}

// Customizable Area Start
const webStyle: any = {
  leftBox: {
    "display": "flex",
    "padding": "20px",

    "gap": "30px",
    "alignSelf": "stretch",
    "borderRadius": "8px 8px 32px 8px",
    "background": "#FFF",
    "height": "832px",
    width: "763px",
    "border": "1px solid  #CBD5E1",
    flexDirection:"column"
  },
  RightBox: {
    "width": "400px",
    "height": "872px",
    "flexShrink": "0",
    "borderRadius": "8px",
    "border": "1px solid  #CBD5E1"
  },
  leftBoxHeading: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "20px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "28px"
  },
  rightHead: {

    "display": "flex",
    "width": "344px",
    "height": "32px",
    "padding": "24px 16px 24px 40px",
    "justifyContent": "space-between",
    "alignItems": "center",
    "flexShrink": "0",
    "borderBottom": "1px solid #E2E8F0",
  },
  rightBoxHeading: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "20px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "28px"
  },
  cross: {
    "display": "flex",
    "width": "32px",
    "height": "32px",
    "padding": "4px",
    "justifyContent": "center",
    "alignItems": "center",
    cursor: "pointer"
  },
  functionalBox: {
    "display": "flex",
    "width": "344px",
    "padding": "0px 24px",
    "flexDirection": "column",
    // "alignItems": "flex-start",
    "gap": "16px",
  },
  signDocText: {
    "color": "#000",
    "fontFamily": "Inter",
    "fontSize": "12px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "18px",
    // opacity: "0.4"
  },
  commonFunctionBox: {
    "display": "flex",
    // "padding": "0px 250px 1px 0px",
    "alignItems": "center",
    "gap": "16px",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "border": "1px solid #CBD5E1",
    "opacity": "0.4",
    "background": "#FFF",
    height: "56px"
  },
  commonFunctionBoxEdit: {
    "display": "flex",
    // "padding": "0px 250px 1px 0px",
    "alignItems": "center",
    "gap": "16px",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "border": "1px solid #CBD5E1",
    // "opacity": "0.4",
    "background": "#FFF",
    height: "56px"
  },
  btnFunction: {
    "display": "flex",
    "width": "56px",
    "height": "56px",
    "padding": "16px",
    "justifyContent": "center",
    "alignItems": "center",
    "borderRadius": "8px",
    "background": "#F6F2EF"
  },
  btnFuncText: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "24px"
  },
  checkOutBox: {
    "display": "flex",
    // "padding": "10px 16px",
    "alignItems": "center",
    "gap": "16px",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "border": "1px solid #396178",
    "opacity": "1",
    "background": "#FFF",
    height: "56px",
    justifyContent: 'center',
    cursor: "pointer"

  },
  checkOutText: {
    "fontFamily": "Inter",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "24px",
    color: '#396178',
    textTransform: "none"

  },
  auditTrail: {
    "display": "flex",

    "alignItems": "center",
    "gap": "16px",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "opacity": "1",
    "backgroundColor": "#F1F5F9",
    height: "56px",
    justifyContent: 'center',
    cursor: "pointer"

  },
  auditText: {
    "fontFamily": "Inter",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "24px",
    color: '#64748B',
    textTransform: "none"

  },
  editauditTrail: {
    "display": "flex",

    "alignItems": "center",
    "gap": "16px",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "opacity": "1",
    "backgroundColor": "#E57727",
    height: "56px",
    justifyContent: 'center',
    cursor: "pointer"

  },
  editauditText: {
    "fontFamily": "Inter",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "24px",
    color: '#ffffff',
    textTransform: "none"

  },
};
// Customizable Area End
