// Customizable Area Start
import React from "react";
import {
  Typography,
  Container,
  Tab,
  withStyles
} from "@material-ui/core";
import ProjectTemplatesController, {
  Props,
} from "./ProjectTemplatesController.web";
import ProjectTemplate from "../../../components/src/ProjectTemplateForm";
import EmailTemplate from "../../../components/src/EmailTemplate";
import TaskTemplate from "./TaskTemplates.web";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";
import EmailTemplates from "./EmailTemplates.web";
import { imgSuccess } from "./assets";
import FolderTemplates from "./FolderTemplates.web";
import {ContractTemplate} from "./ContractTemplates.web";
import InvoiceTemplate from "./InvoiceTemplate.web";

const projectStyle = {
  usergrouptabs:{ 
    width: '100%',
    "& .MuiTabPanel-root": {
    padding: "30px 0"
  }
},
  tabStyle:{
    "& .MuiTabs-indicator": {
      backgroundColor: "#E57727",
      height: "2.5px",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "26px",
    },
    "& .MuiTab-textColorInherit": {
      opacity: 1,
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#E57727",
    },
  },
  messageDivs: { display: "flex", gap: "8px" },
  alertDivs: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    transform: "translate(-50%, 50%)",
    top: "80%",
    left: "50%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  editor: {
    display: "grid",
    "& .ql-toolbar.ql-snow": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    "& .ql-container.ql-snow": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    "& .ql-toolbar.ql-snow + .ql-container.ql-snow": {
      height: "256px"
    }
  },
  radioH: {
    "& .MuiTypography-body1": {
      fontSize: "14px !important",
      color: "#0F172A",
      fontFamily: "Inter",
      lineHeight: "24px",
    },
    '& .MuiRadio-root.Mui-checked': {
      color: '#e87242',
      fontWeight: 700
    },
    '& .MuiRadio-root:not(.Mui-checked)': {
      color: 'grey !important',
    },
  },
  mainDiv:{display: "flex",
  width: "97%",
  height: "60px",
  padding: "0px 24px",
  alignItems: "center",
  marginTop: "24px",
  marginBottom: "24px",
  borderRadius: "8px",
  border: "1px solid #F0E5FF",
  gap: "30px",
  flexShrink: 0,},
  titleStyle:{
    color: "#E57727",
    textAlign: "center" as const,
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px", 
  },
};

export class ProjectsTemplate extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);


  }

  render() {
    const {classes}=this.props
    const tabs = ['Invoices', 'Task/Projects', 'Emails',"Folders","Contracts"];
    return (
  
      <Container maxWidth={"xl"} style={{marginTop:"20px"}}>
 <div style={projectStyle.mainDiv}>
  <Typography style={projectStyle.titleStyle}>Templates</Typography>
</div>

<div className={classes.usergrouptabs}>
						<TabContext value={this.state.value}>
							<div style={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList className={classes.tabStyle} onChange={this.handleChange} style={{borderBottom:"1px solid #94A3B8"}} aria-label="lab API tabs example">
									{tabs.map((tabType, index) => (
										<Tab key={index} label={tabType} value={tabType} />
									))}
								</TabList>
							</div>
							<TabPanel value="Task/Projects"><TaskTemplate classes={classes} handleOpen={this.handleTaskOpen} List={this.state.projectList}/></TabPanel>
							<TabPanel value="Emails"><EmailTemplates classes={classes} handleOpen={this.handleEmailOpen} List={this.state.emailList}/></TabPanel>
              <TabPanel value="Folders"><FolderTemplates classes={classes} /></TabPanel>
							<TabPanel value="Invoices"><InvoiceTemplate classes={classes} teamEmails={this.state.usersEmailData} /></TabPanel>
              <TabPanel value="Contracts"><ContractTemplate classes={classes} handleOpen={this.handleEmailOpen}/></TabPanel>
						</TabContext>
					</div>


      <EmailTemplate
        openDialog={this.state.isTemplateOpen}
        headingText= "Create Email Template"
        btnOkText="Create"
        vtEmailId={this.state.usersEmailData}
        handleOk={this.createEmailTemplate}
        handleCancel={this.handleCancel}
        modeTypeSelect={this.state.modeTypeSelect}
        emailTypeSelect={this.state.emailTypeSelect}
        handleEmailType={this.handleEmailType}
        handleMode={this.handleMode}
      />

              <ProjectTemplate
               openDialog={this.state.openProject}
               headingText= "Create Template"
               btnCancelText="Cancel"
               btnOkText="Create"
               handleOk={this.createTaskTemplate}
               handleCancel={this.cancelBtn}
               showDialogBtns={true}
               vtEmailId={this.state.usersEmailData}
               showCrossBtn={true}
               />
               {this.state.showAlertPop &&
              <Alert style={{ ...projectStyle.alertDivs, position: "absolute" }} icon={false} severity="success">
                <div style={projectStyle.messageDivs} >
                  <img src={imgSuccess} style={{ verticalAlign: "bottom" }} />
                  {console.log(this.state.alertMessage,"web web")}
                  <Typography variant="body1">{this.state.alertMessage}</Typography>
                </div>
              </Alert>}

      </Container>
  
    );
  }
}
export default withStyles(projectStyle)(ProjectsTemplate)
// Customizable Area End