import React from "react";

// Customizable Area Start
import { Container, Box, Modal, Card, Typography, Button } from "@material-ui/core";
import { doc, pdf, } from "./assets"
// Customizable Area End

import { createTheme, ThemeProvider } from "@material-ui/core/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import PdfEditController, { Props} from "./PdfConvertController.web";
import { ArrowForward } from "@material-ui/icons";

export default class PdfEdit extends PdfEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <Container>
               

                    <Modal
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            width: "100%", maxWidth: "none"

                        }}

                        open={this.props.data.state}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        onClose={this.props.data.handleClose}

                    >

                        <Card
                            style={{
                                width: "748px",

                                borderRadius: "12px",
                                padding: "2rem 2rem 2rem 2rem"
                            }}
                        onDragOver={this.handleDragOver} onDrop={this.handleDrop} data-test-id ="drag">
                            <Typography style={{ "alignSelf": "stretch", "color": "#1E293B", "fontFamily": "Inter", "fontSize": "24px", "fontWeight": 700, "lineHeight": "32px", "letterSpacing": "-0.12px" }}>Convert Word to PDF</Typography>

                            <Box sx={webStyle.pdfBox}>
                                <Box style={{ display: 'flex', alignItems: "center", gap: '12px' }}>
                                    <Box sx={webStyle.docimgBox}>
                                        <img src={doc} style={{ width: "72px", height: '72px' }} />
                                    </Box>
                                    <ArrowForward style={{ width: "40px", height: "40px", color: "#CBD5E1" }} />
                                    <Box sx={webStyle.pdfimgBox}>
                                        <img src={pdf} style={{ width: "72px", height: '72px' }} />
                                    </Box>
                                </Box>
                                <Typography style={{ "color": "#0F172A", "textAlign": "center", "fontFamily": "Inter", "fontSize": "16px", "fontStyle": "normal", "fontWeight": 400, "lineHeight": "24px" }}>Drag and drop a document (DOCX or DOC) to convert to PDF.</Typography>

                                <Button style={webStyle.selectFilebtn} onClick={this.openDocFileInput} data-test-id="openDocFileInput">

                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={this.fileInput}
                                        onChange={(e:any)=>{this.handleDocFileChange(e)}}
                                        name="worddoc"
                                        data-test-id="fileupload"
                                        accept=".doc"
                                    />
                                    <Typography style={{ "color": "#FFF", "fontFamily": "Inter", "fontSize": "16px", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "24px", textTransform: "none" }}>Select a file</Typography>
                                </Button>
                            </Box>


                        </Card>
                    </Modal>
                </Container>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {

  
    pdfBox: { "display": "flex", "padding": "40px 115px", "flexDirection": "column", 
    "alignItems": "center", "gap": "32px", "alignSelf": "stretch", "borderRadius": "12px", "border": "2px dashed #CBD5E1", "width": "514px", "height": "232px", marginTop: "32px" },
    docimgBox: { "display": "flex", "padding": "12px", "alignItems": "flex-start", "gap": "10px", 
    "borderRadius": "20px", "border": "2px solid rgba(0, 122, 234, 0.20)" },
    pdfimgBox: { "display": "flex", "padding": "12px", "alignItems": "flex-start",
     "gap": "10px", "borderRadius": "20px", "border": "2px solid rgba(220, 38, 38, 0.20)" },
    selectFilebtn: {
        "display": "flex", "width": "155px", "height": "44px", "padding": "10px 16px", "justifyContent": "center", "alignItems": "center", "borderRadius": "8px", "background": "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
    },


};
// Customizable Area End
