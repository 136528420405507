import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
// import { SvgIconProps } from '@material-ui/core/SvgIcon'

import List from '@material-ui/core/List'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import { chat, setting, analytics, search, task, document, people, notification } from './Icons';
import { getThemeColor,getSecondaryThemeColor } from '../CommonPlatte'
// import { useTranslation } from 'react-i18next'

import MenuItems from './MenuItems'

// React runtime PropTypes
export const MenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  // Icon: PropTypes.elementType,
  Icon: PropTypes.string,
  items: PropTypes.array,
}

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type MenuItemPropTypes = PropTypes.InferProps<typeof MenuItemPropTypes>
type MenuItemPropsWithoutItems = Omit<MenuItemPropTypes, 'items'>

// Improve child items declaration
export type MenuItemProps = MenuItemPropsWithoutItems & {
  items?: MenuItemProps[]
}

const AppMenu: React.FC<MenuItemProps> = props => {
//   const { t } = useTranslation();
  const { name, link, Icon, items = [] } = props
  const classes = useStyles()
  const isExpandable = items && items.length > 0
  const [open, setOpen] = React.useState(false)
  console.log(name, link, Icon, items ,"name, link, Icon, items");
  

  function handleClick() {
    setOpen(!open)
  }

  type IconName = 'chat' | 'task' | 'people' | 'setting' | 'notification' | 'document' | 'search' | 'analytics';
  const ICON_COMPONENTS: { [key in IconName]: React.ComponentType<{ color: string }> }  = {
    chat: chat,
    task: task,
    people: people,
    setting: setting,
    notification: notification,
    document: document,
    search: search,
    analytics:analytics
  };
  type SvgIconProps = {
    iconName: IconName;
    color?: string; // Making color optional, defaulting to 'black' inside components
  };
  const SvgIcon :React.FC<SvgIconProps> = ({ iconName, color = 'black' }) => {
    const IconComponent = ICON_COMPONENTS[iconName];
  console.log(color,iconName,"njnjjnn");
  
    if (!IconComponent) {
      // Return a default or null if the iconName does not match
      return null;
    }
  
    return <IconComponent color={color} />;
  };

  const isValidIconName = (value: string): value is IconName => {
    return ['chat', 'document', 'task', 'people', 'search', 'notification', 'setting', 'analytics'].includes(value);
  };
  

  
  const MenuItemRoot = (
    <MenuItems className={classes.menuItem} link={link} onClick={handleClick}>
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          {/* <Icon /> */}
          {/* <img src="/assets/image_.png" alt="" /> */}
          <div className='default-icon'>
          {isValidIconName(Icon) ?
    <SvgIcon iconName={Icon} color="#396178" />:null
  }
          </div>
          <div className='active-icon'>
            {/* <img src={`${Icon}-active.svg`} alt="" /> */}
  {isValidIconName(Icon) ?
    <SvgIcon iconName={Icon} color={getThemeColor()} />:null
  }
            {/* {iconFunctions[Icon] && iconFunctions[Icon]()} */}
          </div>
        </ListItemIcon>
      )}
      <ListItemText color={getThemeColor()} primary={name} inset={!Icon} />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </MenuItems>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item:any, index:any) => (
          <AppMenu {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    menuItem: {
        display:"grid",
        justifyContent: "center",
        paddingBottom:"12px",
        paddingTop:"12px",
      borderRadius: '8px',
      '& .MuiTypography-root.MuiListItemText-primary': {
        fontSize: '12px',
        textTransform: 'capitalize',
        color:"#396178"
      },
      '&:hover' : {
        background: 'rgb(255 255 255 / 30%)',
        '& .MuiListItemIcon-root': {
          color: '#E57727',
        },
      },
      '& .active-icon':{
        display: 'none'
      },
      '&.active': {
        // background: 'rgba(0, 0, 0, 0.08)',
        '& svg': {
          // color: 'linear-gradient(to bottom, #2d6f8f, #4fb7ba);',
          backgroundImage: 'linear-gradient(to bottom, #2d6f8f, #4fb7ba)',
          // background: '-webkit-linear-gradient(#2d6f8f, #4fb7ba)',
          "-webkit-background-clip": 'text',
          "-webkit-text-fill-color": 'transparent',
        },
        '& .MuiTypography-root.MuiListItemText-primary': {
        //   backgroundImage: 'linear-gradient(to bottom, #2d6f8f, #4fb7ba)',
        color:getThemeColor(),
          "-webkit-background-clip": 'text',
        //   "-webkit-text-fill-color": 'transparent',
        },
        '& .default-icon':{
          display: 'none'
        },
        '& .active-icon':{
          display: 'block'
        },
      },
    },
    menuItemIcon: {
      color: '#E57727',
      minWidth: 'auto',
      justifyContent:"center",
    //   paddingInlineEnd:'10px',
      '& img':{
        width: '15px',
      }
    },
  }),
)

export default AppMenu
