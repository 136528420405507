export const imgLogo = require("../assets/image_logo.png");
export const Slider = require("../assets/image_tax.png");
export const Frame = require("../assets/Frame.png");
export const Vector = require("../assets/Vector.png");
export const filter = require("../assets/filter.png");
export const imageUser = require("../assets/image_user.png");
export const message = require("../assets/message.png");
export const tick = require("../assets/tick.png");
export const dotIcon = require("../assets/dot.svg");
export const plus = require("../assets/plus.svg");







