import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { Search, FileCopyOutlined } from '@material-ui/icons';
/*@ts-ignore*/
import { useField,ErrorMessage } from "formik";
import React from 'react'

const TextError = (props:any) => {
    console.log('props chk',props)
    return (
      <span
        style={useStyle.text_err}
        // style={nationalNumber}
      >
        {props.children}
      </span>
    );
  };
const CustomInput = (props: any) => {
    const [field, meta] = useField(props);
    const {
        
        name,
        isRequired,
        ...rest
      } = props;

   
    return (<div style={useStyle.main}>
        <OutlinedInput
            style={{
                marginTop: "3px",
                marginBottom: "3px",
            }}
            inputProps={{
                style: {
                  padding: "16px 8px",
                  "& .MuiInputBase-input:focus ": {
                    borderColor: "#000",
                  },
                }
                
             }}
            fullWidth
            type={props.type ? props.type : "text"}
            {...field}
            {...props}
            
            error={meta.error && meta.touched}
            testID={props.testIDProps}
            value={field.value}
            disabled={props.disabled}
            
        />
              <ErrorMessage component={TextError} name={name} />
        </div>
    );
}

const useStyle={
    main:{width:"auto",height:"56px"},

    inputfocus:{
        borderColor:"#000"
    },
    text_err :{
        display: "block",
        // "position": "absolute",
        color: "red",
        marginBottom:"5px",
        fontSize: "12px",
        bottom: "-22px",
        left: 0,
        padding: "0 8px",
        zIndex: 10,
      }
}

export default CustomInput