Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.titleWelcome="Welcome to Vincere Tax Portal: Your All-in-One Tax Solution";
exports.details = "Are you ready to take control of your taxes like never before? Look no further – the Vincere Tax Portal is here to simplify your tax journey and empower you with unmatched convenience and expertise. Whether you're a valued tax client, a dedicated Vincere Tax Employee, or a trusted White Label Financial Advisor, our cutting-edge platform is designed to bring you closer to your financial goals.";



exports.btnExampleTitle = "CLICK ME";
// Customizable Area End