import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
}

export interface S {
  // Customizable Area Start
  types: any;
  value: any;
  textEditor:boolean;
  NotesData: any;
  editorContent: any;
  NoteTitle: any;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NotesWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  showNoteApiCallId: string = "";
  createNoteApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationCreateProjectMessage)
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    const tabtypes = ["Profile", "Progress Tracker", "Tracker"];

    this.state = {
      types: tabtypes,
      value: 0,
      NotesData: [],
      textEditor: false,
      editorContent: '',
      NoteTitle: ''
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responsejson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.showNoteApiCallId) {
        if (responsejson.data) {
          this.setState({ NotesData: responsejson.data });
        }
      }
      if (apiRequestCallId === this.createNoteApiCallId) {
        if (responsejson.data) {
          this.showNote();
        }
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  token: string = "";

  tabs_Change = (event:any, newValue:any) => {
    this.setState({ value: newValue });
  };
  a11yProps = (index:any) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };

  editor_Change = (newState:any) => {
		this.setState({ textEditor: newState });
	}

	title_Change = (e:any) => {
		this.setState({ NoteTitle: e.target.value })
	}

  showNote = async () => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.showNoteApiCallId = await this.taskApiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: `/account_block/notes`,
      token: this.token,
    });
  };

  createNote = async () => {
    //@ts-ignore
    const contentState = this.state.textEditor.getCurrentContent();
    const plainText = contentState.getPlainText('\n');
    const data = {
      note: { name: this.state.NoteTitle, description: plainText }
    }

    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.createNoteApiCallId = await this.taskApiCall({
      contentType: 'application/json',
      methods: "POST",
      endPoint: `/account_block/notes`,
      token: this.token,
      body: data
    })
  }

  taskApiCall = async (data: any) => {
    const { methods, endPoint, token, body } = data;
    const Header = {
      "Content-Type": "application/json",
      token,
    };
    const apiRequestMessageforAddSubTask = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforAddSubTask.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforAddSubTask.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    body &&
      apiRequestMessageforAddSubTask.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    apiRequestMessageforAddSubTask.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    runEngine.sendMessage(
      apiRequestMessageforAddSubTask.id,
      apiRequestMessageforAddSubTask
    );
    return apiRequestMessageforAddSubTask.messageId;
  }
  // Customizable Area End
}