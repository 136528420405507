import React, { useEffect ,useContext } from "react";
import { Button, Typography, useMediaQuery, Breadcrumbs, Box, Container, } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link as Redirect } from 'react-router-dom'
import { AddCircleOutline, FilterListSharp, Search, } from "@material-ui/icons"
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PdfConvert from "../../../blocks/pdfedit/src/PdfConvert.web";
import SaveAsPdf1 from "../../../blocks/saveaspdf/src/SaveAsPdf1.web";
import {getSecondaryThemeColor, getThemeColor} from "../CommonPlatte";
import { ModelContext } from '../Context'

// console.log(useDynamicTheme(),"THEME COLOR");


const topStyles = {
  redirection: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px",
    '&:hover': {
      textDecoration: "none",
    },
  },
  currentPage: {
    // color: updateColor,
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px"
  },
}
const BreadCrumbCart = () => {

  return (
    <React.Fragment>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Redirect to="/Settings2">People</Redirect>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Create Account
          </li>
        </ol>
      </nav>
    </React.Fragment>
  );
}
interface StyleProps {
  updateColor1: string;
  updateColor2: string;
}


const TopBar = (props: any) => {
  const mdDevice = useMediaQuery("(min-width:900px)");
  const lgDevice = useMediaQuery("(min-width:1200px)");
  // const [client,setClient] =useState("")
  // const themeColor = theme();

  // console.log("CALING IN COMMON PLATTE",themeColor);

  useEffect(()=>{
    console.log("CALING IN COMMON PLATTE",getThemeColor());
  }) 

  const { openModel }:any = useContext(ModelContext);
  const clientData = localStorage.getItem("clientName");
  const FolderNmae = localStorage.getItem("FolderName")
  const client = clientData ? JSON.parse(clientData) : null;
  const folderName = FolderNmae?JSON.parse(FolderNmae):null
  
  const classes = useStyles({ updateColor1: props.updateColor,updateColor2:props.updateColor1 })();

  const location = window.location
  console.log(window.location , props.updateColor,"sk");

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" >
      <Redirect to="/PeopleWeb" className={classes.redirection}>People</Redirect>
    </Link>,
    // <Link
    //   underline="hover"
    //   key="2"
    //   color="inherit"
    //   href="/material-ui/getting-started/installation/"
    //  
    // >
    //   Core
    // </Link>,
    <Typography key="3" className={classes.currentPage}>
      Create Account
    </Typography>,
  ];
  

  const breadcrumbSubFolder = [
    <Link underline="hover" key="1" color="inherit" >
      <Redirect to="/UserLibrary" className={classes.userLibraryRedirect}>Documents</Redirect>
    </Link>,
   
    <Typography key="3" className={classes.folderName}>
      {folderName}
    </Typography>,
  ];


  
  
  const breadcrumbsavePdf = [
    <Link underline="hover" key="1" color="inherit" >
      <Redirect to="/UserLibrary" className={classes.redirection} style={{marginLeft:"18px"}}>Documents</Redirect>
    </Link>,
    // <Link
    //   underline="hover"
    //   key="2"
    //   color="inherit"
    //   href="/material-ui/getting-started/installation/"
    //  
    // >
    //   Core
    // </Link>,
    <Typography key="3" className={classes.currentPage}>
      Document name
    </Typography>,
  ];
  const breadcrumbsWorkspaceGroup = [
    <Link underline="hover" key="1" color="inherit" >
      <Redirect to="/HomePage" className={classes.redirection}>Workspace</Redirect>
    </Link>,
    <Link underline="hover" key="1" color="inherit" >
      <Redirect to="/HomePage" className={classes.currentPage}>{localStorage.getItem("workspace_name") && localStorage.getItem("workspace_name")}</Redirect>
    </Link>,
    <Link underline="hover" key="1" color="inherit" >
      <Redirect to="/HomePage" className={classes.currentPage}> {localStorage.getItem("boardName") && localStorage.getItem("boardName")}</Redirect>
    </Link>
  ];
  const breadcrumbsAuditTrail = [
    <Link underline="hover" key="1" color="inherit" >
      <Redirect to="/HomePage" className={classes.redirection}>Audit Trail</Redirect>
    </Link>
  ];
  const breadcrumbsWorkspaceAudittrail = [
    <Typography key="3" className={classes.currentPage}>
     {localStorage.getItem("projectName") && localStorage.getItem("projectName")}
    </Typography>,
    <Typography key="3" className={classes.currentPage}>
      Audit Trail
    </Typography>,
  ];
  
  const getHeading = () => {

    switch (location.pathname) {
      case "/Documents":
        return "Documents";
        case "/UserLibrary":
          return "Documents";
      case "/HomePage":
        return "Workspace";
      case "/VisualAnalytics":
        return "Visual Analytics";
      case "/PeopleWeb":
        return "People";
      case "/AccountCreation":
        return "People";
      case "/InvoiceBilling":
        return "Invoices";
      case "/Chat":
        return "Chat";
      case "/Settings2":
        return "Settings";
        case "/ProjectTemplates":
          return "Settings";
      case "/Notifications":
        return "Notification";
      case "Workspacegroup":
        return "Workspacegroup";
      case "Projectnotes":
        return "Projectnotes";
    }
  };
  const breadcrumbsPeople = [
    <Link underline="hover" key="1" color="inherit">
      <Redirect to="/PeopleWeb" className={classes.redirection}>People</Redirect>
    </Link>,
  
    <Typography key="3" className={classes.currentPage} style={{textTransform:"capitalize"}}>
      {client}
    </Typography>,
  ];
  const breadcrumbScannedDocs = [
    <Link underline="hover" key="1" color="inherit">
      <Redirect to="/UserLibrary" className={classes.redirection}>Documents</Redirect>
    </Link>,
  
    <Typography key="3" className={classes.currentPage} style={{textTransform:"capitalize"}}>
      Scanned Documents
    </Typography>,
  ];
  const breadcrumbsTheme = [
    <Link underline="hover" key="1" color="inherit">
      <Redirect to="/Settings2" className={classes.redirection}>Settings</Redirect>
    </Link>,
  
    <Typography key="3" className={classes.currentPage} style={{textTransform:"none"}}>
      Profile theme
    </Typography>,
  ];

  const breadcrumbsPdfEditEdit = [
    <Link underline="hover" key="1" color="inherit">
      <Redirect to="/UserLibrary" className={classes.redirection} style={{marginLeft:"0px"}}>Documents</Redirect>
    </Link>,
  
    <Typography key="3" className={classes.currentPage} style={{textTransform:"none"}}>
      Edit
    </Typography>,
  ];

  return (
    <Box >
      <div className={classes.header_main}>
        {location.pathname ==="/Projectnotes"?( <div className={classes.header_div} style={{ display: "flex", justifyContent: "space-between" }}>
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbsWorkspaceGroup}
          </Breadcrumbs>
          <div style={{ display: "flex", gap: "10px" }}>
          <Button className={classes.btnAddColumn}  onClick={() => openModel('model3')} >Add new group</Button>
            <Button className={classes.btnAddColumn}>Add column</Button>
          </div>
          </div>
          ): location.pathname ==="/Audittrail2"?( <div className={classes.header_div} style={{ display: "flex", justifyContent: "space-between" }}>
          <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbsAuditTrail}
            </Breadcrumbs>
            </div>
            ):
          location.pathname ==="/Audittrail"?( 
            <div className={classes.header_div} style={{ display: "flex", justifyContent: "space-between" }}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbsWorkspaceAudittrail}
              </Breadcrumbs>
              <div style={{ display: "flex", gap: "10px" }}>
              </div>
            </div>
            ):
        location.pathname === "/HomePage" ?(<div className={classes.header_div2}>
        <Typography className={classes.header_name}>
          {getHeading()}
        </Typography>
        <Box>
            <Container id="Container" maxWidth="xl">
              <div style={{ display: "flex", gap: "10px" }} >
                {/* <Redirect className={classes.redirect} to="/Workspacegroup">
                  <Button className={classes.btnAddAccount}> <AddCircleOutline style={{ marginRight: "8px" }} />Goto Workspace</Button>
                </Redirect> */}
                  <Button className={classes.btnAddAccount}  onClick={() => openModel('model1')}> <AddCircleOutline style={{ marginRight: "8px" }} /> Add workspace</Button>
              </div>
            </Container>
        </Box>
      </div>): location.pathname == "/PeopleWeb" ? (<div className={classes.header_div2}>
          <Typography
            className={classes.header_name}
          >
            {getHeading()}
          </Typography>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button className={classes.btnAddColumn}>Add column</Button>
            <Redirect className={classes.redirect} to="/AccountCreation">
              <Button className={classes.btnAddAccount}> <AddCircleOutline style={{ marginRight: "8px" }} /> Add account</Button>    </Redirect>

          </div>
        </div>) : location.pathname == "/PdfEdit" ? (<div className={classes.header_div} style={{ display: "flex", justifyContent: "space-between" }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbsavePdf}
          </Breadcrumbs>
          <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "gap": "12px", width: "278px", height: "44px",marginRight:"18px" }}>

            {/* <Redirect className={classes.redirect} to="/PdfConvert"> */}

              <Button className={classes.wordpdfbtn} onClick={props.handleNavigate} ><Typography className={classes.pdfwordtext}>Convert word to PDF</Typography></Button>

          </div>
        </div>) : location.pathname=="/Profile" ? (<div className={classes.header_div}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbsPeople}
        </Breadcrumbs>
        </div>):location.pathname=="/OcrDocuments" ? (<div className={classes.header_div}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbScannedDocs}
        </Breadcrumbs>
        </div>):location.pathname=="/ThemeBlock" ? (<div className={classes.header_div}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbsTheme}
        </Breadcrumbs>
        </div>): (<div className={classes.header_div}>
          {location.pathname == "/AccountCreation" ? (<Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>) 
           
              :location.pathname == "/Documents" ? (<div className={classes.bulkHeader}> <Typography
                className={classes.header_name}
              >
                {getHeading()}
              </Typography>
              <div style={{ display: "flex", gap: "10px" }}>
                <Redirect className={classes.redirect} to="/BulkUploading">
                  <Button className={classes.btnAddAccount}> <AddCircleOutline style={{ marginRight: "8px" }} /> Upload Document</Button>    </Redirect>
                  <Button className={classes.btnAddColumn} style={{color:"#000"}}>Sort by <FilterListSharp style={{ marginLeft: "8px" }} /> </Button>
      
              </div>
              </div>):location.pathname == "/PdfEditorWrapper" ? (<div className={classes.header_div} style={{ display: "flex", justifyContent: "space-between" ,marginLeft:"0px"}}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbsPdfEditEdit}
          </Breadcrumbs>
          
        </div>): location.pathname=="/SubFolder" ? (<div style={{marginLeft:'18px'}}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbSubFolder}
        </Breadcrumbs>
        </div>):(<Typography
                className={classes.header_name}
              >
                {getHeading()}
              </Typography>)}
          {/* <Typography
        className={classes.header_name}
      >
         {BreadCrumbCart()}
      </Typography> */}




        </div>)}
        {/* <div className={classes.header_div}>
        <Typography
        className={classes.header_name}
      >
        {getHeading()}
      </Typography>
        </div> */}




      </div>

     { props.state && <PdfConvert navigation={""} id={""}  data={props}/>}

     { props.state1 && <SaveAsPdf1 navigation={""} id={""}  data={props}/>}

    </Box>

  );
};

export default TopBar;


const componentStyles = {
  centerContent: {
    display: "flex",
    justifyContent: "space-between",
    padding:"10px 30px"
},
submitBtnStyle: {
  borderRadius: "8px",
  textTransform: "initial",
  background: "#E57727",
  color: "#fff",
  padding: '8px 16px',
  fontSize: "16px",
  fontweight: 700,
}
}

const useStyles = (props: StyleProps)=> makeStyles({
  header_main: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    zIndex: 1,
    backgroundColor: '#fff',
    boxSizing: "content-box",
  },
  redirect: {
    /*@ts-ignore*/
    textDecoration: "none",
  },
  header_div: {
    width: "calc(100% - 193px)",
    display: "flex",
    height: "41px",
    alignItems: "center",
    padding: "15px 30px",
    justifyContent: "start",
    marginInlineStart: 'auto',
    borderBottom: "1px solid #E2E8F0"
  },
  bulkHeader:{
    display:"flex",
    justifyContent:"space-between",
    width:"100%"
  },
  btnAddColumn: {
    display: "flex",
    textTransform: "none",
    height: "44px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    width: "140px",
    background: "#F6F2EF",
    color: props.updateColor2,
    gap: "8px"
  },
  btnAddAccount: {
    display: "flex",
    height: "44px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    width: "185px",
    textTransform: "capitalize",
    background: props.updateColor1,
    color: "#fff",
    gap: "8px",
    '&:hover': {
      backgroundColor: props.updateColor1,
    },
  },
  redirection: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px",
    '&:hover': {
      textDecoration: "none",
    },
  },
  currentPage: {
    color: getSecondaryThemeColor(),
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px"
  },
  wordpdfbtn: {
    "display": "flex", "height": "44px", "padding": "10px 16px", "justifyContent": "center", "alignItems": "center", "gap": "8px",
    "borderRadius": "8px", "background": props.updateColor1, '&:hover': {
      backgroundColor: props.updateColor1,
    },
  },
  pdfwordtext: { "color": "#FFF", "fontFamily": "Inter", "fontSize": "16px", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "24px", textTransform: "none" },
  header_div2: {
    width: "calc(100% - 193px)",
    display: "flex",
    height: "41px",
    alignItems: "center",
    padding: "15px 30px",
    justifyContent: "space-between",
    marginInlineStart: 'auto',
    borderBottom: "1px solid #E2E8F0"
  },
  dialogContent: { "display": "flex", "width": "808px", "padding": "32px", "flexDirection": "column", "alignItems": "flex-start", "gap": "32px", "borderRadius": "12px", "background": "#FFF" },
  header_name: {
    fontSize: "24px !important",
    // color: "#000",
    fontWeight: 700,
    display: "block !important",
    color: "#0F172A",
    fontFamily: "Inter",
    // font-size: 24px,
    fontStyle: "normal",
    lineHeight: "32px", /* 133.333% */
    letterSpacing: "-0.12px",
    marginLeft:"18px"
  },
 userLibraryRedirect:{
  "color": "#0F172A",
  "fontFamily": "Inter",
  "fontSize": "24px",
  "fontStyle": "normal",
  "fontWeight": 400,
  "lineHeight": "32px",
  "letterSpacing": "-0.12px",
  textDecoration: "none",
},
folderName:{
  "color": "#0F172A",
  "fontFamily": "Inter",
  "fontSize": "24px",
  "fontStyle": "normal",
  "fontWeight": 700,
  "lineHeight": "32px",
  "letterSpacing": "-0.12px"
}

});