// Customizable Area Start
// Customizable Area Start
import React from "react";
import {
    Button,
    Container,
    Link,
    Paper,
    Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { imgVinLogo } from "./assets";
import TwoFactorAuthenticationController, {
    Props,
} from "./TwoFactorAuthenticationController.web";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link as Redirect } from 'react-router-dom'
//@ts-ignore
import OtpInput from "react-otp-input"
import { Alert } from "@material-ui/lab";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
});

const compStyles = {
    mainDiv: {
        width: "100%",
        height: "100%",
        background: "#F6F2EF"
    },
    paperDiv:{
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        padding: "10px 0px",
        width: "100%",
        marginTop: "20%"
    },
    customContainer: {
        maxWidth: '508px',
      },
    centerContent: {
        display: "flex",
        justifyContent: "center",
    },
    logoImage: {
        height: "100%",
        width: 'auto'
    },
    msgStyle:{ fontSize: "14px", color: "red", marginLeft: "10px", padding: "8px" },
    mainTitle:{
        color: "#000",
fontFamily: "Inter",
fontSize: "24px",
marginBottom:"37px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "32px",
letterSpacing: "-0.12px",
    },
    alertStyle:{ width: "100%", padding: "0" },
    divFlex:{display:"flex"},
    backBtn:{width:"64px"},
    formStyle:{
        padding: "50px",
        gap:"37px",
        display:"grid"
    },
    subTitle: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    },
    alertMessage:{
        padding:0
    },
    errorStyle:{
        color: "#DC2626",
fontFamily: "Inter",
fontSize: "14px",
marginLeft:"5px",
marginTop:"10px",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "18px", 
    },
    submitTFBtnStyle: {
        background: "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
        color: "#fff",
        height:"56px",
        fontWeight:700,
        borderRadius: "8px",
    },
    otpDiv:{
          border: "1px solid #CBD5E1",
          background: "#FFF",
          borderRadius: "8px",
          display: "flex",
          padding: "16px 8px",
          justifyContent: "center",
          alignItems: "center",
          width: "56px",
          height: "56px",
          fontSize: "14px",
          color: "#000",
          fontWeight: 400,
          caretColor: "blue"
        },
    messageDiv: { display: "flex", gap: "8px" },
    decoration:{
        /*@ts-ignore*/
        textDecoration: "none"
    },
    alertDiv: {
        display: "inline-flex",
        padding: "8px 16px 8px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        marginTop:"25%",
        borderRadius: "8px",
        background: "#FFF",
        boxShadow: "0px 6px 15px -3px",
      },
    linkStyle:{
        /*@ts-ignore*/
        textDecoration: "none",
        color: "#E57727",
        fontFamily: "Inter",
        width:"64px",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
    },
}

export class TwoFactorAuthentication extends TwoFactorAuthenticationController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const{classes}=this.props;
        return (
            // Required for all blocks 
            <div className="mainDiv" style={{...compStyles.mainDiv,overflowY:"scroll"}}>
                <ThemeProvider theme={theme}>
                    <Container id="Container" className={classes.customContainer}>
                    <Loader loading={this.state.Loading}/>
                        <div style={compStyles.centerContent} >
                            <img style={compStyles.logoImage} src={imgVinLogo} alt="logo" />
                        </div>

                        <div className={classes.paperDiv} >
                            <Paper style={{ width: '100%' }} elevation={4} >

                                <div data-test-id="forgotPasswordForm" >
                                    <Formik
                                    data-testId="TwoFactorForm"
                                        initialValues={{Otp: "" }}
                                        validationSchema={Yup.object().shape(this.forgotSchema())}
                                        onSubmit={(values, actions) => {
                                            this.twofactorverify(values.Otp)                                            
                                            actions.setSubmitting(false);
                                            actions.resetForm();
                                          }}
                                    >
                                        {({
                                            handleChange,
                                            handleSubmit,
                                            errors,
                                            setFieldValue,
                                            touched,
                                            values,
                                            resetForm
                                        }) => (
                                            <form onSubmit={handleSubmit} style={{margin:"0"}}>
                                                <div className={classes.formStyle}>
                                                    <div style={{ textAlign: "start" }}>
                                                        <div style={{}} >
                                                            <Typography className={classes.mainTitle}>
                                                                {this.twoFactorAuthenticationTxt}
                                                            </Typography>
                                                           <Typography style={compStyles.subTitle}>
                                                            {this.enterOtpLabel}
                                                                </Typography>

                                                        </div>
                                                    </div>
                                                
                                                    {this.state.showAlert &&
                                                             <Alert classes={{
                                                                message: classes.alertMessage
                                                            }}
                                                                style={compStyles.alertStyle} icon={false} severity="error">
                                                                <div className={classes.divFlex} >
                                                                    <div style={{ border: "2px solid red" }}></div>
                                                                    <Typography className={classes.msgStyle} variant="body1">{this.state.alertMessage}</Typography>
                                                                </div>
                                                            </Alert>} 
                                                            <div>
                                                            <OtpInput data-testId="OtpInput" containerStyle={{gap:"20px"}} value={values?.Otp} onChange={(e:any)=>setFieldValue('Otp', e)} numInputs={5} renderInput={(props:any) => <input {...props} />} inputStyle={compStyles.otpDiv}/>
                                                                {touched.Otp && errors.Otp ? (
                                                                <Typography className={classes.errorStyle} color="error" >{errors.Otp}</Typography>
                                                            ) : null}
                                                            </div>

                                                        <div style={compStyles.centerContent}>
                                                            <Button
                                                                fullWidth
                                                                // @ts-ignore
                                                                className={classes.submitTFBtnStyle}
                                                                style={{textTransform: "initial"}}
                                                                data-test-id="submitBtn"
                                                                id="submitBtn"
                                                                type="submit"
                                                            >
                                                                {this.verifyTxt}
                                                            </Button>
                                                        </div>    
                                                
                                                        <div>            
                                                            <Typography className={classes.backBtn} >
                                                                <Redirect style={compStyles.decoration} to="/EmailLogin">
                                                                    <Link underline="none" style={compStyles.linkStyle} href="#">Go back</Link>
                                                                </Redirect>
                                                            </Typography></div>



                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>


                            </Paper>
                        </div>
                        
                    </Container>
                </ThemeProvider>
            </div>
        );
    }
}
export default withStyles(compStyles)(TwoFactorAuthentication);
// Customizable Area End
// Customizable Area End