// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Avatar,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import * as Yup from "yup";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Link as Redirect } from 'react-router-dom'

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import ThemeController, {
  Props,
} from "./ThemeController.web";
import CustomInput from "../../../components/src/CustomInput.web";
import { Formik } from "formik";
import ColorPicker from "../../../components/src/ColorPicker";
import RectangleRow from "../../../components/src/Rectangle";
import { NavigateNext } from "@material-ui/icons";
import { imgUpload } from "./assets";

const enumTheme = {
  Default: "default",
  Custom: "custom"
}
export class CreateTheme extends ThemeController {


  constructor(props: Props) {
    super(props);
  
  
  }

  render() {
    const { classes } = this.props
    const rectangles_color_code = [
      { color: '#E57727', label: 'Orange' },
      { color: '#073F59', label: 'Blue' },
      { color: '#51BEBA', label: 'Teal' },
      { color: '#CBD5E1', label: 'Cool Grey' },
      { color: '#F1F5F9', label: 'White' },
      { color: '#000000', label: 'Black' },
    ];  
    const breadcrumbsTheme = [
      <Link underline="hover" key="1" color="inherit">
        <Redirect to="/Settings2" className={classes.redirect}>Settings</Redirect>
      </Link>,
    
      <Typography key="3" className={classes.currentScreen} style={{color:this.state.secondaryColor !=""?this.state.secondaryColor :"#E57727",textTransform:"none"}}>
        Profile theme
      </Typography>,
    ];
    const validationSchema = Yup.object().shape({
      title: Yup.string().min(1, "Title is too short")
        .max(30, "Title is too long")
        .required("Title is required."),
      description:Yup.string().min(20, "Description is too short")
      .required("Description is required."),
      theme_image: Yup.string()
      .required("Logo is required."),
    });

    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"}>

        <Box sx={{ display: "flex", flexDirection: "column", 
                    width: "calc(100vw - 135px)", position: 'fixed', top: "0px", right: "0px", alignItems:"flex-start" ,
                    // justifyContent:'center',
                    height:"100vh"}}>
                        <Box className={classes.topStyle}>

                            <Breadcrumbs
                              separator={<NavigateNext fontSize="small" />}
                              aria-label="breadcrumb"
                            >
                              {breadcrumbsTheme}
                            </Breadcrumbs>

                        </Box>

     <div style={{ 
            width:"100%", 
            overflow:"auto"}}>

            <Formik
              data-testId="CreateThemeForm"
              initialValues={{
                title: "",
                description: "",
                theme_image: "",
                typeSlected: false,
                primary_color: "",
                secondary_color: "",
              }}
              validationSchema={validationSchema}

              validateOnChange={false}
              validateOnMount={true}
              validateOnBlur={true}
              onSubmit={async (values, actions) => {
                this.createTheme(values)
              }}
            >
              {({
                handleSubmit,
                touched,
                handleBlur,
                errors,
                values,
                handleChange,
              }) => (
                <form style={{ display: "flex", gap: "31px",marginTop:"38px",marginLeft:"38px",}} onSubmit={handleSubmit}>
                  <Box>
                    <Box className={classes.editBlocks}>
                      <div className={classes.editBox}>
                        <Typography className={classes.subEdit} >Create theme</Typography>
                      </div>
                      <div className={classes.logoBox}>
                        {this.state.selectedImage ? <img style={{maxHeight: '100px',width:"auto"}} src={this.state.theme_image} ></img>:<Typography className={classes.imgText}>Your uploaded logo</Typography>}
                        
                      </div>
                    </Box>
                    <div style={{ display: "grid", marginTop: "32px", gap: "29px" }}>

                      <Grid item style={{ width: "100%", padding: "0px 20px" }} >
                        <label aria-controls="title" >Title</label> <br />
                        <CustomInput
                          data-test-id="txtInputtitle"
                          autoComplete="given-name"
                          name="title"

                          // required
                          fullWidth
                          id="title"
                          placeholder="Your title"
                        />

                      </Grid>
                      <Grid item style={{ width: "100%", padding: "0px 20px" }} >
                        <label aria-controls="description" >Description</label><br />
                        <CustomInput
                          data-test-id="txtInputdescription"
                          fullWidth
                          id="description"
                          name="description"
                          multiline
                          autoComplete="description"
                          placeholder="Your description"
                        />
                      </Grid>
                    </div>
                  </Box>
                  <Box className={classes.themeSelected}>

                    <Box>
                      <div className={classes.subHeadDiv}>
                        <Typography className={classes.subEdit} >Theme settings</Typography>
                      </div>
                      <Grid container style={{ gap: "30px", padding: "20px" }}>
                        <Grid item xs={12} >

                          <FormControl>
                            <RadioGroup row aria-labelledby="demo-row-radiobutton-group-label" name={"typeSlected"}
                            className={classes.radioStyle}
                            data-test-id="radioButton"
                              defaultValue={this.state.themeTypeSelect ? enumTheme.Default : enumTheme.Custom}
                              value={this.state.themeTypeSelect ? enumTheme.Default : enumTheme.Custom}
                              onChange={(event: any) => {
                                handleChange(event);
                                this.handleTheme()
                              }}
                              style={{ gap: "15px" }}
                            >
                              <FormControlLabel className={classes.radioButton} checked={this.state.themeTypeSelect === true} value={enumTheme.Default} control={<Radio style={{ color: this.state.primaryColor }} />} label="Default theme" />
                              <FormControlLabel className={classes.radioButton} checked={this.state.themeTypeSelect === false} value={enumTheme.Custom} control={<Radio style={{ color: this.state.primaryColor }} />} label="Custom theme" />
                            </RadioGroup>
                          </FormControl>

                        </Grid>
                        <div className={classes.partition}></div>
                        {!this.state.themeTypeSelect && <>
                          <Grid item xs={12}>
                            <Box className={classes.pickerDiv}><Typography className={classes.pickerLines}>Primary color</Typography></Box>
                            <ColorPicker name="primary_color"
                              onChange={(e: any) => { this.handlePrimeColorChange(e); handleChange({ target: { name: 'primary_color', value: e } }) }}
                            />
                          </Grid>
                          <div className={classes.partition}></div>
                          <Grid item xs={12}>
                            <Box className={classes.pickerDiv}><Typography className={classes.pickerLines}>Secondary color</Typography></Box>
                            <ColorPicker name="secondary_color"
                              onChange={(e: any) => { this.handleSecondColorChange(e); handleChange({ target: { name: 'secondary_color', value: e } }) }}
                            />
                          </Grid>
                          <div className={classes.partition}></div>
                          <Grid item xs={12} style={{ display: "grid", gap: "5px" }}>
                            <Box className={classes.uploadBox}>
                              <input
                                type="file"
                                name="theme_image"
                                accept=".png, .svg, .jpg, image/png, image/svg+xml, image/jpeg"
                                onBlur={handleBlur}
                                onChange={(e) => { this.handleImageChange(e); handleChange(e) }}
                                style={{ display: 'none' }}
                                ref={this.state.fileInput}
                              />
                              <Button className={classes.uploadStyle} onClick={this.handleButtonClick} startIcon={
                                <Avatar
                                  className={classes.avatarStyle}
                                  src={imgUpload}
                                />
                              }> </Button>
                              <Typography>{this.state.selectedImage ? this.state.fileName : "Upload logo"}</Typography>
                            </Box>
                            {touched.theme_image && errors.theme_image && (
                          <Typography color="error" >{errors.theme_image}</Typography>
                        )}
                            <Typography className={classes.uploadTitle}>Image should be in png, jpg or svg format</Typography> </Grid>
                        </>}


                        {this.state.themeTypeSelect && <RectangleRow rectangles={rectangles_color_code} handleColor={ this.defaultColor } />}
                      </Grid>

                      <Grid style={{ display: "grid", padding: "0 20px", gap: "20px",marginBottom:"20px" }}>
                        <Typography className={classes.preview} data-test-id="previewTheme" style={{color:this.state.secondaryColor}} onClick={()=>this.previewTheme(values?.primary_color,values?.secondary_color)}>Preview theme</Typography>

                        <Button
                          id="btnforFormSubmit"
                          className={classes.createBtn}
                          style={{background:this.state.primaryColor}}
                          type="submit"
                          disableElevation
                        >
                          Create Theme
                        </Button>
                      </Grid>
                    </Box>

                  </Box>
                </form>


              )}
            </Formik>
          </div>

</Box>
        </Container>
      </ThemeProvider>
    );
  
  }
}

const webStyle = {
  uploadStyle: {
    padding: "16px",
    borderRadius: "8px",
    background: "#F6F2EF",
    '& .MuiButton-startIcon': {
      margin: 0
    },
    '& .MuiAvatar-root': {
      width: "24px",
      height: "24px"
    }
  },
  imgText:{
    width: "302px",
    color:"#A2A2A2",
    fontSize: "20px",
    marginLeft: "15px",
    fontStyle: "normal",
    flexShrink: 0,
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "28px",
  },
  radioButton:{
    border: "1px solid #CBD5E1",
    margin: 0,
    borderRadius: "50px",
    width: "150px",
    height: "36px",
  },
 subEdit:{
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal", width: "302px",
    flexShrink: 0,
    fontWeight: 700,
    lineHeight: "28px",
  },
  pickerLines:{
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "28px"
  },
  pickerDiv:{
    display: "flex",
    padding: "10px",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "6px",
    background: "#F6F2EF",
    alignSelf: "stretch",
  },
  uploadTitle:{
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  uploadBox:{
    display: "flex",
    alignItems: "center",
    gap: "16px",
    alignSelf: "stretch", borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF"
  },
  editBlocks:{
    display: "flex",
    width: "803px",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1"
  },
  preview: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center" as const,
    color: "#E57727",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "initial" as const,
  },
  themeSelected:{
    width: "400px",
    height: "auto",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    flexShrink: 0
  },
  subHeadDiv:{
    display: "flex",
    padding: "24px 16px 28px 40px",
    alignItems: "center" as const,
    borderRadius: "8px 8px 0px 0px",
    borderBottom: "1px solid #E2E8F0",
    background: "#FFF",
  },
  createBtn: {
    textTransform: "initial" as const,
    width: "100%",
    height: "56px",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    background: "#E57727",
    display: "flex",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
    "&:hover": {
      background: "#E57727"
    }
  },
  topStyle:{
    "display": "flex", "height": "32px", "padding": "20px 1154px 20px 48px", "justifyContent": "space-between",
    "alignItems": "center", "flexShrink": 0, "borderBottom": "1px solid #CBD5E1", width: "calc(100vw - 193px)",
    position: "sticky" as const
},
  redirect: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px",
    '&:hover': {
      textDecoration: "none",
    },
  },
  currentScreen: {
    // color: updateColor,
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    textDecoration: "none",
    letterSpacing: "-0.12px"
  },
  editBox:{
    display: "flex",
    padding: "24px 461px 28px 40px",
    alignItems: "center" as const,
    borderRadius: "8px 8px 0px 0px",
    borderBottom: "1px solid #E2E8F0",
    background: "#FFF",
  },
  logoBox:{
    display: "flex",
    padding: "20px",
    alignItems: "center" as const,
    gap: "30px",
    alignSelf: "stretch",
  },
  radioStyle:{
    "& .MuiTypography-body1": {
      fontSize: "16px !important",
      color: "#0F172A",
      fontFamily: "Inter",
      lineHeight: "24px",     
  },
  '& .MuiRadio-root.Mui-checked': {
    color: '#e87242',
  },
  '& .MuiRadio-root:not(.Mui-checked)': {
    color: 'grey !important',
  },
},

  avatarStyle: {
    '& .MuiAvatar-root': {
      width: "24px",
      height: "24px"
    },
  }

};
export default withStyles(webStyle)(CreateTheme)
// Customizable Area End