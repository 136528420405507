import React from "react";

// Customizable Area Start
import {

    Box,
    Button,
    Typography,

} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import NotificationDetailsController, {
    Props,
   
} from "./NotificationDetailsController.web";
import { ArrowBack} from "@material-ui/icons";

export default class Notifications extends NotificationDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Loader loading={this.state.showloader}/>
                <Box sx={webStyle.mainWrapper}>
                    
                    <Box sx={webStyle.topbar}>
                        <Button style={webStyle.backBtn} onClick={()=>this.handleNavigateBack()} data-test-id="navigate"> <ArrowBack/> <Typography style={webStyle.back}>Back</Typography></Button>
                        <Typography style={webStyle.detailsHead}>Notification Details</Typography>
                       

                    </Box>

                    <Box sx={webStyle.commonItemWraper} >
                        <Box sx={webStyle.subWraper}>
                            <Box style={{
                                "display": "flex",
                                "alignItems": "flex-start",
                                "gap": "24px"
                            }}>
                                {this.isProfile(this.state.profileImg) ?  <img src={this.state.profileImg} style={webStyle.profileimg} />:
                                <div style={webStyle.imgAlter}>{this.state.sender_name.charAt(0)}</div>}
                                <Box sx={webStyle.subItemPara}>

                                    <Typography style={webStyle.timetext}>{this.formatDate(this.state.created_date)}</Typography>
                                    <Typography style={{...webStyle.subItemTextClr,display:"flex"}}><Typography style={{...webStyle.subItemTextClr,textTransform:"capitalize",marginRight: "6px"}}>{this.state.sender_name}</Typography> - {this.designations(this.state.designation)} </Typography>


                                    <Typography style={{...webStyle.subItemPara2,display:'flex'}}><Typography style={{...webStyle.subItemTextClr,fontWeight:400,textTransform:"capitalize"}}>{this.state.sender_name}</Typography>: {this.state.contents}</Typography>
                                    <Box style={{
                                        "display": "flex",

                                        "alignItems": "flex-start",
                                        "gap": "24px",
                                        marginTop:"24px"
                                    }}>
                                        <Button variant="text" data-test-id="markRead" style={{ paddingLeft: 0 }} onClick={this.updateHandle}> <Typography style={webStyle.otherbtn}>Mark as unread</Typography></Button>
                                        <Button variant="text" data-test-id="deleteNotification" style={{ paddingLeft: 0 }} onClick={()=>this.deleteNotification(this.state.notificationID)}> <Typography style={webStyle.otherbtn}>Delete</Typography></Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>


                    </Box>


                </Box>



            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const webStyle: any = {
    mainWrapper: { "width": "1260px", "height": "912px", 
    "padding": "8px 24px 8px 24px", "flexDirection": "column", 
    "justifyContent": "center", "alignItems": "center", "gap": "8px",
     "flexShrink": "0", "borderRadius": "8px 8px 32px 8px", 
     "background": "#FFF", margin: 'auto' ,top:"30px",position:"absolute"},

    commonItemWraper: {
        "display": "flex",
        "padding": "16px 40px",
        "flexDirection": "column",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "alignSelf": "stretch",
        "background": "#FFF",
        // height: "4rem",
        position: 'relative'
    },

    profileimg: {
        "display": "flex",
        "width": "56px",
        "height": "56px",
        "flexDirection": "column",
        "justifyContent": "center",
        "alignItems": "center",
        "borderRadius": "100px",
    },
    imgAlter:{
        width:"56px",
        height:"56px",
        borderRadius:"50%",
        background:"#F6F2EF",
        color:"#E57727",
        fontSize: "18px",
        fontWeight: 700,
        textTransform: "capitalize",
        display: "flex",
        fontFamily:"Inter",
        alignItems: "center",
        justifyContent: "center"
      },
    subItemPara: {
        "display": "flex",
        "width": "628px",
        "flexDirection": "column",
        "alignItems": "flex-start",
        "gap": "4px"
    },
    subItemTextClr: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "700",
        "lineHeight": "20px",
        // width: "628px"
    },
    subItemPara2: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "400",
        "lineHeight": "20px",
        width: "628px",
        marginTop: "1rem"
    },


    subWraper: {
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "flex-start",
        "alignSelf": "stretch"
    },
    timetext: { "color": "#334155", "fontFamily": "Inter", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "22px" },
    btnreply: {
        "color": "#6200EA",
        "fontFamily": "Inter",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "700",
        "lineHeight": "24px",
        textTransform: "none"
    },
    otherbtn: {
        "display": "flex",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0px",
        "height": "24px",
        "justifyContent": "center",
        "alignItems": "center",
        "flexShrink": "0",
        color: "#64748B",
        textTransform: "none"
    },
    topbar:{
        "display": "flex",
        "width": "1284px",
        "height": "100px",
        "padding": "4px 965px 16px 40px",
        "flexDirection": "column",
        "alignItems": "flex-start",
        "gap": "16px",
        "flexShrink": "0"
      },
      back:{
        "color": "#334155",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "700",
        "lineHeight": "22px",
        textTransform: "none"
      },
      backBtn:{
        "display": "flex",
        "width": "64px",
        "height": "24px",
        "justifyContent": "center",
        "alignItems": "flex-start",
        "gap": "6px",
        "flexShrink": "0"
      },
      detailsHead:{
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "30px",
        "fontStyle": "normal",
        "fontWeight": "700",
        "lineHeight": "40px",
        "letterSpacing": "-0.15px"
      }
};
// Customizable Area End
