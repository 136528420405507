import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from 'moment';
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  userList: any;
  auditTrailsAttachments: any;
  selectedUserId: any;
  selectedItemType: any;
  fromDate: any;
  toDate: any;
  pageNumber: any,
  dataPerPage: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Audittrail2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserListApiCallId: any;
  getAuditTrailsAttachments: any;
  getfetchAuditTrails: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationProjectnotesMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      userList: [],
      auditTrailsAttachments: [],
      selectedUserId: '',
      selectedItemType: '',
      fromDate: '',
      toDate: '',
      pageNumber: 1,
      dataPerPage: 14,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responsejson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getUserListApiCallId) {
        this.getUserListApiCallIdSQ(responsejson);
      }
      if (apiRequestCallId === this.getfetchAuditTrails) {
        this.getfetchAuditTrailsSQ(responsejson);
      }
      if (apiRequestCallId === this.getAuditTrailsAttachments) {
        this.getAuditTrailsAttachmentsSQ(responsejson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  token: string = "";


  itemTypeChange = (event: any) => {
    this.setState({ selectedItemType: event.target.value });
  }

  userSelectionChange = (event: any) => {
    this.setState({ selectedUserId: event.target.value });
  }

  updateFromDate = (event: any) => {
    const { name, value } = event.target;
    //@ts-ignore
    this.setState({ [name]: value })
  };



  dataPerPageOptions = [14, 28, 42, 50, 70];

  handleItemsPerPageChange = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    this.setState({ dataPerPage: newValue, pageNumber: 1 });
  };

  getUserListApiCallIdSQ = (responsejson: any) => {
    if (responsejson) {
      this.setState({ userList: responsejson.users });
    }
  }

  getAuditTrailsAttachmentsSQ = (responsejson: any) => {
    if (responsejson) {
      this.setState({ auditTrailsAttachments: responsejson.data });
    }
  }

  getfetchAuditTrailsSQ = (responsejson: any) => {
    if (responsejson.data) {
      this.setState({ auditTrailsAttachments: responsejson.data });
    }
    else {
      toast.error(responsejson.message);
    }

  }
  getUserList = async () => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.getUserListApiCallId = await this.getAuditTrailapiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: "/bx_block_profile/audit_trails/user_list",
      token: this.token,
    });
  }

  fetchAuditTrailsAttachments = async () => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.getAuditTrailsAttachments = await this.getAuditTrailapiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: "/bx_block_profile/audit_trails/audit_trails_attachments",
      token: this.token,
    });
  }

  fetchAuditTrails = async () => {
    const { selectedUserId, selectedItemType, fromDate, toDate } = this.state;
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;
    const fromDateFormatted = fromDate && moment(fromDate).format('DD/MM/YYYY');
    const toDateFormatted = toDate && moment(toDate).format('DD/MM/YYYY');

    this.getfetchAuditTrails = await this.getAuditTrailapiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: `/bx_block_profile/audit_trails/filter?${selectedUserId ? `account_id=${selectedUserId}` : ''}${selectedItemType ? `&action_type=${selectedItemType}` : ''}${fromDateFormatted ? `&from_date=${fromDateFormatted}` : ''}${toDateFormatted ? `&to_date=${toDateFormatted}` : ''}`,
      token: this.token,
    });

  }


  getAuditTrailapiCall = async (data: any) => {
    const { methods, endPoint, token } = data;
    const Header = {
      "Content-Type": "application/json",
      token,
    };
    const apiRequestMessageforAuditTrail = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforAuditTrail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforAuditTrail.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforAuditTrail.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    runEngine.sendMessage(
      apiRequestMessageforAuditTrail.id,
      apiRequestMessageforAuditTrail
    );
    return apiRequestMessageforAuditTrail.messageId;
  }

  // Customizable Area End
}
