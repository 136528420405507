import React, { useState } from "react";
import { Mention, MentionSearchEvent } from 'primereact/mention';
import { Box, Container, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import moment from 'moment';
import {
	Button,
	TextField,
	Avatar,
	Grid,
	OutlinedInput,
	Popover
} from "@material-ui/core";

const theme = createTheme({
	palette: {
		primary: {
			main: "#fff",
			contrastText: "#fff",
		},
	},
	typography: {
		h6: {
			fontWeight: 500,
		},
		subtitle1: {
			margin: "20px 0px",
		},
	},
});
export  function BasicDemo({ handleInputChange, value, users }: any) {
	// const [value, setValue] = useState<string>('');
	const [suggestions, setSuggestions] = useState<any>([]);



	const handleButtonClick = () => {
		console.log("Mention input:", value);
	};
	const handleValueChange = () => {

	}


	const onSearch = (event: MentionSearchEvent) => {
		setTimeout(() => {
			const query = event.query;
			let filteredUsers;

			if (!query.trim().length) {
				filteredUsers = [...users];
			}
			else {
				filteredUsers = users.filter((user: any) => {
					return user.display.toLowerCase().includes(query.toLowerCase()) || user.email.toLowerCase().includes(query.toLowerCase());
				});
			}

			setSuggestions(filteredUsers);
		}, 250);
	};


	return (
		<ThemeProvider theme={theme}>
			<Container maxWidth={"xl"}>
				<Box sx={webStyle.mainContainer}>
					<Mention
						value={value}
						//@ts-ignore
						onChange={handleInputChange}
						suggestions={suggestions}
						onSearch={onSearch}
						field="email"
						placeholder="Enter @ to mention people"
						rows={2}
					/>
				</Box>
			</Container>
		</ThemeProvider >
	)
}


export const TableListComponent = ({ ProjectNoteMention }: any) => {
	const modifiedArray: any = [];

	ProjectNoteMention.forEach((item: any) => {
		const email = Object.keys(item)[0];
		const data = item[email];
		if (data && typeof data === 'object') {
			const keys = Object.keys(data);

			const modifiedItem = { email };
			keys.forEach(key => {
				//@ts-ignore
				modifiedItem[key] = data[key];
			});
			modifiedArray.push(modifiedItem);
		}
	});
	return (
		<>
			<Box className="comment-wrapper">
				<Typography className="comment-title">{modifiedArray.length} comments</Typography>
				<div className="comments">
					<div className="comment">
						{modifiedArray && modifiedArray.map((item: any, index: any) => {
							const firstPropertyName = Object.keys(item)[1];
							const firstPropertyValue = item[firstPropertyName];
							return (
								<>
									<div className="comment" key={index}>
										<div className="comment-profile">
											<Avatar className="comment-profile-img">{Object.keys(item)[1]?.split(" ")[0]?.charAt(0)}</Avatar>
											<Typography className="comment-profile-name">{Object.keys(item)[1]}</Typography>
										</div>

										<Typography className="comment-profile-name comment-text">
											<strong>{Object.keys(item)[1]}</strong>	{firstPropertyValue}</Typography>
									</div>
								</>
							)
						})}
					</div>
				</div>
			</Box>
		</>
	)
}
const webStyle = {
	mainContainer: {
		marginBottom: "28px",
		'& > div': {
			width: '100%',
			"& textarea": {
				padding: '10px 8px',
				borderRadius: '8px',
				border: '1px solid #CBD5E1',
				width: '100%',
				fontSize: '16px',
				color: '#64748B',
				resize: 'vertical',
				'&:focus[data-focusvisible-polyfill]': {
					outline: '2px solid #CBD5E1',
				}
			},
			'& .p-mention-panel': {
				background: '#ffffff',
				color: '#4b5563',
				border: '0 none',
				borderRadius: '6px',
				boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.1)',
				'& .p-mention-item': {
					cursor: 'pointer',
					whiteSpace: 'nowrap',
					position: 'relative',
					overflow: 'hidden',
					padding: '0.75rem 1.25rem',
					background: 'transparent',
					textTransform: 'capitalize',
					borderBottom: '1px solid #f1f1f1',
					'&:hover': {
						background: 'rgb(232 116 36 / 90%)',
						color: '#fff',
					},
					'&.p-highlight': {
						background: 'rgb(232 116 36 / 90%)',
						color: '#fff',
					}
				}
			}
		}
	}
};