import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, CircularProgress, OutlinedInput, Grid, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, TextField, Typography, InputAdornment, } from '@material-ui/core';
// import LabelWithIcon from './LabelWithIcon';
import Select from "react-select";
import { Close } from '@material-ui/icons';
import CustomInput from './CustomInput.web';
import { Formik,useField,useFormik } from 'formik';
import * as Yup from "yup";


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            margin: '0',
            borderRadius: '8px',
            width: '624px',
            maxHeight: "calc(100% - 3px)",
        },
        "& [role='none presentation']": {
          alignItems: "start",
          justifyContent: "end",
      },
    },
    headingStyle: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    paraStyle:{
        textAlign: 'center',
    },
    btnWrapper:{
        justifyContent: 'flex-end',
        padding: "24px 24px 24px 350px",
        borderTop: "1px solid #E2E6F0",
        display: "inline-flex",
        alignItems: "flex-start",
        gap: "16px",
    
        '& button': {
            padding: '16px',
            width: '105px',
            fontWeight:700,
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: '#E57727',
    },
      "text-transform": "none",
      "color": "white"
    },
    cancelBtn: {
      color: '#000',
      background: "#F6F2EF",
      width:"105px !important",
      height:"56px",
      borderColor: '#F6F2EF',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      textTransform: "capitalize"
  },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    textInputStyle: {
        margin: "0 !important",
        "& .MuiOutlinedInput-input": {
          textTransform:"uppercase",
        }
    },
    labelStyle:{fontSize:"14px",fontWeight:700,lineHeight:"22px",color:"#334155"},
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 40px",
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    phoneInputLine: {
      borderLeft: "2px solid gray",
      height: "25px",
      marginLeft: "5px"
  },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    reasonsSection: {
        // marginBottom: '25px !important',
        display:"flex",
        flexDirection:"column",
        gap:"10px",
        width:"520px"
    },
    downloaIcon: {
        cursor: "pointer"
    },
    pushBackBtn: {
      border: '1px solid primary'
    },
    errorInputStyle:{
      border: '1px solid red'
    }
})

const initialValues={ fullName: "", contactName: "",companyName:"",contactNumber:"", emailId:"" ,address:"",dateofBirth:"" }
const validationSchema = Yup.object().shape({
  fullName: Yup.string().min(1, "Full name is too short")
  .max(20,"Full name is too long")
  .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Full name is invalid')
  .required("Full name is required."),
  contactName: Yup.string().min(1, "Contact name is too short")
  .max(20,"Contact name is too long")
  .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Contact name is invalid')
  .required('Contact name is required'),
  companyName: Yup.string().required('Company name is required'),
  contactNumber: Yup.string()
  .matches(/^\d{10}$/, 'Number should be 10 digits')
  .matches(/^(?!0+$)/, 'Invalid number')
  .required('Contact number is required'),
  dateofBirth: Yup.date()
  .max(new Date(), 'Date of Birth cannot be selected')
  .required('Date of Birth is required'),
  emailId: Yup.string().min(1,"Email is too short")
  .max(50,"Email is too long")
  .email('Invalid email').required("Email is required."),
  address: Yup.string().required('Address is required'),
});
type AddAccountProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText?: string,
    btnOkText: string,
    handleOk: any;
    handleCancel:any;
    primaryColor:string;
    secondaryColor:string;
    // handleInsertValue?:any;
    // insertValue?:string[];
    // btnLoading?:boolean;
    // inputDisable?: boolean;
    // columnNames?: string[];
    // testIDProps?: string;
    subHeading?: string;
    showDialogBtns?: boolean;
    // pushBackOptions?:any;
    // textId?:string;
    // setSelectedPushBackTo?: any;
    showCrossBtn?: boolean;
    // stepValue?: any;
    // typeSelected?:any;
    // handleRadio?:any;
    // handleFindText?:any;
    // dialogFind?:any;'
    passwordSchema?:any;
}




const AddAccount = (props:AddAccountProps) => {
    const classes = useStyles();
    const [maxDate, setMaxDate] = useState(calculateMaxDate());

  function calculateMaxDate() {
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    return maxDate.toISOString().split('T')[0];
  }

  console.log("dateDELETED18",maxDate)
      
  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                    <span className={classes.subHeading}>{props.subHeading}</span>
                </div>
                <Close
                    className={classes.downloaIcon}
                    // onClick={props?.handleCancel}
                    onClick={() => {
                      props?.handleCancel()
                    }} 
                />
           </div>
        </DialogTitle>
        <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                  console.log("VALUES",values);
                  
                props.handleOk(values);
                actions.setSubmitting(false)
                actions.resetForm();
                }}
              >
                {({
                  handleSubmit,
                  touched,
                  handleBlur,
                  errors,
                  values,
                  handleChange,
                }) => (
                  <form style={{margin:"0"}}onSubmit={handleSubmit}>

       <DialogContent style={{ display:"grid",padding: "24px 40px 8px",gap:"16px"}}>

          <Grid className={classes.reasonsSection} item xs={12}>
          
               <label className={classes.labelStyle}>Full name</label>
               <OutlinedInput
                  
                    fullWidth
                    name="fullName"
                    id="fullName"
                    value={values.fullName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter your name"
                />
                    {touched.fullName && errors.fullName && (
                    <Typography color="error" >{errors.fullName}</Typography>
                    )}
                
          </Grid>

          <Grid className={classes.reasonsSection} item xs={12}>
               <label className={classes.labelStyle}>Contact name</label>
               <OutlinedInput
                  
                    fullWidth
                    name="contactName"
                    type="text"
                    id="contactName"
                    value={values.contactName}
                    onChange={handleChange}
                        onBlur={handleBlur}
                    placeholder="Enter your contact name "
                />
                         {touched.contactName && errors.contactName && (
                    <Typography color="error" >{errors.contactName}</Typography>
                  )}
          </Grid>
          <Grid className={classes.reasonsSection} item xs={12}>
               <label className={classes.labelStyle}>Company name</label>
               <OutlinedInput
                  
                    fullWidth
                    name="companyName"
                    type="text"
                    id="companyName"
                    onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.companyName}
                    placeholder="Enter your company name "
                />
                         {touched.companyName && errors.companyName && (
                    <Typography color="error" >{errors.companyName}</Typography>
                  )}
          </Grid>
          <div style={{width: "520px",height: "1px",background:"#E2E8F0"}}></div>
          <Grid style={{display:"flex",gap:"24px"}} item xs={12} >
          <Grid item style={{display: "grid",gap: "5px",width:"248px"}} >
          <label className={classes.labelStyle}>Contact number</label>
          <OutlinedInput
              
               fullWidth
               name="contactNumber"
               id="contactNumber"
               value={values.contactNumber}
               onBlur={handleBlur}
               onChange={handleChange}
               placeholder="123 456 7890"
               startAdornment={
                <InputAdornment position="start">
                    + 1<div className={classes.phoneInputLine} />
                </InputAdornment>
              }
           />
               {touched.contactNumber && errors.contactNumber && (
               <Typography color="error" >{errors.contactNumber}</Typography>
               )}
     </Grid>
     <Grid item style={{display: "grid",gap: "5px",width:"248px"}} >
     <label className={classes.labelStyle}>Date of Birth</label>
          <TextFieldControl
              data-test-id={`dateofBirth`}
              id={`dateofBirth`}
              className={classes.textInputStyle}
              name={`dateofBirth`}
              type={"date"}
              onBlur={handleBlur}
              placeholder="DD-MM-YYY"
              style={{textTransform:"uppercase"}}
              inputProps={{
                min: "1920-01-01",
                max: maxDate,
              }}
              // value={this.values?.dateofBirth}
              // disabled={this.state.view || item.quotationReceived == "no" || !item.quotationReceived ? true : false}
              onChange={handleChange}
          />
          {touched.dateofBirth && errors.dateofBirth && (
               <Typography color="error" >{errors.dateofBirth}</Typography>
               )}
      </Grid>
      </Grid>
     <Grid className={classes.reasonsSection} item xs={12}>
    
          <label className={classes.labelStyle}>Email</label>
          <OutlinedInput
              
               fullWidth
               name="emailId"
               id="emailId"
               onChange={handleChange}
               onBlur={handleBlur}
               value={values.emailId}
               placeholder="Enter your email ID"
               error={touched.emailId && Boolean(errors.emailId)}
           />
               {touched.emailId && errors.emailId && (
               <Typography color="error" >{errors.emailId}</Typography>
               )}
           
     </Grid>
     <div style={{width: "520px",height: "1px",background:"#E2E8F0"}}></div>
     <Grid className={classes.reasonsSection} item xs={12}>
    
          <label className={classes.labelStyle}>Address</label>
          <OutlinedInput
              
               fullWidth
               name="address"
               id="address"
               onBlur={handleBlur}
               onChange={handleChange}
               value={values.address}
               placeholder="Enter your address"
               error={touched.address && Boolean(errors.address)}
           />
               {touched.address && errors.address && (
               <Typography color="error" >{errors.address}</Typography>
               )}
           
     </Grid>
       </DialogContent>
        
    {/* <DialogActions className={classes.btnWrapper}> */}

<Grid className={classes.btnWrapper}>
<Button variant="outlined" className={classes.cancelBtn} style={{color:props.secondaryColor}}
                        onClick={() => {
                            // setReason("");
                            // setSelectValue("");
                            props.handleCancel()
                        }}
                    >
                        {props.btnCancelText}
                    </Button>
<Button variant="contained"  className={classes.primaryBtnStyle} style={{background:props.primaryColor}} type='submit'>
                            {props.btnOkText}
                        </Button>
    </Grid>          
        
{/* 
        </DialogActions> */}
        </form>
                )}
        </Formik>

      </Dialog>
    </div>
  )
}

const TextFieldControl = (props: any) => {
  const [field, meta] = useField(props);

  return (
      <OutlinedInput
          fullWidth
          type={props.type}
          {...field}
          {...props}
          error={meta.error && meta.touched}
      />
  );
};

export default AddAccount

