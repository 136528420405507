import React from "react";
// Customizable Area Start
import {
  Button,
  InputAdornment,
  TextField,
  Table,
  TableHead,
  TableCell,
  Chip,
  TableRow,
  TableBody,
  Modal,
  Typography,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,

} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import { MoreVert, FilterList, ArrowForwardIos } from "@material-ui/icons";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PeopleWebController from "./PeopleWebController.web";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export class PeopleWeb extends PeopleWebController {
  render() {
    const { usersAccountData,cuurentUserType } = this.state;
    console.log(cuurentUserType ,":::::::::::currenttype:::::::::::")

    return (
      /* Customizable Area Start */
      <ThemeProvider theme={theme}>
        
        <div>
          <div
            style={{
              width: "1244px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                border: "1px solid #F0E5FF",
                padding: "16px 24px",
                borderRadius: "0.5rem",
                height: "3rem",
                alignItems: "center",
                marginTop: "21px",
              }}
            >
              <div
                className="btncontainer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <Chip
                  data-test-id="clienttab"
                  onClick={this.handleClientClick}
                  disabled={cuurentUserType =="client"? true :false}
                  label="Clients"
                  variant="outlined"
                  style={{
                    backgroundColor: this.state.clientTab
                      ? this.state.btnColor
                      : "#F6F2EF",
                    color: this.state.clientTab ? "#ffffff" : "",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "1.5rem",
                    display: "flex",
                    width: "180px",
                    height: "48px",
                    padding: "10px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "50px",
                    border: "none",
                  }}
                />
                <Chip
                  data-test-id="teammates"
                  onClick={this.handleTeammatesClick}
                  disabled={cuurentUserType =="vincertax_employee"? false :true}
                  label="Teammates"
                  variant="outlined"
                  style={{
                    backgroundColor: this.state.teamMatesTab
                      ? this.state.btnColor
                      : "#F6F2EF",
                    color: this.state.teamMatesTab ? "#ffffff" : "",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "1.5rem",
                    display: "flex",
                    width: "180px",
                    height: "48px",
                    padding: "10px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "50px",
                    border: "none",
                  }}
                />
                <Chip
                  data-test-id="finAdvisor"
                  onClick={this.handleFinAdvisorClick}
                  disabled={cuurentUserType =="client"? true :false}
                  label="Financial Advisor"
                  variant="outlined"
                  style={{
                    backgroundColor: this.state.finAdvisorTab
                      ? this.state.btnColor
                      : "#F6F2EF",
                    color: this.state.finAdvisorTab ? "#ffffff" : "",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "1.5rem",
                    display: "flex",
                    width: "220px",
                    height: "48px",
                    padding: "10px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "50px",
                    border: "none",
                  }}
                />

                <TextField
                  type="search"
                  id="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: { borderRadius: "1.875rem", height: "44px" },
                  }}
                  placeholder="Search"
                  variant="outlined"
                  style={{
                    width: "25rem",
                    borderRadius: "98px",
                    border: "1px solid #CBD5E1",
                  }}
                  value={this.state.searchedRows}
                  onChange={(searchedRows) => this.requestSearch(searchedRows)}
                />
              </div>

              <div>
                <Button
                  data-test-id="filterbutton"
                  variant="outlined"
                  style={{"display":"flex","padding":"7px 10px","alignItems":"center","gap":"8px","alignSelf":"stretch", borderRadius:"6px",
                border:"1px solid #E2E8F0",fontSize:"18px",fontWeight:700,textTransform:"none",width:"6.25rem",height:"2.875rem"}
                  }
                  aria-describedby="data"
                  onClick={this.filterPopoverOPen}
                >
                  Filter
                  <FilterList style={{ marginLeft: "8px",width:"1.5rem",height:"1.5rem"}} />
                </Button>
              </div>
            </div>
            <div
              className="table"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "21px",
                position: "relative",
              }}
            >
              {this.state.filterSelect && (
                <div className="modalAccountFilter">
                  <Card
                    elevation={5}
                    style={{
                      position: "absolute",
                      top: "-2rem",
                      right: "2%",

                      width: "36.5rem",
                      height: "32.44rem",
                      borderRadius: "0.5rem",
                      boxShadow:
                        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "32px",
                        padding: "12px 25px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "6px",
                        borderBottom: "1px solid #CBD5E1",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          paddingLeft: "1rem",
                        }}
                      >
                        {this.state.filterCount} filter Selected
                      </Typography>
                      <Button
                        onClick={() => this.getAccounts(this.state.accountType)}
                        data-test-id="filterbtnclose"
                        variant="contained"
                        style={{
                          display: "flex",
                          height: "32px",
                          padding: "6px 10px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "6px",
                          textTransform: "none",
                          fontSize:"16px",
                          borderRadius: "0.5rem",
                          backgroundColor: "#F6F2EF",
                          color: this.state.textColor,
                          fontWeight: 700,
                        }}
                      >
                        Clear all
                      </Button>
                    </div>
                    {/* <Divider variant="fullWidth" /> */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex" }}>
                        <List component="nav" style={useStyles.root}>
                          <ListItem button divider style={useStyles.listItems}>
                            {/* <ListItemText primary="By Client name  " style={{fontSize:"14px"}}/> */}
                            <span style={{fontSize:"14px"}}>By Client name</span>
                            <ArrowForwardIos style={{ fontSize: "14" }} />
                          </ListItem>
                          <Divider />
                          <ListItem button divider style={useStyles.listItems}>
                            {/* <ListItemText primary="Status" /> */}
                            <span style={{fontSize:"14px"}}>Status</span>
                            <ArrowForwardIos style={{ fontSize: "14" }} />
                          </ListItem>
                          <Divider />
                          <ListItem
                            button
                            divider
                            onClick={this.handleShowDate}
                            data-test-id="showdate"
                            style={useStyles.listItems}
                          >
                            {/* <ListItemText primary="Date Created" /> */}
                            <span style={{fontSize:"14px"}}>Date Created</span>
                            <ArrowForwardIos style={{ fontSize: "14" }} />
                          </ListItem>
                          <Divider />
                          <ListItem button divider style={useStyles.listItems}>
                            {/* <ListItemText primary="Company Name" /> */}
                            <span style={{fontSize:"14px"}}>Company Name</span>
                            <ArrowForwardIos style={{ fontSize: "14" }} />
                          </ListItem>
                          <Divider />
                        </List>
                        <Divider
                          orientation="vertical"
                          style={{ height: "22.5rem" }}
                        />
                        {/* data     */}
                        <div
                          className="filterMenu"
                          style={{ width: "10.375rem" }}
                        >
                          {this.state.showDate && (
                            <TextField
                              id="date"
                              type="date"
                              onChange={this.handleDateChange}
                              data-test-id="dateinput"
                              style={{ padding: "10px 0px 10px 25px" }}
                            />
                          )}
                        </div>
                      </div>
                      <Divider variant="fullWidth" />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          gap: "1rem",
                          padding: "1.5rem",
                        }}
                      >
                        <Button
                          variant="text"
                          onClick={this.filterPopoverClose}
                          style={{
                            display: "flex",
                            width: "6.5625rem",
                            height: "3.5rem",
                            padding: "1rem",
                            textTransform: "none",
                            backgroundColor: this.state.btnColor,
                            borderRadius: "0.5rem",
                            color: "#fff",
                            fontWeight: 700,
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="text"
                          onClick={this.filterAccountByfilter}
                          style={{
                            display: "flex",
                            width: "6.5625rem",
                            height: "3.5rem",
                            padding: "1rem",
                            textTransform: "none",
                            backgroundColor: this.state.btnColor,
                            borderRadius: "0.5rem",
                            color: "#fff",
                            fontWeight: 700,
                          }}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      height: "2.43rem",
                      borderRadius: " 0.375rem",
                      border: "1px solid #CBD5E1",
                    }}
                  >
                    <TableCell
                      style={{
                        color: "#073F59",
                        fontWeight: 400,
                        fontSize: "15px",
                        width: "30%",
                      }}
                    >
                      Client's Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#073F59",
                        fontWeight: 400,
                        fontSize: "15px",
                        width: "30%",
                      }}
                    >
                      Email address
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#073F59",
                        fontWeight: 400,
                        fontSize: "15px",
                        width: "30%",
                      }}
                    >
                      Contact Number
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#073F59",
                        fontWeight: 400,
                        fontSize: "15px",
                        width: "10%",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <br />
                {usersAccountData !== undefined ? (
                  <TableBody>
                    {usersAccountData.map((item: any, index: number) => (
                      <React.Fragment key={item?.id}>
                        <TableRow
                          style={{
                            borderRadius: "0.375rem",
                            border: "1px solid #CBD5E1",
                            padding: "0.625rem",
                            height: "3.5rem",
                            cursor:"pointer"
                          }}
                          onClick={()=>this.gotoProfile(item?.id,item?.attributes.full_name)}
                          key={item?.id}
                          data-test-id={`row-${item?.id}`}
                        >
                          <TableCell
                            data-test-id={`firstname-${index}`}
                            style={{
                              fontSize: "20px",
                              fontWeight: 600,
                              width: "30%",
                            }}
                          >
                            {item.attributes.first_name}{" "}
                            {item.attributes.last_name}
                          </TableCell>
                          <TableCell style={{ padding: "1rem", width: "30%" }}>
                            {item?.attributes?.email}
                          </TableCell>
                          <TableCell style={{ fontSize: "14px", width: "30%" }}>
                            {item?.attributes?.phone_number}
                          </TableCell>

                          <TableCell
                            data-test-id={`accountStatus-${index}`}
                            style={{
                              fontSize: "14px",
                              cursor: "pointer",
                              width: "10%",
                            }}
                            align="right"
                            onClick={() => {
                              this.accountProfileStatusModalOpen(
                                item?.id,
                                usersAccountData[index]?.id
                              );
                            }}
                          >
                            <MoreVert />
                          </TableCell>
                        </TableRow>

                        <div style={{ marginBottom: "0.8rem" }}></div>
                        <div>
                          <Modal
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            open={this.state.accountProfileStatusModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Card
                              style={{
                                width: "37.5rem",
                                borderRadius: "0.5rem",
                                paddingTop: "3.75rem",
                                display: "flex",
                                flexDirection: "column",
                                gap: "1.5rem",
                                height: "16.3rem",
                              }}
                            >
                              <div
                                className="cardcontent"
                                style={{
                                  display: "flex",
                                  width: "32.5rem",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  gap: "1rem",
                                  alignSelf: "center",
                                  height:"132px"
                                }}
                              >
                                <Typography
                                  id="modal-modal-description"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: 700,
                                    color: "#0F172A",
                                    fontFamily: "Inter",

                                    fontStyle: "normal",

                                    lineHeight: "2rem",
                                    letterSpacing: "-0.0075rem",
                                  }}
                                >
                                  Are you sure you want to delete{" "}
                                  {item.attributes.first_name}{" "}
                                  {item.attributes.last_name} account?
                                </Typography>
                                <Typography
                                  style={{
                                    color: "#0F172A",
                                    fontFamily: "Inter",
                                    fontSize: "1.125rem",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "1.625rem",
                                  }}
                                >
                                  This action can’t be undone and all the data
                                  stored in this account won’t be accessible
                                  anymore.
                                </Typography>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  padding: "1.5rem 1.5rem 1.5rem 0",

                                  alignItems: "center",
                                  justifyContent: "flex-end",

                                  gap: "1rem",
                                  flex: 1,

                                  borderTop: "1px solid #E2E8F0",
                                }}
                              >
                                <Button
                                  onClick={this.accountProfileStatusModalClose}
                                  data-test-id="cancelbtn"
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#F6F2EF",
                                    color: this.state.textColor,
                                    textTransform: "none",
                                    padding: "1rem",
                                    fontSize: "1rem",
                                    fontWeight: 700,
                                    width: "11rem",
                                    height: "3.5rem",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Keep Account
                                </Button>
                                <Button
                                  onClick={() =>
                                    this.deleteuserAccounts(item?.id)
                                  }
                                  data-test-id="accountDelete"
                                  variant="contained"
                                  style={{
                                    backgroundColor: this.state.btnColor,
                                    color: "#ffffff",
                                    textTransform: "none",

                                    padding: "1rem",
                                    fontSize: "1rem",
                                    fontWeight: 700,
                                    width: "11rem",
                                    height: "3.5rem",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Delete Account
                                </Button>
                              </div>
                            </Card>
                          </Modal>
                        </div>
                      </React.Fragment>
                    ))}
                  </TableBody>  
                ) : (
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: 700,
                      color: this.state.textColor,
                    }}
                  >
                    Record not found
                  </div>
                )}
              </Table>
            </div>
          </div>
        </div>
       
      </ThemeProvider>
      /* Customizable Area End */
    );
  }
}

const useStyles = {
  tableRow: {
    borderRadius: "0.375rem",
    border: "1px solid #CBD5E1",
    padding: "0.625rem;",
    height: "3.5rem",
  },

  button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
  },
  root: {
    width: "10.375rem",
    padding:"0px",
    backgroundColor: theme.palette.background.paper,
  },
  listItems:{
    padding:" 10px 4px 10px 16px",
    height:"45px",
    display:'flex',
    justifyContent: "space-between",
    fontFamily: "Inter",
    fontWeight: 400,
lineHeight: "22px",
fontSize:"14px"

  }
};
export default PeopleWeb;
