import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  openDialog:boolean;
  handleCancel:()=>void;
  handleOk:()=>void;
  subHeadingText?:string;
  txtPrivacyData?:string;
  headingText:string;
  btnCancelText:string;
  btnOkText:string;
  txtTermsData?:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  termsCondition:boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      termsCondition:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }
  handleCheckbox=()=>{
    this.setState({termsCondition:true})
  }
  TermsSchema = () => {
  const vaildate = {
      termCondition: Yup.boolean().oneOf([true],"Please accept the Terms and Conditions to continue")
          .required("Please accept the Terms and Conditions to continue."),
  };
  return vaildate
}
 
}
