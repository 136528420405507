import React from "react";
import {
  Container,
  IconButton,
  // Customizable Area Start
  TableCell,
  TableRow,
  Table,
  TableBody,
  Avatar,
  TableContainer,
  TableHead,
  Paper
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { MoreVert as MoreVertIcon  } from '@material-ui/icons';


import AvatarGroup from '@material-ui/lab/AvatarGroup';
import AudittrailController from "./AudittrailController";
import moment from 'moment';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

export default class Audittrail extends AudittrailController {
  //@ts-ignore
  constructor(props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    const projectId = localStorage.getItem('projectId');
    if (projectId) {
      this.showAuditTrail(projectId);
    }
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl" className="spaceWrap">
          <TableContainer component={Paper} className="adudit-table-wrapper">
            <Table aria-label="simple table" className="adudit-table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date & Time</TableCell>
                  <TableCell align="left">Team</TableCell>
                  <TableCell align="left">Changes made by</TableCell>
                  <TableCell align="left">Actions</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.auditTrailData?.length > 0 && this.state.auditTrailData.map((item: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {moment(item.attributes.audit_trail.date_time).format('h:mmA, MMM D YYYY')}
                    </TableCell>
                    <TableCell align="left">
                      <AvatarGroup max={4}>
                        <Avatar
                          alt={item.attributes.audit_trail.project_name.charAt(0)}
                          src="/static/images/avatar/1.jpg"
                        />
                        <Avatar
                          alt={item.attributes.audit_trail.project_name.charAt(0)}
                          src="/static/images/avatar/2.jpg"
                        />
                      </AvatarGroup>
                    </TableCell>
                    <TableCell align="left">{item.attributes.audit_trail.changes_done_by_name}</TableCell>
                    <TableCell align="left"><a href="#" className="addtasklink">Added subtask</a></TableCell>
                    <TableCell align="right">
                      <IconButton data-test-id="iconbutton">
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const useStyles = ({
});

// Customizable Area End
