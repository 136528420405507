import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, TextareaAutosize , Button, makeStyles, OutlinedInput, Grid, Typography, Switch, InputAdornment, IconButton, } from '@material-ui/core';
import Select from "react-select";
import { Close, AddCircleOutline} from '@material-ui/icons';
import { Formik,useField } from 'formik';
import * as Yup from "yup";


const useStyles = makeStyles({
  dialogWrapper: {
    "& [role='dialog']": {
      margin: '0',
      borderRadius: '8px',
      width: '624px',
      maxHeight: "calc(100% - 36px)",
    },
  },
  headingStyle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  btnWrapper: {
    justifyContent: 'flex-end',
    padding: "24px 24px 24px 285px",
    borderTop: "1px solid #E2E6F0",
    display: "inline-flex",
    alignItems: "flex-start",
    gap: "16px",

    '& button': {
      padding: '16px',
      width: '176px',
      fontSize:"16px",
      fontFamily:"Inter",
      fontWeight: 700,
      borderRadius: '8px',
    }
  },
  primaryBtnStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    "text-transform": "none",
    "color": "white"
  },
  cancelBtn: {
    color: '#E57727',
    background: "#F6F2EF",
    width:"105px !important",
    height:"56px",
    borderColor: '#F6F2EF',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    textTransform: "capitalize"
},
  textInputStyle: {
    margin: "0 !important",
    "& .MuiOutlinedInput-input": {
      textTransform: "uppercase",
    },
    "& .css-yk16xz-control": {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      height: "56px"
    },
    "& .css-1pahdxg-control": {
      height: "56px",
      color: "#000",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    "& .css-2613qy-menu": {
      height: "106px",
    },
    "&:hover .css-1pahdxg-control": {
      height: "56px",
      borderColor: "#2d6f8f"
    }
  },
  textInputStyle1: {
    margin: "0 !important",
    "& .MuiOutlinedInput-input": {
      textTransform: "uppercase",
    },
    "& .css-yk16xz-control": {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
      padding:"8px"
    },
    "& .css-1pahdxg-control": {
      // height: "56px",
      padding:"8px",
      color: "#000",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
    },
    "& .css-2613qy-menu": {
      height: "106px",
    },
    "&:hover .css-1pahdxg-control": {
      // height: "56px",
      borderColor: "#2d6f8f"
    }
  },
  labelStyle: { fontSize: "14px", fontWeight: 700, lineHeight: "22px", color: "#334155" },
  dialogTitle: {
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 16px 24px 40px",
  },
  confirmBtn: {
    borderRadius: '8px',
  },
  reasonsSection: {
    // marginBottom: '25px !important',
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "520px"
  },
  toggle: {
    width: '53px',
    height: '41px',
    "& .MuiSwitch-input": {
      width: "48px",
      height: "28px",
      left: "-41%"
    },
    "& .Mui-checked": {
      transform: "translateX(13px)",
      color: '#fff',
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#989898"
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      background: '#E57727',
      opacity: "1"
    },
    "&>span": {
      top: '4px !important',
      left: '4px !important',
      color: "#fff !important",
      right: "17px !important",
      "&>span": {
        "&>span": {
          width: '15px !important',
          height: '15px !important'
        }
      }
    }
  },
  multiline:{
  width: '520px', height:"98px",padding:"9px",resize: 'vertical', fontSize:"16px",fontWeight:400,borderRadius:"8px" 
  },
  downloaIcon: {
    cursor: "pointer"
  },
})

type InvoiceTemplateProps = {
  openDialog: boolean,
  headingText: string,
  btnCancelText?: string,
  btnOkText: string,
  handleOk: any,
  handleCancel: any,
  templateList: any,
  teamEmails:any;
  serviceList:any;
  invoiceBilling:boolean;
}




const InvoiceTemplateForm = (props: InvoiceTemplateProps) => {
  const classes = useStyles();
  const [clientCredits, setClientCredits] = useState(false);
  const [invoiceEmail, setInvoiceEmail] = useState(false);
  const [recuring, setRecurring] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleProductChange = (selectedOptions:any) => {
      setSelectedOptions(selectedOptions);
  };

let validationSchemaFields: { [key: string]: Yup.Schema<any> } = {
  account_data: Yup.string().min(1, "Field is too short")
    .max(50, "Field is too long")
    .required("Field is required."),
  payment_method: Yup.string()
    .required('Select the payment method')
    .oneOf(['card', 'manual'], 'Invalid method'),
    product_name: Yup.string()
    .required('Select the service'),
  description: Yup.string().min(1, "Description is too short")
    .max(50, "Description is too long")
    .required('Description is required'),
  message_for_client: Yup.string().min(1, "Message is too short")
    .max(50, "Message is too long")
    .required("Message is required."),
};

if (props.invoiceBilling) {
  validationSchemaFields.date = Yup.date().required("Date is required.");
}

const validationSchema = Yup.object().shape(validationSchemaFields);

  const handleRecurring=()=>{
    setRecurring(!recuring)
  }

  const payment_methods=[
  {
    value: null,
    label: "Select payment type",
    labelValue: ""
  },
  {
    value: 1,
    label: "Credit card",
    labelValue: "card"
  },
  {
    value: 2,
    label: "Debit card",
    labelValue: "card"
  },
  {
    value: 3,
    label: "Manual",
    labelValue: "manual"
  },
]
const containerStyles = {
  position: 'relative' as const,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  width: '292px',
  padding: "4px",
  border: '3px solid #F8FAFC',
  borderRadius: '20px',
  background: '#F8FAFC',
  fontWeight: 700,
  color: '#white',
  cursor: 'pointer',
justifyContent: "center",
alignItems: "center",
flexShrink: 0,
};

const beforeStyles = {
  content: '',
  position: 'absolute' as const,
  width: "144px",
  height: "40px",
  justifyContent: "center",
  alignItems: "center",
  left: recuring ? '50%' : '0%',
  borderRadius: '20px',
  background: '#FFF',
  transition: 'all 0.3s',
};

const textStyles = {
  width: "72px",
  padding: "8px 37px 8px 33px",
  height: "24px",
  textAlign: 'center' as const,
  zIndex: 1,
  color: recuring ? '#64748B' : '#E57752',
  transition: 'color 0.3s',
};
const textAccountStyles = {
  width: "67px",
  padding: "8px 38px 8px 39px",
  height: "24px",
  textAlign: 'center' as const,
  zIndex: 1,
  color: recuring ? '#E57752' : '#64748B',
  transition: 'color 0.3s',
};
console.log("productList",selectedOptions);

  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
          <div className={classes.dialogTitle}>
            <div>
              <span className={classes.headingStyle}>{props?.headingText}</span>
            </div>
            <Close
              className={classes.downloaIcon}
              // onClick={props?.handleCancel}
              onClick={() => {
                props?.handleCancel()
              }}
            />
          </div>
        </DialogTitle>
        <Formik
          initialValues={{ account_data: "", invoiceTemplate: "", payment_method: "", invoiceNumber: "", recurring:recuring,
          teamMember: "", message_for_client: "", description: "",date:"", product_name: "",clientCredits:clientCredits,invoiceEmail:false }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnMount={true}
          validateOnBlur={true}
          onSubmit={(values, actions) => {
            props.handleOk(values);
            actions.setSubmitting(false)
            setSelectedOptions([]);
            setInvoiceEmail(false)
            actions.resetForm();
          }}
        >
          {({
            handleSubmit,
            touched,
            handleBlur,
            errors,
            values,
            handleChange,
            setFieldValue
          }) => (
            <form style={{ margin: "0" }} onSubmit={handleSubmit}>

              <DialogContent style={{ display: "grid", padding: "40px", gap: "16px" }}>
              <Grid >
    <input
        type="checkbox"
        id="toggle"
        name="recurring"
        style={{ display: 'none' }}
        onChange={()=>{setFieldValue("recurring", recuring)}}
        checked={recuring}
      />
      <label htmlFor="toggle" style={containerStyles}>
        <div style={beforeStyles}></div>
        <div style={textStyles}>One-Time</div>
        <div style={textAccountStyles}>Recurring</div>
      </label>
          </Grid>

                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Account name, id or Email</label>
                  <OutlinedInput

                    fullWidth
                    name="account_data"
                    id="account_data"
                    value={values.account_data}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Type here"
                    error={touched.account_data && Boolean(errors.account_data)}
                  />
                  {touched.account_data && errors.account_data && (
                    <Typography color="error" >{errors.account_data}</Typography>
                  )}

                </Grid>

                <Grid className={classes.reasonsSection} item xs={12}>
                  <label className={classes.labelStyle}>Invoice template</label>
                  <Select
                    name="invoiceTemplate"
                    data-test-id="invoiceTemplate"
                    className={classes.textInputStyle}
                    options={props.templateList}
                    value={props.templateList.find((option: any) => option.value === Number(values.invoiceTemplate))}
                    onChange={(option: any) =>
                      setFieldValue('invoiceTemplate', option?.labelValue)
                    }
                    placeholder="Select"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontWeight: state.data.value === null ? 400 : 700,
                        color: state.data.value === null ? "#64748B" : provided.color,
                      }),
                    }}
                  />
                  {touched.invoiceTemplate && errors.invoiceTemplate && (
                    <Typography color="error" >{errors.invoiceTemplate}</Typography>
                  )}
                </Grid>
                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Invoice number</label>
                  <OutlinedInput

                    fullWidth
                    name="invoiceNumber"
                    id="invoiceNumber"
                    value={values.invoiceNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Type here"
                  />
                  {touched.invoiceNumber && errors.invoiceNumber && (
                    <Typography color="error" >{errors.invoiceNumber}</Typography>
                  )}


                </Grid>
                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Payment method</label>
                  <Select
                    name="payment_method"
                    data-test-id="payment_method"
                    className={classes.textInputStyle}
                    options={payment_methods}
                    value={payment_methods.find((option: any) => option.value === Number(values.payment_method))}
                    onChange={(option: any) =>
                      setFieldValue('payment_method', option?.labelValue)
                    }
                    placeholder="Select payment"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontWeight: state.data.value === null ? 400 : 700,
                        color: state.data.value === null ? "#64748B" : provided.color,
                      }),
                    }}
                    error={touched.payment_method && Boolean(errors.payment_method)}
                  />
                  {touched.payment_method && errors.payment_method && (
                    <Typography color="error" >{errors.payment_method}</Typography>
                  )}

                </Grid>
                {props.invoiceBilling &&
                                <Grid className={classes.reasonsSection} item xs={12}>

                                <label className={classes.labelStyle}>Date</label>
                                <TextFieldControl
              data-test-id={`date`}
              id={`date`}
              className={classes.textInputStyle}
              name={`date`}
              type={"date"}
              onBlur={handleBlur}
              placeholder="Start Date"
              style={{textTransform:"uppercase"}}
              onChange={handleChange}
              inputProps={{
                min: new Date().toISOString().split('T')[0],
              }}
          />
                                {touched.date && errors.date && (
                                  <Typography color="error" >{errors.date}</Typography>
                                )}
              
                              </Grid>}
                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Team member</label>
                  <Select
                    name="teamMember"
                    data-test-id="teamMember"
                    className={classes.textInputStyle}
                    options={props.teamEmails}
                    value={props.teamEmails.find((option: any) => option.value === Number(values.teamMember))}
                    onChange={(option: any) =>
                      setFieldValue('teamMember', option?.labelValue)
                    }
                    placeholder="Select member"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontWeight: state.data.value === null ? 400 : 700,
                        color: state.data.value === null ? "#64748B" : provided.color,
                      }),
                    }}
                  />
                  {touched.teamMember && errors.teamMember && (
                    <Typography color="error" >{errors.teamMember}</Typography>
                  )}
                </Grid>
                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Description</label>
                  <TextareaAutosize
                    maxRows={5}
                    className={classes.multiline}
                    style={{border: touched.description && errors.description ? '1px solid red' : "1px solid #CBD5E1",height:"98px",minHeight:"50px"}} 
                    name="description"
                    id="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    placeholder="Type here"
                  />
                   {touched.description && errors.description && (
                    <Typography color="error">{errors.description}</Typography>
                  )}
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{
                      color: "#E57727",
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "22px",
                    }}><AddCircleOutline style={{ marginRight: "8px", width: "20px", height: "20px" }} />Add shortcode</Typography>

                    <Typography style={{
                      color: "#334155",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "18px",
                    }}>{`${values.description.length} /5000`}</Typography>
                  </div>
                </Grid>
                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Select service</label>
                  <Select
                    name="product_name"
                    data-test-id="product_name"
                    className={classes.textInputStyle1}
                    options={props.serviceList}
                    isMulti
                    value={selectedOptions}
                    onChange={(options: any) =>{
                      setSelectedOptions(options);
                      const selectedValues = options.map((option:any) => option.labelValue);
                      setFieldValue('product_name', selectedValues);
                      handleProductChange(options);

                    }}
                    
                  />
                  {touched.product_name && errors.product_name && (
                    <Typography color="error" >{errors.product_name}</Typography>
                  )}

                </Grid>
                <Grid xs={12} style={{ display: "flex", alignItems: "center" }}>

                  <Switch
                    className={classes.toggle}
                    name="clientCredits"
                    disabled
                    checked={clientCredits}
                    onChange={()=>{ setClientCredits((prevClientCredits) => {
                      setFieldValue("clientCredits", !prevClientCredits);
                      return !prevClientCredits;
                    });}}
                    color="primary"
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography style={{
                    color: "#94A3B8",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}>Pay invoice using clients credits</Typography>
                </Grid>
                <Grid xs={12} style={{ display: "flex", alignItems: "center" }}>
                  <Switch
                    name="invoiceEmail"
                    className={classes.toggle}
                    checked={invoiceEmail}
                    onChange={()=>{setInvoiceEmail((prevInvoiceEmail) => {
                      setFieldValue("invoiceEmail", !prevInvoiceEmail);
                      return !prevInvoiceEmail;
                    });}}
                    color="primary"
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography style={{
                    color: "#0F172A",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}>Email invoice to client</Typography>
                </Grid>
                <Grid className={classes.reasonsSection} item xs={12}>

                  <label className={classes.labelStyle}>Message for client</label>
                  <TextareaAutosize
                    maxRows={5}
                    className={classes.multiline}
                    style={{border: touched.message_for_client && errors.message_for_client ? '1px solid red' : "1px solid #CBD5E1",height:"98px",minHeight:"50px"}} 
                    name="message_for_client"
                    id="message_for_client"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.message_for_client}
                    placeholder="Type here"
                  />
                  {touched.message_for_client && errors.message_for_client && (
                    <Typography color="error">{errors.message_for_client}</Typography>
                  )}
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{
                      color: "#E57727",
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "22px",
                    }}><AddCircleOutline style={{ marginRight: "8px", width: "20px", height: "20px" }} />Add shortcode</Typography>

                    <Typography style={{
                      color: "#334155",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "18px",
                    }}>{`${values.message_for_client.length} /5000`}</Typography>
                  </div>
                </Grid>
              </DialogContent>

              <Grid className={classes.btnWrapper}>
              <Button variant="outlined" className={classes.cancelBtn}
                        onClick={() => {
                            props.handleCancel()
                        }}
                    >
                        {props.btnCancelText}
                    </Button>
                <Button variant="contained" className={classes.primaryBtnStyle} type='submit'>
                  {props.btnOkText}
                </Button>
              </Grid>
            </form>
          )}
        </Formik>

      </Dialog>
    </div>
  )
}
const TextFieldControl = (props: any) => {
  const [field, meta] = useField(props);

  return (
      <OutlinedInput
          fullWidth
          type={props.type}
          {...field}
          {...props}
          error={meta.error && meta.touched}
      />
  );
};

export default InvoiceTemplateForm

