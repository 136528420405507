// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Chip,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Delete, MoreVert } from "@material-ui/icons";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


import OcrListingController, {
  Props
} from "./OcrListingController.web";
import { imgDoc, imgSuccess } from "./assets";
import { Alert } from "@material-ui/lab";


export default class OcrListing extends OcrListingController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    
    return (
      <ThemeProvider theme={theme}>
        <Container style={{padding:"25px 0px 0px 35px",margin:"0px",display:"flex",flexDirection:"column",gap:"20px"}}>
         <Box sx={webStyle.topWraper1}>
            <Chip
              data-test-id="allFiles"
              onClick={()=>this.handleFilterClick("allFiles")}
              label="All files"
              variant="outlined"
              style={{
                backgroundColor: this.state.allFiles
                  ? this.state.primaryColor
                  : "#F6F2EF",
                color: this.state.allFiles ? "#ffffff" : "",
                fontSize: "1rem",
                fontWeight: 700,
                lineHeight: "1.5rem", display: "flex",
                width: "13.25rem", height: "3rem",
                padding: "10px 16px",
                justifyContent: "center", alignItems: "center", gap: "0.5rem",
                borderRadius: "3.125rem", border: "none", cursor: "pointer"
              }}
            />
            <Chip
              data-test-id="xml"
              onClick={()=>this.handleFilterClick("xml")}
              label="XML"
              variant="outlined"
              style={{
                backgroundColor: this.state.Xml
                  ? this.state.primaryColor
                  : "#F6F2EF",
                color: this.state.Xml ? "#ffffff" : "",
                fontSize: "1rem",
                fontWeight: 700, lineHeight: "1.5rem",
                display: "flex", width: "13.75rem",
                height: "3rem", padding: "0.625rem 1rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.5rem", borderRadius: "3.125rem",
                border: "none", cursor: "pointer"
              }}
            />
            <Chip
              data-test-id="csv"
              onClick={()=>this.handleFilterClick("csv")}
              label="CSV"
              variant="outlined"
              style={{
                backgroundColor: this.state.Csv
                  ? this.state.primaryColor
                  : "#F6F2EF",
                color: this.state.Csv ? "#ffffff" : "",
                fontSize: "1rem",
                fontWeight: 700, lineHeight: "1.5rem",
                display: "flex", width: "13.75rem",
                height: "3rem", padding: "0.625rem 1rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.5rem", borderRadius: "3.125rem",
                border: "none", cursor: "pointer"
              }}
            />


          </Box>
        <Box sx={webStyle.secondTopWrapper} >
            <Box style={{ display: "flex", flexDirection: "column" }}>
            {this.state.ocrList?.length > 0 ? (this.state.ocrList?.map((item: any, index: any) => (
                <Box sx={webStyle.rowField} key={item.id}  >
                  <Box sx={webStyle.rowFieldLeft} data-test-id={`row-${index}`}>

                    <img src={imgDoc}/>
                    <Typography style={webStyle.folderName}>{item.attributes.ocr_file.filename}</Typography>
                  </Box>
                  <Box>
                    <MoreVert style={{ marginLeft: "20px", cursor: 'pointer' }} onClick={() => this.handleShowModal(item)} data-test-id={`dialogueshow-${index}`} />
                  </Box>

                  {this.state.showModal && this.state.selectedFile == item ?
                    <Box sx={webStyle.modalEditDel}>
                      <Button style={webStyle.delebtn} data-test-id={`deleteOcr-${index}`} onClick={() => this.deleteOCR(item.id)}><Delete style={{ width: '18px', height: "18px" }} /><Typography style={webStyle.editText} >Delete folder</Typography></Button>
                    </Box> : null}
                </Box>))):(<Typography>No scanned documents</Typography>)}
          

            </Box>
            </Box>

            {this.state.showAlert &&
              <Alert style={{ ...webStyle.alert, position: "absolute" }} icon={false} severity="success">
                <div style={webStyle.message} >
                  <img src={imgSuccess} style={{ verticalAlign: "bottom" }} />
                  <Typography variant="body1">{this.state.alertMessage}</Typography>
                </div>
              </Alert>}
        </Container>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  rowField: {
    "display": "flex",
    "width": "calc(-269px + 100vw)",
    "padding": "20px 20px",

    "alignItems": "center",
    "gap": "10px",
    "borderRadius": "6px 6px 0px 0px",
    "border": "0.5px solid  #CBD5E1",
    height: "36px",
    justifyContent: 'space-between',
    position: "relative"
  },
  rowFieldLeft: {
    display: "flex",
    alignItems: "center",
    gap: '30px',

  },
  ocrText:{
    "color": "#0F172A",
   
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": 400,
    "lineHeight": "22px",
    width:"100%"
 
  },
  secondTopWrapper: {
    "display": "flex",
    "width": "calc(100vw - 217px)",
    "height": "765px",
    // "padding": "30px 25px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "10px", overflowY: "auto",
    "flexShrink": "0",
    "borderRadius": "8px",
    // "border": "1px solid  #CBD5E1",
    "background": "#FFF",
    cursor: 'pointer',
   

  },
  message: { display: "flex", gap: "8px" },
  delebtn: {
    "display": "flex",
    "width": "184px",
    "height": "36px",
    // "padding": "7px 56px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "borderRadius": "8px",
    "background": "#FFF"
  },
  folderName: {
    "color": "#000",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": 600,
    "lineHeight": "normal"
  },
  topWraper1: {
    "display": "flex",
    "width": "calc(100vw - 279px)",
    "padding": "16px 24px 16px 24px",
    "alignItems": "center",

    "borderRadius": "8px",
    "border": "0.5px solid  #94A3B8",
    "background": "#FFF",
    height: "48px",
    gap: "1rem",

  },
  alert: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    marginTop:"10%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  modalEditDel: {
    "display": "flex",
    "width": "194px",
    "padding": "5px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "5px",
    "borderRadius": "8px",
    "background": "#FFF",
    "boxShadow": "4px 8px 32px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08)",
    position: "absolute",
    top: '12px',
    right: "20px",
    zIndex: 1
  },
  editText: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight":400,
    "lineHeight": "22px",
    textTransform: "none" as const
  },
};
// Customizable Area End
