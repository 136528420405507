import React from "react";
// Customizable Area Start
import {
  Box,

  Button,
  Container,
  Typography,
  TextField,
  InputAdornment,
  Dialog,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Close, ControlPoint, Delete, Edit, FolderOpen, MoreVert, Search, Sort } from "@material-ui/icons";
import { pdfDoc } from "./assets";

// Customizable Area End

import UserLibraryController, { Props } from "./UserLibraryController.web";



const theme = createTheme({
  palette: {
    primary: {
      main: "#396178",
      contrastText: "#fff",
    },
  },
});

export default class UserLibrary extends UserLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDilog = () => {
    const { createFolder, selectedFolder, folders } = this.state;
    return (
      <Dialog className="dialog"
      PaperProps={{
        style: {
          minWidth: 200, 
        },
      }}

        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
           

          boxShadow:
            " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        }}
        data-test-id="editmodal"
        open={this.state.showCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          style={{
            maxWidth: "37.5rem",

            borderRadius: "0.5rem",


          }}
        
        >


          <div
            style={{
              display: "flex",
              height: "2rem", padding: "1.5rem 1rem 1.5rem 2.5rem",
              justifyContent: "space-between",
              alignItems: "center", alignSelf: "stretch",

            }}
          >
            <p
              style={{
                fontSize: "20px",
                fontWeight: 600, color: "#000000", fontFamily: "inter"
              }}
            >
              {this.getCreateFolderText()}

            </p>
            <Close style={webStyle.closeMOdalCross} onClick={this.handleHideFolderModal} data-test-id="closefolder" />

          </div>
          <Divider />
          <div className="diveFormcontrol"
            style={{
              display: "flex",
              padding: "2.5rem 2.5rem",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "1rem", alignSelf: "stretch",
              width: "32.5rem", height: this.calculateContainerHeight(),
            }}
          >
            {createFolder ?
              <FormControl
                style={{
                  marginBottom: "0.94rem",
                  display: "flex", width: "32.5rem",flexDirection:"column",
                  height: "5.13rem", 
                  justifyContent: "center",
                  alignItems: "flex-start",

                }}
                className="textFieldWidth"
              >
                <FormLabel style={{
                  paddingBottom: "10px", fontWeight: 700,
                  color: "#64748B", fontSize: '14px',
                  lineHeight: '22px'
                }}

                >
                  Folder name
                </FormLabel>
                <TextField type="text" variant="outlined"
                  style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
                  name="name"
                  color="primary"
                  onChange={this.handleFolderName}
                  value={this.state.folderName}
                  InputProps={{

                    style: {
                      color: "#64748B", height: '56px',
                      padding: '10px 8px'
                    },
                    placeholder: "Untitled folder"
                  }}
                  fullWidth
                />
                {this.state.folderNameErr && (
                  <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                    {this.state.errorMessage}
                  </Typography>
                )}

              </FormControl> : <Box>
                <FormControl style={{ width: "100%", marginBottom: "20px" }}   className="textFieldWidth">
                  <FormLabel id="template-type1" style={{
                    fontWeight: 700,
                    color: "#64748B", fontSize: '14px',
                    lineHeight: '22px'
                  }}

                  >
                    Select folder
                  </FormLabel>

                  <Select
                    id="template-type2"
                    variant="outlined"
                    name="template"
                    displayEmpty
                    placeholder="Select Option"
                    value={selectedFolder}
                    style={{ backgroundColor: "#ffffff" }}
                    MenuProps={{
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                      transformOrigin: { vertical: 'top', horizontal: 'left' },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: '25%',
                          top: '360px',
                        },
                      },
                    }}
                    onChange={this.handleSelectFolder}
                  >
                    <MenuItem value="" disabled  >
                      <span style={{ opacity: 0.5, color: "#64748B" }}>Select Folder</span>
                    </MenuItem>
                    {folders?.map((folder: any) => <MenuItem value={`${folder.data.id}`} key={folder.data.id}><Typography style={webStyle.selectOPtion}>{folder.data.attributes.name}</Typography></MenuItem>)}

                  </Select>
                  {this.state.selectedFolderErr && (
                    <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                      Selected folder is required
                    </Typography>
                  )}

                </FormControl>
                <FormControl
                  className="textFieldWidth"
                  style={{
                    marginBottom: "0.94rem",
                    display: "flex", width: "32.5rem",
                    height: "5.13rem", flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",

                  }}
                >
                  <FormLabel style={{
                    fontWeight: 700,
                    color: "#64748B", fontSize: '14px',
                    lineHeight: '22px'
                  }}

                  >
                    Sub folder name
                  </FormLabel>
                  <TextField type="text" variant="outlined"
                    style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
                    name="subFolderName"
                    color="primary"
                    onChange={this.handleSubFolderName}
                    value={this.state.subFolderName}
                    InputProps={{

                      style: {
                        color: "#64748B", height: '56px',
                        padding: '10px 8px'
                      },
                      placeholder: "Untitled folder"
                    }}
                    fullWidth

                  />
                  {this.state.subFolderNameErr && (
                    <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'inter' }}>
                      Subfolder name is required
                    </Typography>
                  )}
                </FormControl>
              </Box>}
          </div>
          <Divider />
          <div
            style={{
              display: "flex",

              padding: "1.5rem 1.5rem 1.5rem 20rem",
              justifyContent: "flex-end",
              alignItems: "center", alignSelf: "stretch",
            }}
          >
            <Button style={webStyle.cancelbtn} onClick={this.handleHideFolderModal}><Typography
              style={{ ...webStyle.cancelbtnText, color: this.state.secondaryColor }}>Cancel</Typography></Button>
            <Button

              data-test-id="ok" variant="contained" style={{
                display: "flex", width: "7.5rem", height: "3.5rem",
                padding: "1rem 0",
                borderRadius: "0.5rem", backgroundColor: this.state.primaryColor,
                color: "#FFF", fontWeight: 700,
                fontSize: "1rem",
              }} onClick={this.handleFieldError}
            >
              <Typography style={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Inter', textTransform: 'none' }}>Ok</Typography>
            </Button>
          </div>

        </Card>
      </Dialog>
    )
  }

  renderChip=()=>{
    return(
      <Box sx={webStyle.topWraper1}>
      {this.state.userType == "client" ? null :
        <div style={webStyle.chipWrapper}>
          <Chip
            data-test-id="client"
            onClick={this.handleClientClick}
            className="chipText"
            label="Client"
            variant="outlined"
            style={{
              backgroundColor: this.getBackgroundColor(),

              color: this.getTextColor(),
              fontSize: "1rem",
              fontWeight: 700,
              lineHeight: "1.5rem", display: "flex",
              width: "11.25rem", height: "3rem",
              padding: "10px 16px",
              justifyContent: "center", alignItems: "center", gap: "0.5rem",
              borderRadius: "3.125rem", border: "none", cursor: "pointer"
            }}
          />
          <Chip
            data-test-id="finAdvisor"
            className="chipText"
            onClick={this.handleFinAdvisorClick}
            label="Financial Advisor"
            variant="outlined"
            style={{
              backgroundColor: this.getFinAdvBackgroundColor(),

              color: this.getFinAdvTextColor(),
              fontSize: "1rem",
              fontWeight: 700, lineHeight: "1.5rem",
              display: "flex", width: "13.75rem",
              height: "3rem", padding: "0.625rem 1rem",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem", borderRadius: "3.125rem",
              border: "none", cursor: "pointer"
            }}
          />
          {this.state.userType !=="vincertax_employee" ?null:
           <Chip
            data-test-id="VTemployee"
            className="chipText"
            onClick={this.handleVtEmpClick}
            label="Team Member"
            variant="outlined"
            style={{
              backgroundColor: this.getVtEmpBackgroundColor(),

              color: this.getVtEmpTextColor(),
              fontSize: "1rem",
              fontWeight: 700, lineHeight: "1.5rem",
              display: "flex", width: "13.75rem",
              height: "3rem", padding: "0.625rem 1rem",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem", borderRadius: "3.125rem",
              border: "none", cursor: "pointer"
            }}
          />}
        </div>}
     

      <Button
        data-test-id="OcrScan"
        className="chipText"
        onClick={this.navigateToOcrRecognation}
        style={{
          "display": "flex", "padding": "10px 12px 10px 10px",
          "alignItems": "center", "gap": "8px", "alignSelf": "stretch", borderRadius: "8px",
          fontSize: "16px", fontWeight: 700, textTransform: "none",
          width: "117px", height: "44px", color: '#E57727', background: "#F6F2EF"
        }
        }
        aria-describedby="data"

      >
        OCR scan

      </Button>



    </Box>
    )
    
  }
  // Customizable Area End

  render() {
    const {folders,showFilterOptions, showREnameBox, renameFolderName } = this.state


    return (
      /* Customizable Area Start */
      <ThemeProvider theme={theme}>
        <Container style={{ display: "flex", justifyContent: "center", padding: "21px 30px", flexDirection: "column", gap: "21px", alignItems: "center" }}>

          {this.renderChip()}
          <Box sx={webStyle.topWraper}>

            <Button style={webStyle.buttonStyle} onClick={this.handleUploadDoc}> <ControlPoint className="icon" style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} /><Typography className="uploadbtnText" style={webStyle.btnText}>Upload documents</Typography></Button>
            <Button style={webStyle.buttonStyle} onClick={this.handleShowCreateFolder} data-test-id="createFolder"> <FolderOpen className="icon" style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} /> <Typography className="uploadbtnText" style={webStyle.btnText}>Create folder</Typography></Button>
            <Button style={webStyle.buttonStyle} onClick={this.handleShowCreateSubFolder} data-test-id="createSubFolder"> <FolderOpen className="icon" style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} /> <Typography className="uploadbtnText" style={webStyle.btnText}>Create sub folder</Typography></Button>
            <Button style={webStyle.buttonStyle} onClick={this.navigateToScannedOcr} data-test-id="createSubFolder"> <FolderOpen className="icon" style={{ color: "#396178", width: "1.5rem", height: '1.5rem' }} /> <Typography className="uploadbtnText" style={webStyle.btnText}>Scanned documents</Typography></Button>

            <TextField
              type="search"
              id="searchPdfFolder"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                style: { borderRadius: "1.875rem", height: "44px", color: '#64748B', },
              }}
              placeholder="Search"
              variant="outlined"
              style={{
                width: "36.3%",

                borderRadius: "98px",
                marginLeft: '8px'
              }}

              value={this.state.searchFolder}
              onChange={(searchedRows) => this.SerachRequestPdfDoc(searchedRows)}
            />
            <Button
              data-test-id="sortbtn"
              className="sortbtn"
              onClick={this.handleShowFilterOptions}
              style={webStyle.sortbtn
              }
              aria-describedby="data"

            >
              <Sort className="icon" style={{ width: "1.5rem", height: "1.5rem", marginRight: "8px" }} />

              Sort

            </Button>
            {showFilterOptions ?
              <Box sx={webStyle.filterBox} >
                <Button style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('name')}
                  startIcon={this.getStartIcon('name')} data-test-id="sortname"
                ><Typography className="siortbtntext" style={webStyle.filterText}>Name</Typography></Button>
                <Button data-test-id="sortkind" style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('kind')} startIcon={this.getStartIcon('kind')}><Typography  className="siortbtntext" style={webStyle.filterText}>Kind</Typography></Button>
                <Button data-test-id="sortupdate" style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('updated_at')} startIcon={this.getStartIcon('updated_at')}><Typography  className="siortbtntext" style={webStyle.filterText}>
                  Date modified</Typography></Button>
                <Button data-test-id="sortcreate" style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('created_at')} startIcon={this.getStartIcon('created_at')}><Typography  className="siortbtntext" style={webStyle.filterText}>Date created</Typography></Button>
                <Button data-test-id="sortsize" style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('size')} startIcon={this.getStartIcon('size')}><Typography  className="siortbtntext" style={webStyle.filterText}>Size</Typography></Button>

              </Box> : null}

          </Box>

          {this.renderDilog()}

          <Box sx={webStyle.secondTopWrapper} >
            <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              {folders?.map((item1: any, index: any) => (
                <Box sx={webStyle.rowField} key={item1.data.id}  >
                  <Box sx={webStyle.rowFieldLeft} onClick={() => this.handleNavigate(item1?.data.attributes?.name, item1?.data.id)} data-test-id={`row-${index}`}>

                    <FolderOpen style={{ color: "#D97706" }} className="pdfimg" />
                    <Typography className="pdfDocNameLibray" style={webStyle.folderName}>{item1.data.attributes.name}</Typography>
                  </Box>
                  <Box>

                    <MoreVert className="morevertbtn" style={{ marginLeft: "20px", cursor: 'pointer' }} onClick={() => this.handleShowDialog(item1)} data-test-id={`dialogueshow-${index}`} />
                  </Box>

                  {this.state.showDialog && this.state.selectedDoc == item1.data ?
                    <Box sx={webStyle.modalEditDel}>
                      <Button data-test-id={`dialoguesRenameOPen-${index}`} className="renamedelBtn"
                        style={webStyle.renameFolder} onClick={this.handleopenRenameBox}><Edit style={{ width: '18px', height: "18px" }} /><Typography style={webStyle.editText}className="siortbtntext">Rename folder</Typography></Button>
                      <Button className="renamedelBtn" style={webStyle.delebtn} data-test-id={`deleteFolder-${index}`}
                        onClick={() => this.DeleteFolder(item1.data.id)}><Delete style={{ width: '18px', height: "18px" }} /><Typography className="siortbtntext" style={webStyle.editText} >Delete folder</Typography></Button>
                      {showREnameBox ?
                        <Dialog className="dialog"
                        PaperProps={{
                          style: {
                            minWidth: 200, 
                          },
                        }}
                          style={{
                            display: "flex", justifyContent: "center", alignItems: "center",
                            boxShadow:
                              " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
                          }}
                          data-test-id="renameModal"
                          open={showREnameBox}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Card
                            style={{
                              maxWidth: "37.5rem", borderRadius: "0.5rem",
                              overflowY: "auto"
                            }}
                          >

                            <div
                              style={{
                                display: "flex",
                                height: "2rem", padding: "1.5rem 1rem 1.5rem 2.5rem",
                                justifyContent: "space-between", alignItems: "center", alignSelf: "stretch",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "1.5rem",
                                  fontWeight: 700, color: "#000000",
                                }}
                              >
                                Rename folder

                              </p>
                              <Close style={webStyle.closeMOdalCross} onClick={this.handleCloseREnameBox}
                                data-test-id="closefolderrename" />

                            </div>
                            <Divider />
                            <div className="diveFormcontrol"
                              style={{
                                display: "flex",
                                padding: "2.5rem 2.5rem",
                                flexDirection: "column", alignItems: "flex-start",
                                gap: "1rem",
                                alignSelf: "stretch", width: "32.5rem",
                              }}
                            >
                              <Box>

                                <FormControl
                                   className="textFieldWidth"
                                  style={{

                                    display: "flex", width: "32.5rem", height: "5.13rem",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <FormLabel style={{
                                    fontWeight: 700,
                                    color: "#64748B", fontSize: '14px',
                                    lineHeight: '22px'
                                  }}

                                  >
                                    Rename
                                  </FormLabel>
                                  <TextField type="text" variant="outlined"    className="textFieldWidth"
                                    style={{
                                      "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF"
                                    }}
                                    name="subFolderName"
                                    color="primary"
                                    data-test-id="renameFolder"
                                    onChange={this.handleRenameFolder} value={renameFolderName}
                                    InputProps={{

                                      style: {
                                        color: "#64748B", height: '56px', padding: '10px 8px'
                                      },
                                      placeholder: `${item1.data.attributes.name}`
                                    }}
                                    fullWidth
                                  />
                                </FormControl>
                              </Box>
                            </div>
                            <Divider />
                            <div
                              style={{
                                display: "flex", padding: "1.5rem 1.5rem 1.5rem 20rem",
                                justifyContent: "flex-end",
                                alignItems: "center", alignSelf: "stretch",
                              }}
                            >
                              <Button style={webStyle.cancelbtn} onClick={this.handleCancelRename}><Typography
                                style={webStyle.cancelbtnText}>Cancel</Typography></Button>
                              <Button

                                data-test-id="okFolderrename" variant="contained" style={{
                                  display: "flex", width: "7.5rem", height: "3.5rem", padding: "1rem 0", borderRadius: "0.5rem", backgroundColor: "#E57727",
                                  color: "#FFF", fontWeight: 700, fontSize: "1rem",
                                }} onClick={() => this.RenameFolder(item1.data.id)}
                              >
                                <Typography style={{
                                  fontSize: '16px', fontWeight: 700, fontFamily: 'Inter', textTransform: 'none'
                                }}>Save</Typography>
                              </Button>
                            </div>

                          </Card>
                        </Dialog> : null}
                    </Box> : null}
                </Box>))}

            </Box>
            <Box style={{ display: "flex", flexDirection: "column", gap: "8px", paddingBottom: "30px" }}>
              {this.state.documentspdf?.map((item1: any, index: any) => (
                <Box sx={webStyle.rowField} key={item1.file_id}  >
                  <Box className="rowFieldLeft" sx={webStyle.rowFieldLeft} data-test-id={`rowpdfdoc-${index}`} onClick={() => this.handleNavigatePdfEdit(item1.attachment_id, item1.file_id)}>

                    <img className="pdfimg" src={pdfDoc} style={{ width: '1.5rem', height: "1.5rem" }} alt="PdfDoc" />
                    <Typography className="pdfDocNameLibray" style={webStyle.folderName}>{item1?.file_name}</Typography>
                  </Box>
                  <Box>

                    <MoreVert className="morevertbtn" style={{ marginLeft: "20px", cursor: 'pointer' }} data-test-id={`dialogueshowPdfDoc-${index}`} onClick={() => this.handleShowDialogPdfDoc(item1)} />
                  </Box>

                  {this.state.showDialog && this.state.selectedDoc == item1.file_id ?

                    <Box sx={webStyle.modalEditDel}>
                      <Button className="renamedelBtn" data-test-id={`dialoguesRenameOPen-${index}`} style={webStyle.renameFolder} onClick={this.handleopenRenameBox}><Edit className="icon" style={{ width: '18px', height: "18px" }} /><Typography className="siortbtntext" style={webStyle.editText}>Rename doc</Typography></Button>
                      <Button className="renamedelBtn" style={webStyle.delebtn} data-test-id={`deleteFolder-${index}`} onClick={() => this.DeletePdfDoc(item1.file_id, item1.attachment_id)}><Delete className="icon" style={{ width: '18px', height: "18px" }} /><Typography style={webStyle.editText}className="siortbtntext" >Delete doc</Typography></Button>
                      {showREnameBox ?
                        <Dialog
                          style={{
                            display: "flex", justifyContent: "center", alignItems: "center",
                            boxShadow:
                              " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
                          }}
                          data-test-id="renameModal" open={showREnameBox}
                          className="dialog"
                          aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
                          PaperProps={{
                            style: {
                              minWidth: 200, 
                            },
                          }}
                        >
                          <Card
                            style={{
                              maxWidth: "37.5rem", borderRadius: "0.5rem",
                              overflowY: "auto"
                            }}
                           
                          >

                            <div
                              style={{
                                display: "flex", height: "2rem", padding: "1.5rem 1rem 1.5rem 2.5rem",
                                justifyContent: "space-between", alignItems: "center",
                                alignSelf: "stretch",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "1.5rem",
                                  fontWeight: 700, color: "#000000",
                                }}
                              >
                                Rename document

                              </p>
                              <Close style={webStyle.closeMOdalCross}
                                onClick={this.handleCloseREnameBox} data-test-id="closefolderrename" />

                            </div>
                            <Divider />
                            <div
                              style={{
                                display: "flex",
                                padding: "2.5rem 2.5rem",
                                flexDirection: "column", alignItems: "flex-start", gap: "1rem",
                                alignSelf: "stretch",
                                width: "32.5rem",
                              }}
                            >
                              <Box>

                                <FormControl
                                  style={{

                                    display: "flex", width: "32.5rem",
                                    height: "5.13rem", flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                  }}
                                  className="textFieldWidth"
                                >
                                  <FormLabel style={{
                                    fontWeight: 700,
                                    color: "#64748B", fontSize: '14px', lineHeight: '22px'
                                  }}

                                  >
                                    Rename
                                  </FormLabel>
                                  <TextField type="text" variant="outlined"
                                    style={{
                                      "borderRadius": "8px", "border": "1px solid #CBD5E1",
                                      "background": "#FFF"
                                    }}
                                    name="subFolderName"
                                    color="primary" data-test-id="renameFolder"
                                    onChange={this.handleRenameFolder} value={renameFolderName}
                                    InputProps={{

                                      style: {
                                        color: "#64748B", height: '56px',
                                        padding: '10px 8px'
                                      },
                                      placeholder: `${item1.file_name}`
                                    }}
                                    fullWidth
                                  />
                                </FormControl>
                              </Box>
                            </div>
                            <Divider />
                            <div
                              style={{
                                display: "flex", padding: "1.5rem 1.5rem 1.5rem 20rem",
                                justifyContent: "flex-end",
                                alignItems: "center", alignSelf: "stretch",
                              }}
                            >
                              <Button style={webStyle.cancelbtn} onClick={this.handleCancelRename}><Typography
                                style={webStyle.cancelbtnText}>Cancel</Typography></Button>
                              <Button

                                data-test-id="okFolderrename" variant="contained" style={{
                                  display: "flex", width: "7.5rem",
                                  height: "3.5rem", padding: "1rem 0",
                                  borderRadius: "0.5rem", backgroundColor: "#E57727",
                                  color: "#FFF", fontWeight: 700,
                                  fontSize: "1rem",
                                }} onClick={() => this.RenamePdfDoc(item1.file_id, item1.attachment_id)}
                              >
                                <Typography style={{
                                  fontSize: '16px', fontWeight: 700, fontFamily: 'Inter', textTransform: 'none'
                                }}>Save</Typography>
                              </Button>
                            </div>

                          </Card>
                        </Dialog> : null}
                    </Box> : null}

                </Box>))}

            </Box>


          </Box>





        </Container>
      </ThemeProvider>
      /* Customizable Area Start */
    );
  }
}

// Customizable Area Start
const webStyle: any = {
  topWraper1: {
    "display": "flex",
    "width": "calc(100vw - 269px)",
    "padding": "16px 24px 16px 24px",
    "alignItems": "center",
    justifyContent: "space-between",
    "borderRadius": "8px",
    "border": "0.5px solid  #94A3B8",
    "background": "#FFF",
    height: "48px",
    gap: "1rem",

    "@media (max-width: 820px)": {

      "flexWrap": "wrap",
      "width": "calc(100vw - 218px)",
      height: "auto"
    },
    "@media (max-width: 1045px)": {

      "flexWrap": "wrap", "height": "auto",
      
    },

  },
  chipWrapper: {
    display: "flex", gap: "15px", flexWrap: "wrap",
  

  },
  topWraper: {
    "display": "flex",
    "width": "calc(100vw - 269px)",
    "padding": "16px 24px 16px 24px",
    "alignItems": "center",
    position: "relative",
    "borderRadius": "8px",
    "border": "0.5px solid  #94A3B8", "background": "#FFF",
    height: "44px", justifyContent: "space-between",
    "@media (max-width: 820px)": {

      "flexWrap": "wrap", height: "auto",
      "width": "calc(100vw - 220px)",
    },
  
  
  },

  secondTopWrapper: {
    "display": "flex",
    "width": "calc(100vw - 271px)",
    "height": "765px",
    "padding": "30px 25px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "10px", overflowY: "auto",
    "flexShrink": "0",
    "borderRadius": "8px", "border": "1px solid  #CBD5E1",
    "background": "#FFF", cursor: 'pointer',
    "@media (max-width: 820px)": {
      "width": "calc(100vw - 222px)",
      "flexWrap": "wrap", "fontSize": "50%", 
    },
  },

  buttonStyle: {
    "display": "flex",
    "height": "44px",
    // "padding": "10px 16px",
    "justifyContent": "center",
    "alignItems": "center",
    "gap": "8px", "borderRadius": "8px"
  },
  btnText: {
    "fontFamily": "Inter",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": 700,
    // "lineHeight": "24px",
    textTransform: "none",
    color: "#396178",
    marginLeft: "8px"
  },
  rowField: {
    "display": "flex",
    "width": "calc(-291px + 100vw)",
    "padding": "10px 10px",

    "alignItems": "center",
    "gap": "10px",
    "borderRadius": "6px 6px 0px 0px",
    "border": "0.5px solid  #CBD5E1",
    height: "36px",
    justifyContent: 'space-between',
    position: "relative",
    "@media (max-width: 820px)": {
      // "flexWrap": "wrap", 
      "fontSize": "50%",
      "width": "calc(100vw - 242px)",
      height: "auto"
    },

  },
  rowFieldLeft: {
    display: "flex",
    alignItems: "center",
    gap: '30px',

    "@media (max-width: 768px)": {

      "flexWrap": "wrap",
      "fontSize": "50%",
      height: "auto",
      gap: "8px"
    },

  },

  sortbtn: {
    "display": "flex", "padding": "10px 12px 10px 10px",
    "alignItems": "center", "gap": "8px",
    "alignSelf": "stretch",justifyContent:"flex-start",
    borderRadius: "8px",
    fontSize: "16px", fontWeight: 700, textTransform: "none",
    width: "95px", height: "44px", color: '#64748B',
  },
  rowField1: {
    "display": "flex",
    "width": "calc(-291px + 100vw)",
    "padding": "10px 10px",

    "alignItems": "center",
    "gap": "10px",
    "borderRadius": "6px 6px 0px 0px",
    "border": "0.5px solid  #CBD5E1",
    height: "24px",
    justifyContent: 'space-between',
    position: "relative"
  },

  modalEditDel: {
    "display": "flex",
    "width": "194px",
    "padding": "5px",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "5px",
    "borderRadius": "8px",
    "background": "#FFF",
    "boxShadow": "4px 8px 32px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08)",
    position: "absolute",
    top: '12px',
    right: "2rem",
    zIndex: 1,
    "@media (max-width: 768px)": {
     maxWidth:"110px"
      
    },
  },

  editText: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "22px",
    textTransform: "none"
  },
  renameFolder: {
    "display": "flex",
    "width": "184px",
    "height": "36px",

    "alignItems": "center",
    "gap": "8px",
    "borderRadius": "8px",
    "background": "#F6F2EF",
   
  },
  delebtn: {
    "display": "flex",
    "width": "184px",
    "height": "36px",
    // "padding": "7px 56px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "borderRadius": "8px",
    "background": "#FFF",
   
   
  },
  folderName: {
    "color": "#000",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "600",
    "lineHeight": "normal",
    "@media (max-width: 300px)": {

      "fontSize": "12px",

    },
  },
  cancelbtn: {
    "display": "flex",
    "width": "120px",
    "height": "56px",
    "padding": "16px 24.5px 16px 40.5px",
    "justifyContent": "flex-end",
    "alignItems": "center",
    "flexShrink": "0",
    "borderRadius": "8px",
    "background": "#F6F2EF",
    marginRight: "1rem"
  },
  cancelbtnText: {
    "color": "var(--Orange, #E57727)",
    "textAlign": "center",
    "fontFamily": "Inter",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "24px",
    textTransform: "none"
  },
  closeMOdalCross: {
    "display": "flex",
    "width": "32px",
    "height": "32px",
    "padding": "4px",
    "justifyContent": "center",
    "alignItems": "center",
    "flexShrink": "0",
    cursor: 'pointer'
  },

  filterBox: {
    "display": "flex",
    "width": "11.25rem",
    // "height": "26.125rem",
    "paddingTop": "0.75rem",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "0.625rem",
    "flexShrink": "0",
    // backgroundColor:"red",
    position: "absolute",
    top: "4rem",
    right: '0.625rem',
    "zIndex": "2",
    "background": "#FFFFFF",
    "boxShadow": "4px 8px 32px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08)",

    "@media (max-width: 820px)": {
    maxWidth:"50%",
     position: "absolute",
     top: "90%",
     right: '0.625rem',
     gap:0,
     paddingTop:0
    },

  },
  filterText: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "0.875rem",
    "fontStyle": "normal",
    "fontWeight": 400,
    "lineHeight": "1.375rem",
    textTransform: "none",
 
  },
  filtrBtn: {
    "display": "flex",
    "maxWidth": "180px",
    "padding": "10px 0px 10px 25px",
    "alignItems": "center",
    "gap": "8px",
    justifyContent: 'flex-start'
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
  selectOPtion: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "24px"
  }
};
// Customizable Area End
