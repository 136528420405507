import { Button, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { useField } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
//@ts-ignore
import { FileError, FileRejection, useDropzone } from "react-dropzone"
import { SingleFileUploadWithProgress } from './SingleFileUpload';
import { AddCircleOutline } from '@material-ui/icons';
// import * as fileType from 'file-type';
// import { SingleFileUploadWithProgress } from './SingleFileUploadWithProgress';
// import { UploadError } from './UploadError';

let currentId = 0;

function getNewId() {
  return ++currentId;
}

export interface UploadableFile {
 id: number;
  file: File;
  errors: FileError[];
  url?: string;
}

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: `2px dashed #CBD5E1`,
    borderRadius: "16px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: "#FFFFFF",
    height: "218px",
    gap:"16px",
    outline: 'none',
    padding: "32px 24px",
    flexDirection: "column",
    alignSelf: "stretch",
  },
}));

export function MultipleFileUploadField({ name,textColor }: { name: string,textColor:string }) {
  const [_, __, helpers] = useField(name);
  const classes = useStyles();

  const [files, setFiles] = useState<UploadableFile[]>([]);

  const onDrop = useCallback(async(accFiles: File[], rejFiles: FileRejection[]) => {
    const pdfFiles = accFiles.filter(file => file.type === 'application/pdf');
      const mappedAcc = pdfFiles.map((file) => ({ file, errors: [], id: getNewId() }));
    const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId() }));
    
    setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
  }, []);
  

  useEffect(() => {
    helpers.setValue(files);
  }, [files]);

  function onUpload(file: File, url: string) {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url };
        }
        return fw;
      })
    );
  }

  function onDelete(file: File) {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
  }

  const { getRootProps, getInputProps,open } = useDropzone({
    onDrop,
    accept: ['.pdf'] as any,
    maxSize: 300 * 1024, // 300KB
    noClick: true,
    // onDragEnter: () => setIsDropTriggered(true),
  });

  return (
    <Container maxWidth={"xl"}>
      <Grid item>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input type="application/pdf" {...getInputProps()} />
          <img src={require("../../web/public/assets/image_photo_camera.svg")}></img>
          <div style={{gap:"4px",textAlign: "center",display: "grid"}}>
          <Typography style={{color:" #0F172A",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "24px"}}>Upload your files here</Typography>
          <Typography>Drag and drop some files here</Typography>
          </div>
          
          <Typography style={{display:"flex",color:textColor}} onClick={open}><AddCircleOutline style={{ marginRight: "8px" }} /> Upload Documents</Typography>
        </div>
      </Grid>

      {files.map((fileWrapper) => (
        console.log(fileWrapper),
        
        <Grid item key={fileWrapper.id}>
          {/* {fileWrapper.errors.length ? (
            <UploadError
              file={fileWrapper.file}
              errors={fileWrapper.errors}
              onDelete={onDelete}
            />
          ) : ( */}
            <SingleFileUploadWithProgress
              onDelete={onDelete}
              onUpload={onUpload}
              file={fileWrapper.file}
            />
          {/* )} */}
        </Grid>
      ))}
    </Container>
  );
}