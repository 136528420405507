import React from 'react'
import { Switch } from "@material-ui/core";
import { makeStyles, createStyles,Theme } from '@material-ui/core/styles';

interface StyleProps {
  color: string; // Assuming color is a string
}

function Toggle({isChecked,handleToggle,color}:any) {
  const classes = useStyles({ color });
  return (
    <Switch
   className={classes.toggle}
      checked={isChecked}
      onChange={handleToggle}
      color="primary"
      inputProps={{ 'aria-label': 'controlled' }}
    />
  )
}
const useStyles = makeStyles<Theme, StyleProps>(theme=>createStyles({
  toggle:{
    width: '53px',
    height: '41px',
    "& .MuiSwitch-input":{
      width:"48px",
      height:"28px",
      left:"-41%"
    },
    "& .Mui-checked":{
      transform: "translateX(13px)",
      color:'#fff',
    },
    "& .MuiSwitch-track":{
      backgroundColor:"#989898"
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track":{
      background:props => props.color,
      opacity:"1"
    },
    "&>span":{
      top: '4px',
      left: '4px',
      "&>span":{
        "&>span":{
        width: '15px'
,
        height: '15px'
        }
      }
    }
}
}));

export default Toggle
