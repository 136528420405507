//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
	Box,
	Container,
	Tab,
} from "@material-ui/core";
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./UserGroup.css"
import { withRouter } from 'react-router-dom';

// Customizable Area End

const theme = createTheme({
	palette: {
		primary: {
			main: "##000000",
			contrastText: "#fff",
		},
	},
	typography: {
		h6: {
			fontWeight: 500,
		},
		subtitle1: {
			margin: "20px 0px",
		},
	},
});
// Customizable Area End


import { Notes } from "./Notes.web";
import UserGroupController,
{
	Props
} from "./UserGroupController.web";


export class UserGroup extends UserGroupController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start

		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End

	render() {
		const { value } = this.state;
		return (
			// Customizable Area Start
			<ThemeProvider theme={theme}>
				<Container maxWidth="xl" >
					<Box className="usergroup-tabs" sx={{ width: '100%' }} >
						<TabContext value={value}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList onChange={this.handleChange} aria-label="lab API tabs example" className="usergroup-tablist">
									{this.tabtypes.map((tabType, index) => (
										<Tab key={index} label={tabType} value={tabType} />
									))}
								</TabList>
							</Box>
							<TabPanel value="Profile">Profile</TabPanel>
							<TabPanel value="Progress Tracker">Progress Tracker</TabPanel>
							<TabPanel value="Documents">Documents</TabPanel>
							<TabPanel value="Communication">Communication</TabPanel>
							<TabPanel value="Invoices">Invoices</TabPanel>
							<TabPanel value="Notes"><Notes /></TabPanel>
							<TabPanel value="Projects">Projects</TabPanel>
						</TabContext>
					</Box>

				</Container>
			</ThemeProvider>
			// Customizable Area End
		);
	}
}
export default withRouter(UserGroup);

// Customizable Area Start
// Customizable Area End
