// Customizable Area Start
import React from "react";
import {
    Button,
    Container,
    Link,
    Paper,
    Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { imgVinLogo,imgAlert } from "./assets";
import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController.web";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link as Redirect } from 'react-router-dom'
import CustomInput from "../../../components/src/CustomInput.web";
import { Alert } from "@material-ui/lab";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
});

const componentStyles = {
    mainDiv: {
        width: "100%",
        height: "100%",
        background: "#F6F2EF"
    },
    customContainer: {
        maxWidth: '508px',
      },
    centerContent: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    centerbtn: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "26px",
        paddingBottom: "20px"
    },
    logoImage: {
        height: "100%",
        width: 'auto'
    },
    subTitle: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
    },
    textInputStyle: { marginTop: "10px" },
    labelInputStyle: {
        color: "#000",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px"
    },
    alertMessage:{
        padding:0
    },
    sublabel: { color: "#0F172A", marginTop: "5px",letterSpacing:"0.4", fontWeight: 400, lineHeight: "24px", fontSize: '16px' },
    submitForgotBtnStyle: {
        background: "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
        color: "#fff",
        height:"56px",
        fontWeight:700,
        marginBottom: "20px",
        borderRadius: "8px",
    },
    messageDiv: { display: "flex", gap: "8px" },
    decoration:{
        /*@ts-ignore*/
        textDecoration: "none"
    },
    alertDiv: {
        display: "inline-flex",
        padding: "8px 16px 8px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        marginTop:"25%",
        borderRadius: "8px",
        background: "#FFF",
        boxShadow: "0px 6px 15px -3px",
      },
    linkStyle:{
        /*@ts-ignore*/
        textDecoration: "none",
        color: "#E57727",
        fontWeight: 700
    },
    resendMail:{ 
     fontSize: "16px",
     fontWeight: 700,
     color: "#E57727",
     cursor:"pointer"
    }
}

export class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const{classes}=this.props;
        return (
            // Required for all blocks 
            <div className="mainDiv" style={{...componentStyles.mainDiv,overflowY:"scroll"}}>
                <ThemeProvider theme={theme}>
                    <Container id="Container" className={classes.customContainer}>
                        <div style={componentStyles.centerContent} >
                            <img style={componentStyles.logoImage} src={imgVinLogo} alt="logo" />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "10px 0px",
                                width: "100%",
                                marginTop: "20%"
                            }}
                        >
                            <Paper style={{ width: '100%' }} elevation={4} >

                                <div data-test-id="forgotPasswordForm" >
                                    <Formik
                                    data-testId="ForgotPasswordForm"
                                        initialValues={{email: "" }}
                                        validationSchema={Yup.object().shape(this.forgotSchema())}
                                        onSubmit={(values, actions) => {
                                            this.submitForgot(values)
                                            actions.setSubmitting(false);
                                          }}
                                    >
                                        {({
                                            handleChange,
                                            handleSubmit,
                                            errors,
                                            setFieldTouched,
                                            touched,
                                            values,
                                            resetForm
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div style={{ padding: "50px" }}>
                                                    <div style={{ textAlign: "start" }}>
                                                        <div style={{}} >
                                                            <Typography style={{ fontWeight: 700, fontSize: "24px", marginBottom: "25px" }} variant="h5" component="h5">
                                                                {this.PasswordRecovery}
                                                            </Typography>
                                                            {!this.state.emailGiven ? (<><Typography style={componentStyles.subTitle}>
                                                                Forgot password?
                                                            </Typography>
                                                                <p style={componentStyles.sublabel} >{this.sublabel}</p></>) : (<><Typography style={componentStyles.subTitle}>
                                                                {this.txtCheckyouremail}
                                                                </Typography>
                                                                    <p style={componentStyles.sublabel} >{`${this.txtMailtoEmail} ${this.state.emailWriten}`}</p></>)}

                                                        </div>
                                                    </div>
                                                    {this.state.showAlert &&
                                                            <Alert classes={{
                                                                message: classes.alertMessage
                                                            }}
                                                                style={{ width: "100%", padding: "0" }} icon={false} severity="error">
                                                                <div style={{ display: "flex" }} >
                                                                    <div style={{ border: "2px solid red" }}></div>
                                                                    <Typography style={{ fontSize: "14px", color: "red", marginLeft: "10px", padding: "8px" }} variant="body1">{this.state.alertMessage}</Typography>
                                                                </div>
                                                            </Alert>}
                                                    {!this.state.emailGiven ? (<div>  <div style={{ paddingTop: "20px",marginBottom:"10px" }} >
                                                        <label htmlFor="email" aria-controls="email" style={componentStyles.labelInputStyle} >{this.firstInputPlaceholder}</label><br />
                                                        <CustomInput
                                                            autoComplete="given-name"
                                                            name="email"
                                                            data-test-id="emailAddress"
                                                            required
                                                            fullWidth
                                                            id="Email"
                                                            placeholder="Your email"
                                                        />
                                                    </div>
                                                        <div style={componentStyles.centerbtn}>
                                                            <Button
                                                                fullWidth
                                                                // @ts-ignore
                                                                className={classes.submitForgotBtnStyle}
                                                                style={{textTransform: "initial"}}
                                                                data-test-id="submitBtn"
                                                                id="submitBtn"
                                                                type="submit"
                                                            >
                                                                {this.labelTextIsSendlinkonEmail}
                                                            </Button>
                                                        </div>

                                                        <Typography style={{ fontSize: "16px" }} >
                                                            Back to <Redirect style={componentStyles.decoration} to="/EmailLogin">
                                                                <Link underline="none" style={componentStyles.linkStyle} href="#">Login</Link>
                                                            </Redirect>
                                                        </Typography></div>) : (
                                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
                                                            <Typography style={componentStyles.resendMail} onClick={this.reSendEmail} >
                                                                {this.txtResendEmail}
                                                            </Typography>

                                                            <Typography style={{ fontSize: "16px" }} >
                                                                Back to <Redirect style={componentStyles.decoration} to="/EmailLogin">
                                                                    <Link underline="none" style={componentStyles.linkStyle} href="#">Login</Link>
                                                                </Redirect>
                                                            </Typography></div>)}



                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>


                            </Paper>
                            {this.state.showSuccessAlert && this.state.resendEmail &&
                            <Alert style={componentStyles.alertDiv} icon={false} severity="success">
                                <div style={componentStyles.messageDiv} >
                                <img src={imgAlert} style={{ verticalAlign: "bottom" }} />
                                <Typography variant="body1">{this.state.alertMessage}</Typography>
                                </div>
                            </Alert>}
                        </div>
                        
                    </Container>
                </ThemeProvider>
            </div>
        );
    }
}
export default withStyles(componentStyles)(ForgotPassword);
// Customizable Area End