import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getSecondaryThemeColor, getThemeColor } from "../../../components/src/CommonPlatte";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start

  accountProfileStatusModal: boolean;
  filterSelect: boolean;
  clientTab: boolean;
  teamMatesTab: boolean;
  finAdvisorTab: boolean;
  usersAccountData: any[];
  dropdownId: string;
  deleteApiResponse: string;
  tokenValue: string;
  searchedRows: string;
  accountType: string;
  setDate: any;
  errorFilter: string;
  showDate: boolean;
  filterCount: number;
  cuurentUserType:any;
  btnColor:string;
  textColor:string;
  disableClientTab:boolean;
  disableTeamTab:boolean;
  disablefinAdv:boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PeopleWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUsersAccoountAPI: any;
  deleteUserAccountAPI: any;
  filterAccountByAPI: any;
  getCurrentUserAPI:any

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start

      usersAccountData: [],

      accountProfileStatusModal: false,
      filterSelect: false,
      clientTab: true,
      finAdvisorTab: false,
      teamMatesTab: false,
      btnColor:getThemeColor(),
      textColor:getSecondaryThemeColor(),
      dropdownId: "",
      deleteApiResponse: "",
      tokenValue: "",
      searchedRows: "",
      accountType: "client",
      setDate: "",
      errorFilter: "",
      showDate: false,
      filterCount: 0,
     cuurentUserType:null,
     disableClientTab:false,
  disableTeamTab:false,
  disablefinAdv:false,
     
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    if (this.state.clientTab) {
      this.getAccounts("client");
    }
    this.getCurrentUser()
   
    // Customizable Area End
  }

  // Customizable Area Start

  token: string = "";

  

  getAccounts = async (role: string) => {
    let someData = localStorage.getItem("loginData");
    let someDataParsed = someData && JSON.parse(someData);
    this.token = someDataParsed?.token;

    console.log("hey i am calling create account", this.token);
    this.getUsersAccoountAPI = await this.getUserAccountapiCall({
      methods: "GET",
      endPoint: `account_block/account_creations/people_list?account[user_type]=${role}`,

      token: this.token,
    });
    this.setState({ showDate: false, filterCount: 0 });
  };

  filterAccountByfilter = async () => {
    this.filterAccountByAPI = await this.getUserAccountapiCall({
      methods: "GET",
      endPoint: `account_block/account_creations/people_list_by_filter?user_type=${this.state?.accountType}&date=${this.state.setDate}`,
      token: this.token,
    });
  };

  deleteuserAccounts = async (id: string) => {
    this.deleteUserAccountAPI = await this.getUserAccountapiCall({
      methods: "DELETE",
      endPoint: `account_block/account_creations/${id}`,

      token: this.token,
    });
    const updatedItems = this.state.usersAccountData.filter(
      (item: any) => item.id !== id
    );
    this.setState({
      usersAccountData: updatedItems,
      accountProfileStatusModal: false,
    });
  };


  getCurrentUser = async()=>{


    this.getCurrentUserAPI = await this.getUserAccountapiCall({
      methods: "GET",
      endPoint: `account_block/accounts/show`,

      token: this.token,
    }
    );
  
   
    
   
  }

  getUserAccountapiCall = async (data: any) => {
    const { ContentType, methods, endPoint, token } = data;
    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestMessageforAccountPeopleweb = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforAccountPeopleweb.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforAccountPeopleweb.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforAccountPeopleweb.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );

    runEngine.sendMessage(
      apiRequestMessageforAccountPeopleweb.id,
      apiRequestMessageforAccountPeopleweb
    );
    return apiRequestMessageforAccountPeopleweb.messageId;
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestId === this.getUsersAccoountAPI) {
        if (responseJson.data !== null) {
          this.setState({ usersAccountData: responseJson.data });
        } else {
          console.log(responseJson.data, "error");
        }
      }
      if (apiRequestId === this.deleteUserAccountAPI) {
        this.setState({ deleteApiResponse: responseJson.data });
      }

      if (apiRequestId === this.filterAccountByAPI) {
        this.setState({ usersAccountData: responseJson.data });
      }
      if(apiRequestId === this.getCurrentUserAPI)
      {
        this.setState({cuurentUserType:responseJson?.data?.attributes?.user_type})
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Customizable Area End
  }

  handleClientClick = () => {
    this.setState({
      clientTab: true,
      finAdvisorTab: false,
      teamMatesTab: false,
      accountType: "client",
      setDate: "",
    });
    this.getAccounts("client");
    // this.filterAccountByfilter()
  };

  handleDateChange = (e: any) => {
    const inputValue = e.target.value;

    this.setState({ setDate: this.formatDate(inputValue), filterCount: 1 });
  };

  formatDate = (date: any) => {
    if (date) {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    }
    return "";
  };

  handleTeammatesClick = () => {
    this.setState({
      clientTab: false,
      finAdvisorTab: false,
      teamMatesTab: true,
      accountType: "vincertax_employee",
      setDate: "",
    });
    this.getAccounts("vincertax_employee");
  };

  handleFinAdvisorClick = () => {
    this.setState({
      clientTab: false,
      finAdvisorTab: true,
      teamMatesTab: false,
      accountType: "white_lable_user",
      setDate: "",
    });
    this.getAccounts("white_lable_user");
  };

  accountProfileStatusModalOpen = (id: any, roleid: any) => {
    if (id == roleid) {
      this.setState({ accountProfileStatusModal: true });
    }
  };

  accountProfileStatusModalClose = () => {
    this.setState({ accountProfileStatusModal: false });
  };

  filterPopoverClose = () => {
    this.setState({ filterSelect: false, showDate: false });
  };

  filterPopoverOPen = () => {
    this.setState({ filterSelect: true });
  };

  requestSearch = (searchedVal: any) => {
    this.setState({ searchedRows: searchedVal.target.value }, () =>
      this.searchresult(this.state.searchedRows)
    );
  };
  gotoProfile(id:any,fullName:string) {
    localStorage.setItem("clientName",JSON.stringify(fullName))
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Profile");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationProfileMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationProfileMessage),
      id
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  searchresult(value: any) {
    let filteredRows = this.state.usersAccountData.filter((row: any) => {
      return row.attributes.first_name
        ?.toLowerCase()
        .includes(value.toLowerCase());
    });

    if (value == "") {
      this.getAccounts(this.state.accountType);
    } else {
      this.setState({ usersAccountData: filteredRows });
    }
  }

  handleShowDate = () => {
    this.setState({ showDate: !this.state.showDate });
  };

  // Customizable Area End
}
