import React from "react";

// Customizable Area Start
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";

import {
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
let artBoardHeightOrg = 667;
let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import SalesReportingController, {
  Props,
  configJSON,
} from "./SalesReportingController";
import GenericCard from "../../../components/src/GenericCard";
import Chart from "react-google-charts";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";


export default class SalesReporting extends SalesReportingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start
    return (
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container} testID="sales-reporting-title">
        <View>
          <View>
            <View style={styles.selectPeriodContainer} testID="sales-period-selector">
              {configJSON.periods.map((frame: { pkey: string, value: string }, index: number) => 
              <TouchableOpacity testID={`${frame.pkey}_period`} onPress={() => this.setSelectedPeriod(frame)} key={`${index}_period`}>
                <Text style={{ fontWeight: frame.pkey === this.state.selectedFrame ? 'bold' : 'normal' }}>{frame.value}</Text>
              </TouchableOpacity>)}
            </View>
            <View style={styles.frameSelectContainer} testID="date-navigate-container">
              <IconButton
                aria-label="chevron left"
                onClick={() => this.setSelectedDate('left')}
                edge="end"
              >
                <ChevronLeftIcon />
              </IconButton>
              <View>
                <Text>{this.state.selectedDate.from.format('lll')} -</Text>
                <Text>{this.state.selectedDate.through.format('lll')}</Text>
              </View>
              <IconButton
                aria-label="chevron right"
                onClick={() => this.setSelectedDate('right')}
                edge="end"
              >
                <ChevronRightIcon />
              </IconButton>
            </View>
          </View>

          <GenericCard title={configJSON.overviewTitle}>
            <View style={styles.overviewContainer}>
              <View style={styles.overviewSubContainer}>
                <Text style={styles.overviewText}>{this.state.currency}{this.state.totalSaleRevenue}</Text>
                <Text>{configJSON.overviewRevenue}</Text>
              </View>
              <View style={styles.overviewSubContainer}>
                <Text style={styles.overviewText}>{this.state.totalSaleUnit}</Text>
                <Text>{configJSON.overviewUnit}</Text>
              </View>
            </View>

          </GenericCard>
          <GenericCard title={configJSON.salesByUnitTitle}>
            <Chart
              chartType="BarChart"
              width="100%"
              height="400px"
              data={this.state.webSalesData}
              options={configJSON.webSalesChartOptions}
            />
          </GenericCard>
          <GenericCard title={configJSON.salesSumTitle}>
            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={this.state.webSalesCumulativeData}
              options={configJSON.webSalesChartOptions}
            />
          </GenericCard>
          <GenericCard title={configJSON.revenueBreakdownTitle}>
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={this.state.webRevenueBreakdown}
              options={configJSON.webSalesChartOptions}
            />
          </GenericCard>

        </View>
      </ScrollView>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "75%",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: 0,
  },
  bgMobileInput: {
    flex: 1,
  },
  showHide: {
    alignSelf: "center",
  },
  imgShowhide: { height: 30, width: 30 },
  selectPeriodContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    height: 50,
    alignItems: 'center',
  },
  overviewContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 70,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  overviewSubContainer: {
    alignItems: 'center',
  },
  overviewText: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  frameSelectContainer: {
    backgroundColor: '#d3d3d3',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 100,
    alignItems: 'center',
  }
});
// Customizable Area End
