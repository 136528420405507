// Customizable Area Start
import React from "react";
import {
  Link,
  Box,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Avatar,
  TextField,
  InputAdornment,
  Breadcrumbs,
  Container,
} from "@material-ui/core";

import { Link as Redirect } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search';

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import ThemeController, {
  Props,
} from "./ThemeController.web";
import { imgEdit, imgSuccess, imgUpload } from "./assets";
import CustomInput from "../../../components/src/CustomInput.web";
import { Formik } from "formik";
import ColorPicker from "../../../components/src/ColorPicker";
import RectangleRow from "../../../components/src/Rectangle";
import { Alert } from "@material-ui/lab";
import { NavigateNext } from "@material-ui/icons";
import ClearIcon from '@material-ui/icons/Clear';

const enumTheme = {
  Default: "default",
  Custom: "custom"
}
export class ThemeBlock extends ThemeController {


  constructor(props: Props) {
    super(props);
  
  
  }




  render() {
    const { classes } = this.props
    const rectangles = [
      { color: '#E57727', label: 'Orange' },
      { color: '#073F59', label: 'Blue' },
      { color: '#51BEBA', label: 'Teal' },
      { color: '#CBD5E1', label: 'Cool Grey' },
      { color: '#F1F5F9', label: 'White' },
      { color: '#000000', label: 'Black' },
    ];  
    const breadcrumbsTheme = [
      <Link underline="hover" key="1" color="inherit">
        <Redirect to="/Settings2" className={classes.redirection}>Settings</Redirect>
      </Link>,
    
      <Typography key="3" className={classes.currentPage} style={{color:this.state.secondaryColor !=""?this.state.secondaryColor :"#E57727",textTransform:"none"}}>
        Profile theme
      </Typography>,
    ];
    
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"}>
        <Box sx={{ display: "flex", flexDirection: "column", 
                    width: "calc(100vw - 135px)", position: 'fixed', top: "0px", right: "0px", alignItems:"flex-start" ,
                    // justifyContent:'center',
                    height:"100vh"}}>
                        <Box sx={{
                            "display": "flex", "height": "32px", "padding": "20px 1154px 20px 48px", "justifyContent": "space-between",
                            "alignItems": "center", "flexShrink": 0, "borderBottom": "1px solid #CBD5E1", width: "calc(100vw - 193px)", position: "sticky"
                        }}>

                            <Breadcrumbs
                              separator={<NavigateNext fontSize="small" />}
                              aria-label="breadcrumb"
                            >
                              {breadcrumbsTheme}
                            </Breadcrumbs>
                            {!this.state.isEdit &&
                            <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "gap": "12px", height: "44px", paddingRight: "48px" }}>

                              <Button 
                                onClick={this.goToCreateTheme}
                                  data-test-id="searchbtn" style={{
                                    "display": "flex", "height": "2.75rem", "padding": ".625rem 1rem", "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
                                    "borderRadius": ".5rem", "background": this.state.primaryColor,marginRight:"15px"

                                }}><Typography style={{ "color": "#FFF", "fontFamily": "Inter", "fontSize": "1rem", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "1.5rem", textTransform: "none", }
                                } >Add theme</Typography></Button>

{!this.state.searchBar ? <SearchIcon data-test-id="searchTheme" onClick={this.showSearchBar}/> :<><TextField
                                    type="search"
                                    id="search"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ClearIcon onClick={this.showSearchBar} style={{ width: "1.5rem", height: "1.5rem" }} />
                                            </InputAdornment>
                                        ),
                                        style: {
                                            "display": "flex", "width": "14.5rem", "height": "2.75rem", "alignItems": "center",
                                            "flexShrink": 0, "borderRadius": "0.5rem", "background": "#FFF", padding: ".625rem 0.5rem .625rem 0.5rem"
                                        },
                                        inputProps: {
                                            style: {
                                                padding: 0, "fontSize": "16px", "lineHeight": "24px", "fontWeight": 400, "fontFamily": "inter",

                                            } as React.CSSProperties,
                                        },


                                    }}
                                    style={{ fontFamily: "inter" }}
                                    placeholder="Search for themes"
                                    variant="outlined"
                                     className={classes.searchBar}
                                    value={this.state.searchTheme}
                                    onChange={(searchValue) => this.themeSearch(searchValue)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '1rem', fontWeight: 400, lineHeight: "1.5rem", fontFamily: "Inter", color: "#64748B",
                                        },
                                    }}
                                />

                                <Button 
                                onClick={this.handleSearchTheme}
                                  data-test-id="searchbtn" style={{
                                    "display": "flex", "height": "2.75rem", "padding": ".625rem 1rem", "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
                                    "borderRadius": ".5rem", "background": this.state.primaryColor

                                }}><Typography style={{ "color": "#FFF", "fontFamily": "Inter", "fontSize": "1rem", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "1.5rem", textTransform: "none", }
                                } >Search</Typography></Button></>}


                            </div>}

                        </Box>
          
          {this.renderThemeList() ? ( 
          <div style={{width:"100%",height:"100%",overflowY:"auto"}}>
          {this.state.themes.map((item: any, index: number)=>(
            <div key={item?.id} >
         <Box className={classes.mainDiv}>

        <div className={classes.mainHead}>
          <Typography className={classes.themeView}>Theme preview</Typography>
          <img src={imgEdit} id="editTheme" style={{ cursor: "pointer" }} onClick={()=>this.editTheme(item.id)} ></img>
        </div>
        <div style={{ padding: "20px", display: "grid", gap: "27px" }}>
          <img style={{maxHeight: '100px',width:"auto"}} src={item?.attributes.theme_image.url} ></img>
          <Typography className={classes.welcomeText}>
            {item.attributes.title}
          </Typography>

          <Typography className={classes.titleText}>
          {item.attributes.description}
           </Typography>
          </div>
        </Box>
        </div>
          ))
          }
          </div>): <Typography className={classes.searchStyle}>{this.state.searchResult}</Typography>
          }

          {this.state.isEdit && <div style={{ 
            width:"100%",
            overflow:"auto"}}>

            <Formik
              data-testId="ThemeForm"
              initialValues={{
                title: this.state.title,
                description: this.state.description,
                theme_image: this.state.selectedImage,
                typeSlected: false,
                primary_color: this.state.primary_color,
                secondary_color: this.state.secondary_color,
              }}

              validateOnChange={false}
              validateOnMount={true}
              validateOnBlur={true}
              onSubmit={async (values, actions) => {
                this.updateTheme(values,this.state.themeId)
              }}
            >
              {({
                handleSubmit,
                touched,
                handleBlur,
                errors,
                values,
                handleChange,
              }) => (
                <form style={{ display: "flex", gap: "31px",marginTop: "38px",marginLeft: "36px" }} onSubmit={handleSubmit}>
                  <Box>
                    <Box className={classes.editBlock}>
                      <div className={classes.editDiv}>
                        <Typography className={classes.subHeading} >Edit theme</Typography>
                      </div>
                      <div className={classes.logoDiv}>
                        {this.state.selectedImage ? <img style={{maxHeight: '100px',width:"auto"}} src={this.state.theme_image} ></img>:<img style={{maxHeight: '100px',width:"auto"}} src={this.state.fetchedImage} ></img>}
                        
                      </div>
                    </Box>
                    <div style={{ display: "grid", marginTop: "32px", gap: "29px" }}>

                      <Grid item style={{ width: "100%", padding: "0px 20px" }} >
                        <label aria-controls="title" >Title</label> <br />
                        <CustomInput
                          data-test-id="txtInputtitle"
                          autoComplete="given-name"
                          name="title"

                          required
                          fullWidth
                          id="title"
                          placeholder="Your full name"
                        />

                      </Grid>
                      <Grid item style={{ width: "100%", padding: "0px 20px" }} >
                        <label aria-controls="description" >Description</label><br />
                        <CustomInput
                          data-test-id="txtInputdescription"
                          fullWidth
                          id="description"
                          name="description"
                          multiline
                          autoComplete="description"
                          placeholder="Your description"
                        />
                      </Grid>
                    </div>
                  </Box>
                  <Box className={classes.themeSelect}>

                    <Box>
                      <div className={classes.subheadDiv}>
                        <Typography className={classes.subHeading} >Theme settings</Typography>
                      </div>
                      <Grid container style={{ gap: "30px", padding: "20px" }}>
                        <Grid item xs={12} >

                          <FormControl>
                            <RadioGroup row aria-labelledby="demo-row-radiobutton-group-label" name={"typeSlected"}
                            className={classes.radioH}
                            data-test-id="radioBtn"
                              defaultValue={this.state.themeTypeSelect ? enumTheme.Default : enumTheme.Custom}
                              value={this.state.themeTypeSelect ? enumTheme.Default : enumTheme.Custom}
                              onChange={(event: any) => {
                                handleChange(event);
                                this.handleTheme()
                              }}
                              style={{ gap: "15px" }}
                            >
                              <FormControlLabel className={classes.radioBtn} checked={this.state.themeTypeSelect === true} value={enumTheme.Default} control={<Radio style={{ color: this.state.primaryColor }} />} label="Default theme" />
                              <FormControlLabel className={classes.radioBtn} checked={this.state.themeTypeSelect === false} value={enumTheme.Custom} control={<Radio style={{ color: this.state.primaryColor }} />} label="Custom theme" />
                            </RadioGroup>
                          </FormControl>

                        </Grid>
                        <div className={classes.partition}></div>
                        {!this.state.themeTypeSelect && <>
                          <Grid item xs={12}>
                            <Box className={classes.pickerBox}><Typography className={classes.pickerText}>Primary color</Typography></Box>
                            <ColorPicker name="primary_color"
                              onChange={(e: any) => { this.handlePrimeColorChange(e); handleChange({ target: { name: 'primary_color', value: e } }) }}
                            />
                          </Grid>
                          <div className={classes.partition}></div>
                          <Grid item xs={12}>
                            <Box className={classes.pickerBox}><Typography className={classes.pickerText}>Secondary color</Typography></Box>
                            <ColorPicker name="secondary_color"
                              onChange={(e: any) => { this.handleSecondColorChange(e); handleChange({ target: { name: 'secondary_color', value: e } }) }}
                            />
                          </Grid>
                          <div className={classes.partition}></div>
                          <Grid item xs={12} style={{ display: "grid", gap: "5px" }}>
                            <Box className={classes.uploadDiv}>
                              <input
                                type="file"
                                name="theme_image"
                                accept=".png, .svg, .jpg, image/png, image/svg+xml, image/jpeg"
                                onBlur={handleBlur}
                                onChange={(e) => { this.handleImageChange(e); handleChange(e) }}
                                style={{ display: 'none' }}
                                ref={this.state.fileInput}
                              />
                              <Button className={classes.avatarStyle} onClick={this.handleButtonClick} startIcon={
                                <Avatar
                                  className={classes.avatarImg}
                                  src={imgUpload}
                                />
                              }> </Button>
                              <Typography>{this.getFileName()}</Typography>
                            </Box>
                            <Typography className={classes.uploadSub}>Image should be in png, jpg or svg format</Typography> </Grid>
                        </>}


                        {this.state.themeTypeSelect && <RectangleRow rectangles={rectangles} handleColor={this.defaultColor} />}
                      </Grid>

                      <Grid style={{ display: "grid", padding: "0 20px", gap: "20px",marginBottom:"20px" }}>
                        <Typography className={classes.themePreview} data-test-id = "previewTheme" onClick={()=>this.previewTheme(values?.primary_color,values?.secondary_color)} style={{color:this.state.secondaryColor}}>Preview theme</Typography>

                        <Button
                          id="btnforFormSubmit"
                          className={classes.saveBtn}
                          style={{background:this.state.primaryColor}}
                          type="submit"
                          disableElevation
                        >
                          Save changes
                        </Button>
                      </Grid>
                    </Box>

                  </Box>
                </form>


              )}
            </Formik>
          </div>}


          {this.state.showAlertPop &&
              <Alert style={{ ...webStyle.alertDivs, position: "absolute" }} icon={false} severity="success">
                <div style={webStyle.messageDivs} >
                  <img src={imgSuccess} style={{ verticalAlign: "bottom" }} />
                  <Typography variant="body1">{this.state.alertMessage}</Typography>
                </div>
              </Alert>}

</Box>
        </Container>
      </ThemeProvider>
    );
  
  }
}

const webStyle = {
  mainDiv:{
    display: "flex",
    width: "1234px",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    marginTop: "38px",
    marginLeft: "38px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1"
  },
  themeView:{
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px"
  },
welcomeText:{
  color: "#000",
  fontFamily: "Inter",
  fontSize: "36px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "44px", /* 122.222% */
  letterSpacing: "-0.36px",
},
titleText:{
  color: "#475569",
  fontFamily: "Inter",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "150%",
},
redirection: {
  color: "#0F172A",
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px",
  textDecoration: "none",
  letterSpacing: "-0.12px",
  '&:hover': {
    textDecoration: "none",
  },
},
currentPage: {
  // color: updateColor,
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px",
  textDecoration: "none",
  letterSpacing: "-0.12px"
},
mainBtn:{
  display: "flex",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flex: "1 0 0",
  alignSelf: "stretch",
  borderRadius: "8px",
  background: "#095BA6",
  width: "360px",
  height: "56px",
  color: "#fff",
  textTransform: "capitalize" as const
},
  avatarStyle: {
    padding: "16px",
    borderRadius: "8px",
    background: "#F6F2EF",
    '& .MuiButton-startIcon': {
      margin: 0
    },
    '& .MuiAvatar-root': {
      width: "24px",
      height: "24px"
    }
  },
  patition:{ border: "1px solid #E2E8F0", width: "100%" },
  radioBtn:{
    border: "1px solid #CBD5E1",
    margin: 0,
    borderRadius: "50px",
    width: "150px",
    height: "36px",
  },
  messageDivs: { display: "flex", gap: "8px" },
  mainHead:{ display: "flex", width: "93.5%", padding: "24px 40px 28px 40px", borderBottom: "1px solid #CBD5E1", justifyContent: "space-between" },
  subHeading:{
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal", width: "302px",
    flexShrink: 0,
    fontWeight: 700,
    lineHeight: "28px",
  },
  pickerText:{
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "28px"
  },
  pickerBox:{
    display: "flex",
    padding: "10px",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "6px",
    background: "#F6F2EF",
    alignSelf: "stretch",
  },
  uploadSub:{
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  alertDivs: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    transform: "translate(-50%, 50%)",
    top: "80%",
    left: "50%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  uploadDiv:{
    display: "flex",
    alignItems: "center",
    gap: "16px",
    alignSelf: "stretch", borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF"
  },
  editBlock:{
    display: "flex",
    width: "803px",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1"
  },
  themePreview: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center" as const,
    color: "#E57727",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "initial" as const,
  },
  themeSelect:{
    width: "400px",
    height: "auto",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    flexShrink: 0
  },
  subheadDiv:{
    display: "flex",
    padding: "24px 16px 28px 40px",
    alignItems: "center" as const,
    borderRadius: "8px 8px 0px 0px",
    borderBottom: "1px solid #E2E8F0",
    background: "#FFF",
  },
  saveBtn: {
    textTransform: "initial" as const,
    width: "100%",
    height: "56px",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    background: "#E57727",
    display: "flex",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
    "&:hover": {
      background: "#E57727"
    }
  },
  editDiv:{
    display: "flex",
    padding: "24px 461px 28px 40px",
    alignItems: "center" as const,
    borderRadius: "8px 8px 0px 0px",
    borderBottom: "1px solid #E2E8F0",
    background: "#FFF",
  },
  logoDiv:{
    display: "flex",
    padding: "20px",
    alignItems: "center" as const,
    gap: "30px",
    alignSelf: "stretch",
  },
  radioH:{
    "& .MuiTypography-body1": {
      fontSize: "16px !important",
      color: "#0F172A",
      fontFamily: "Inter",
      lineHeight: "24px",     
  },
  '& .MuiRadio-root.Mui-checked': {
    color: '#e87242',
  },
  '& .MuiRadio-root:not(.Mui-checked)': {
    color: 'grey !important',
  },
},

  avatarImg: {
    '& .MuiAvatar-root': {
      width: "24px",
      height: "24px"
    },
  },
  searchStyle:{    display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  transform: "translate(-50%,50%)",
  position: "relative" as const,
  top: "15%",
  left: "50%",
  fontSize: "24px",
  color: "#0F172A",
  textTransform: "capitalize" as const,
  fontFamily: "Inter",
  fontWeight: 700,}

};
export default withStyles(webStyle)(ThemeBlock)
// Customizable Area End