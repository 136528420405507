import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ChangeEvent } from "react";
import Papa from 'papaparse';
import { parseString } from 'xml2js';
interface ApiParams {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: string | object | FormData;
  token?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openDialog: boolean;
  inputValue?: string[];
  insertValues?: string[];
  dialogReasons?: string;
  columnNames?: string[]
  btnOkText: string;
  handleFindText?: Function;
  handleInputChange?: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
  handleOk: Function;
  handleRadio?: Function;
  btnCancelText?: string;
  handleCancel: Function;
  headingText: string;
  subHeading?: string;
  typeSelected?: boolean
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  xmlFiles: string;
  next: boolean;
  showCreateDialog: boolean;
  typeSelected: boolean;
  stringtypeSelected: string;
  dialogReason: number;
  xmlFileName: string;
  columnNames: string[];
  inputValues: string[];
  insertValues: string[],
  fileURL: string;
  fileId: string;
  errors: string;
  dialogFind: string
  dialogValue: string;
  showFindDialog: boolean;
  showInsertDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class XmlcsvtemplatebuilderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createFileApi: string = "";
  insertCsvFileApi: string = "";
  insertXmlFileApi: string = "";
  findFileApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      next: false,
      typeSelected: false,
      stringtypeSelected: "",
      xmlFiles: "",
      dialogReason: 0,
      dialogFind: "",
      showCreateDialog: false,
      showFindDialog: false,
      xmlFileName: "",
      inputValues: [],
      insertValues: [],
      columnNames: [],
      errors: "",
      dialogValue: "",
      fileURL: "",
      fileId: "",
      showInsertDialog: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.createFileApi || apiRequestCallId === this.insertCsvFileApi || apiRequestCallId === this.insertXmlFileApi) {
        if (responseJson.data) {
          const file = responseJson.data.attributes.document.url;

          this.setState({
            fileURL: file, fileId: responseJson.data.id,
            xmlFileName: responseJson.data.attributes.document.filename, errors: ""
          })
          this.parseColumns(file);

        } else {
          this.setState({ errors: responseJson.meta.message, xmlFileName: "" })
        }

      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.findFileApi != null &&
      this.findFileApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson.data) {
        const file = responseJson.data.attributes.document.url;

        this.setState({
          fileURL: file, fileId: responseJson.data.id,
          xmlFileName: responseJson.data.attributes.document.filename, errors: ""
        })
      } else {
        this.setState({ errors: responseJson.meta.message, xmlFileName: "" })
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };
  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleRadio = (value: string) => {
    this.setState({ typeSelected: !this.state.typeSelected, stringtypeSelected: value })
  }
  handleChangeReason = (value: string) => {
    const inputValue = value;
    const numericValue = inputValue === "" ? 0 : parseFloat(inputValue);
    this.setState({
      dialogReason: numericValue
    })
  }
  handleFindName = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[a-zA-Z0-9.]+$/.test(value) || value === "") {
      this.setState({
        dialogFind: value
      })
    }
  }
  handleInputChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
      this.setState(prevState => {
        const newValues = [...prevState.inputValues];
        newValues[index] = value;
        return { inputValues: newValues };
      });
    }

  };
  handleInsertChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value;
    if (/^[a-zA-Z0-9\s]+$/.test(value) || value === "") {
      this.setState(prevState => {
        const newValues = [...prevState.insertValues];
        newValues[index] = value;
        return { insertValues: newValues };
      });
    }

  };
  onNext = () => {
    this.setState({ next: !this.state.next })
  }
  changeOK = () => {
    this.setState({
      showCreateDialog: false,
      inputValues: []
    })
    this.createFile(this.state.inputValues)
  };
  findOK = () => {
    this.setState({
      showFindDialog: false,
      dialogFind: ""
    })
    this.findFileApiCall(this.state.dialogFind)
  }
  insertChangeOK = () => {
    this.setState({
      showInsertDialog: false,
      insertValues: []
    })
    if (this.state.typeSelected) {
      this.InsertXmlApiCall(this.state.insertValues)
    } else {
      this.InsertCsvApiCall(this.state.insertValues)
    }
  }
  parseColumns = (data: string) => {
    if (this.state.typeSelected) {
      this.parseXml(data)
    } else {
      this.parseCsv(data)
    }
  }
  formDataFormat = (values: string[]) => {
    const formData = new FormData();
    const temp = [...values]

    temp.forEach((item: string, index: number) => {
      formData.append(`column_names[]`, item);
    });

    this.state.typeSelected ? formData.append(`xmlcsv[xml]`, "true") : formData.append(`xmlcsv[xml]`, "false")
    this.state.typeSelected ? formData.append(`xmlcsv[csv]`, "false") : formData.append(`xmlcsv[csv]`, "true")
    return formData;
  }
  createFile = (values: string[]) => {
    const formData = this.formDataFormat(values);
    this.CreateApiCall(formData)
  }
  async CreateApiCall(data: object) {
    this.createFileApi = await this.apiCalls({
      method: 'POST',
      endPoint: '/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts',
      body: data
    })
  }
  async InsertCsvApiCall(value: string[]) {
    let dataCSV = {
      "new_data_row": value
    }
    this.insertCsvFileApi = await this.apiCalls({
      contentType: 'application/json',
      method: 'PUT',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/update_csvfile?id=${this.state.fileId}`,
      body: JSON.stringify(dataCSV)
    })
  }
  async InsertXmlApiCall(value: string[]) {

    let dataXML = {
      "data":
      {
        "insert_data": true,
        "columns": this.state.columnNames,
        "value": value
      }
    }

    this.insertXmlFileApi = await this.apiCalls({
      contentType: 'application/json',
      method: 'PUT',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/${this.state.fileId}`,
      body: JSON.stringify(dataXML)
    })
  }
  async findFileApiCall(value: string) {

    this.findFileApi = await this.apiCalls({
      method: 'GET',
      endPoint: `/bx_block_xmlcsvtemplatebuilder/xmlcsv_converts/xml_csv_file_list?file_name=${value}`,
    })
  }

  handleColumnData = (columnData: string[]) => {
    this.setState({ columnNames: columnData })
  }
  closePopup = () => {
    this.setState({ xmlFileName: "", errors: "" })
  }

  handleCreateDialog = () => {
    this.setState({ showCreateDialog: false })
    this.setState({ insertValues: [], inputValues: [], dialogFind: "", dialogReason: 0, next: false })
  }
  handleInsertDialog = () => {
    this.setState({ showCreateDialog: false })
    this.setState({ insertValues: [], inputValues: [], dialogFind: "", dialogReason: 0, next: false })
  }
  handleFindDialog = () => {
    this.setState({ showCreateDialog: false })
    this.setState({ insertValues: [], inputValues: [], dialogFind: "", dialogReason: 0, next: false })
  }
  reset = () => {
    this.setState({ insertValues: [], inputValues: [], dialogFind: "", dialogReason: 0, next: false })
  }
  buttonClicked = (type: string) => {
    const keyName = `show${type.charAt(0).toUpperCase()}${type.slice(1)}Dialog`;
    this.setState({
      ...this.state,
      [keyName]: true
    });
    this.setState({ errors: "", xmlFileName: "" })
  }
  handleSaveButtonClick = () => {
    this.downloadFile(this.state.fileURL, this.state.xmlFileName);
  }

  downloadFile = (fileURL: string, fileName: string) => {
    if (typeof XMLHttpRequest !== 'undefined') {
      let xhrRequest = new XMLHttpRequest();

      xhrRequest.onreadystatechange = () => {
        if (xhrRequest.readyState === 4 && xhrRequest.status === 200) {
          const blob = new Blob([xhrRequest.response], { type: 'application/octet-stream' });
          const urlFile = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = urlFile;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(urlFile);
        }
      };

      xhrRequest.open('GET', fileURL, true);
      xhrRequest.responseType = 'blob';
      xhrRequest.send();
    } else {
      console.error('XMLHttpRequest is not supported in this environment.');
    }
  };


  parseCsv = (fileUrl: string) => {
    let xhrRequest = new XMLHttpRequest();

    xhrRequest.onreadystatechange = () => {
      if (xhrRequest.readyState === 4) {
        if (xhrRequest.status === 200) {
          const csvData = xhrRequest.responseText;
          const parsedData = Papa.parse(csvData, { header: true });
          const extractedColumnNames = parsedData.meta.fields ?? [];
          this.setState({ columnNames: extractedColumnNames });
        } else {
          console.error('Error fetching CSV:', xhrRequest.statusText);
        }
      }
    };

    xhrRequest.open("GET", fileUrl, true);
    xhrRequest.send();

  };

  parseXml = (fileUrl: string) => {
    let xhrRequest = new XMLHttpRequest();

    xhrRequest.onreadystatechange = () => {
      if (xhrRequest.readyState === 4) { // Request completed
        if (xhrRequest.status === 200) { // Successfully retrieved the data
          const xmlData = xhrRequest.responseText;
          parseString(xmlData, (error, result) => {
            if (error) {
              console.error(error);
              return;
            }
            const columnHeaders = Object.keys(result.root.row[0].column[0]);
            this.setState({ columnNames: columnHeaders });
          });
        } else {
          console.error('Error fetching XML:', xhrRequest.statusText);
        }
      }
    };

    xhrRequest.open("GET", fileUrl, true);
    xhrRequest.send();
  }

  apiCalls = async (data: ApiParams) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}
