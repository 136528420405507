import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Breadcrumbnavigation2Controller, {
  Props,
} from "./Breadcrumbnavigation2Controller.web";

export default class Breadcrumbnavigation2 extends Breadcrumbnavigation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            {this.state.account && <Typography>
              {BreadCrumb()}
            </Typography>}
            {this.state.selected && <Typography>
              {BreadCrumbTaxUpdate()}
            </Typography>}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

const BreadCrumb = () => {
  return (
    <React.Fragment>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/LandingPage">Landing</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/Invoice">Invoice</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/PeopleWeb">PeopleWeb</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/AccountCreation">AccountCreation</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Selected
          </li>
        </ol>
      </nav>
    </React.Fragment>
  );
}


const BreadCrumbTaxUpdate = () => {
  return (
    <React.Fragment>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/PeopleWeb">People</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to="/TaxUpdate">Tax Update</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/Profile">Profile</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/Documents">Documents</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Selected
          </li>
        </ol>
      </nav>
    </React.Fragment>
  );
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
