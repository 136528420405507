import React from "react";

// Customizable Area Start
import {
    Modal,
    Container,
    Box,
    Button,
    Typography,
    Input,
    TextField,
    InputAdornment,
    Link,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { linkimg, pdfDOC, profile, sendIcon } from "./assets"
const theme = createTheme({
    palette: {
        primary: {
            main: "#CBD5E1",
            contrastText: "#fff",
        },
    },



});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./Chat1Controller.web";
import { Add, KeyboardArrowRight, MicNoneRounded, MoreVert, PhoneOutlined, SearchOutlined, Send, VideoCallOutlined } from "@material-ui/icons";


export default class Chat extends ChatController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { messages, newMessage} = this.state;
      
       
        return (
            <ThemeProvider theme={theme}>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", margin: "auto", gap: "20px", padding: "20px 20px", height: '895px', width: "1244px" }}>
                    <Box className="clientListforChat" sx={webStyles.chatLIstBox}>

                        <Box style={{ display: 'flex', justifyContent: "center", alignItems: 'cener', width: "100%", paddingTop: "20px" }}>
                            <TextField
                                type="search"
                                id="search"

                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined style={{ width: "1.5rem", height: "1.5rem" }} />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        "display": "flex", "width": "339px", "height": "60px", "alignItems": "center", outline: "none", border: "none",
                                        "flexShrink": 0, "borderRadius": "0.5rem", "background": "#FFF", padding: ".625rem 0.5rem .625rem 0.5rem"
                                    },
                                    inputProps: {
                                        style: {
                                            padding: 0, "fontSize": "16px", "lineHeight": "24px", "fontWeight": 400, "fontFamily": "inter",
                                            border: "none"

                                        } as React.CSSProperties,
                                    },


                                }}
                                style={{ fontFamily: "inter", border: "1px solid #CBD5E1", borderRadius: "8px", backgroundColor: "#fff" }}

                                placeholder="Search"
                                variant="outlined"
                                color="primary"

                                //  className={classes.searchBar}
                                // value={this.state.searchText}
                                // onChange={(searchValue) => this.requestSearch(searchValue)}
                                InputLabelProps={{
                                    style: {
                                        fontSize: '1rem', fontWeight: 400, lineHeight: "1.5rem", fontFamily: "Inter", color: "#94A3B8",
                                    },
                                }}
                            />
                        </Box>

                        <Box sx={webStyles.commonBox}>
                            <img src={profile} style={webStyles.profilePic} />
                            <Box>
                                <Box sx={webStyles.box1}>
                                    <Typography style={webStyles.text1}>Elisa Samuel</Typography>
                                    <Typography style={webStyles.text3}>5min</Typography>

                                </Box>
                                <Box style={webStyles.box1}>

                                    <Typography style={webStyles.text2}>I’ll send you later you can...</Typography>
                                    <Typography style={webStyles.redDot}>3</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="chatviewWithprofile" style={{
                        "width": "844px",
                        "height": "891px",
                        display: 'flex'
                    }}>
                        <div style={this.state.showProfile ? webStyles.chatView1 : webStyles.chatView} >
                            <Box sx={this.state.showProfile ? webStyles.topbarProfile1 : webStyles.topbarProfile}>
                                <Box sx={webStyles.profileBox}>
                                    <img src={profile} style={webStyles.profilePic} />
                                    <Typography data-test-id="profilename" style={webStyles.profileName}>Elisa Samual</Typography>
                                </Box>
                                <MoreVert style={{ width: '2rem', height: "2rem", marginRight: "20px", cursor: 'pointer' }} onClick={this.showModal} data-test-id="showViewModal" />
                            </Box>
                            {this.state.isVisibleModal &&
                                <Box sx={webStyles.boxMOdal}>
                                    <Button style={webStyles.btnModal} onClick={this.handleShowProfile} data-test-id="viewProfilebtn"> <Typography style={webStyles.btnmodalText}>View Contact</Typography></Button>
                                    <Button style={webStyles.btnModal}> <Typography style={webStyles.btnmodalText}>Mute notifications</Typography></Button>
                                    <Button style={webStyles.btnModal}><Typography style={webStyles.btnmodalText}> Search messages</Typography></Button>
                                </Box>}


                            <div ref={this.scrollViewRef} style={{ height: "730px", overflowY: '-moz-initial', overflowX: "hidden" }}>
                                {messages.map((message: any) => (
                                    <Box key={message.id} style={{ margin: '20px 20px 20px 20px', display: 'flex', alignItems: 'flex-end', flexDirection: "column" }}>
                                        <Box sx={webStyles.userSenmsgBox} style={{ padding: "20px 13px", display: "flex", alignItems: "center" }}>
                                            <Typography style={webStyles.uesrmsgText}>  {`${message.type === 'text' ? message.content : ''}`}</Typography>
                                            {message.type === 'image' && (
                                                <Box sx={webStyles.pdfdocBox}>
                                                    <img
                                                        src={URL.createObjectURL(message.file)}
                                                        alt="user"
                                                        style={{ width: '60px', height: '60px', background: "#fff" }}
                                                    />
                                                    <Typography style={webStyles.uesrmsgText}>{message.file.name}</Typography>
                                                </Box>
                                            )}
                                            {message.type === 'application/pdf' && (
                                                <Box sx={webStyles.pdfdocBox}>
                                                    <img
                                                        src={pdfDOC}
                                                        alt="user"
                                                        style={{ width: '60px', height: '60px', background: "#fff" }}
                                                    />
                                                    <Typography style={webStyles.uesrmsgText}>{message.file.name}</Typography>
                                                </Box>

                                            )}



                                        </Box>

                                        <Typography style={webStyles.timeStamp}>{` ${this.formatAMPM(message.timestamp)}`}</Typography>

                                    </Box>
                                ))}
                            </div>

                            <Box className="bottomInput" style={{ width: "100%", position: 'absolute', bottom: "21px", display: 'flex', justifyContent: "center", gap: "10px", }}>
                                <Button data-test-id="fileSelect" style={webStyles.addInput} onClick={() => this.fileInput?.current?.click()} ><Add style={{ color: "#396178" }} /></Button>
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={this.fileInput}
                                    onChange={this.handleFileChange}
                                    name="profile"
                                    data-test-id="fileupload"
                                    accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                />
                                <TextField
                                    type="text"
                                    id="sendMsg"

                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Box >
                                                    <MicNoneRounded style={{ width: "1.75rem", height: "1.75rem", opacity: '0.5', marginRight: '20px', cursor: 'pointer' }} />
                                                </Box>
                                                <Box onClick={this.sendMessage} data-test-id="sendMsg">
                                                    <img src={sendIcon} style={{ width: "2rem", height: '2rem', cursor: 'pointer' }} />
                                                </Box>
                                            </InputAdornment>
                                        ),
                                        style: {
                                            "display": "flex",
                                            // "width": "732px",
                                            width: '100%',
                                            "height": "60px",
                                            "padding": "10px 8px",
                                            "justifyContent": "center",
                                            "alignItems": "center",
                                            "gap": "8px",

                                            "borderRadius": "10px",

                                            "background": "#FFF",

                                        },
                                        inputProps: {
                                            style: {
                                                padding: 0, "fontSize": "16px", "lineHeight": "24px", "fontWeight": 400, "fontFamily": "inter",
                                                border: "none"

                                            } as React.CSSProperties,
                                        },


                                    }}
                                    style={{ fontFamily: "inter", borderRadius: "8px", backgroundColor: "#fff", flex: 1, marginRight: '20px' }}

                                    placeholder="Type a message"
                                    variant="outlined"
                                    color="primary"
                                    data-test-id="txtInputname"

                                    //  className={classes.searchBar}
                                    value={newMessage}
                                    onChange={(e) => this.setState({ newMessage: e.target.value })}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '1rem', fontWeight: 400, lineHeight: "1.5rem", fontFamily: "Inter", color: "#94A3B8",
                                        },
                                    }}
                                />

                            </Box>
                        </div>
                        {this.state.showProfile &&
                            <Box className="profileDetail" sx={webStyles.profilDetailBox}>
                                <Box sx={webStyles.profileImg}>
                                    <img src={profile} style={{
                                        "display": "flex",
                                        "width": "140px",
                                        "height": "140px",
                                        "justifyContent": "center",
                                        "alignItems": "center",

                                    }} />
                                    <Typography style={webStyles.ProfileName1}>Elisa Samual</Typography>
                                    <Typography style={webStyles.profileLable2}>@ElisaSamual</Typography>
                                    <Box style={{ display: "flex", gap: '1.5rem', alignItems: 'center', marginTop: '20px' }}>
                                        <Button style={webStyles.viewProfilebtn} onClick={this.handleView} data-test-id="hideviewProfile" >View Profile</Button>
                                        <PhoneOutlined style={{ width: "26px", height: '26px', color: "#64748B" }} />
                                        <VideoCallOutlined style={{ width: "26px", height: '26px', color: "#64748B" }} />
                                    </Box>
                                </Box>
                                <Box className="aboutDocBox" style={{ display: 'flex', flexDirection: "column", gap: "20px" }}>
                                    <Box style={{ padding: "0 24px 0 20px", marginTop: "40px" }} >
                                        <Typography style={webStyles.about}>
                                            About
                                        </Typography>
                                        <Typography style={webStyles.aboutDescr}>It is a long established fact that a reader will be distracted by the readable
                                            content of a page when looking at its layout.</Typography>
                                    </Box>
                                    <Box style={{ padding: "0 24px 0 20px", }} >
                                        <Box sx={webStyles.DocBoxHead}>
                                            <Typography style={webStyles.about}>
                                                Media
                                            </Typography>
                                            <KeyboardArrowRight />
                                        </Box>
                                        <img src={linkimg} style={{ width: "82px", height: "55px" }} />
                                    </Box>
                                    <Box style={{ padding: "0 24px 0 20px", }} >
                                        <Box sx={webStyles.DocBoxHead}>
                                            <Typography style={webStyles.about}>
                                                Document
                                            </Typography>
                                            <KeyboardArrowRight />
                                        </Box>
                                        <img src={pdfDOC} style={{ width: "60px", height: "60px" }} />
                                    </Box>
                                    <Box style={{ padding: "0 24px 0 20px", }} >
                                        <Box sx={webStyles.DocBoxHead}>
                                            <Typography style={webStyles.about}>
                                                Link
                                            </Typography>
                                            <KeyboardArrowRight />
                                        </Box>
                                        <img src={linkimg} style={{ width: "82px", height: "55px" }} />
                                        <Link><Typography style={webStyles.link}>https://www.drupal.org/project/dummy_link</Typography></Link>
                                    </Box>

                                </Box>
                            </Box>}
                    </Box>
                </Box>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyles: any = {
    chatLIstBox: {
        "width": "380px",
        "height": "891px",
        "flexShrink": "0",
        "borderRadius": "10px",
        "border": "1px solid #CBD5E1",


    },
    chatView: {
        // "width": "844px",
        "height": "891px",
        "flexShrink": "0",
        "borderRadius": "10px",
        "border": "1px solid  #94A3B8",

        "background": "#F8F8F8",

        position: 'relative',
        flex: 2

    },
    chatView1: {

        "height": "891px",
        "flexShrink": "0",
        "borderRadius": "10px 0px 0px 10px",
        "border": "1px solid  #94A3B8",

        "background": "#F8F8F8",

        position: 'relative',
        flex: 2

    },
    profilePic: {
        "display": "flex",
        "width": "56px",
        "height": "56px",
        "justifyContent": "center",
        "alignItems": "center",
        "flexShrink": "0",
        "borderRadius": "136px",
        "border": "2px solid  #F0E5FF)",
        "background": "#FFF"
    },
    redDot: {
        "display": "flex",
        "width": "22px",
        "height": "22px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "7px",
        "flexShrink": "0",
        "borderRadius": "16px",
        "background": "#DC2626",
        color: "#fff",
        fontSize: "12px"
    },
    commonBox: {
        display: "flex",

        alignItems: "center",
        gap: "20px",
        padding: "20px",
        cursor: "pointer"

    },
    text1: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "18px",
        "fontStyle": "normal",
        "fontWeight": "500",
        "lineHeight": "28px"
    }
    ,
    text2: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "600",
        "lineHeight": "28px"
    },
    text3: {
        "color": "#94A3B8",
        "textAlign": "right",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "500",
        "lineHeight": "28px"
    },
    box1: { display: "flex", alignItems: "flex-start", justifyContent: "space-between" },
    topbarProfile: {

        width: '100%',
        "height": "74px",
        "flexShrink": "0",
        "borderRadius": "10px 10px 0px 0px",
        "border": "1px solid #CBD5E1",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

    },

    topbarProfile1: {

        width: '100%',
        "height": "74px",
        "flexShrink": "0",
        "borderRadius": "10px 0px 0px 0px",
        "border": "1px solid #CBD5E1",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

    },
    profileBox: {
        "display": "flex",
        alignItems: 'center',

        gap: "10px",
        marginLeft: '21px'

    },
    profileName: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "20px",
        "fontStyle": "normal",
        "fontWeight": "500",
        "lineHeight": "28px"
    },
    boxMOdal: {
        "display": "flex",
        "width": "192px",
        "padding": "4px",
        "flexDirection": "column",
        "alignItems": "center",
        "gap": "2px",
        "borderRadius": "8px",
        "border": "1px solid #E2E8F0",
        "background": "#FFF",
        position: "absolute",
        right: '33px',
        top: '57px'
    },
    btnModal: {
        "display": "flex",
        "height": "36px",
        "padding": "9px 20px 9px 16px",
        "alignItems": "center",
        "gap": "8px",
        "alignSelf": "stretch",
        "borderRadius": "8px",
        "background": "#FFF",
        justifyContent: 'flex-start'
    },
    btnmodalText: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "400",
        "lineHeight": "22px",
        textTransform: 'none'
    },
    profilDetailBox: {
        // "width": "320px",
        flex: 1.24,
        "height": "891px",
        "flexShrink": "0",
        "borderRadius": "0px 10px 10px 0px",
        "border": "1px solid  #CBD5E1"
    },
    profileImg: {
        "display": "flex",
        marginTop: "41px",
        "justifyContent": "center",
        "alignItems": "center",
        "flexShrink": "0",
        flexDirection: 'column',
        "background": "#FFF",

    },
    ProfileName1: {
        "color": "#0F172A",
        "textAlign": "center",
        "fontFamily": "Inter",
        "fontSize": "20px",
        "fontStyle": "normal",
        "fontWeight": "500",
        "lineHeight": "28px",
        marginTop: '20px'
    },
    profileLable2: {
        "color": "#64748B",
        "textAlign": "center",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "500",
        "lineHeight": "28px"
    },
    viewProfilebtn: {
        "display": "inline-flex",
        "height": "44px",
        "padding": "10px 16px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "flexShrink": "0",
        "borderRadius": "8px",
        "background": "#F1F5F9",
        textTransform: "none"
    },
    about: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "600",
        "lineHeight": "28px",

    },
    aboutDescr: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "400",
        "lineHeight": "28px"
    },
    userSenmsgBox: {
        "maxWidth": "448px",
        // "height": "50px",
        "flexShrink": "0",
        "borderRadius": "10px 0px 10px 10px",
        "background": "#396178"
    },
    uesrmsgText: {
        "color": "#FFFFFF",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "500",
        "lineHeight": "24px"
    },
    timeStamp: {
        "color": "#94A3B8",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "500",
        "lineHeight": "28px"
    },
    addInput: {
        "display": "flex",
        "width": "60px",
        "height": "60px",
        "padding": "10px 8px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "flexShrink": "0",
        "borderRadius": "10px",
        "border": "1px solid #CBD5E1",
        "background": "#FFF",
        marginLeft: '20px'
    },
    DocBoxHead: {
        display: "flex",
        justifyContent: 'space-between',
        marginBottom: "10px",
        cursor: 'pointer'
    },
    link: {
        "color": "#073F59",
        "fontFamily": "Inter",
        "fontSize": "10px",
        "fontStyle": "normal",
        "fontWeight": "400",
        "lineHeight": "28px"
    },
    pdfdocBox: {
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "gap": "1rem",
        "flexDirection": "column",
        "width": "200px"
    }
};
// Customizable Area End
