import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, CircularProgress, OutlinedInput, Grid, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, TextField, } from '@material-ui/core';
// import LabelWithIcon from './LabelWithIcon';
import Select from "react-select";
import { Close } from '@material-ui/icons';
import CustomInput from './CustomInput.web';
import { Formik, useFormik } from 'formik';
import * as Yup from "yup";


const useStyles = makeStyles({
    dialogWrapper: {
        "& [role='dialog']": {
            // gap: '40px',
            borderRadius: '8px',
            width: '600px',
            height:"271px"
        }
    },
    headingStyle: {
       
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "25px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px", /* 133.333% */
        letterSpacing: "-0.12px",

    },
    paraStyle: {
        textAlign: 'center',
    },
    btnWrapper: {
        justifyContent: 'flex-end',
        padding: "24px 24px 24px 320px",
        borderTop: "1px solid #E2E6F0",
        display: "inline-flex",
        alignItems: "flex-start",
        gap: "20px",
        flexShrink: 0,

        '& button': {
            padding: '16px 20px',
            width: '150px',
            height:"56px",
            fontWeight:700,
            fontSize:"16px",
            borderColor: '#E57727',
            borderRadius: '8px',
            cursor: "pointer",
        }
    },
    primaryBtnStyle: {
        background: "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
        fontWeight:700,
        "text-transform": "none",
        "color": "white"
    },
    cancelBtnStyle: {
        background: "linear-gradient(to bottom, red, #C87674)",
        "text-transform": "none",
        "color": "white",
        borderRadius: "10px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        // textAlign: "left",
        // fontSize: "16px",
        // fontWeight: 700,
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        // borderBottom: "1px solid #E2E8F0",
        // padding: "24px 16px 24px 40px",
    },
    subHeading: {
        // fontSize: "16px",
        // margin: "0 4px"
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "26px", /* 144.444% */
        marginBottom: "24px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    reasonsSection: {
        marginBottom: '20px'
    },
    downloaIcon: {
        cursor: "pointer",
        padding: "4px",
        width:"26px",
        height:"26px"

    },
    pushBackBtn: {
        border: '1px solid primary'
    },
    errorInputStyle: {
        border: '1px solid red'
    },
    cancelBtn: {
    color: '#E57727',
    background: "#F6F2EF !important",
    width:"85px !important",
    display: "flex",
    padding: "16px 15px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    textTransform: "capitalize"
}
})
const enumXmlCsv = {
    XML: "xml",
    CSV: "csv"
}

type LogoutBoxProps = {
    openDialog: boolean,
    headingText: string,
    btnCancelText: string,
    btnOkText: string,
    btnColor:string;
    textColor:string;
    handleOk: any;
    handleCancel: any;
    subHeading?: string;
    showDialogBtns?: boolean;
    showCrossBtn?: boolean;
}




const LogoutBox = (props: LogoutBoxProps) => {
    const classes = useStyles();
    const [reason, setReason] = useState("");
    const [error, setError] = useState(false);

    return (
        <>
            <Dialog
                open={props?.openDialog}
                onClose={props?.handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                disableBackdropClick={true}
                className={classes.dialogWrapper}
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
                    <div className={classes.dialogTitle}>
                       
                        {props.showCrossBtn ? (
                            <div style={{paddingTop:"16px",paddingRight:"16px"}}>
                        <Close
                            className={classes.downloaIcon}
                            onClick={props?.handleCancel}

                        />
                        </div>) : <div></div>}
                    </div>
                </DialogTitle>
                <DialogContent style={{ padding: "0px 40px" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        <span className={classes.headingStyle}>{props?.headingText}</span>
                    </div>
                </DialogContent>

                {props.showDialogBtns && <DialogActions className={classes.btnWrapper}>

                    <Button variant="outlined" style={{color:props.textColor,borderColor:props.textColor}} className={classes.cancelBtn}
                        onClick={() => {
                            setReason("");
                            // setSelectValue("");
                            props.handleCancel()
                        }}
                    >
                        {props.btnCancelText}
                    </Button>

                    <Button variant="contained"
                        style={{ borderRadius: "8px",background:props.btnColor, textTransform: "none",marginLeft:"0" }}
                        className={classes.primaryBtnStyle}
                        onClick={() => {
                            setReason("");
                            props?.handleOk()
                        }}
                        color="primary"
                    >
                        {props.btnOkText}
                    </Button>
                </DialogActions>}

            </Dialog>
        </>
    )
}

export default LogoutBox

