import React from "react";
// Customizable Area Start
import {
    Box,

    Container,

} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import PdfEditController, { Props } from "./PdfEditController";
import MUIRichTextEditor from "mui-rte";




// Customizable Area End



const theme = createTheme({
    palette: {
        primary: {
            main: "#396178",
            contrastText: "#fff",
        },
    },
});

export default class PdfEdit1 extends PdfEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {

        return (
            /* Customizable Area Start */
            <ThemeProvider theme={theme}>
                <Container style={{
                    "width": "1284px", display: "flex", justifyContent: "center",
                    padding: "36px 20px", gap: "31px", margin: 'auto', alignItems: "center", flexDirection: 'column'
                }}>

                    <Box sx={webStyle.leftBox}>
                        <MUIRichTextEditor
                              
                            label="Type something here..."
                            // onSave={save}
                            inlineToolbar={true}
                        />

                    </Box>
                </Container>
            </ThemeProvider>
            /* Customizable Area End */
        );
    }
}

// Customizable Area Start
const webStyle: any = {
    leftBox: {
        "display": "flex",
        "padding": "20px",

        "gap": "30px",
        "alignSelf": "stretch",
        "borderRadius": "8px 8px 32px 8px",
        "background": "#FFF",
        "height": "680px",
        width: "1194px",
        "border": "1px solid  #CBD5E1",
        flexDirection: "column"
    },

};
// Customizable Area End
