// Customizable Area Start
import React,{ChangeEvent} from "react";

import {
  Button,
  Grid,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  DialogActions,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { Close} from "@material-ui/icons";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import XmlcsvtemplatebuilderController, {
  Props,
} from "./XmlcsvtemplatebuilderController";

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'space-around',
  '& button': {
    padding: '13px 0px',
    width: '100px',
    borderColor: '#3b8da2',
    textTransform:"capitalize" as const,
    borderRadius: '15px',
  },
});
const StyledButton = styled(Button)({
  '&.primaryBtn': {
    background: "#25FA1",
    textTransform: "capitalize",
    color: "white",
    borderRadius: "8px",
  },
  '&.disableBtns': {
    color: "#fff",
    borderRadius: '8px',
    background: '#757575',
  },
});
const StyledDialog = styled(Dialog)({
  '& [role="dialog"]': {
    padding: '30px',
    borderRadius: '16px',
    width: '300px',
  },
});
export default class Insertdialogbox extends XmlcsvtemplatebuilderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
      
return (
    <div>
        <StyledDialog
        open={this.props.openDialog}
        onClose={()=>this.props.handleCancel("backdropClick")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
      >
        <DialogTitle id="alert-dialog-title">
           <div style={insertStyles.dialogTitles}>
                <div>
                    <span style={insertStyles.headingStyle}>{this.props.headingText}</span>
                    <span style={insertStyles.subHeader}>{this.props.subHeading}</span>
                </div>
            <Close
                    style={insertStyles.downloadIcon}
                    id="btnExample"
                    onClick={() => {
                      this.props.handleCancel()
                      this.reset()
                    }} 
                />
           </div>
        </DialogTitle>

     <DialogContent>
          
          <Grid id="renderInput" style={insertStyles.reasonSection} item xs={12}>
          {this.props.columnNames?.map((name:string, index:number) => (
                    <div key={index}>
                      <label style={{fontSize:"16px",fontWeight:700}}>{`Column name:- ${name}`}</label>
                      <OutlinedInput
                        id={`input-${index}`}
                        type="text"
                        style={{marginTop:"10px",marginBottom:"20px"}}
                        placeholder={`Enter value of column `}
                        fullWidth
                        value={this.state.insertValues ? this.state.insertValues[index]: ""}
                        color='primary'
                        onChange={(event:ChangeEvent<HTMLInputElement>) => {
                          this.props.handleInputChange && this.props.handleInputChange(event, index);
                        }} 
                      />
                    </div>
                  ))}
          </Grid>
       
       </DialogContent>
        
       <StyledDialogActions>
          <Button variant="outlined" id="Cancelbtn" style={insertStyles.btnClass} 
          onClick={() => {
            this.props.handleCancel();
            this.reset()
          }} 
          >
            {this.props.btnCancelText}
          </Button>
          
          <StyledButton
        variant="contained"
        id="submitBtn"
        disabled={this.props.insertValues?.length ==0}
        className='primaryBtn'
        onClick={() => {
          this.props.handleOk();
        }}
        color="primary"
        autoFocus
      >
        {this.props.btnOkText}
      </StyledButton>
        </StyledDialogActions>

      </StyledDialog>
    </div>
  )
 }
}


const insertStyles = {
    headingStyle: {
        '& h2': {
            fontWeight: 700,
            
        },
        fontSize: "16px"
    },
    btnClass:{
      color: '#757575',
      borderRadius: "8px",
      textTransform:"capitalize" as const,
      padding: '13px 0px',
      width: '100px',
      borderColor: '#3b8da2',
    },
    dialogTitles: {
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subHeader: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    reasonSection: {
        margin: '10px 0'
    },
    downloadIcon: {
        cursor: "pointer"
    },
}
  // Customizable Area End