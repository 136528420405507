import { Dialog, DialogTitle, Typography, makeStyles } from '@material-ui/core';
import { AddCircleOutline, Close } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface FileUploaderProps {
  onFileUpload: (files: File[]) => void;
  accept?: string | string[];
  multiple?: boolean;
    openDialog:boolean,
    headingText: string,
    handleCancel:any
}
const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            borderRadius: '8px',
            width: '600px'
        }
    },
    headingStyle: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    paraStyle:{
        textAlign: 'center',
    },
    btnWrapper:{
        justifyContent: 'end',
        padding: "24px 37px 24px 381px",
        borderTop: "1px solid #E2E6F0",
    
        '& button': {
            padding: '16px 20px',
            width: '182px',
            borderColor: "#E57727",
            fontWeight: 700,
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: "#E57727",
    },
      "text-transform": "none",
      "color": "white",
      cursor: "pointer"
    },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 40px",
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    reasonsSection: {
        marginBottom: '25px !important',
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    downloaIcon: {
        cursor: "pointer"
    },
    pushBackBtn: {
      border: '1px solid primary'
    },
    errorInputStyle:{
      border: '1px solid red'
    }
})

const FileUploader = (props:FileUploaderProps) => {
    const classes = useStyles();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    props.onFileUpload(acceptedFiles);
  }, [props.onFileUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: {
    'text/csv': ['.csv'],
    'application/xml': ['.xml'],
    'text/xml': ['.xml'],
  } ,multiple: false});

  return (
    <>
    <Dialog
    open={props?.openDialog}
    onClose={props?.handleCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="lg"
    disableBackdropClick={true}
    className={classes.dialogWrapper}
  >
    <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
       <div className={classes.dialogTitle}>
            <div>
                <span className={classes.headingStyle}>{props?.headingText}</span>
            </div>
            <Close
                className={classes.downloaIcon}
                // onClick={props?.handleCancel}
                onClick={() => {
                  props?.handleCancel()
                }} 
            />
       </div>
    </DialogTitle>
    <div style={{width:"86.5%",padding:"40px 40px 70px 40px"}}>
    <div {...getRootProps()} style={{ border: '2px dashed gray',borderRadius:"10px", padding: '20px', textAlign: 'center' }}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :(<>
          <img src={require("../../web/public/assets/add_notes.svg")}></img>
          <p style={{fontFamily: "Inter",
                      color:"#0F172A",
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      textAlign: "center",
}}>Select a CSV file to import</p>
          <p style={{fontFamily: "Inter",
                      color:"#334155",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "22px",
                      letterSpacing: "0px",
                      textAlign: "center",}}>Drag and drop the files here</p></>)
      }
       <Typography style={{display:"flex",textAlign:"center",justifyContent:"center",color:"#E57727"}}><AddCircleOutline style={{ marginRight: "8px" }} /> Browse file to upload</Typography>
        
    </div>
    </div>
    </Dialog>
    </>
  );
};

export default FileUploader;
