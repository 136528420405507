// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  classes:any;
  teamEmails:string
}

interface S {
  pdfList:string[];
  showLoader:boolean;
  modalPosition:any;
  selectedProjectId:string;
  isVisibleModal:boolean;
  token:string;
  InvoiceList:object[];
  InvoiceTemplateList:object[];
  openInvoiceForm:boolean
  alertMessage:string;
  showAlert:boolean;
  serviceList:string;
}

interface SS {
  id: any;
  // Customizable Area Star
}

export default class InvoiceTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  getInoviceApiCallId: string='';
  createInvoiceTemplateApi:string="";
  clientDataAPI:string="";
  invoiceListingAPI:string="";
  deleteInvoiceByIdAPI:string="";
  getServiceAPI:string="";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
  
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),

    ];

    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);

    this.state = {
      token:Parsed?.token,
      pdfList:[],
      showLoader:false,
      serviceList:"",
      modalPosition:{ top: 0, left: 0 },
      selectedProjectId:"",
      isVisibleModal:false,
      openInvoiceForm:false,
      alertMessage:"",
      showAlert:false,
      InvoiceList:[],
    InvoiceTemplateList:[
      {
        value: null,
        label: "Select",
        labelValue: ""
    },
    {
      value: 1,
      label: "pdf Edit",
      labelValue: "pdf_Edit"
  },
    {
      value: 2,
      label: "pdf Edit 1",
      labelValue: "pdf_Edit1"
  },
     ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }


   async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        runEngine.debugLog("API Message Recived", message);
  
        if(apiRequestCallId === this.invoiceListingAPI){
          const invoiceDetails = await responseJson.data;
  
          this.setState({InvoiceList:invoiceDetails});
          this.setState({ showLoader: false })
        }
  
        if(apiRequestCallId === this.createInvoiceTemplateApi){
            this.setState({alertMessage:responseJson.data ? responseJson.messages:responseJson.meta.errors,showAlert: true})
            this.invoiceListing()
          }
  
        if(apiRequestCallId=== this.deleteInvoiceByIdAPI){
            this.invoiceListing()
            this.setState({alertMessage:responseJson.meta?responseJson.meta.messages:responseJson.errors[0],showAlert: true}) 
        }
  
        if(apiRequestCallId === this.getServiceAPI){
          const newArray = responseJson.data.map((obj:any, index:any) => {
            const name = obj.attributes.name;
        
            return {
                value: index+1,
                label: name,
                labelValue: name
            };
        });
        newArray.unshift({
          value: null,
          label: "Select service",
          labelValue: ""
      });
        this.setState({ serviceList: newArray });
        }
      }
       
    }

  async componentDidMount(){
    this.invoiceListing()
    this.getService()
  }
  async componentDidUpdate() {
    if(this.state.showAlert){
      setTimeout(()=>{
      this.setState({showAlert:false})
    },5000)}
  } 

  openForm =()=>{
    this.setState({openInvoiceForm:true})
  }
  closeForm=()=>{
    this.setState({openInvoiceForm:false})
  }
  handleShowProfile = (event: any, id: any) => {
    console.log(event,"events");
    
    const rect = event?.currentTarget?.getBoundingClientRect();
    console.log(rect,"events");
    if (rect) {
      const { top, left } = rect;
      this.setState({
        isVisibleModal: !this.state.isVisibleModal,
        selectedProjectId: id,
        modalPosition: { top: top + 23, left: left - 185 },
      });
    } else {
      console.error('Event or currentTarget is undefined');
    }
  }
  
  invoiceFormData = (values:any) => {
    const formData = new FormData(); 
    const currentDate =new Date()
  
        formData.append(`invoice[product_name][]`,values.product_name )
        formData.append(`invoice[account_data]`,values.account_data )
        formData.append(`invoice[payment_method]`,values.payment_method )
        formData.append(`invoice[team_member]`,values.team_member )
        formData.append(`invoice[invoice_date]`,currentDate.toISOString())
        formData.append(`invoice[message_for_client]`,values.message_for_client )
        formData.append(`invoice[description]`,values.description )
        formData.append(`invoice[recurring]`,"false")
        formData.append(`invoice[tax]`,"12")
        
     return formData;
  }
  createInvoiceTemplate = async (data:any) => {
    const formData=this.invoiceFormData(data)
    console.log(data,"IvoiceTemplate");
    this.createInvoiceTemplateApi=await this.invoiceApiCall({
      method: 'POST',
      endPoint: `/bx_block_invoice/invoice`,
      body:formData,
      token:this.state.token
    })
    this.setState({openInvoiceForm:false});
  };
  formatDate=(dates:string)=>{
    const date = new Date(dates);
    const formattedDate = [
        date.toLocaleDateString('en-US', { day: '2-digit' }),
        date.toLocaleDateString('en-US', { month: 'long' }),
        date.toLocaleDateString('en-US', { year: 'numeric' })
    ].join(' ');

    return formattedDate
  }
  ClientData = async () => {
    this.clientDataAPI = await this.invoiceApiCall({
      method: "GET",
      endPoint: "/account_block/account_creations/people_list_by_filter?user_type=vincertax_employee",
      token: this.state.token,
    });
  };
  invoiceListing = async () => {
    this.setState({ showLoader: true })
    this.invoiceListingAPI = await this.invoiceApiCall({
      method: "GET",
      endPoint: "/bx_block_invoice/invoice",
      token: this.state.token,
    });
  };
  getService = async () => {
    this.getServiceAPI = await this.invoiceApiCall({
      method: "GET",
      endPoint: "/bx_block_catalogue/catalogues",
      token: this.state.token,
    });
  };

  deleteInvoiceById = async (id:any) => {
    this.deleteInvoiceByIdAPI = await this.invoiceApiCall({
      method: "DELETE",
      endPoint: `/bx_block_invoice/invoice/${id}`,
      token: this.state.token,
    });
  };

  
  invoiceApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const invoiceRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    invoiceRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    invoiceRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    invoiceRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      invoiceRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(invoiceRequestMessage.id, invoiceRequestMessage);
    return invoiceRequestMessage.messageId;
  };
}
// Customizable Area End