import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles({
  dialogWrapper:{
      "& [role='dialog']":{
          borderRadius: '8px',
          width: '600px'
      }
  },
  radioH:{
    "& .MuiTypography-body1": {
      fontSize: "14px !important",
      color: "#0F172A",
      fontFamily: "Inter",
      lineHeight: "24px",     
  },
  '& .MuiRadio-root.Mui-checked': {
    color: '#e87242',
    fontWeight:700
  },
  '& .MuiRadio-root:not(.Mui-checked)': {
    color: 'grey !important',
  },
},
  headingStyle: {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
  },
  paraStyle:{
      textAlign: 'center',
  },
  dialogTitle: {
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 16px 24px 40px",
},
  btnWrapper:{
      justifyContent: 'end',
      padding: "24px 37px 24px 381px",
      borderTop: "1px solid #E2E6F0",
  
      '& button': {
          padding: '16px 20px',
          width: '182px',
          borderColor: "#E57727",
          background: "#E57727",
          fontWeight: 700,
          borderRadius: '8px',
      }
  },
  primaryBtnStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: "#E57727",
  },
    "text-transform": "none",
    "color": "white",
    cursor: "pointer"
  },
  downloaIcon: {
      cursor: "pointer"
  },

})
const enumMode = {
    Current: "current",
    All: "all"
  }
  const enumType = {
    Spreadsheet: "spreadsheet",
    Plain: "plain"
  }
interface ExportDialogProps {
    openDialog:boolean,
    headingText: string,
    handleCancel:any,
    exportAsType:boolean,
    exportPageType:boolean,
    handleExportAs:any,
    handleExportPage:any
  }

const ExportDialog = (props: ExportDialogProps) => {
  const classes = useStyles();

  
    return (
      <Dialog
        open={props.openDialog}
        onClose={props.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
          <div className={classes.dialogTitle}>
            <div>
              <span className={classes.headingStyle}>{props.headingText}</span>
            </div>
            <Close className={classes.downloaIcon} onClick={props.handleCancel} />
          </div>
        </DialogTitle>
        <DialogContent style={{padding:"40px",display:"flex",flexDirection:"column",gap:"40px"}}>
            <div style={{display:"flex",flexDirection:"column"}}>
            <label style={{color: "#0F172A",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "24px"}}>Export:</label>
            
        <FormControl>
  <RadioGroup aria-labelledby="demo-row-radiobutton-group-label" name={"format"}
  className={classes.radioH}
  data-test-id="radioBtn"
    defaultValue={props.exportPageType ? enumMode.Current : enumMode.All}
    value={props.exportPageType ? enumMode.Current : enumMode.All}
    onChange={(event: any) => {
      props.handleExportPage()
    }}
  >
    <FormControlLabel checked={props.exportPageType === true} value={enumMode.Current} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1">
              Current page
            </Typography>
          } />
    <FormControlLabel checked={props.exportPageType === false} value={enumMode.All} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1">
              All products
            </Typography>
          } />
  </RadioGroup>
</FormControl>
</div>
<div style={{display:"flex",flexDirection:"column"}}>
    <label style={{color: "#0F172A",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "24px"}}>Export as:</label>

<FormControl>
  <RadioGroup aria-labelledby="demo-row-radiobutton-group-label" name={"format"}
  className={classes.radioH}
  data-test-id="radioBtn"
    defaultValue={props.exportAsType ? enumType.Spreadsheet : enumType.Plain}
    value={props.exportAsType ? enumType.Spreadsheet : enumType.Plain}
    onChange={(event: any) => {
      props.handleExportAs()
    }}
  >
    <FormControlLabel checked={props.exportAsType === true} value={enumType.Spreadsheet} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1">
              CSV for Excel, Numbers, or other spreadsheet programs
            </Typography>
          } />
    <FormControlLabel checked={props.exportAsType === false} value={enumType.Plain} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1">
              Plain CSV file
            </Typography>
          } />
  </RadioGroup>
</FormControl>
</div>

        </DialogContent>
        <DialogActions className={classes.btnWrapper}>

          <Button color="primary">
            Export
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ExportDialog;