import React from "react";
// Customizable Area Start
import {

    Button,
    FormControlLabel,
    Box,
    TextField,
    Card,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    Chip,
    Typography,
    Dialog,
    Divider

} from "@material-ui/core";



import { createTheme, ThemeProvider, } from "@material-ui/core/styles";
import { AddCircleOutlineRounded, CloseOutlined, RemoveCircleOutlineRounded } from "@material-ui/icons";


// Customizable Area End

import AccountCreationController, {
    Props
} from "./AccountCreationController.web";




const initialValues = {
    fullName: "",
    email: "",
    password: "",
    termCondition: false,
};
const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
export  default class AccountCreation extends AccountCreationController {



    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const names = [
            "Oliver Hansen",
            "Van Henry",
            "April Tucker",
            "Ralph Hubbard",
            "Omar Alexander",
            "Carlos Abbott",
            "Miriam Wagner",
            "Bradley Wilkerson",
            "Virginia Andrews",
            "Kelly Snyder",
        ];

        const { userdata, teamdata } = this.state


        return (
            /* Customizable Area Start */
            <ThemeProvider theme={theme}>


                <div className="container" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Inter" }}>

                    <div className="formcontainer" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", margin: "2rem" }}>


                        <Card style={{ width: "50%", backgroundColor: "#F4F6F9", padding: "1rem", }} data-test-id="card">
                            <form style={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#F4F6F9",
                                justifyContent: "center",
                                alignItems: "center",

                                padding: "20px",
                                margin: "auto"
                            }} onSubmit={(e) => this.handleSubmit(e)} data-test-id="form">
                                <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    {this.state.apiError[0]?.error && <p style={{ color: "#DC2626", padding: "1rem", backgroundColor: "#FEE2E2", borderLeft: "4px solid #DC2626", fontFamily: "inter" }}>{this.state.apiError[0]?.error}</p>}



                                    <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>Client Type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="selectedOption"
                                        value={userdata.selectedOption}
                                        onChange={this.handleChange}

                                    >
                                        {this.state.LoggedUserType === "vincertax_employee" ? <FormControlLabel value="white_lable_user" control={<Radio style={{ color: this.state.btnColor, fontFamily: "inter" }} />} checked={userdata.selectedOption === "white_lable_user"} label="Financial advisor" style={{ fontFamily: "inter" }} /> : null}
                                        <FormControlLabel value="client" control={<Radio style={{ color: this.state.btnColor, fontFamily: "inter" }} />} checked={userdata.selectedOption === "client"} label="Client" style={{ fontFamily: "inter" }} />
                                        {this.state.LoggedUserType === "vincertax_employee" ? <FormControlLabel value="vincertax_employee" control={<Radio style={{ color: this.state.btnColor, fontFamily: "inter" }} />} checked={userdata.selectedOption === "vincertax_employee"} label="Team member" style={{ fontFamily: "inter" }} /> : null}



                                    </RadioGroup>
                                    {this.state.errors.selectedOption && (
                                        <Typography style={{ color: 'red', marginTop: '5px' }}>{this.state.errors.selectedOption}</Typography>
                                    )}
                                </FormControl>

                                <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", marginBottom: "1rem", fontFamily: "inter" }}>Account Info</FormLabel>
                                    <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>Account Name*</FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        style={{ backgroundColor: "#ffffff", fontFamily: "inter" }}
                                        value={userdata.name}
                                        name="name"
                                        color="primary"
                                        data-test-id="txtInputname"

                                        onChange={(e) => this.handleChange(e)}
                                        fullWidth
                                        error={!!this.state.errors.name}



                                    />
                                    {this.state.errors.name && (
                                        < Typography style={{ color: 'red', marginTop: '5px' }}>{this.state.errors.name}</Typography>
                                    )}

                                </FormControl>
                                <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>Email*</FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={userdata.email}
                                        name="email"
                                        color="primary"
                                        data-test-id="emailid"
                                        style={{ backgroundColor: "#ffffff", fontFamily: "inter" }}
                                        onChange={(e) => this.handleChange(e)}
                                        fullWidth
                                        error={!!this.state.errors.email}



                                    />
                                    {this.state.errors.email && (
                                        <Typography style={{ color: 'red', marginTop: '5px' }}>{this.state.errors.email}</Typography>
                                    )}

                                </FormControl>
                                <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>Password*</FormLabel>
                                    <TextField
                                        type="password"
                                        variant="outlined"
                                        value={userdata.password}
                                        name="password"
                                        color="primary"
                                        data-test-id="pass"

                                        style={{ backgroundColor: "#ffffff", fontFamily: "inter" }}
                                        onChange={(e) => this.handleChange(e)}
                                        fullWidth
                                        error={!!this.state.errors.password}


                                    />
                                    {this.state.errors.password && (
                                        <Typography style={{ color: 'red', marginTop: '5px' }}>{this.state.errors.password}</Typography>
                                    )}

                                </FormControl>
                                <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>Tags</FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={userdata.tags}
                                        name="tags"
                                        color="primary"
                                        style={{ backgroundColor: "#ffffff", fontFamily: "inter" }}

                                        onChange={this.handleChange}
                                        fullWidth


                                    />

                                </FormControl>





                                {this.state.LoggedUserType === "vincertax_employee" ? <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    <FormLabel id="demo-multiple-chip-label" style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>Team Member</FormLabel>

                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        style={{ backgroundColor: "#ffffff", fontFamily: "inter" }}
                                        multiple
                                        variant="outlined"

                                        value={teamdata}
                                        onChange={(e: any) => this.handleTeam(e)}

                                        renderValue={(selected: any) => (
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                    rowGap: 2,

                                                }}
                                            >
                                                {selected.map((value: any) => (

                                                    <span style={{ display: "flex", flexDirection: "row", margin: "0 2px", fontFamily: "inter" }} key={value}>
                                                        <Chip

                                                            variant="default"
                                                            label={value}
                                                            style={{ marginRight: 3, backgroundColor: "#f8f4ec", color: "#e87424", fontFamily: "inter" }}
                                                        />
                                                    </span>
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {names.map((name) => (
                                            <MenuItem key={name} value={name} style={{ fontFamily: "inter" }}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> : null}
                                <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    <FormLabel id="template-type" style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }} >Folder Template</FormLabel>

                                    <Select
                                        id="template-type1"
                                        variant="outlined"
                                        name="template"
                                        displayEmpty
                                        placeholder="Select Option"

                                        value={userdata.template}
                                        style={{ backgroundColor: "#ffffff", fontFamily: "inter" }}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value="" disabled >
                                            <span style={{ opacity: 0.5, color: "#64748B", fontFamily: "inter" }}>Select template</span>
                                        </MenuItem>
                                        <MenuItem value={'Template1'}>Template1</MenuItem>
                                        <MenuItem value={'Template2'}>Template2</MenuItem>
                                        <MenuItem value={'Template3'}>Template3</MenuItem>
                                    </Select>


                                </FormControl>



                                <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>Office Locations</FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={userdata.location}
                                        style={{ backgroundColor: "#ffffff", fontFamily: "inter" }}
                                        name="location"
                                        color="primary"

                                        InputProps={{
                                            placeholder: "Select location",
                                            style: { color: '#64748B' }
                                        }}
                                        onChange={this.handleChange}
                                        fullWidth


                                    />

                                </FormControl>
                                <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>Business EIN</FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={userdata.ein}
                                        style={{ backgroundColor: "#ffffff" }}
                                        name="ein"
                                        color="primary"

                                        InputProps={{
                                            placeholder: "Business EIN",
                                            style: { color: '#64748B' }
                                        }}
                                        onChange={this.handleChange}
                                        fullWidth


                                    />

                                </FormControl>
                                <FormControl style={{ width: "90%", marginBottom: "20px" }}>
                                    <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>Business type</FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={userdata.btype}
                                        style={{ backgroundColor: "#ffffff", fontFamily: "inter" }}
                                        name="btype"
                                        color="primary"
                                        // label="Business type"
                                        InputProps={{
                                            placeholder: 'Business Type',
                                            style: { color: '#64748B' }
                                        }}
                                        onChange={this.handleChange}
                                        fullWidth


                                    />

                                </FormControl>
                         

                                {this.state.requiredfields.map((item: any, index: any) => (
                                    <FormControl key={index} style={{ width: "90%", marginBottom: "20px" }}>

                                        <FormLabel style={{ paddingBottom: "10px", fontWeight: 700, color: "#64748B", fontFamily: "inter" }}>{item.addfieldName}</FormLabel>
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            style={{ backgroundColor: "#ffffff", fontFamily: "inter" }}
                                            name={item.addfieldName}
                                            value={item.addfieldDescr}
                                            data-test-id={`addfield-${index}`}
                                            color="primary"
                                            InputProps={{
                                                placeholder: 'Business Type',
                                                style: { color: '#64748B' }
                                            }}
                                            // onChange={(e) => this.handleInputChange(key, e.target.value)}
                                            fullWidth


                                        />
                                        <RemoveCircleOutlineRounded onClick={() => this.handleDelete(index)}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                marginRight: "0.5rem",
                                                position: "absolute",
                                                right: "0px",
                                                cursor: "pointer"
                                            }}
                                            data-test-id="removeField"
                                        />
                                    </FormControl>
                                ))}



                                <div style={{ width: "90%", margin: "1rem", display: "flex", justifyContent: "flex-start", gap: "1rem" }}>
                                    <Button data-test-id="cancelbtn" variant="contained" style={{ backgroundColor: "#f8f4ec", color: this.state.textColor, textTransform: "none", padding: "0.8rem", fontFamily: "inter" }} onClick={this.handleCancel}>Cancel</Button>
                                    <Button data-test-id="fieldModalOpen" variant="contained" style={{ backgroundColor: "#f8f4ec", color: this.state.textColor, textTransform: "none", padding: "0.8rem", fontFamily: "inter" }} onClick={this.handleaddFieldModal}>
                                        <AddCircleOutlineRounded
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                marginRight: "0.5rem",
                                            }}
                                        />Add Field</Button>

                                    <Dialog
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",

                                            boxShadow:
                                                " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
                                        }}
                                        open={this.state.addFieildModal}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Card
                                            style={{
                                                maxWidth: "37.5rem",

                                                borderRadius: "0.5rem",
                                            }}
                                        >

                                            <div
                                                style={{
                                                    display: "flex",
                                                    height: "2rem",
                                                    padding: "1.5rem 1rem 1.5rem 2.5rem",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    alignSelf: "stretch",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: "1.5rem",
                                                        fontWeight: 700,
                                                        color: "#000000",
                                                    }}
                                                >

                                                    Add Field
                                                </p>
                                                <CloseOutlined onClick={this.handlecloseFieldModal1} style={{ cursor: "pointer" }} data-test-id="close"/>
                                            </div>

                                            <Divider />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    padding: "2.5rem 2.5rem",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    gap: "1rem",
                                                    alignSelf: "stretch",
                                                    width: "32.5rem",
                                                    // height: "11.3rem",
                                                }}
                                            >
                                                {this.state.duplicatekeyError && <Typography style={{ color: "red", fontSize: '12px', fontFamily: "inter" }}>{this.state.duplicatekeyError}</Typography>}
                                                <FormControl
                                                    style={{
                                                        // marginBottom: "0.94rem",
                                                        display: "flex",
                                                        width: "32.5rem",
                                                        height: "5.13rem",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                        // gap: "0.25rem",
                                                    }}
                                                >

                                                    <FormLabel
                                                        style={{
                                                            paddingBottom: "10px",
                                                            fontWeight: 700,
                                                            color: "#64748B",
                                                            fontSize: '14px',
                                                            lineHeight: '22px'
                                                        }}
                                                    >
                                                        Field Name
                                                    </FormLabel>
                                                    <TextField
                                                        type="text"
                                                        variant="outlined"
                                                        style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
                                                        name="new_column_name"
                                                        data-test-id="newField"
                                                        value={this.state.addfieldName}
                                                        // color="primary"
                                                        onChange={this.handleChangeAddFieldname}
                                                        InputProps={{
                                                            placeholder: "Enter Field Name",
                                                            style: { color: "#64748B" },
                                                        }}
                                                        fullWidth
                                                        error={this.state.addfieldError}
                                                    />
                                                    {this.state.addfieldError ? <Typography style={{ color: "red", fontSize: '12px', fontFamily: "inter", paddingBottom: "2rem" }}>Field name is required</Typography> : null}

                                                </FormControl>

                                                <FormControl
                                                    style={{

                                                        display: "flex",
                                                        width: "32.5rem",
                                                        height: "5.13rem",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",

                                                    }}
                                                >
                                                    <FormLabel
                                                        style={{
                                                            paddingBottom: "10px",
                                                            fontWeight: 700,
                                                            color: "#64748B",
                                                            fontSize: '14px',
                                                            lineHeight: '22px'
                                                        }}
                                                    >
                                                        Field value
                                                    </FormLabel>
                                                    <TextField
                                                        type="text"
                                                        variant="outlined"
                                                        style={{
                                                            "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF",


                                                        }}
                                                        name="new_data"
                                                        data-test-id="newFieldValue"
                                                        value={this.state.addfieldDescr}
                                                        onChange={this.handleChangeAddFieldDescr}
                                                        color="primary"
                                                        InputProps={{
                                                            placeholder: "Enter Description",
                                                            style: { color: "#64748B" },
                                                        }}
                                                        fullWidth
                                                        error={this.state.addfieldDesError}

                                                    />
                                                    {this.state.addfieldDesError ? <Typography style={{ color: "red", fontSize: '12px', fontFamily: "inter" }}>Field value is required</Typography> : null}

                                                </FormControl>

                                            </div>
                                            <Divider />
                                            <div
                                                style={{
                                                    display: "flex",

                                                    padding: "1.5rem 1.5rem 1.5rem 28.5rem",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                    alignSelf: "stretch",
                                                }}
                                            >
                                                <Button
                                                    data-test-id="addnewfield"
                                                    variant="contained"
                                                    style={{
                                                        display: "flex",
                                                        width: "7.5rem",
                                                        height: "3.5rem",
                                                        padding: "1rem 2.59375rem 1rem 2.46875rem",
                                                        borderRadius: "0.5rem",
                                                        backgroundColor: this.state.btnColor,
                                                        color: "#FFF",
                                                        fontWeight: 700,
                                                        fontSize: "1rem",
                                                        textTransform: "none"
                                                    }}
                                                    onClick={this.handleFieldError}
                                                >
                                                    Save
                                                </Button>

                                            </div>
                                        </Card>
                                    </Dialog>



                                    <Button data-test-id="accountCreate" variant="contained" style={{ backgroundColor: this.state.btnColor, color: "#ffffff", textTransform: "none", padding: "0.8rem", fontFamily: "inter" }} type="submit">Create Account</Button>
                                </div>
                            </form>

                        </Card>

                    </div>

                </div>

            </ThemeProvider>
            /* Customizable Area End */
        );
    }
}

const webStyles = {
    select: {
        padding: "11px !important"
    }
}
