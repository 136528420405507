import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ModelContext } from "../../../components/src/Context";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  project_Id: any,
  auditTrailData:any;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectnotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType: React.Context<any | undefined> = ModelContext;
  getshowAuditTrailApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.NavigationAudittrailMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      project_Id: null,
      auditTrailData:[]     
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
     
      let responsejson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getshowAuditTrailApiCallId) {
        this.getshowAuditTrailApiCallIdSQ(responsejson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }




  // Customizable Area Start
  token: string = "";
  getshowAuditTrailApiCallIdSQ = (responsejson:any) => {
    this.setState({auditTrailData:responsejson.projects.data})
  }


  showAuditTrail = async (project_id:any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.getshowAuditTrailApiCallId = await this.getAuditTrailapiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: `bx_block_profile/audit_trails?project_id=${project_id}`,
      token: this.token,
    });
  };
  getAuditTrailapiCall = async (data: any) => {
    const { methods, endPoint, token, body } = data;
    const Header = {
      "Content-Type": "application/json",
      token,
    };
    const apiRequestMessageAuditTrail = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageAuditTrail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageAuditTrail.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    body &&
    apiRequestMessageAuditTrail.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    apiRequestMessageAuditTrail.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    runEngine.sendMessage(
      apiRequestMessageAuditTrail.id,
      apiRequestMessageAuditTrail
    );
    return apiRequestMessageAuditTrail.messageId;
  }


  // Customizable Area End
}
