export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backarrow = require("../assets/backarrow.png");
export const cardblank = require("../assets/cardblank.png");
export const fillstar = require("../assets/fillstar.png");
export const empty = require("../assets/empty.png");
export const imgSuccess = require("../assets/image_.svg");
export const uploadImg = require("../assets/uploadImg.png");
export const imgPdf = require("../assets/image_pdf.png");
export const deleteImg = require("../assets/delete.svg");
export const upload = require("../assets/upload.svg");
