import React from "react";
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    Link,
    Typography,
    InputAdornment,
    IconButton,
    Checkbox
} from "@material-ui/core";
import { CheckCircle, VisibilityOff, Visibility, CancelRounded } from '@material-ui/icons';
import { createTheme ,withStyles} from "@material-ui/core/styles";
import { Formik } from "formik";
import { Alert } from "@material-ui/lab";
import * as Yup from "yup";

import CustomInput from "./CustomInput.web";
const initialValues = {
    fullName: "",
    email: "",
    password: "",
    termCondition: false,
};
const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const EmailRegisterForm = (props: any) => {
    const {classes}=props;

    return (
        <div data-test-id="emailRegistrationForm">

            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape(props.SignUpFormSchema())}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={async (values, actions) => {
                    props.signUpUser(values);
                    await actions.setSubmitting(false);
                    await actions.resetForm()
                }}
            >
                {({
                    handleSubmit,
                    touched,
                    errors,
                    values,
                    handleChange,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {touched.termCondition && !values.termCondition &&
                            <Alert classes={{
                                message: classes.alertMessage 
                            }}
                             style={{ width: "100%",padding:"0",marginTop:"10px" }} icon={false} severity="error">
                                <div style={{ display: "flex" }} >
                                    <div style={{border:"2px solid red"}}></div>
                                    <Typography style={{fontSize: "14px",color:"red",marginLeft:"10px",padding:"8px"}} variant="body1">{props.txtTerms}</Typography>
                                </div>
                            </Alert>}
                            {props.showAlert &&
                            <Alert classes={{
                                message: classes.alertMessage 
                            }}
                             style={{ width: "100%",padding:"0",marginTop:"10px" }} icon={false} severity="error">
                                <div style={{ display: "flex" }} >
                                    <div style={{border:"2px solid red"}}></div>
                                    <Typography style={{fontSize: "14px",color:"red",marginLeft:"10px",padding:"8px"}} variant="body1">{props.alertMessage}</Typography>
                                </div>
                            </Alert>}
                        <Box component="form" sx={{ mt: 3 }}>
                            <Grid container >
                                <Grid className={classes.marginBotm} item xs={12} >
                                    <label aria-controls="fullName" style={useStyles.label} >{props.FullName}</label><br />
                                    <CustomInput
                                        data-test-id="txtInputFullName"
                                        autoComplete="given-name"
                                        name="fullName"
                                        required
                                        fullWidth
                                        id="fullName"
                                        placeholder="Your full name"
                                    />

                                </Grid>
                                <Grid className={classes.marginBotm} item xs={12}>
                                    <label aria-controls="email" style={useStyles.label} >{props.labelEmail}</label><br />
                                    <CustomInput
                                        data-test-id="txtInputEmail"
                                        fullWidth
                                        id="email"
                                        name="email"
                                        autoComplete="email"
                                        placeholder="Your email"
                                    />
                                </Grid>
                                <Grid className={classes.marginBotm} item xs={12}>
                                    <label aria-controls="password" style={useStyles.label} >{props.labelPassword}</label><br />
                                    <CustomInput
                                        data-test-id="txtInputPassword"
                                        fullWidth
                                        name="password"
                                        type={props.enablePasswordField ? "password" : "text"}
                                        id="password"
                                        autoComplete="new-password"
                                        placeholder="Your password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { props.handleClickShowPassword() }}
                                                    edge="end"
                                                >
                                                    {props.enablePasswordField ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                                <Grid >
                                    <div>
                                        <Typography style={useStyles.checkTitle}>{props.checkTitle}</Typography>
                                        <div style={{ display: "flex" }} >
                                            {touched.password ? <CheckCircle style={{ color: `${/^(?=.*[A-Z])/.test(values.password) ? "green" : "red"}`, padding: "2px" }} /> : <CheckCircle style={{ color: "gray", padding: "2px" }} />}
                                            <Typography style={{ fontSize: "13px", margin: "2px" }}>{props.subCheckOneCapital}</Typography>
                                        </div>
                                        <div style={{ display: "flex" }} >
                                            {touched.password ? <CheckCircle style={{ padding: "2px", color: `${/^(?=.*[a-z])/.test(values.password) ? "green" : "red"}` }} /> : <CheckCircle style={{ color: "gray", padding: "2px" }} />}
                                            <Typography style={{ fontSize: "13px", margin: "2px" }}>{props.subCheckOneLowerCase}</Typography>
                                        </div>
                                        <div style={{ display: "flex" }} >
                                            {touched.password ? <CheckCircle style={{ color: `${/^(?=.*\d)/.test(values.password) ? "green" : "red"}`, padding: "2px" }} /> : <CheckCircle style={{ color: "gray", padding: "2px" }} />}
                                            <Typography style={{ fontSize: "13px", margin: "2px" }}>{props.subCheckOneNumber}</Typography>
                                        </div>
                                        <div style={{ display: "flex" }} >
                                            {touched.password ? <CheckCircle style={{ color: `${values.password.length >= 8 && values.password.length <= 20 ? "green" : "red"}`, padding: "2px" }} /> : <CheckCircle style={{ color: "gray", padding: "2px" }} />}
                                            <Typography style={{ fontSize: "13px", margin: "2px" }}>{props.subCheckPasswordMustbe}</Typography>
                                        </div>
                                    </div>
                                    <div style={useStyles.termsStyle}>

                                        <FormControlLabel
                                            color="primary"
                                            labelPlacement="end"
                                            style={{alignItems:"flex-start"}}
                                            control={<Checkbox color="primary" style={{padding:"0px 9px"}} checked={values.termCondition} onChange={handleChange("termCondition")} />}
                                            label={<label>I hereby consent to the <span onClick={()=>props.PrivacyPolicyOpen()} style={{ color: "#073F59",cursor:"pointer", textDecoration: "underline" }}>
                                                Privacy Policy
                                            </span> and <span onClick={()=>props.TermsConditionOpen()} style={{ color: "#073F59",cursor:"pointer", textDecoration: "underline" }}>
                                                    Terms of Use
                                                </span></label>}
                                        />
                                    </div>

                                </Grid>


                            </Grid>
                            <Button
                                id="btnforUserSignUP"
                                className={classes.signUpBtn}
                                style={{textTransform: "initial" }}
                                type="submit"
                                disableElevation
                            >
                                {props.btnTxtCreateAccount}
                            </Button>

                        </Box>
                    </form>


                )}
            </Formik>
        </div>
    );
}

const useStyles = ({
    label: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px", /* 150% */
    },
    title: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "36px",
        fontStyle: "normal",
        // fontWeight: "700",
        lineHeight: "44px", /* 122.222% */
        letterSpacing: "-0.36px",
    },
    startBtn: {
        display: "flex",
        width: "50%",
        color: "white",
        padding: "10px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flex: "1 0 0",
        alignSelf: "stretch",
        borderRadius: "8px",
        background: "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
    },
    checkTitle: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px"
    },
    marginBotm:{marginBottom:"15px !important"},
    termsStyle: {
        marginRight: "15px",
        marginTop: "20px"
    },

    img: {
        width: "auto",
        height: 50,
        marginTop: "10px",
        marginLeft: "30px"
    },
    alertMessage: {
        padding: 0,
    },
    signUpBtn: {
        display: "flex",
        width: "100%",
        height:"56px",
        "& .MuiButton-label":{
            width:"122px",
            height:"24px"
        },
        '&:hover': {
            backgroundColor: '#E57727',
        },
        color: "white",
        marginTop: "26px",
        fontSize: "16px",
        padding: "10px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flex: "1 0 0",
        alignSelf: "stretch",
        borderRadius: "8px",
        fontWeight:700,
        background: "#E57727",
    },
});

export default withStyles(useStyles)(EmailRegisterForm);