import { createTheme } from '@material-ui/core/styles';


export const getThemeColor = () => {
  // let storedColor = JSON.parse(localStorage?.getItem("themeColor")||"");
  let storedColorString  = localStorage?.getItem("themeColor");
  let storedColor: { primary_color?: string } = {};

  // Check if storedColor is not null before parsing
  if (storedColorString ) {
    try {
      storedColor = JSON.parse(storedColorString );
    } catch (error) {
      console.error("Error parsing storedColor:", error);
      // Handle the error, maybe provide a default value or do something else
    }
  }
  let primaryColor=storedColor?.primary_color
  if (primaryColor && primaryColor.startsWith('"') && primaryColor.endsWith('"')) {
    primaryColor = primaryColor.slice(1, -1);
  }
  console.log("CALING",primaryColor);
  
  return primaryColor || "#E57727"; 
};

export const getThemeLogo = () => {
  let storedColor = JSON.parse(localStorage?.getItem("themeColor")||"");
  let logo=storedColor?.theme_image?.url
  console.log(logo,"LOGO");
  
  return logo || "/assets/image_logo.svg"; 
};

export const getSecondaryThemeColor = () => {
  // let storedColor = JSON.parse(localStorage?.getItem("themeColor")||"");
  let storedColorString  = localStorage?.getItem("themeColor");
  let storedColor: { secondary_color?: string } = {};

  // Check if storedColor is not null before parsing
  if (storedColorString ) {
    try {
      storedColor = JSON.parse(storedColorString );
    } catch (error) {
      console.error("Error parsing storedColor:", error);
      // Handle the error, maybe provide a default value or do something else
    }
  }
  let secondaryColor=storedColor?.secondary_color
  if (secondaryColor && secondaryColor.startsWith('"') && secondaryColor.endsWith('"')) {
    secondaryColor = secondaryColor.slice(1, -1);
  }
  console.log("CALING",secondaryColor);
  
  return secondaryColor || "#E57727"; 
};


export async function downloadFile(fileURL:string,fileName:string){      
  const response = await fetch(fileURL);
  const filename=fileName;
  
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${filename}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
