import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    Grid,
    Link,
    Typography,
    Divider,
    Button,
} from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { imgLogo, Frame, googleImg} from "./assets"
import { Link as Redirect } from "react-router-dom";
import GoogleLogin from "react-google-login";
// Customizable Area End

import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController.web";
import EmailRegisterForm from "../../../components/src/EmailRegisterForm";
import Loader from "../../../components/src/Loader.web";
import TermsConditions from "../../termsconditions/src/TermsCondition.web";
import PrivacyPolicy from "../../termsconditions/src/PrivacyPolicy.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
export class EmailAccountRegistration extends EmailAccountRegistrationController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes } = this.props;

        return (
            /* Customizable Area Start */
            <ThemeProvider theme={theme}>
                <Container maxWidth={"xl"} style={{ padding: "0",overflowX:"hidden" }}>
                    <Loader loading={this.state.dataLoading}/>
                    <Grid container component="main" className={classes.root}>
                        <CssBaseline />
                        <Grid item xs={8} sm={8} md={6} component={Paper} >
                            <img src={imgLogo} className={classes.img}></img>
                            <Box
                                sx={{
                                    my: 4,
                                    mb: 0,
                                    mx: 22.5,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >

                                <Typography className={classes.mainHead}>
                                    Sign up
                                </Typography>

                                <EmailRegisterForm
                                    enablePasswordField={this.state.enablePasswordField}
                                    labelPassword={this.labelPassword}
                                    labelEmail={this.labelEmail}
                                    FullName={this.FullName}
                                    btnTxtCreateAccount={this.btnTxtCreateAccount}
                                    checkTitle={this.checkTitle}
                                    subCheckOneCapital={this.subCheckOneCapital}
                                    subCheckOneLowerCase={this.subCheckOneLowerCase}
                                    subCheckOneNumber={this.subCheckOneNumber}
                                    subCheckPasswordMustbe={this.subCheckPasswordMustbe}
                                    txtTerms={this.txtTerms}
                                    signUpUser={this.signUpUser}
                                    SignUpFormSchema={this.SignUpFormSchema}
                                    handleCloseAlert={this.handleCloseAlert}
                                    showAlert={this.state.showAlert}
                                    alertMessage={this.state.alertMessage}
                                    handleClickShowPassword={this.handleClickShowPassword}
                                    TermsConditionOpen={this.TermsConditionOpen}
                                    PrivacyPolicyOpen={this.PrivacyPolicyOpen}
                                />

                                <Grid container >
                                <Box style={{ display: 'flex', alignItems: 'center',width:"100%",marginTop:"23px",
                                justifyContent:"center",padding:"0 2rem" }}>
                                        <Divider style={{ width: '50%',border:"1px solid #E2E8F0",marginRight:'1rem' }} />
                                        <Typography style={{fontSize:'14px',fontWeight:700,
                                        height:'22px',color:"#64748B"}}>OR</Typography>
                                        <Divider style={{ width: '50%', border:"1px solid #E2E8F0",marginLeft:"1rem"}} />
                                    </Box>
                                    <GoogleLogin
                                     clientId={'this.clientData'}
                                     data-test-id="googleLogin"
                                     render={renderProps => (
                                    <Button
                                        fullWidth
                                        data-test-id="googleLogin"
                                        type="submit"
                                        className={classes.logingoogleBtn}
                                         onClick={renderProps.onClick}
                                        style={{ textTransform: "initial", marginTop: "37px",
                                        marginBottom:"37px",display:"flex",alignItems:"center", height:"56px","borderRadius":"8px","border":"1px solid #CBD5E1"}}
                                    >
                                        <div style={{display:"flex",
                                        alignItems:"center",gap:"8px"}}>
                                        <img src={googleImg} style={{ width: "24px", 
                                        height: "24px"}} />
                                        <Typography style={{"color":"#334155","fontFamily":"Inter",
                                        "fontSize":"16px",width:"202px","fontStyle":"normal","fontWeight":700,
                                        "lineHeight":"24px"}}>
                                            Continue with Google</Typography>
                                        </div>

                                    </Button>)}
                                     onSuccess={this.responseGoogle} onFailure={this.responseGoogle} cookiePolicy={'single_host_origin'}
                                    />
                                    <Grid item>
                                        <Typography>Already have an account?
                                            <Redirect to="/EmailLogin" style={{ textDecoration: "none" }}> <Link href="#" style={{ color: "#E57727",fontWeight: 700}}>
                                                Log in
                                            </Link></Redirect></Typography>

                                    </Grid>
                                </Grid>

                            </Box>
                        </Grid>

                        <Grid item xs={false} sm={4} md={6} className={classes.mainSec} >
                            <div className={classes.secDiv} style={{ position: "relative" }}>
                                <img src={Frame} className={classes.image}></img>
                                <div className={classes.headerDiv} style={{ flexDirection: "column", }}>
                                    <h1 style={{ marginBottom: "0px" }}>{this.mainHeader}</h1>
                                    <p>{this.subHeader}</p>
                                </div>
                            </div>


                        </Grid>

                    </Grid>
                        <TermsConditions
                        openDialog={this.state.showTermsDialog}
                        handleCancel={() => this.TermsCondition()}
                        handleOk={()=> this.TermsCondition()}
                        txtTermsData={this.state.txtTermsData}
                        headingText="Terms and Conditions"
                        btnCancelText="Cancel"
                        btnOkText="Agree"
                        navigation={undefined} 
                        id={""}
                        classes={undefined}                        
                        />
                        <PrivacyPolicy
                        openDialog={this.state.showPrivacyDialog}
                        handleCancel={() => this.PrivacyPolicy()}
                        handleOk={()=>this.PrivacyPolicy()}
                        txtPrivacyData={this.state.txtPrivacyData}
                        headingText="Privacy Policy"
                        subHeadingText={this.state.lastModified}
                        btnCancelText="Cancel"
                        btnOkText="Agree"
                        navigation={undefined} 
                        id={""}
                        classes={undefined}                        
                        />
                </Container>
            </ThemeProvider>
            /* Customizable Area End */
        );
    }
}

const useStyles = ({
    root: {
        height: '100vh',
    },
    mainHead: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px", /* 133.333% */
        letterSpacing: "-0.12px",
    },
    title: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "36px",
        fontStyle: "normal",
        lineHeight: "44px", /* 122.222% */
        letterSpacing: "-0.36px",
    },
    secDiv: {
        display: "grid",
        padding: "13%",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
    },
    headerDiv: {
        display: "inline-flex",
        alignItems: "center"
    },
    image: {
        width: "auto",
        height: "auto",
        marginLeft: "15%"
    },
    mainSec: {

        background: '#F6F2EF',
    },
    img: {
        width: "201px",
        height: "59px",
        marginTop: "7px",
        marginLeft: "27px"
    },
});

export default withStyles(useStyles)(EmailAccountRegistration);