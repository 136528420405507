import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    TextField,
	Avatar,
	Grid,
	OutlinedInput,
	Popover
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import "./AddSubTask.css"
import { dot, right } from "./assets"
import Select from "react-select";
import  {BasicDemo, TableListComponent } from "../../../components/src/Mention"


// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "##000000",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import AddSubTaskController, {
    Props,
} from "./AddSubTaskController";



export default class AddSubTask extends AddSubTaskController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

		componentDidMount(): any {
			this.getVtEmpList();
		}

	componentDidUpdate(prevProps: any, prevState: any) {
		if (this.props.isDrawerOpen && this.props.isDrawerOpen !== prevProps.isDrawerOpen) {
			this.showTask(this.props.Task_Id);
		}
	}
    // Customizable Area End

    render() {

		const optionStatus = [
			{ value: 0, label: "To Do", labelValue: "to_do" },
			{ value: 1, label: "In Progress", labelValue: "in_progress" },
			{ value: 2, label: "Complete", labelValue: "complete" },
			{ value: 3, label: "Deleted", labelValue: "modeletednthly" },
		];

		const users = [
			{ id: 1, display: 'John Doe' },
			{ id: 2, display: 'Jane Smith' },
			{ id: 3, display: 'Alice Johnson' },
			{ id: 4, display: 'Bob Brown' },
		];
		const anchorEl = this.state.anchorEl;
		const open = Boolean(anchorEl);
		const id = open ? 'simple-popover' : undefined;
		const statusOption = optionStatus.find(option => {
			if (typeof this.state.formData.status === 'string') {
				return option.label === this.state.formData.status;
			} else if (typeof this.state.formData.status === 'number') {
				return option.value === this.state.formData.status;
			}
		});
		const statusLabel = statusOption ? statusOption.label : '';
		return (
			// Customizable Area Start
			<ThemeProvider theme={theme}>
				<Box>
					<Box>
						<Drawer className="addsubtask-drawer" anchor="right" open={this.props.isDrawerOpen} onClose={this.props.handleDrawer}>
							<Box className="addsubtask-drawer-header">
								<h2 className="addsubtask-drawer-title"> {this.state.formData.title}</h2>
								<Box className="mark-right">
									<button className="mark-right-btn">
										<img src={right}></img>
										Mark complete
									</button>
									<button
										className="mark-right-btn"
										onClick={this.handleClick}>
										<img src={dot}></img>
									</button>
									<Popover
										id={id}
										open={open}
										anchorEl={anchorEl}
										onClose={this.handleClose.bind(this)}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'center',
										}}
									>
										<Button
											style={{ padding: '10px' }}
											onClick={() => {this.gotoAuditTrail(this.props.projectId) }}
										>
											Audit Trail
										</Button>
									</Popover>

								</Box>
							</Box>
							<Box className="addsubtask-body">
								{!this.state.taskFormToggle ? <>
									<Grid container spacing={1}>
										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Task Assign name</Typography>
										</Grid>
										<Grid item xs={9} className="task-detail">
											<Typography className="taskname">Amelia Nelson</Typography>
										</Grid>
										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Due date</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography className="taskname">{this.state.formData.due_date} </Typography>
										</Grid>
										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Status</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography className="taskname">{statusLabel}</Typography>
										</Grid>
										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Company</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography className="taskname">{this.state.formData.company_name}</Typography>
										</Grid>
										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Description</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography className="taskname">{this.state.formData.description}</Typography>
										</Grid>
									</Grid>
									<Button
										className="edit-btn"
										onClick={() => { this.setState({ taskFormToggle: true }) }}>
										Edit
									</Button>
								</> : <>
									<Grid container spacing={3}>
										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Task Assign name</Typography>
										</Grid>
										<Grid item xs={9}>
											<Typography className="taskname">Amelia Nelson</Typography>
										</Grid>


										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Due date</Typography>
										</Grid>
										<Grid item xs={9}>
											<OutlinedInput
												className="subtask-field"
												data-test-id="startDate"
												id="due_date"
												name="due_date"
												type="date"
												value={this.state.formData.due_date}
												onChange={this.handleChange}
											// InputLabelProps={{ shrink: true, }}
											/>
										</Grid>
										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Status</Typography>
										</Grid>
										<Grid item xs={9} className="statusselect">

											<Select
												name="status"
												data-test-id="status"
												options={optionStatus}
												//@ts-ignore
												value={optionStatus.find(option => option.value === this.state.formData.status) || ''}
												onChange={(selectedOption) => {
													if (selectedOption && selectedOption.value) {
														this.handleChange({ target: { name: 'status', value: selectedOption.value } });
													}
												}}
											/>
										</Grid>
										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Company</Typography>
										</Grid>
										<Grid item xs={9}>

											<TextField
												style={{ width: '100%' }}
												id="outlined-basic"
												variant="outlined"
												name="company_name"
												value={this.state.formData.company_name}
												onChange={this.handleChange}
											/>
										</Grid>

										<Grid item xs={3} className="task-detail">
											<Typography className="tasktitle">Description</Typography>
										</Grid>
										<Grid item xs={9}>

											<TextField
												style={{ width: '100%' }}
												id="outlined-basic"
												variant="outlined"
												name="description"
												value={this.state.formData.description}
												onChange={this.handleChange}
											/>
										</Grid>
									</Grid>

									<Button
										className="edit-btn"
										onClick={() => { this.toggleTaskForm() }}
										>
										Save
									</Button>
								</>
								}
							</Box>
							<Typography className="project-note-title">Project note</Typography>

							<BasicDemo
								handleInputChange={this.handleInputChange}
								value={this.state.value}
								users={this.state.users}
							/>

							<Box className="task-btn-wrapper">
								<Button
									style={{
										backgroundColor: 'rgb(232, 116, 36)',
										color: 'rgb(255, 255, 255)',
										fontSize: '16px',
										fontWeight: 700,
										lineHeight: '2',
										height: '44px',	
										padding: '10px 16px',
										borderRadius: '10px',
										border: 'none',
										marginTop: '8px',
										textTransform: 'capitalize',
									}}
									onClick={this.editProjectNote}
								>
									Post
								</Button>
							</Box>
							<TableListComponent
								ProjectNoteMention={this.state.ProjectNoteMention}
							/>
						</Drawer>
					</Box>
				</Box>
			</ThemeProvider>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
// Customizable Area End
