import React, { useContext, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid, IconButton, InputLabel, TextField, Typography } from "@material-ui/core";
import { Formik } from "formik";
import { Close as CloseIcon } from '@material-ui/icons';
import * as Yup from "yup";
import { ModelContext } from './Context'
import ColorPicker from "./ColorPicker";

const initialValues = {
  Workspace_Name: "",
  Description: ""
}
let initialValues2 = {
  Workspace_Name: "",
  Description: ""
}
let BoardWorkspaceModelInitialValues = {
  Board_name: ""
}

const validationSchema = Yup.object().shape({
  Workspace_Name: Yup.string().min(1, "Name is too short")
    .max(20, "Name is too long")
    .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, ' Name is invalid')
    .required("Name is required."),
  Description: Yup.string().min(1, "Description is too short")
    .max(200, "Description is too long")
    .required("Description is required."),
});

const Board_name_validationSchema = Yup.object().shape({
  Board_name: Yup.string().min(1, "Name is too short")
    .max(20, "Board name is too long")
    .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Full name is invalid')
    .required("Name is required."),
});

const GroupInitialValues = {
  group_name: "",
  primary_color: "#000000"
}

const GroupvalidationSchema = Yup.object().shape({
  group_name: Yup.string().min(1, "Name is too short")
    .max(20, "Board name is too long")
    .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Full name is invalid')
    .required("Name is required."),
  // primary_color: Yup.string().required("Color is required.")
});

export const CreateWorkspaceModel = ({ createWorkspace, editWorkspace, selectedItem, primaryColor, secondaryColor }: any) => {
  const { openModel, closeModel, modelStates }: any = useContext(ModelContext);
  if (selectedItem) {
    initialValues2 = { Workspace_Name: selectedItem.attributes.name, Description: selectedItem.attributes.description }
  }

  return (
    <>
      <div className="all_model_css">
        <Dialog
          // onClose={() => closeModel('model1')}
          aria-labelledby="simple-dialog-title"
          open={modelStates.model1}
          className="modalSize"
        >
          <Formik
            initialValues={selectedItem ? initialValues2 : initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnMount={true}
            validateOnBlur={true}
            onSubmit={(values, { resetForm }) => {
              !selectedItem ? createWorkspace(values) : editWorkspace(selectedItem.id, values)
              resetForm()
            }}
          >
            {({
              handleSubmit,
              touched,
              handleBlur,
              errors,
              values,
              handleChange,
              setFieldValue
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="simple-dialog-title">Create workspace</DialogTitle>
                  <IconButton style={{ position: 'absolute', right: '8px', top: '8px', color: '#0F172A', }} aria-label="close"
                    onClick={() => closeModel('model1')}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <Box className="inputArea">
                      <InputLabel>Workspace Name</InputLabel>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Workspace_Name}
                        name="Workspace_Name"
                        placeholder="Enter name"
                        hiddenLabel id="filled-hidden-label-normal"
                        variant="outlined"
                        fullWidth
                      />
                      {touched.Workspace_Name && errors.Workspace_Name && (
                        <Typography color="error" data-test-id="Workspace_Name_error">{errors.Workspace_Name}</Typography>
                      )}
                    </Box>
                    <Box className="inputArea">
                      <InputLabel>Description</InputLabel>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Description}
                        name="Description"
                        placeholder="Enter description"
                        hiddenLabel id="filled-hidden-label-normal"
                        variant="outlined"
                        fullWidth
                      />
                      {touched.Description && errors.Description && (
                        <Typography color="error" data-test-id="Description_error">{errors.Description}</Typography>
                      )}
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button type='submit'
                      style={{
                        minWidth: '105px',
                        fontSize: '16px',
                        textTransform: 'capitalize',
                        background: primaryColor,
                        padding: '14px 16px',
                        borderRadius: '8px',
                        color: '#fff',
                        fontWeight: 700,
                        opacity: '1'
                      }}
                    >
                      {selectedItem ? "Edit" : "Create"}
                    </Button>
                  </DialogActions>
                </form>
              </>)}
          </Formik>
        </Dialog>
      </div>
    </>
  )
}

export const BoardWorkspaceModel = ({ createWorkspaceCatalogs, workspaceCatalogsid, primaryColor, secondaryColor }: any) => {
  const { openModel, closeModel, modelStates }: any = useContext(ModelContext);
  return (
    <>
      <div className="all_model_css">
        <Dialog
          // onClose={() => closeModel('model2')}
          aria-labelledby="simple-dialog-title"
          open={modelStates.model2}
          className="modalSize"
        >
          <Formik
            initialValues={BoardWorkspaceModelInitialValues}
            validationSchema={Board_name_validationSchema}
            validateOnChange={false}
            validateOnMount={true}
            validateOnBlur={true}
            onSubmit={(values, { resetForm }) => {
              createWorkspaceCatalogs(workspaceCatalogsid, values);
              resetForm();
            }}
          >
            {({
              handleSubmit,
              touched,
              handleBlur,
              errors,
              values,
              handleChange,
              setFieldValue
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="simple-dialog-title" >Create Boards</DialogTitle>
                  <IconButton aria-label="close" style={{ position: 'absolute', right: '8px', top: '8px', color: '#0F172A', }} onClick={() => closeModel('model2')} >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <Box className="inputArea">
                      <InputLabel>Board name</InputLabel>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Board_name}
                        name="Board_name"
                        placeholder="Enter name"
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="outlined"
                        fullWidth
                      />
                      {touched.Board_name && errors.Board_name && (
                        <Typography color="error" data-test-id="Board_name_error">{errors.Board_name}</Typography>
                      )}
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button type='submit'
                      style={{
                        minWidth: '105px',
                        fontSize: '16px',
                        textTransform: 'capitalize',
                        background: primaryColor,
                        padding: '14px 16px',
                        borderRadius: '8px',
                        color: '#fff',
                        fontWeight: 700,
                        opacity: '1'
                      }}
                    >
                      Create
                    </Button>
                  </DialogActions>
                </form>
              </>)}
          </Formik>
        </Dialog>
      </div>
    </>
  )
}

export const GroupWorkspaceModel = ({ createGroup, primaryColor, secondaryColor }: any) => {
  const { openModel, closeModel, modelStates }: any = useContext(ModelContext);

  return (
    <>
      <div className="all_model_css">
        <Dialog
          // onClose={() => closeModel('model3')}
          aria-labelledby="simple-dialog-title"
          open={modelStates.model3}
          className="modalSize"
        >
          <Formik
            initialValues={GroupInitialValues}
            validationSchema={GroupvalidationSchema}
            validateOnChange={false}
            validateOnMount={true}
            validateOnBlur={true}
            onSubmit={(values, { resetForm }) => {
              console.log(values);
              createGroup(values);
              resetForm();
            }}
          >
            {({
              handleSubmit,
              touched,
              handleBlur,
              errors,
              values,
              handleChange,
              setFieldValue
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DialogTitle id="simple-dialog-title">Create group</DialogTitle>
                  <IconButton aria-label="close"
                    style={{ position: 'absolute', right: '8px', top: '8px', color: '#0F172A', }}
                    onClick={() => closeModel('model3')}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <Box className="inputArea">
                      <InputLabel>Group name</InputLabel>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.group_name}
                        name="group_name"
                        placeholder="Enter name"
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="outlined"
                        fullWidth
                      />
                      {touched.group_name && errors.group_name && (
                        <Typography color="error" data-test-id="Board_name_error">{errors.group_name}</Typography>
                      )}
                    </Box>
                    <Box className="inputArea colorPicker">
                      <InputLabel>Choose color</InputLabel>
                      <ColorPicker
                        name="primary_color"
                        onChange={(e: any) => {
                          setFieldValue('primary_color', e);
                        }}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button type="submit"
                      style={{
                        minWidth: '105px',
                        fontSize: '16px',
                        textTransform: 'capitalize',
                        background: primaryColor,
                        padding: '14px 16px',
                        borderRadius: '8px',
                        color: '#fff',
                        fontWeight: 700,
                        opacity: '1'
                      }}
                    >
                      Create
                    </Button>
                  </DialogActions>
                </form>
              </>
            )}
          </Formik>
        </Dialog>
      </div>
    </>
  )
}

export const DrawerComponent = ({ isDrawerOpen, WorkspaceCatelogData, gotoWorkspace, closeDrawer, state,primaryColor,secondaryColor }: any) => {

  useEffect(() => {
  }, [])

  const { openModel, closeModel, modelStates }: any = useContext(ModelContext);
  if (!WorkspaceCatelogData) {
    return <div>Loading...</div>; // Or any other loading indicator
  }
  return (
    <Box>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={closeDrawer}
      >
        <div style={useStyles.statusModalHeader} id="alert-dialog-title">
          <span style={{ width: '90%' }}>Taxes </span>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Button style={{...useStyles.addBoardsBtn,background:primaryColor}}
              onClick={() => { openModel('model2') }}
            >
              Add boards
            </Button>
            <CloseIcon onClick={closeDrawer} />
          </Box>
        </div>

        <div style={useStyles.TaxesBody} >
          <Grid container spacing={5}>
            {Array.isArray(WorkspaceCatelogData) && WorkspaceCatelogData.length > 0 && WorkspaceCatelogData?.map((item: any, index: any) => {
              return (
                //@ts-ignore
                <React.Fragment key={index} onClick={() => gotoWorkspace({ catelogid: item.id, workspaceid: "12" })} >
                  <Grid item xs={6}>
                    <Typography style={useStyles.textCard}>
                      {item?.attributes?.name}
                    </Typography>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </div>
      </Drawer>
    </Box>
  )
}

const useStyles = ({
  statusModalHeader: {
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    color: "#0F172A",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px",
    borderBottom: "1px solid #E2E8F0", background: "#FFF",
    minWidth: '500px',
    alignItems: 'center',
  },
  TaxesBody: {
    padding: '24px 40px',

  },
  addBoardsBtn: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    padding: '10px 16px',
    borderRadius: '8px',
    background: '#E57727',
    color: "#fff",
    width: '150px'
  },
  textCard: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "6px",
    borderLeft: "4px solid #E57727",
    background: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    padding: '16px 20px'

  },
  actionModalBox: {
    "display": "flex",
    "width": "192px",
    "height": "75px",
    "padding": "4px",
    "flexDirection": "column",
    "justifyContent": "center",
    "alignItems": "flex-start",
    "gap": "2px",
    "flexShrink": "0",
    "borderRadius": "8px",
    "border": "1px solid #E2E8F0",
    "background": "#FFF",
    "boxShadow": "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    position: 'absolute',
    top: '44px',
    right: "24px",
    zIndex: 1

  },
  repltybtn: {
    "display": "flex",
    "height": "36px",
    "padding": "7px 105px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "flexShrink": "0",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "background": "#F0E5FF",
    "justifyContent": "flex-start",
  },
  inboxbtn: {
    "display": "flex",
    "height": "36px",
    "padding": "7px 56px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "flexShrink": "0",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "background": "#FFF",
    "justifyContent": "flex-start",
  },
  deletebtn: {
    "display": "flex",
    "height": "36px",
    "padding": "7px 16px 7px 16px",
    "alignItems": "center",
    "gap": "8px",
    "flexShrink": "0",
    "alignSelf": "stretch",
    "borderRadius": "8px",
    "background": "#FFF",
    "justifyContent": "flex-start",
  },
  replyText: {
    "color": "#0F172A",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "22px",
    textTransform: "none"
  },

  deleteText: {
    "color": "#DC2626",
    "fontFamily": "Inter",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "lineHeight": "22px",
    textTransform: "none"
  },

});