import React from 'react'
import Select from "@material-ui/core/Select";
import {
	Container,
	Button,
	Typography,
	Box,
	TableCell,
	TableRow,
	TableHead,
	Table,
	TableBody,
	Collapse,
	IconButton,
	Grid,
	Avatar,
	TextField,
	InputAdornment,
	Dialog
} from "@material-ui/core";
import ChevronRight from '@material-ui/icons/ChevronRight';
import MoreArrow from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { AddCircleOutline, FilterListSharp, Search, } from "@material-ui/icons"

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 4,
		borderRadius: 5,
		width: 113,
		marginTop: 5
	},
	colorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: "#51BEBA",
	},
}))(LinearProgress);


export const Selected = ({ label, value, onChange, options, ...props }: any) => {
	return (
		<>
			<Select
				native
				value={value}
				onChange={onChange}
				label={label}
				inputProps={{
					name: props.name,
					id: props.id,
				}}
				{...props}
			>
				{options?.map((option: any) => (
					<option key={option.labelValue} value={option.labelValue}>
						{option.label}
					</option>))}
			</Select>
		</>
	)
}

export const CustomTableRow = ({ data, toggleOpenState, index, openStates, gotoWorkspacePage, item }: any) => {
	return (
		<>
			<TableRow className="workroot workspace-acc">
				<TableCell className="root-head workspace-acc-first">
					<Box className="root-mainhead">
						<IconButton aria-label="expand row" size="small" onClick={() => { toggleOpenState(index) }}>
							{openStates[index] ? <ChevronRight /> : <MoreArrow />}
						</IconButton>
						<Typography variant="h5" className={`${!openStates[index] ? "" : "title"}`}>
							{item.attributes.groups.name}
						</Typography>
					</Box>
					{!openStates[index] ? <Typography variant="body1" className="root-subitems">
						{item.attributes?.groups?.projects?.length > 0 && item.attributes?.groups?.projects?.length} sub items
					</Typography> : ""}
				</TableCell>
				{!openStates[index] ?
					<>
						<TableCell>
							Payment status
							<BorderLinearProgress variant="determinate" value={data.paymentStatus} />
						</TableCell>
						<TableCell align="right">{data.date}</TableCell>
						<TableCell align="right">{data.companyName}</TableCell>
						<TableCell align="right">{data.team}</TableCell>
						<TableCell align="left">
							Status
							<BorderLinearProgress variant="determinate" value={data.status} />
						</TableCell>
					</>
					:
					<TableCell align="right"><Button className='btn-orange' onClick={() => gotoWorkspacePage(item.id, "ADD_PROJECT")}><AddCircleOutline style={{ marginRight: "8px" }} />Add Project</Button></TableCell>}
			</TableRow>
		</>
	);
};

export const SubtaskTableRow = ({ message, tick, task, handleDrawer ,projectId ,name }: any) => {
	return (
		<>
			<TableBody>
				<TableRow>
					<TableCell className="subtask-table-head">#</TableCell>
					<TableCell className="subtask-table-head subtask-name">
						Sub task name
					</TableCell>
					<TableCell className="subtask-table-head">
						Task Assign name
					</TableCell>
				</TableRow>


				{task.length > 0 ? (
					task?.map((item: any, index: any) => {
						const count = index + 1;
						return (
							<>
							<TableRow className="subtask-row" onClick={() => {
									if (item && item.data) {
										handleDrawer(item.data.id, projectId ,name);
									}
								}}
								>
									<TableCell className="subtask-table-detail">
										{count}
									</TableCell>
									<TableCell className="subtask-table-detail">
										<img src={tick} alt="" style={{ verticalAlign: 'top', marginRight: '5px' }} />
										{item?.data?.attributes?.title} <span>3<img src={message} style={{ verticalAlign: 'top', marginLeft: '5px' }}></img></span>
									</TableCell>
									<TableCell className="subtask-table-detail">
									<Box className="innerlogo">
											<Avatar className="profile">H</Avatar>
											<Typography>
												{(item?.data?.attributes?.title)?.replace(/_/g, ' ').replace(/\b\w/g, (match: string) => match.toUpperCase())}
											</Typography>
										</Box>
									</TableCell>
								</TableRow>
							</>
						)
					})
				) : (
					<TableCell className="subtask-table-detail">
						No data avabvliable
					</TableCell>
				)}
			</TableBody >

		</>
	);
};
export const WorkspaceHeadGrid = () => {
	return (
		<Grid container spacing={3} className="workspace-head">
			<Grid item xs={4} className="workspace-project-name">Project name </Grid>
			<Grid item xs={2} className="head">Payment status</Grid>
			<Grid item xs={1} className="head">Date</Grid>
			<Grid item xs={2} className="head">Company name</Grid>
			<Grid item xs={1} className="head">Team</Grid>
			<Grid item xs={2} className="head">Status</Grid>
		</Grid>
	);
}

export const optionStatus = [
	{ value: 0, label: "Signed Engagement", labelValue: "signed_engagement" },
	{ value: 1, label: "File And Completed", labelValue: "e-file_and_completed" },
	{ value: 2, label: "Ready To Work", labelValue: "ready_to_work" },
	{ value: 3, label: "Taxes In Progress", labelValue: "taxes_in_progress" },
	{ value: 4, label: "Ready For Review", labelValue: "ready_for_review" },
	{ value: 5, label: "Final Review Complete", labelValue: "final_review_complete" },
	{ value: 6, label: "Waiting On Sinature", labelValue: "waiting_on_sinature" },
	{ value: 7, label: "Waiting On Payment", labelValue: "waiting_on_payment" },
	{ value: 8, label: "Ready To File", labelValue: "ready_to_file" },
	{ value: 9, label: "File And Completed", labelValue: "file_and_completed" }
];

export const optionFrequency = [
	{ value: 0, label: "Not Repeat", labelValue: "not_repeat" },
	{ value: 1, label: "Daily", labelValue: "daily" },
	{ value: 2, label: "Weekly", labelValue: "weekly" },
	{ value: 3, label: "Monthly", labelValue: "monthly" },
	{ value: 4, label: "Custom", labelValue: "custom" },
];

