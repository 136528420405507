// Customizable Area Start
import React from "react";
import { Button, Chip, Container, Grid, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ProfileController from "./ProfileController.web";
import { Formik } from "formik";
import { AddCircleOutline, MoreVert } from "@material-ui/icons";
import AddFeild from "../../../components/src/AddFeild";
import AddAccount from "../../../components/src/AddAccount";
import { Alert } from "@material-ui/lab";
import { imgAlert,imgAlertRed } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  mainContainer: {
    width: "97%",
    marginTop: "20px",
    marginLeft:"20px",
    display:"flex",
    gap:"15px"
  },
  allowed:{ cursor:"pointer",width:"561px",height:"44px",padding:"10px 8px" },
  root: {
    height: '100vh',
},
main: {
    background: "#fff",
},
cltId:{
color:"#000",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "24px",
marginBottom:"11px"
},
label:{
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    marginBottom:"17px",
    lineHeight: "24px"
},
title: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
    marginBottom: "10px"
},
toggleDiv:{width: "1299px",borderRadius: "8px",
border: "1px solid #CBD5E1",
marginTop: "20px",
padding:"17px 24px",
background: "#FFF",
display:"flex",
justifyContent:"space-between",
height: "48px",
flexShrink: 0,},
wlcmDiv: {
    display: "grid",
    padding: "13%",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
},
wlcmImg: {
    display: "inline-flex",
    alignItems: "center"
},
addBtns:{display: "inline-flex",
height: "44px",
padding: "10px 16px",
justifyContent: "center",
alignItems: "center",
gap: "8px",
borderRadius: "8px",
border:" 0.5px solid #CBD5E1",
color: "#396178",
fontFamily: "Inter",
textTransform:"none" as const,
fontSize: "16px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "24px",
flexShrink: 0,},
bottomMargin:{marginBottom:"28px !important"},
image: {

    background: '#F6F2EF',
},
img: {
    width: "201px",
    height: "59px",
    marginTop: "7px",
    marginLeft: "27px"
},
imgs: {
    width: "auto",
    height: "auto",
    marginLeft: "15%"
},
alertMessage: {
    padding: 0,
},
welcomeTitle:{
  color: "#0F172A",
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  marginBottom:"12px",
  fontWeight: 600,
  lineHeight: "28px"
},
loginBtn: {
    display: "flex",
    width: "100%",
    color: "white",
    marginTop: "26px",
    fontSize:"16px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "8px",
    fontWeight:700,
    background: "#E57727",
    '&:hover': {
        backgroundColor: '#E57727',
    },
},
tabelCell:{
  color: "#073F59",
  fontWeight: 400,
  fontSize: "15px",
  width: "25%",
  padding:"0px 16px"
},
alertDivs: {
  display: "inline-flex",
  padding: "8px 16px 8px 8px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  top:"80%",
  left:"50%",
  transform:"translate(-50%,50%)",
  borderRadius: "8px",
  background: "#FFF",
  boxShadow: "0px 6px 15px -3px",
},
messageDivs: { display: "flex", gap: "8px" },
};

export class Profile extends ProfileController {
  // constructor(props: Props) {
  //   super(props);
  // }

  render() {
    const containerStyles = {
        position: 'relative' as const,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        width: '292px',
        padding: "4px",
        border: '3px solid #F6F2EF',
        borderRadius: '20px',
        background: '#F6F2EF',
        fontWeight: 700,
        color: '#white',
        cursor: 'pointer',
justifyContent: "center",
alignItems: "center",
flexShrink: 0,
      };
    
      const beforeStyles = {
        content: '',
        position: 'absolute' as const,
        width: "144px",
        height: "40px",
        justifyContent: "center",
        alignItems: "center",
        left: this.state.isChecked ? '50%' : '0%',
        borderRadius: '20px',
        background: '#396178',
        transition: 'all 0.3s',
      };
    
      const textStyles = {
        width: "67px",
        padding: "8px 38px 8px 39px",
        height: "24px",
        textAlign: 'center' as const,
        zIndex: 1,
        color: this.state.isChecked ? '#396178' : 'white',
        transition: 'color 0.3s',
      };
      const textAccountStyles = {
        width: "67px",
        padding: "8px 38px 8px 39px",
        height: "24px",
        textAlign: 'center' as const,
        zIndex: 1,
        color: this.state.isChecked ? 'white' : '#396178',
        transition: 'color 0.3s',
      };

    const {classes}=this.props
    return (
      <div>
        <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"} style={{ padding: "0",overflowX:"hidden" }}>
        <Grid container component="main" className={classes.mainContainer}>
        <Chip
                  data-test-id="clienttab"
                //   onClick={this.handleClientClick}
                  label={"Profile"}
                  variant="outlined"
                  style={{
                    backgroundColor: this.state.clientTab
                      ? "#e87424"
                      : "#F6F2EF",
                    color: this.state.clientTab ? "#ffffff" : "",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "1.5rem",
                    display: "flex",
                    width: "160px",
                    height: "48px",
                    padding: "10px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "50px",
                    border: "none",
                  }}
                />
            {this.state.types.map((label, index) => (
            <Chip
                  data-test-id="clienttab"
                //   onClick={this.handleClientClick}
                  label={label}
                  variant="outlined"
                  style={{
                    backgroundColor: !this.state.clientTab
                      ? "#e87424"
                      : "#F6F2EF",
                    color: !this.state.clientTab ? "#ffffff" : "",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "1.5rem",
                    display: "flex",
                    width: "180px",
                    height: "48px",
                    padding: "10px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "50px",
                    border: "none",
                  }}
                />

            ))}
                      <div className={classes.toggleDiv}>
    <input
        type="checkbox"
        id="toggle"
        style={{ display: 'none' }}
        onChange={this.handleToggleChange}
        checked={this.state.isChecked}
      />
      <label htmlFor="toggle" style={containerStyles}>
        <div style={beforeStyles}></div>
        <div style={textStyles}>Account</div>
        <div style={textAccountStyles} onClick={this.getClientListing}>Contacts</div>
      </label>
{this.state.isChecked ? (<Button className={classes.addBtns} data-test-id="buttonContactClicked"  onClick={()=>this.openContactDialog()}><AddCircleOutline style={{ marginRight: "8px" }} /> Add contact</Button>):(
    <Button className={classes.addBtns} data-test-id="buttonFieldClicked" onClick={()=>this.openFeildDialog()}><AddCircleOutline style={{ marginRight: "8px" }} /> Add feild</Button>
)}
          </div>
          <div style={{width:"97%",height:"100%"}}>
            {this.state.isChecked ? ( <div>
                                <Table>
                <TableHead>
                  <TableRow
                    style={{
                      height: "2.43rem",
                      borderRadius: " 0.375rem",
                      border: "1px solid #CBD5E1",
                    }}
                  >
                    <TableCell
                      style={webStyle.tabelCell}
                    >
                      Name
                    </TableCell>
                    <TableCell
                    style={webStyle.tabelCell}
                    >
                      Email address
                    </TableCell>
                    <TableCell
                    style={webStyle.tabelCell}
                    >
                      Contact Number
                    </TableCell>
                    <TableCell
                    style={webStyle.tabelCell}
                    >
                        Company Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <br />
                {this.state.usersAccountData !== undefined ? (
                  <TableBody>
                    {this.state.usersAccountData.map((item: any, index: number) => (
                      <React.Fragment key={item?.id}>
                        <TableRow
                          style={{
                            borderRadius: "0.375rem",
                            border: "1px solid #CBD5E1",
                            padding: "0.625rem",
                            height: "3.5rem",
                          }}
                          key={item?.id}
                        >
                          <TableCell
                            data-test-id={`firstname-${index}`}
                            style={{
                              width: "25%",
                              color: "#396178",
                              fontFamily: "Inter",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "normal",
                            }}
                          >
                            {item.attributes.full_name}
                          </TableCell>
                          <TableCell style={{ padding: "1rem", width: "25%" }}>
                            {item?.attributes?.email}
                          </TableCell>
                          <TableCell style={{ fontSize: "14px", width: "25%" }}>
                            {item?.attributes?.contact_number}
                          </TableCell>
                          <TableCell style={{ fontSize: "14px", width: "25%" }}>
                            {item?.attributes?.company_name}
                          </TableCell>

                          <TableCell
                            data-test-id={`accountStatus-${index}`}
                            style={{
                              fontSize: "14px",
                              cursor: "pointer",
                              width: "10%",
                            }}
                            align="right"
                            // onClick={() => {
                            //   this.accountProfileStatusModalOpen(
                            //     item?.id,
                            //     this.state.usersAccountData[index]?.id
                            //   );
                            // }}
                          >
                            <MoreVert />
                          </TableCell>
                        </TableRow>

                        <div style={{ marginBottom: "0.8rem" }}></div>
                        
                      </React.Fragment>
                    ))}
                  </TableBody>
                ) : (
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: 700,
                      color: "#e87424",
                    }}
                  >
                    Record not found
                  </div>
                )}
              </Table>
                                </div>):(<div style={{width:"100%",height:"100%"}}>
                                    <Formik
                                    data-testId="profileForm"
                                    enableReinitialize
                                    initialValues={{
                                      clientName:this.state.clientName,
                                      clientId:this.state.clientId,
                                      contactNo:this.state.contactNo,
                                      addressL1:this.state.addressL1,
                                      addressL2:this.state.addressL2,
                                      email: this.state.email,
                                      account: this.state.account,
                                      accountstatus: this.state.accountstatus,
                                      accountNumber: this.state.accountNumber,
                                      accountDate: this.state.accountDate,
                                      idNumber:this.state.idNumber,
                                      dob:this.state.dob
                                    }}
                                    onSubmit={(values, actions) => {
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        values,
                                    }) => (

                                    <form onSubmit={handleSubmit}>
                                        <div style={{ width:"100%",height:"100%",marginLeft:"1%"}}>
                                          <Typography className={classes.welcomeTitle}>Hello!, <span style={{fontWeight: 500,textTransform:"capitalize"}}>{values.clientName}</span></Typography>

                                          <Typography className={classes.cltId}>id:#<span >{values.clientId}</span></Typography>
                                              <Grid container style={{display:"flex",gap:"58px"}}  >
                                              <Grid item> 
                                              <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="clientName" className={classes.label} >Client name</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtClientName"}
                                                        id={"txtClientName"}
                                                        fullWidth
                                                        readOnly
                                                        className={classes.allowed}
                                                        name="clientName"
                                                        value={values.clientName}
                                                        autoComplete="clientName"
                                                        placeholder="Your client name"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="contactNo" className={classes.label} >Contact Number</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputEmail"}
                                                        id={"txtInputEmail"}
                                                        fullWidth
                                                        readOnly
                                                        className={classes.allowed}
                                                        name="contactNo"
                                                        value={values.contactNo}
                                                        autoComplete="contactNo"
                                                        placeholder="Your Contact Number"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="addressL1" className={classes.label} >Address Line 1</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputEmail"}
                                                        id={"txtInputEmail"}
                                                        fullWidth
                                                        readOnly
                                                        name="addressL1"
                                                        className={classes.allowed}
                                                        value={values.addressL1}
                                                        autoComplete="addressL1"
                                                        placeholder="Your Address Line 1"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="dob" className={classes.label} >DOB</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputEmail"}
                                                        id={"txtInputEmail"}
                                                        fullWidth
                                                        name="dob"
                                                        readOnly
                                                        className={classes.allowed}
                                                        value={values.dob}
                                                        autoComplete="dob"
                                                        placeholder="Your DOB"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="account" className={classes.label} >Account</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputEmail"}
                                                        id={"txtInputEmail"}
                                                        fullWidth
                                                        name="account"
                                                        value={values.account}
                                                        className={classes.allowed}
                                                        readOnly
                                                        autoComplete="account"
                                                        placeholder="Your Account"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="idNumber" className={classes.label} >ID Number</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputEmail"}
                                                        id={"txtInputEmail"}
                                                        className={classes.allowed}
                                                        fullWidth
                                                        name="idNumber"
                                                        readOnly
                                                        value={values.idNumber}
                                                        autoComplete="idNumber"
                                                        placeholder="Your Id Number"
                                                    />
                                                </Grid>
                                                </Grid>
                                                <Grid item >
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="clientId" className={classes.label} >Client ID</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputPassword"}
                                                        id={"txtInputPassword"}
                                                        fullWidth
                                                        name="clientId"
                                                        readOnly
                                                        value={values.clientId}
                                                        className={classes.allowed}
                                                        autoComplete="new-Client ID"
                                                        placeholder="Your Client ID"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="email" className={classes.label} >Email ID</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputPassword"}
                                                        id={"txtInputPassword"}
                                                        className={classes.allowed}
                                                        fullWidth
                                                        name="email"
                                                        readOnly
                                                        value={values.email}
                                                        autoComplete="new-Email ID"
                                                        placeholder="Your Email ID"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="addressL2" className={classes.label} >Address Line 2</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputPassword"}
                                                        id={"txtInputPassword"}
                                                        fullWidth
                                                        name="addressL2"
                                                        readOnly
                                                        className={classes.allowed}
                                                        value={values.addressL1}
                                                        autoComplete="new-addressL2"
                                                        placeholder="Your Address Line 2"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="accountstatus" className={classes.label} >Account Status</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputPassword"}
                                                        id={"txtInputPassword"}
                                                        fullWidth
                                                        name="accountstatus"
                                                        readOnly
                                                        className={classes.allowed}
                                                        value={values.accountstatus}
                                                        autoComplete="new-Account Status"
                                                        placeholder="Your Account Status"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="accountNumber" className={classes.label} >Account number</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputPassword"}
                                                        id={"txtInputPassword"}
                                                        fullWidth
                                                        readOnly
                                                        name="accountNumber"
                                                        className={classes.allowed}
                                                        value={values.accountNumber}
                                                        autoComplete="new-Accountnumber"
                                                        placeholder="Your Account number"
                                                    />
                                                </Grid>
                                                <Grid className={classes.bottomMargin}>
                                                    <label aria-controls="accountDate" className={classes.label} >Account date</label><br />
                                                    <OutlinedInput 
                                                        data-test-id={"txtInputPassword"}
                                                        id={"txtInputPassword"}
                                                        fullWidth
                                                        readOnly
                                                        className={classes.allowed}
                                                        name="accountDate"
                                                        value={values.accountDate}
                                                        autoComplete="new-accountdate"
                                                        placeholder="Your Account date"
                                                    />
                                                </Grid>
                                                </Grid>
                                        </Grid>
                                    </div>
                                </form>
                                )}
                            </Formik>
                                </div>)}
                                {this.state.showAlert &&
              <Alert style={{ ...webStyle.alertDivs, position: "absolute" }} icon={false} severity="success">
                <div className={classes.messageDivs} >
                  <img src={this.state.error? imgAlertRed:imgAlert} style={{ verticalAlign: "bottom" }} />
                  <Typography variant="body1">{this.state.alertMessage}</Typography>
                </div>
              </Alert>}
                            </div>
        </Grid>
        <AddFeild
  openDialog={this.state.showFeildDialog}
  headingText= {"Add field"}
  btnOkText= {"Save"}
  handleCancel={()=>this.closeFeildDialog()}
  primaryColor={this.state.primaryColor}
  secondaryColor={this.state.secondaryColor}
  />
  <AddAccount
   openDialog={this.state.showContactDialog}
   headingText= {"Add contact"}
   btnCancelText="Cancel"
   btnOkText= {"Add"}
   handleOk= {(e:any)=>this.createClientContact(e)}
   handleCancel={()=>this.closeContactDialog()}
   primaryColor={this.state.primaryColor}
   secondaryColor={this.state.secondaryColor}
   />
       </Container>
        </ThemeProvider >
      </div>
    );
  }
}

export default withStyles(webStyle)(Profile);
// Customizable Area End
