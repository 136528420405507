import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  data:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  modalwordtopdf:boolean,
  showModalpdf:boolean,
  attachment_id:string;
  pdf_id:string;
  username:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SaveAsPdfController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  savePdfApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationCreateProjectMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      modalwordtopdf:true,
      showModalpdf:true,
      attachment_id:"",
      pdf_id:"",
      username:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestId === this.savePdfApiCallId) {
        this.savePdfApiCallIdSQ(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  token:string = "";

  handleChange = (event:any) => {
    this.setState({ username: event.target.value });
  }

  savePdfApiCallIdSQ = (responseJson: any) => {
    if (responseJson) {
      this.props?.data?.handleCloseSavepdf();
      toast.success(responseJson.messages);
    }
    else {
      toast.error(responseJson.messages);
    }
  }

  apiCall = async () => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    const formdata = new FormData();
    formdata.append("id", this.state.attachment_id);
    formdata.append("pdf_id", this.state.pdf_id);
    formdata.append("name", `${this.state.username}.pdf`);

    this.savePdfApiCallId = await this.savePdfapiCall({
      body: formdata,
      methods: "PATCH",
      endPoint: "/bx_block_saveaspdf2/saveaspdf2/save_pdf",
      token: this.token,
    });
  }

  savePdfapiCall = async (data: any) => {
    const { methods, endPoint, token, body } = data;
    const Header = {
      contentType: 'application/json',
      token,
    };
    const apiRequestMessageforCreateProjectweb = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforCreateProjectweb.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      Header
    );
    apiRequestMessageforCreateProjectweb.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforCreateProjectweb.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    body &&
      apiRequestMessageforCreateProjectweb.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(
      apiRequestMessageforCreateProjectweb.id,
      apiRequestMessageforCreateProjectweb
    );
    return apiRequestMessageforCreateProjectweb.messageId;
  }
 
  
  // Customizable Area End
}
