import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

import { createWorker } from "tesseract.js";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  ocr :string;
  editableOcr:string;
  imageData:any;
  isEditing:boolean;
  worker:any;
  showOcrscreen:boolean;
  typeSelected:boolean;
  showloader:boolean;
  currentToken:string;
  isExpanded:boolean;
  stringtypeSelected:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ocropticalcharacterrecognition2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createOCRApi:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    const userData=JSON.parse(localStorage.getItem("loginData") || "{}");
    const userToken= userData.token
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      ocr :"",
      editableOcr:"",
      imageData:null,
      worker:null,
      isEditing:false,
      showOcrscreen:true,
      typeSelected:false,
      currentToken:userToken,
      showloader:false,
      isExpanded:false,
      stringtypeSelected:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.initializeTesseractWorker();
  }

  async componentDidUpdate(_:any, prevState:any) {
    const { worker, imageData } = this.state;
    if (worker && imageData && prevState.imageData !== imageData) {
      console.log("bloew");
      
      this.convertImageToText();
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createOCRApi &&
      this.createOCRApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.navigateToUserLiberary()
      }
    } 
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleRadio = (value: string) => {
    this.setState({ typeSelected: !this.state.typeSelected, stringtypeSelected: value })
  }
   
  initializeTesseractWorker = async () => {
    const newWorker = await createWorker();
    await newWorker.load();
   //@ts-ignore
    await newWorker.loadLanguage("eng");
   //@ts-ignore
    await newWorker.initialize("eng");
    this.setState({ worker: newWorker });
  };


  convertImageToText = async () => {
    const { imageData, worker } = this.state;
    if (!imageData || !worker) return;
    this.setState({showloader:true})
    try {
      const { data: { text } } = await worker.recognize(imageData);
      console.log("OCR Text:", text);
      if(text){
        this.setState({ ocr: text, editableOcr: text,showloader:false });
      }
    } catch (error) {
      console.error("Error during OCR:", error);
    }
  };

  renderText = () => {
    if (this.state.isExpanded || this.state.editableOcr.length <= 100) {
      return this.state.editableOcr;
    }
    return this.state.editableOcr.substring(0, 100) + '...';
  };

  handleText=()=>{
    this.setState({isExpanded:!this.state.isExpanded})
  }

  formDataFormat = () => {
    const formData = new FormData();
  
  
        formData.append(`ocr[content]`,this.state.editableOcr )
        this.state.typeSelected ? formData.append(`ocr[csv]`,"true"):formData.append(`ocr[csv]`,"false")
        
     return formData;
  }

  createOCR= async()=>{
    const formData=this.formDataFormat()
    this.createOCRApi=await this.OcrApiCall({
      method: 'POST',
      endPoint: `/bx_block_optical_character_recognition/ocrs`,
      token:this.state.currentToken,
      body:formData
    })
  }
  

  OcrApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const OcrApiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    OcrApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    OcrApiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    OcrApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    OcrApiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
       body
        );
    runEngine.sendMessage(OcrApiRequestMessage.id, OcrApiRequestMessage);
    return OcrApiRequestMessage.messageId;
  };

  handleImageChange = (e:any) => {
    const file = e.target?.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageDataUri = reader.result;
      console.log({ imageDataUri });
      this.setState({imageData:imageDataUri});
    };
    reader.readAsDataURL(file);
  };


  handleOcrEdit = (e:any) => {
    this.setState({ editableOcr: e?.target?.value });
  };
  handleOcrEditor = () => {
    this.setState({isEditing:!this.state.isEditing });
  };

  handleCopyText = () => {
    const { editableOcr } = this.state;
    navigator.clipboard.writeText(editableOcr);
  };

  handleRevertEdit = () => {
    const { ocr } = this.state;
    this.setState({ editableOcr: ocr });
  };

  handleDeleteText = () => {
    this.setState({ editableOcr: "" });
  };

  handleCloseOcrMOdal=()=>{
    this.setState({showOcrscreen:false})
    this.navigateToUserLiberary()
  }
  navigateToUserLiberary=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationDocumentsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
