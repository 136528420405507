import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, Button, makeStyles, OutlinedInput, Grid, Typography } from '@material-ui/core';
import Select from "react-select";
import { Close } from '@material-ui/icons';
import { Formik } from 'formik';
import * as Yup from "yup";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            margin: '0',
            width: '658px',
            maxHeight: "calc(100% - 55px)",
        },
    },
    headingStyle: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    DatePicker:{
      width:"100%",
      height:"56px",
      padding:"10px",
      borderRadius: "8px",
      border: "1px solid #BDBBBB"
    },
  
    btnWrapper:{
        justifyContent: 'flex-end',
        padding: "24px 24px 24px 512px",
        borderTop: "1px solid #E2E6F0",
        display: "inline-flex",
        alignItems: "flex-start",
        gap: "16px",
    
        '& button': {
            padding: '16px',
            width: '105px',
            fontWeight:700,
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: '#E57727',
    },
      "text-transform": "none",
      "color": "white"
    },
    cancelBtn: {
      color: '#000',
      background: "#F6F2EF",
      width:"105px !important",
      height:"56px",
      borderColor: '#F6F2EF',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      textTransform: "capitalize"
  },
  
    textInputStyle: {
        margin: "0 !important",
        "& .MuiOutlinedInput-input": {
          textTransform:"uppercase",
        },
        "& .css-yk16xz-control":{
          color: "#000",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "24px", 
          height:"56px"
        },
        "& .css-1pahdxg-control":{
          height:"56px",
          color: "#000",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "24px", 
        },
        "& .css-2613qy-menu":{
          height:"106px", 
        },

        "&:hover .css-1pahdxg-control":{
          height:"56px",
          borderColor:"#2d6f8f"
        }
    },
    labelStyle:{fontSize:"14px",fontWeight:700,lineHeight:"22px",color:"#334155"},
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 40px",
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },

    reasonsSection: {
        // marginBottom: '25px !important',
        display:"flex",
        flexDirection:"column",
        gap:"10px",
        width:"520px"
    },
    downloaIcon: {
        cursor: "pointer"
    },
    toggleBtnss:{
     "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
        background: "#E57727",
    },
  },
  toggle:{
    width: '53px',
    height: '41px',
    "& .MuiSwitch-input":{
      width:"48px",
      height:"28px",
      left:"-41%"
    },
    "& .Mui-checked":{
      transform: "translateX(13px)",
      color:'#fff',
    },
    "& .MuiSwitch-track":{
      backgroundColor:"#989898"
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track":{
      background:'#E57727',
      opacity:"1"
    },
    "&>span":{
      top: '4px',
      left: '4px',
      "&>span":{
        "&>span":{
        width: '15px'
,
        height: '15px'
        }
      }
    }
}

})

const optionStatus = [
  { value: '', label: 'Choose Status',labelValue:"" },
	{ value: 0, label: "Not Started", labelValue: "not_started" },
	{ value: 1, label: "Ready to work", labelValue: "ready_to_work" },
];

export const optionPriority = [
  { value: '', label: 'Choose Priority',labelValue:"" },
	{ value: 0, label: "Low", labelValue: "low" },
	{ value: 1, label: "Medium", labelValue: "medium" },
	{ value: 2, label: "High", labelValue: "high" },
  { value: 3, label: "Critical", labelValue: "critical" },
];

const initialValues={ template_name: "",start_date:"", status: "",tags:"",description:"", priority:"" ,task_assignee:"",due_date:"" }
const validationSchema = Yup.object().shape({
  template_name: Yup.string().min(1, "Template name is too short")
  .max(20,"Template name is too long")
  .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Template name is invalid')
  .required("Template name is required."),
  status: Yup.string()
  .required('Status is required')
  .oneOf(['not_started', 'ready_to_work'], 'Invalid status'),
  start_date: Yup.date()
    .required('Start date is required'),
  due_date: Yup.date()
    .min(Yup.ref('start_date'), 'Due date must be later than start date')
    .required('Due date is required'),
  priority:Yup.string().required('Priority is required'),
  task_assignee: Yup.string().required('Assigne is required'),
  description:Yup.string().min(1, "Description is too short")
  .max(5000,"Description is too long")
  .required("Description is required."),
});
type ProjectTemplateProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText?: string,
    btnOkText: string,
    handleOk: any;
    handleCancel:any;
    vtEmailId:any;
    subHeading?: string;
    showDialogBtns?: boolean;
    showCrossBtn?: boolean;
}




const ProjectTemplate = (props:ProjectTemplateProps) => {
    const classes = useStyles();   
      
  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                    <span className={classes.subHeading}>{props.subHeading}</span>
                </div>
                <Close
                    className={classes.downloaIcon}
                    // onClick={props?.handleCancel}
                    onClick={() => {
                      props?.handleCancel()
                    }} 
                />
           </div>
        </DialogTitle>
        <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                  console.log("VALUES",values);
                  
                props.handleOk(values);
                actions.setSubmitting(false)
                actions.resetForm();
                }}
              >
                {({
                  handleSubmit,
                  touched,
                  handleBlur,
                  errors,
                  values,
                  setFieldValue,
                  handleChange,
                }) => (
                  <form style={{margin:"0"}}onSubmit={handleSubmit}>

       <DialogContent>

<div style={{display:"grid",padding: "24px 40px 8px",gap:"16px"}}>
          <Grid className={classes.reasonsSection} item xs={12}>
          
               <label className={classes.labelStyle}>Template name</label>
               <OutlinedInput
                  
                    fullWidth
                    name="template_name"
                    id="template_name"
                    value={values.template_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter template name"
                />
                    {touched.template_name && errors.template_name && (
                    <Typography color="error" >{errors.template_name}</Typography>
                    )}
                
          </Grid>

          <Grid item xs={12}>
            <label  className={classes.labelStyle}>Status:</label>
            <Select
              name="status"
              data-test-id="status"
              className={classes.textInputStyle}
              placeholder="Choose Status"
              options={optionStatus}
              onBlur={handleBlur}
              value={optionStatus.find((option: any) => option.value === values.status)}
              onChange={(option: any) =>
                setFieldValue('status', option?.labelValue)
              }
            />
            {touched.status && errors.status && (
              <Typography color="error" >{errors.status}</Typography>
            )}
          </Grid>
          <div style={{width: "520px",height: "1px",background:"#E2E8F0"}}></div>
  
          <Grid style={{display:"flex",gap:"24px"}} item xs={12} >
          <Grid item style={{display: "grid",gap: "5px",width:"248px"}} >
          <label className={classes.labelStyle}>Start Date</label>
          {/* @ts-ignore */}
          <CustomDatePicker
            classes={classes.DatePicker}
            placeholder='Start Date'
            onChange={(date) => {
              setFieldValue('start_date', date);
            }}
            onBlur={handleBlur}
            fieldName='start_date'
            restrictMinimumDate={true}
          />
          {touched.start_date && errors.start_date && (
               <Typography color="error" >{errors.start_date}</Typography>
               )}
     </Grid>
     <Grid item style={{display: "grid",gap: "5px",width:"248px"}} >
     <label className={classes.labelStyle}>Due Date</label>
     {/* @ts-ignore */}
          <CustomDatePicker
          classes={classes.DatePicker}
          placeholder='Due date'
          onBlur={handleBlur}
          onChange={(date) => {
            setFieldValue('due_date', date); // Handle setting field value
          }}
          fieldName='due_date'
        />
          {touched.due_date && errors.due_date && (
               <Typography color="error" >{errors.due_date}</Typography>
               )}
      </Grid>
      </Grid>
      <div style={{width: "520px",height: "1px",background:"#E2E8F0"}}></div>
      <Grid item xs={12}>
            <label  className={classes.labelStyle}>Priority:</label>
            <Select
              name="priority"
              data-test-id="priority"
              className={classes.textInputStyle}
              options={optionPriority}
              onBlur={handleBlur}
              value={optionPriority.find((option: any) => option.value === values.priority)}
              onChange={(option: any) =>
                setFieldValue('priority', option?.labelValue)
              }
            />
            {touched.priority && errors.priority && (
              <Typography color="error" >{errors.priority}</Typography>
            )}
          </Grid>
     <Grid item xs={12}>
            <label  className={classes.labelStyle}>Task Assignee:</label>
            <Select
              name="task_assignee"
              data-test-id="task_assignee"
              className={classes.textInputStyle}
              options={props.vtEmailId}
              onBlur={handleBlur}
              value={props.vtEmailId.find((option: any) => option.value === Number(values.task_assignee))}
              onChange={(option: any) =>
                setFieldValue('task_assignee', option?.labelValue)
              }
            />
            {touched.task_assignee && errors.task_assignee && (
              <Typography color="error" >{errors.task_assignee}</Typography>
            )}
          </Grid>
     <Grid className={classes.reasonsSection} item xs={12}>
    
    <label className={classes.labelStyle}>Description</label>
      <OutlinedInput
        fullWidth
        multiline
        rows={5}
        name="description"
        id="description"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.description}
        placeholder="Type here"
        error={touched.description && Boolean(errors.description)}
      />
      {touched.description && errors.description && (
        <Typography color="error">{errors.description}</Typography>
      )}
      <Typography style={{color: "#334155",
        textAlign: "right",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px",}}>{`${values.description.length} /5000`}</Typography>
    
</Grid>
</div>
       </DialogContent>
        
    {/* <DialogActions className={classes.btnWrapper}> */}

<Grid className={classes.btnWrapper}>

<Button variant="contained"  className={classes.primaryBtnStyle} type='submit'>
                            {props.btnOkText}
                        </Button>
    </Grid>          
        
{/* 
        </DialogActions> */}
        </form>
                )}
        </Formik>

      </Dialog>
    </div>
  )
}

interface CustomDatePickerProps {
  onChange: (date: Date | null) => void;
  onBlur:(field: string | React.FocusEvent<any>) => void;
  placeholder: string;
  restrictMinimumDate?: boolean;
  classes:string;
  fieldName:string;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ onChange, onBlur,placeholder,fieldName, restrictMinimumDate,classes }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const minDate = restrictMinimumDate ? new Date() : undefined; // Set minimum date only if restrictMinimumDate is true

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onChange(date);
  };
  const handleBlur = () => {
    onBlur(fieldName); // Pass the field name to the onBlur function
  };

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      onBlur={handleBlur}
      placeholderText={placeholder}
      minDate={minDate}
      className={classes}
    />
  );
};

export default ProjectTemplate

