// Customizable Area Start
import React from "react";
import {
    Container,
    Paper,
    Button,
    Grid,
    Link,
    Typography,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import { CheckCircle, VisibilityOff, Visibility } from '@material-ui/icons';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { imgVinLogo } from "./assets";
import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController.web";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link as Redirect } from 'react-router-dom'
import CustomInput from "../../../components/src/CustomInput.web";
import { Alert } from "@material-ui/lab";
import RecoverySuccess from "../../../components/src/RecoverySuccess";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
});
const alertTypeToColor = {
    error: 'red',
    // Add more mappings as needed
};
export class NewPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (
            // Required for all blocks 
            <div className="mainDiv" style={{...useStyles.mainDiv,overflowY:"scroll"}}>
                <ThemeProvider theme={theme}>
                    <Container id="Container" className={classes.customContainer}>
                        <div className={classes.centerContent} >
                            <img className={classes.logoImage} src={imgVinLogo} alt="logo" />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                                marginTop: "20px"
                            }}
                        >
                            <Paper style={{ width: '100%' }} elevation={4} >

                                <div data-test-id="ForgotPasswordForm" >
                                    <Formik
                                        initialValues={{ password: "", confirmPassword: "" }}
                                        validationSchema={Yup.object().shape(this.state.passwordSchemaWeb)}
                                        onSubmit={(values, actions) => {
                                            this.resetPassword(values)
                                            actions.setSubmitting(false);
                                        }}
                                        data-testId="ResetPasswordForm"
                                    >
                                        {({
                                            handleSubmit,
                                            touched,
                                            values,
                                        }) => (
                                            <form style={{ margin: "0", marginBlock: "0" }} onSubmit={handleSubmit}>
                                                <div style={useStyles.formDiv}>
                                                    <div style={{ textAlign: "start" }}>
                                                        <Typography style={{ fontWeight: 700, fontSize: "24px", marginBottom: "20px" }} variant="h5" component="h5">
                                                            {this.ResetPassword}
                                                        </Typography>
                                                        <p className={classes.sublabel} style={{ wordBreak: "break-all" }} >{`${this.txtEnterNewPass} ${this.state.emailWriten}`}</p>
                                                    </div>
                                                    {this.state.showAlert &&
                                                        <Alert classes={{
                                                            message: classes.alertMessage
                                                        }}
                                                            style={{ width: "100%", padding: "0", marginTop: "10px" }} icon={false} severity="error">
                                                            <div style={{ display: "flex" }} >
                                                                <div style={{ border: "2px solid red" }}></div>
                                                                <Typography style={{ fontSize: "14px", color: "red", marginLeft: "10px", padding: "8px" }} variant="body1">{this.state.alertMessage}</Typography>
                                                            </div>
                                                        </Alert>}
                                                    <div className={classes.newPassDiv} >
                                                        <label aria-controls="email" style={useStyles.label} >New Password</label><br />
                                                        <CustomInput
                                                            required
                                                            fullWidth
                                                            name="password"
                                                            type={this.state.enablePasswordField ? "password" : "text"}
                                                            id="password"
                                                            autoComplete="new-password"
                                                            placeholder="Your new password"
                                                            endAdornment={
                                                                <InputAdornment style={useStyles.passIcon} position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => { this.handleClickShowNewPassword() }}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.enablePasswordField ? (
                                                                            <VisibilityOff />
                                                                        ) : (
                                                                            <Visibility />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </div>
                                                    <div style={{ marginBottom: "10px" }} >
                                                        <label aria-controls="email" style={useStyles.label} >Confirm New Password</label><br />
                                                        <CustomInput
                                                            required
                                                            fullWidth
                                                            name="confirmPassword"
                                                            type={this.state.enableConfirmPasswordField ? "password" : "text"}
                                                            id="confirmPassword"
                                                            autoComplete="new-password"
                                                            placeholder="Confirm your new password"
                                                            endAdornment={
                                                                <InputAdornment style={useStyles.passIcon} position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => { this.handleClickShowConfirmPassword() }}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.enableConfirmPasswordField ? (
                                                                            <VisibilityOff />
                                                                        ) : (
                                                                            <Visibility />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </div>
                                                    <Grid >
                                                        <div>
                                                            <Typography style={useStyles.textStyle}>Your password must contain</Typography>
                                                            <div style={{ display: "flex" }} >
                                                                {touched.password ? <CheckCircle style={{ color: this.passwordCondition(/^(?=.*[A-Z])/.test(values.password), "green", "red"), padding: "2px", width: "0.7em" }} /> : <CheckCircle style={{ color: "gray", padding: "2px", width: "0.7em" }} />}
                                                                <Typography style={useStyles.containStyle}>At least one capital letter</Typography>
                                                            </div>
                                                            <div style={{ display: "flex" }} >
                                                                {touched.password ? <CheckCircle style={{ padding: "2px", width: "0.7em", color: this.passwordCondition(/^(?=.*[a-z])/.test(values.password), "green", "red") }} /> : <CheckCircle style={{ color: "gray", padding: "2px", width: "0.7em" }} />}
                                                                <Typography style={useStyles.containStyle}>At least one lowercase letter</Typography>
                                                            </div>
                                                            <div style={{ display: "flex" }} >
                                                                {touched.password ? <CheckCircle style={{ color: this.passwordCondition(/^(?=.*\d)/.test(values.password), "green", "red"), padding: "2px", width: "0.7em" }} /> : <CheckCircle style={{ color: "gray", padding: "2px", width: "0.7em" }} />}
                                                                <Typography style={useStyles.containStyle}>At least one number</Typography>
                                                            </div>
                                                            <div style={{ display: "flex" }} >
                                                                {touched.password ? <CheckCircle style={{ color: this.passwordCondition((values.password.length >= 8 && values.password.length <= 20), "green", "red"), padding: "2px", width: "0.7em" }} /> : <CheckCircle style={{ color: "gray", padding: "2px", width: "0.7em" }} />}
                                                                <Typography style={useStyles.containStyle}>Password must be 8-20 characters long.</Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <div className={classes.centerContent}>
                                                        <Button
                                                            fullWidth
                                                            // @ts-ignore
                                                            className={classes.submitBtnStyle}
                                                            style={{ textTransform: "initial" }}
                                                            data-test-id="btnGetOtpForEmailButton"
                                                            id="btnGetOtpForEmailButton"
                                                            type="submit"
                                                        >
                                                            Set new password
                                                        </Button>
                                                    </div>

                                                    <Typography style={{ fontSize: "16px" }} >
                                                        Back to <Redirect style={useStyles.redirect} to="/EmailLogin">
                                                            <Link underline="none" style={useStyles.loginClick} href="#">Login</Link>
                                                        </Redirect>
                                                    </Typography>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>


                            </Paper>
                        </div>
                        <RecoverySuccess
                            openDialog={this.state.showRecoveryAlert}
                            headingText={'Password updated!!'}
                            subHeading={'Your password has been successfully updated.'}
                            btnOkText="Login"
                        />
                    </Container>
                </ThemeProvider>
            </div>
        );
    }
}

const useStyles = {
    mainDiv: {
        width: "100%",
        height: "100%",
        background: "#F6F2EF",
    },
    centerContent: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    logoImage: {
        height: "100%",
        width: 'auto'
    },
    redirect: {
        /*@ts-ignore*/
        textDecoration: "none",
    },
    customContainer: {
        maxWidth: '488px',
    },
    loginClick: {
        /*@ts-ignore*/
        textDecoration: "none",
        color: "#E57727",
        fontWeight: 700
    },
    label: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
    },
    textStyle: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        width: "327px"
    },
    alertMessage: {
        padding: "0",
    },
    newPassDiv: { margin: "10px 0px 10px 0px" },
    subTitle: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
    },
    textInputStyle: { marginTop: "10px" },
    labelInputStyle: {
        color: "#000",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px"
    },
    passIcon: { marginRight: "6px" },
    formDiv: { padding: "30px 40px" },
    sublabel: { color: "#0F172A", marginTop: "5px", fontWeight: 400, lineHeight: "24px", fontSize: '16px' },
    submitBtnStyle: {
        borderRadius: "8px",
        fontSize: "16px",
        background: "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
        "& .MuiButton-label":{
            width:"159px",
            height:"24px"
        },
        fontWeight: 700,
        height: "56px",
        padding: "10px 16px",
        color: "#fff",
        marginTop: "10px",
        marginBottom: "20px"
    },
    displayFlex: {
        display: "flex"
    },
    containStyle: {
        fontSize: "13px",
        margin: "4px"
    }
}
export default withStyles(useStyles)(NewPassword);
// Customizable Area End