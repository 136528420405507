import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, CircularProgress, OutlinedInput, Grid, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, TextField, Typography, } from '@material-ui/core';
// import LabelWithIcon from './LabelWithIcon';
import Select from "react-select";
import { Close } from '@material-ui/icons';
import CustomInput from './CustomInput.web';
import { Formik,useFormik } from 'formik';
import * as Yup from "yup";
import {getThemeColor} from './CommonPlatte';


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            gap: '40px',
            borderRadius: '8px',
            width: '600px'
        }
    },
    headingStyle: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    paraStyle:{
        textAlign: 'center',
    },
    btnWrapper:{
        justifyContent: 'end',
        padding: "24px 37px 24px 381px",
        borderTop: "1px solid #E2E6F0",
    
        '& button': {
            padding: '16px 20px',
            width: '182px',
            borderColor: "#E57727",
            fontWeight: 700,
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: "#E57727",
    },
      "text-transform": "none",
      "color": "white",
      cursor: "pointer"
    },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 40px",
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    reasonsSection: {
        marginBottom: '25px !important',
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    downloaIcon: {
        cursor: "pointer"
    },
    pushBackBtn: {
      border: '1px solid primary'
    },
    errorInputStyle:{
      border: '1px solid red'
    }
})
const enumXmlCsv={
    XML:"xml",
    CSV:"csv"
}
const initialValues={ oldPassword: "", newPassword: "" }

type ChangePasswordBoxProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText: string,
    btnOkText: string,
    handleOk: any;
    handleCancel:any;
    btnColor?:string;
    // handleInsertValue?:any;
    // insertValue?:string[];
    // btnLoading?:boolean;
    // inputDisable?: boolean;
    // columnNames?: string[];
    // testIDProps?: string;
    subHeading?: string;
    showDialogBtns?: boolean;
    // pushBackOptions?:any;
    // textId?:string;
    // setSelectedPushBackTo?: any;
    showCrossBtn?: boolean;
    // stepValue?: any;
    // typeSelected?:any;
    // handleRadio?:any;
    // handleFindText?:any;
    // dialogFind?:any;'
    passwordSchema:any;
}




const ChangePasswordBox = (props:ChangePasswordBoxProps) => {
    const classes = useStyles();
    const [oldPass,setOldPass] = useState("");
    const [newPass,setNewPass] = useState("");
    const [error,setError] = useState(false);
    
    // const handleSubmitFromOutside = () => {
    //     const { current: formikInstance } = 
    //     if (formikInstance) {
    //     //   this.checkErrorForStageTwoForm(formikInstance)
    //       formikInstance.handleSubmit();
    //     }
    // }
    const vaildate = Yup.object().shape ({
        oldPassword: Yup.string()
          .required("Please enter your old password")
          .matches(/^(?=.*[A-Z])/,"Password should have at least one capital letter")
          .matches(/^(?=.*[a-z])/,"Password should have at least one lowercase letter")
          .matches(/^(?=.*\d)/,"Password should have at least one number")
          .min(8, "Password must be at least 8 characters")
          .max(20, "Password should not be more than 20 characters"),
        newPassword: Yup.string()
          .required("Please enter your new password")
          .matches(/^(?=.*[A-Z])/,"Password should have at least one capital letter")
          .matches(/^(?=.*[a-z])/,"Password should have at least one lowercase letter")
          .matches(/^(?=.*\d)/,"Password should have at least one number")
          .min(8, "Password must be at least 8 characters")
          .max(20, "Password should not be more than 20 characters")
          .when("oldPassword", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().notOneOf(
              [Yup.ref("oldPassword")],"Password should not Match"
            )
          })
      });
      
  return (
    <>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                    <span className={classes.subHeading}>{props.subHeading}</span>
                </div>
                {props.showCrossBtn ? (<Close
                    className={classes.downloaIcon}
                    // onClick={props?.handleCancel}
                    onClick={() => {
                      setOldPass("");
                      setNewPass("");
                      props?.handleCancel()
                    }} 
                />) : <div></div>}
           </div>
        </DialogTitle>
        <Formik
                initialValues={initialValues}
                validationSchema={vaildate}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                props.handleOk(values);
                actions.resetForm();
                setOldPass("");
                setNewPass("");
                }}
              >
                {({
                  handleSubmit,
                  touched,
                  handleBlur,
                  errors,
                  values,
                  handleChange,
                }) => (
                  <form onSubmit={handleSubmit}>

       <DialogContent style={{ padding: "0px 40px"}}>

          <Grid className={classes.reasonsSection} item xs={12}>
          
               {/* <LabelWithIcon label={'Reason'} /> */}
               <label style={{fontSize:"16px",fontWeight:700,marginBottom:"5px"}}>Old password</label>
               <OutlinedInput
                    required
                    fullWidth
                    name="oldPassword"
                    id="oldPassword"
                    value={oldPass}
                    autoComplete="old-password"
                    onChange={(e)=>{handleChange(e);
                    console.log(e.target.value);
                    setOldPass(e.target.value)
                    }}
                    onBlur={handleBlur}
                    placeholder="Enter your old password"
                    error={touched.oldPassword && Boolean(errors.oldPassword)}
                />
                    {touched.oldPassword && errors.oldPassword && (
                    <Typography color="error" >{errors.oldPassword}</Typography>
                    )}
                
          </Grid>

          <Grid className={classes.reasonsSection} item xs={12}>
               {/* <LabelWithIcon label={'Reason'} /> */}
               <label style={{fontSize:"16px",fontWeight:700,marginBottom:"5px"}}>New password</label>
               <OutlinedInput
                    required
                    fullWidth
                    name="newPassword"
                    type="text"
                    id="newPassword"
                    // onChange={handleChange}
                    value={newPass}
                    onChange={(e)=>{handleChange(e);
                        console.log(e.target.value)
                        setNewPass(e.target.value)
                        }}
                        onBlur={handleBlur}
                    autoComplete="new-password"
                    placeholder="Enter your new password"
                    error={touched.newPassword && Boolean(errors.newPassword)}
                />
                         {touched.newPassword && errors.newPassword && (
                    <Typography color="error" >{errors.newPassword}</Typography>
                  )}
          </Grid>
       </DialogContent>
        
    {/* <DialogActions className={classes.btnWrapper}> */}

<Grid className={classes.btnWrapper}>
<Button variant="contained"  className={classes.primaryBtnStyle} style={{background:props.btnColor}} type='submit'>
                            {props.btnOkText}
                        </Button>
    </Grid>          
        
{/* 
        </DialogActions> */}
        </form>
                )}
        </Formik>

      </Dialog>
    </>
  )
}

export default ChangePasswordBox

