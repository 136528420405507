// Customizable Area Start
import React from "react";
import { array, object } from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Typography, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import { Close } from "@material-ui/icons";

const initialValues = { termCondition: false }

import BulkUploadingController, {
  Props,
} from "./BulkUploadingController.web";
import { MultipleFileUploadField } from "../../../components/src/fileupoad.web";
import FileUploadPop from "../../../components/src/FileUploadPop.web";
import { Alert } from "@material-ui/lab";
import { imgAlert, imgAlertRed } from "./assets";

export class BulkUpload extends BulkUploadingController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const { classes } = this.props;
    const {btnColor,textColor} = this.state;
    return (
      <div>
        <Dialog
          // open={props?.openDialog}
          open={this.state.dialog && this.state.openDialog}
          onClose={this.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          disableBackdropClick={true}
          className={classes.dialogWrapper}
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
            <div className={classes.dialogTitle}>
              <div>
                <span className={classes.headingStyle}>{this.headingText}</span>
              </div>
              <Close
                className={classes.downloaIcon}
                onClick={() => this.handleCancel()}
              />
            </div>
          </DialogTitle>


          <Formik
            data-testId="UploadForm"
            initialValues={{ files: [] }}
            validationSchema={object({
              files: array().min(1, 'At least one file is required'),
            })}
            onSubmit={async (values) => {
              this.uploadFileData(values.files);
            }}
          >
            {({ values, errors, isValid, handleSubmit }) => {
              return (
                <form style={{ margin: "0" }} onSubmit={handleSubmit}>
                  <DialogContent style={{ padding: "40px" }}>
                    <Grid container spacing={2} direction="column">
                      {this.state.showInput &&
                      <div style={{display:"grid"}}>
                      <label style={{marginLeft:"24px",marginBottom:"5px"}}>Client email ID</label>   
                      <OutlinedInput
                      id="input"
                      style={{width:"300px",height:"44px",marginLeft:"24px",marginBottom:"14px"}}
                      type="text"
                      placeholder="Enter client email id"
                      value={this.state.clientEmailId}
                      onChange={this.handleClientEmail}
                      />
                      {this.state.emailErrorShow && 
                      <Typography style={{color:"red",marginLeft:"24px",marginBottom:"10px", fontSize:"12px"}}>{this.state.emailError}</Typography>}
                      </div>
                       }
                      <MultipleFileUploadField name="files" textColor={textColor} />
                    </Grid>
                  </DialogContent>

                  <DialogActions className={classes.btnWrapper}>
                      <Button variant="contained" onClick={this.handleCancel} style={{color:textColor}} className={classes.cancelBtnStyle}>
                        {this.btnCancelText}
                      </Button>
                      <Button variant="contained" id="btnUpload" style={{background:btnColor}} className={classes.primaryBtnStyle} type='submit'>
                        {this.btnOkText}
                      </Button>
                  </DialogActions>

                </form>
              );
            }}
          </Formik>
          {this.state.showAlert &&
              <Alert style={{ ...compStyles.alertDivs, position: "absolute" }} icon={false} severity="success">
                <div className={classes.messageDivs} >
                  <img src={this.state.errors? imgAlertRed:imgAlert} style={{ verticalAlign: "bottom" }} />
                  <Typography variant="body1">{this.state.alertMessage}</Typography>
                </div>
              </Alert>}
        </Dialog>

        <FileUploadPop
          openDialog={this.state.showPopUp}
          fileStatus={this.state.filesStatus}
          headingText={this.state.heading}
          subHeading={this.state.subHeading}
          btnOkText={this.state.btnText}
          btnColor={btnColor}
          handleOk={(e:any) => {this.uploadDone(e)}}
        />
      </div>
    );


  }
}

const compStyles = {
  dialogWrapper: {
    "& [role='dialog']": {
      borderRadius: '8px',
      width: '600px'
    }
  },
  headingStyle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  paraStyle: {
    textAlign: 'center' as const,
  },
  btnWrapper: {
    display: "flex",
    gap: "16px",
    margin:"0",
    justifyContent: 'end',
    padding: "24px 24px 24px 320px",
    borderTop: "1px solid #E2E6F0",
  "& .MuiDialogActions-spacing > :not(:first-child)": {
    marginLeft: "0",
  },
    '& button': {
      padding: '16px 32px',
      width: '120px',
      borderColor: '#E57727',
      borderRadius: '8px',
    }
  },
  primaryBtnStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    "text-transform": "none",
    "color": "white"
  },
  cancelBtnStyle: {
    color: '#E57727',
    background: "#F6F2EF",
    display: "flex",
    padding: "16px 21px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    width: "159px",
    gap: "10px", borderRadius: "8px", textTransform: "none" as const
  },
  textInputStyle: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  alertDivs: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    top:"80%",
    left:"50%",
    transform:"translate(-50%,50%)",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  messageDivs: { display: "flex", gap: "8px" },
  dialogTitle: {
    textAlign: "left" as const,
    fontSize: "16px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 16px 24px 40px",
  },
  subHeading: {
    fontSize: "16px",
    margin: "0 4px"
  },
  confirmBtn: {
    borderRadius: '8px',
  },
  disableBtn: {
    color: "#fff",
    borderRadius: '8px',
    background: '#757575'
  },
  reasonsSection: {
    marginBottom: '25px !important',
    display: "flex",
    flexDirection: "column" as const,
    gap: "10px"
  },
  downloaIcon: {
    cursor: "pointer"
  },
  pushBackBtn: {
    border: '1px solid primary'
  },
  errorInputStyle: {
    border: '1px solid red'
  }
}
export default withStyles(compStyles)(BulkUpload)
// Customizable Area End



