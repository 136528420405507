
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import  {gapi} from "gapi-script"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes:any;
}

export interface S {
  // Customizable Area Start
  enablePasswordField: boolean;
  alertMessage:string;
  showAlert:boolean;
  alertType:string;
  dataLoading:boolean;
  userDetails:{},
  isUserLoggedIn:boolean
  showTermsDialog:boolean;
  showPrivacyDialog:boolean;
  txtTermsData:string;
  lastModified:string;
  txtPrivacyData:string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  createUserAccountAPI:any;
  loginUserAccountAPIgoogle:any;
  termsConditionDataAPI:any;
  privacyPolicyDataAPI:any;
  mainHeader:string;
  subHeader:string;
  labelEmail:string;
  labelPassword:string;
  FullName:string;
  btnTxtCreateAccount:string;
  checkTitle:string
  subCheckOneCapital:string;
  subCheckOneLowerCase:string;
  subCheckOneNumber:string;
  subCheckPasswordMustbe:string;
  txtTerms:string;
  clientData:string
    // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      enablePasswordField: true,
      alertMessage:"",
      showAlert:false,
      alertType:"",
      dataLoading:false,
      userDetails:{},
      isUserLoggedIn:false,
      showTermsDialog:false,
      showPrivacyDialog:false,
      txtTermsData:"",
      lastModified:"",
      txtPrivacyData:""
      // Customizable Area End
    };

    // Customizable Area Start
    this.mainHeader = configJSON.mainHeader;
    this.subHeader = configJSON.subHeader;
    this.labelEmail = configJSON.labelEmail;
    this.FullName = configJSON.FullName;
    this.labelPassword = configJSON.labelPassword;
    this.btnTxtCreateAccount=configJSON.btnTxtCreateAccount;
    this.checkTitle=configJSON.checkTitle;
    this.subCheckOneCapital=configJSON.subCheckOneCapital;
    this.subCheckOneLowerCase=configJSON.subCheckOneLowerCase;
    this.subCheckOneNumber=configJSON.subCheckOneNumber;
    this.subCheckPasswordMustbe=configJSON.subCheckPasswordMustbe;
    this.txtTerms=configJSON.txtTerms;
    this.clientData=configJSON.Clientdata

     // Customizable Area End
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
   
    // Customizable Area Start
    gapi.load("client:auth2",()=>{
      gapi.auth2.init({client_id:this.clientData})
    })
    this.termsConditionData();  
  
  this.privacyPolicyData();
    // Customizable Area End
  }
  async componentDidUpdate() {
    // Customizable Area Start
    if(this.state.showAlert){
      setTimeout(()=>{
        this.handleCloseAlert()
      },3000)
    }
    
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId1 = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse1 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      
      if (apiRequestCallId1 === this.loginUserAccountAPIgoogle) {
        
        
          
          localStorage.setItem("loginData", JSON.stringify(responseJson1?.meta))
              window.location.href = '/HomePage';
        
        

       
      }

      if (apiRequestCallId1 === this.createUserAccountAPI) {
        this.setState({dataLoading:true})
        if ( !responseJson1.errors) {
            window.location.href = '/EmailLogin';
        } else {          
          this.setState({alertMessage: responseJson1.errors[0].account})
          this.setState({showAlert: true,alertType:"error",dataLoading:false})
        }

        this.parseApiCatchErrorResponse(errorReponse1);
      }
      if (apiRequestCallId1 === this.termsConditionDataAPI) {
           const data=responseJson1?.data.attributes.description
         this.setState({txtTermsData:data})  
        
      }
      if (apiRequestCallId1 === this.privacyPolicyDataAPI) {
           const data=responseJson1?.data.attributes.description
           const date=responseJson1?.data.attributes.updated_at
          const formattedDate = new Date(date).toLocaleDateString('en-US', { year: 'numeric',month: 'short', day: 'numeric' });
         this.setState({txtPrivacyData:data,lastModified:formattedDate})  
       
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage1.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
    return requestMessage1.messageId;
  };
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
 
  SignUpFormSchema = () => {
    const schema = {
        fullName: Yup.string()
          .min(1, "Full name is too short")
          .max(20,"Full name is too long")
          .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Full name is invalid')
          .required("Full name is required."),
        
        email: Yup.string()
          .min(1,"Email is too short")
          .max(50,"Email is too long")
          .email().required("Email is required."),
     
        password: Yup.string()
          .required("Please Enter Password")
          .min(8,"Password must be at least 8 characters")
          .max(20,"Password should not be more than 20 characters")
          .matches(/^(?=.*[a-z])/, "Password must contain lowercase character")
          .matches(/^(?=.*[A-Z])/, "Password must contain uppercase character")
          .matches(/^(?=.*\d)/, "Password must contain at least one number"),
          
        termCondition: Yup.boolean().oneOf([true],"termAndConditionErrorMessage")
          .required("termAndConditionErrorMessage")
      }
      return schema
}
TermsCondition=()=>{
  this.setState({
    showTermsDialog: false,
  })
}
PrivacyPolicy=()=>{
  this.setState({
    showPrivacyDialog: false,
  })
}
TermsConditionOpen=()=>{
  this.setState({
    showTermsDialog: true,
  })
}
PrivacyPolicyOpen=()=>{
  this.setState({
    showPrivacyDialog: true,
  })
}
handleCloseAlert=()=>{
  this.setState({showAlert:false})
}
termsConditionData = async () => {    
  this.termsConditionDataAPI = await this.apiCall({
    method: 'GET',
    endPoint: '/bx_block_terms_and_conditions/terms_and_conditions/show',
  })
}
privacyPolicyData = async () => {    
  this.privacyPolicyDataAPI = await this.apiCall({
    method: 'GET',
    endPoint: '/bx_block_terms_and_conditions/privacy_policies/show',
  })
}

signUpUser = async (formValues: any) => {    
  this.createUserAccountAPI = await this.apiCall({
    contentType: 'application/json',
    method: 'POST',
    endPoint: 'account_block/accounts/account',
    body: {
      "data": {
          "type" : "email_account", 
          "attributes": {
               "full_name": formValues.fullName,
               "email": formValues.email,
               "password": formValues.password,
               "password_confirmation":formValues.password,
               "activated": true
          }
       }    
   },
    token:''
  })
}

loginUserGoogle = async () => {
  this.loginUserAccountAPIgoogle = await this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: 'account_block/accounts/create_social_account',
      body: {
        "data": {
            "type" : "social_account", 
            "attributes": {
                "type":"google",
                "serverAuthCode" : "",
                "access_token": this.state.userDetails,
            }
         }    
     }
    })
  }

responseGoogle = (response:any) => {
  
  this.setState({ userDetails: response.tokenId, isUserLoggedIn: true });

  this.state.isUserLoggedIn && this.loginUserGoogle()
};

 
  // Customizable Area End
}
