import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { downloadFile } from "../../../components/src/CommonPlatte";
import { Check } from "@material-ui/icons";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  classes?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  invoices:object[];
  pageNumber: number;
  totalDocs: string[];
  dataPerPage:any;
  token:string;
  searchTitle:string;
  openInvoiceForm:boolean;
  InvoiceTemplateList:object[];
  serviceList:string;
  showSortOptions:boolean;
  recurring:boolean;
  teamEmails:object[],
  alertMessage:string,
  sortByName:boolean;
  sortByDate:boolean;
  showAlert:boolean;
  openServiceForm:boolean;
  showLoader:boolean;
  searchInvoice:string;
  selectedSortOption:any;
  selectedInvoiceId:string;
  isVisibleModal:boolean;
  modalPosition:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  getInvoiceApi:string="";
  showInvoiceApi:string="";
  createInvoiceApi:string="";
  deleteInvoiceApi:string="";
  getServiceListAPI:string="";
  createServiceAPI:string="";
  searchInvoiceAPI:string="";
  sortInvoiceAPI:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      sortByName:false,
      sortByDate:false,
      recurring:false,
      searchTitle:"",
      invoice: "",
      searchInvoice:"",
      invoiceError: "",
      invoicePdf: "",
      pageNumber: 1,
      totalDocs: [],
      showSortOptions:false,
      selectedSortOption:"",
      InvoiceTemplateList:[
        {
          value: 0,
          label: "Select",
          labelValue: ""
      },
       ],
      openInvoiceForm:false,
      openServiceForm:false,
      token:Parsed?.token,
      dataPerPage:this.dataPerPageOptions[0],
      alertMessage:"",
      showAlert:false,
      showLoader:false,
      serviceList:"",
      teamEmails:[{
        value: 0,
        label: "Select",
        labelValue: ""
      }],
      modalPosition:{ top: 0, left: 0 },
      selectedInvoiceId:"",
      isVisibleModal:false,
      invoices:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
  
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    runEngine.debugLog("API Message Recived", message);

    if(apiRequestCallId === this.getInvoiceApi){
      const invoiceDetails = await responseJson.data;

      this.setState({invoices:invoiceDetails});
      this.setState({ showLoader: false })
    }

    if(apiRequestCallId === this.createInvoiceApi){
        this.setState({alertMessage:responseJson.data ? responseJson.messages:responseJson.meta.errors,showAlert: true})
        this.getInvoices()
      }

    if(apiRequestCallId=== this.deleteInvoiceApi){
        this.getInvoices()
        this.setState({alertMessage:responseJson.meta?responseJson.meta.messages:responseJson.errors[0],showAlert: true,isVisibleModal:false}) 
    }

    if(apiRequestCallId === this.getServiceListAPI){
      const newArray = responseJson.data.map((obj:any, index:any) => {
        const name = obj.attributes.name;
    
        return {
            value: index,
            label: name,
            labelValue: name
        };
    });

    this.setState({ serviceList: newArray });
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
    this.getInvoices()
    this.getServiceList()
  }

  handleGenerateInvoice=()=>{
    this.setState({openInvoiceForm:!this.state.openInvoiceForm})
  }
  handleCreateService=()=>{
    this.setState({openServiceForm:!this.state.openServiceForm})
  }
  handleRecurringService=()=>{
    this.setState({recurring:!this.state.recurring})
  }
  getFile=(url:string,fileName:string)=>{
    downloadFile(url,fileName)
  }
  showSort=()=>{
    this.setState({showSortOptions:!this.state.showSortOptions})
  };
  handleSortBy = (sort: string) => {
    switch (sort) {
      case "name":
        return this.setState({ sortByName: true, sortByDate: false }, () => this.sortInvoice());
      case "created_at":
        return this.setState({ sortByName: false, sortByDate: true }, () => this.sortInvoice());
      default:
        break;
    }
  }
  getStartIcon = () => {
    return this.state.sortByName === true ? <Check /> : null;
  };
  getIcon = () => {
    return this.state.sortByDate === true ? <Check /> : null;
  };
  SearchInvoices = (searchedVal: any) => {
    this.setState({ searchInvoice: searchedVal.target.value }, () =>
      this.searchResultFolder(this.state.searchInvoice)
    );
  };



  searchResultFolder(value: any) {
    let filterFolder= this.state.invoices.filter((row: any) => {
      return row.attributes.invoice_items[0].name
        ?.toLowerCase()
        .includes(value.toLowerCase());
    });

    if (value == "") {
      this.getInvoices()
    } else {
      this.setState({ invoices: filterFolder });
    }
  }
  formatDates=(dates:string)=>{
    const date = new Date(dates);
    const formattedDate = [
        date.toLocaleDateString('en-US', { day: '2-digit' }),
        date.toLocaleDateString('en-US', { month: 'long' }),
        date.toLocaleDateString('en-US', { year: 'numeric' })
    ].join(' ');

    return formattedDate
  }
  getInvoices = async () => {


    this.getInvoiceApi=await this.invoiceBillingApi({
      method: 'GET',
      endPoint: `/bx_block_invoice/invoice`,
      token:this.state.token
    })
  };

  deleteInvoice = async (id:any) => {
    this.deleteInvoiceApi=await this.invoiceBillingApi({
      method: 'DELETE',
      endPoint: `/bx_block_invoice/invoice/${id}`,
      token:this.state.token
    })
  };
  handleShowModal = (event: any, id: any) => {
    const rect = event?.currentTarget?.getBoundingClientRect();
    if (rect) {
      const { top, left } = rect;
      this.setState({
        isVisibleModal: !this.state.isVisibleModal,
        selectedInvoiceId: id,
        modalPosition: { top: top - 168, left: left - 354 },
      });
    } else {
      console.error('Event or currentTarget is undefined');
    }
  }


  formDataFormat = (values:any) => {
    const formData = new FormData();  
  
        formData.append(`invoice[account_data]`,values.account_data )
        formData.append(`invoice[payment_method]`,values.payment_method )
        formData.append(`invoice[team_member]`,values.team_member )
        formData.append(`invoice[invoice_date]`,values.date)
        formData.append(`invoice[message_for_client]`,values.message_for_client )
        formData.append(`invoice[description]`,values.description )
        formData.append(`invoice[recurring]`,"false")
        formData.append(`invoice[tax]`,"12")
        values.product_name.map((items:any,index:any)=>{
          formData.append(`invoice[product_name][]`,items)
        })
     return formData;
  }
  createInvoice = async (data:any) => {  
    const formData=this.formDataFormat(data)

    this.createInvoiceApi=await this.invoiceBillingApi({
      method: 'POST',
      endPoint: `/bx_block_invoice/invoice`,
      body:formData,
      token:this.state.token
    })
    this.handleGenerateInvoice()
  };

  getServiceList = async () => {
    this.getServiceListAPI = await this.invoiceBillingApi({
      method: "GET",
      endPoint: "/bx_block_catalogue/catalogues",
      token: this.state.token,
    });
  };
  serviceFormData = (values:any) => {
    const formData = new FormData();  
  
        formData.append(`catalogue[price]`,values.amount )
        formData.append(`catalogue[currency]`,values.currency )
        formData.append(`catalogue[billing_period]`,values.billing )
        formData.append(`catalogue[manufacture_date]`,values.date)
        formData.append(`catalogue[name]`,values.name )
        formData.append(`catalogue[description]`,values.description )
        formData.append(`catalogue[recurring]`,"false")
     return formData;
  }

  createService = async (values:any) => {
    const formData=this.serviceFormData(values)
    this.createServiceAPI = await this.invoiceBillingApi({
      method: "POST",
      endPoint: "/bx_block_catalogue/catalogues",
      token: this.state.token,
      body:formData
    });
    this.handleCreateService()
  };

  sortInvoice = async () => {
    this.setState({showLoader:true})
    this.getInvoiceApi = await this.invoiceBillingApi({
      method: "GET",
      endPoint: `/bx_block_invoice/invoice/sort_invoice_detail?name=${this.state.sortByName}&created_at=${this.state.sortByDate}`,
      token: this.state.token,
    });
  };

  searchInvoice = async () => {
    this.searchInvoiceAPI = await this.invoiceBillingApi({
      method: "GET",
      endPoint: `/bx_block_invoice/invoice/search_invoice?data=${this.state.searchTitle}`,
      token: this.state.token,
    });
  };

  invoiceBillingApi = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const invoiceBillingRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    invoiceBillingRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    invoiceBillingRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    invoiceBillingRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      invoiceBillingRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(invoiceBillingRequestMessage.id, invoiceBillingRequestMessage);
    return invoiceBillingRequestMessage.messageId;
  };
  generateRandomPage = () => {
    const data = [];
    for (let i = 1; i <= 14; i++) {
      data.push(`Item ${i}`);
    }
    this.setState({ totalDocs: data });
    return data;
  };

  totalData = this.generateRandomPage()

  PageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ pageNumber: value });
  };
   dataPerPageOptions = [14,28,42,50,70];
  handleItemsPerPage = (event:any) => {
    const newValue = parseInt(event.target?.value, 10);
     this.setState({dataPerPage:newValue})
    this.setState({pageNumber:1})
  };
  gotoInvoiceDetails=(id:any)=>{
    localStorage.setItem("invoiceId",JSON.stringify(id))
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "InvoiceDetails");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationInvoiceDetailsMessage)
      );
      raiseMessage.addData(
        getName(MessageEnum.NavigationInvoiceDetailsMessage),
        id
      );
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg);
  }
  // Customizable Area End
}
