import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography, } from '@material-ui/core';
import { Link as Redirect } from 'react-router-dom'


const useStyles = makeStyles({
    dialogWrapper: {
        "& [role='dialog']": {
            // gap: '40px',
            borderRadius: '8px',
            width: '600px',
            padding: "40px 0px"
        }
    },
    headingStyle: {
        // textAlign: 'center',
        // '& h2': {
        //     fontWeight: 700,

        // },
        // fontSize: "16px"
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px", /* 133.333% */
        letterSpacing: "-0.12px",

    },
    paraStyle: {
        textAlign: 'center',
    },
    btnWrapper: {
        justifyContent: 'flex-end',
        padding: "24px 24px 24px 256px",
        borderTop: "1px solid #E2E6F0",
        display: "inline-flex",
        alignItems: "flex-start",
        gap: "20px",
        flexShrink: 0,

        '& button': {
            padding: '16px 20px',
            width: '150px',
            borderColor: '#E57727',
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
        background: "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
        "text-transform": "none",
        "color": "white"
    },
    cancelBtnStyle: {
        background: "linear-gradient(to bottom, red, #C87674)",
        "text-transform": "none",
        "color": "white",
        borderRadius: "10px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        // textAlign: "left",
        // fontSize: "16px",
        // fontWeight: 700,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // borderBottom: "1px solid #E2E8F0",
        // padding: "24px 16px 24px 40px",
    },
    redirect:{
        /*@ts-ignore*/
        textDecoration: "none",
      },
    subHeading: {
        // fontSize: "16px",
        // margin: "0 4px"
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        textAlign:"center",
        lineHeight: "26px", /* 144.444% */
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    reasonsSection: {
        marginBottom: '20px'
    },
    downloaIcon: {
        cursor: "pointer",
        padding: "16px"

    },
    pushBackBtn: {
        border: '1px solid primary'
    },
    errorInputStyle: {
        border: '1px solid red'
    }
})
const enumXmlCsv = {
    XML: "xml",
    CSV: "csv"
}
const initialValues = { OldPassword: "", newPassword: "" }

type RecoverySuccessProps = {
    openDialog: boolean,
    headingText: string,
    btnOkText: string,
    subHeading:string
}




const RecoverySuccess = (props: RecoverySuccessProps) => {
    const classes = useStyles();

    return (
        <>
            <Dialog
                open={props?.openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                disableBackdropClick={true}
                className={classes.dialogWrapper}
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
                    <div className={classes.dialogTitle}>
                    <img src="/assets/image_success.svg"></img>
                    </div>
                </DialogTitle>
                <DialogContent style={{ padding: "0px 40px" }}>
                    <div style={{ display: "flex", flexDirection: "column",alignItems:"center", gap: "16px" }}>
                        <span className={classes.headingStyle}>{props?.headingText}</span>
                        <span className={classes.subHeading}>{props.subHeading}</span>
                        <Redirect className={classes.redirect} to="/EmailLogin">
                    <Typography style={{color:"#E57727",fontWeight:700}}>
                    {props.btnOkText}                        
                    </Typography>
                    </Redirect>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RecoverySuccess

