import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Grid,
    Link,
    Typography,
    InputAdornment,
    IconButton,
    Divider,

} from "@material-ui/core";
import { VisibilityOff, Visibility} from '@material-ui/icons';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { imgLogo, Frame, googleImg } from "./assets"
import { Formik } from "formik";
import { Alert } from "@material-ui/lab";
import { Link as Redirect } from "react-router-dom";
import * as Yup from "yup";
import GoogleLogin from "react-google-login";

// Customizable Area End

import EmailLoginController, {
    Props
} from "./EmailLoginController.web";
import CustomInput from "../../../components/src/CustomInput.web";
import Loader from "../../../components/src/Loader.web";
const initialValues = {
    email: "",
    password: "",
};
const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

export class EmailLogin extends EmailLoginController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes } = this.props;
       

        return (
            /* Customizable Area Start */
            <ThemeProvider theme={theme}>
                <Container maxWidth={"xl"} style={{ padding: "0", overflowX: "hidden" }}>
                    <Loader loading={this.state.Loading} />
                    <Grid container component="main" className={classes.root}>
                        <CssBaseline />
                        <Grid item xs={8} sm={8} md={6} className={classes.main}>
                            <img src={imgLogo} className={classes.img}></img>
                            <Box style={{...useStyles.mainBox,flexDirection: "column",transform: "translate(-50%, -50%)",position:"relative"}}>
                                <Typography className={classes.title}>
                                    {this.title}
                                </Typography>

                                {this.state.showAlert && <Alert classes={{
                                message: classes.alertMessage 
                            }}
                             style={{ width: "100%",padding:"0",marginTop:"10px" }} icon={false} severity="error">
                                <div style={{ display: "flex" }} >
                                    <div style={{border:"2px solid red"}}></div>
                                    <Typography style={useStyles.alerted} variant="body1">{this.state.alertMessage}</Typography>
                                </div>
                            </Alert>}

                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={Yup.object().shape(this.LoginFormSchema())}
                                    validateOnChange={false}
                                    validateOnMount={true}
                                    validateOnBlur={true}
                                    onSubmit={(values, actions) => {
                                        this.loginUser(values);
                                        actions.setSubmitting(false);
                                        actions.resetForm()
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        errors,
                                        touched,
                                        values,
                                        setFieldValue,
                                        handleChange,
                                        validateForm
                                    }) => (

                                        <form onSubmit={handleSubmit} >
                                            <Box component="form" sx={{ mt: 3 }}>
                                                <Grid container >

                                                    <Grid className={classes.bottomMargin} item xs={12}>
                                                        <label aria-controls="email" className={classes.label} >Email</label><br />
                                                        <CustomInput
                                                            data-test-id={"txtInputEmail"}
                                                            id={"txtInputEmail"}
                                                            fullWidth
                                                            name="email"
                                                            autoComplete="email"
                                                            placeholder="Your email"
                                                        />
                                                    </Grid>
                                                    <Grid className={classes.bottomMargin} item xs={12}>
                                                        <label aria-controls="password" className={classes.label} >Password</label><br />
                                                        <CustomInput
                                                            data-test-id={"txtInputPassword"}
                                                            id={"txtInputPassword"}
                                                            fullWidth
                                                            name="password"
                                                            type={this.state.enablePasswordField ? "password" : "text"}
                                                            autoComplete="new-password"
                                                            placeholder="Your password"
                                                            maxLength={20}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        data-test-id="handleClickShowPassword"
                                                                        onClick={() => { this.handleClickShowPassword() }}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.enablePasswordField ? (
                                                                            <VisibilityOff />
                                                                        ) : (
                                                                            <Visibility />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <p style={{
                                                            display: 'flex',
                                                            justifyContent: 'end',
                                                            marginTop: "-5px"
                                                        }}  >
                                                            <Redirect to="/ForgotPassword" style={{ textDecoration: "none" }}>

                                                                <Link href="#" style={{ color: "#E57727", fontWeight: 700, justifyContent: "flex-end !important", textDecoration: "underline" }}>
                                                                    Forgot password?
                                                                </Link>
                                                            </Redirect>
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    fullWidth
                                                    data-test-id={"btnEmailLogIn"}
                                                    type="submit"
                                                    className={classes.loginBtn}
                                                    style={{ textTransform: "initial" }}
                                                >
                                                    {this.title}
                                                </Button>





                                            </Box>
                                        </form>
                                    )}
                                </Formik>

                                <Grid container >
                                    <Box style={{ display: 'flex', alignItems: 'center',width:"100%",marginTop:"23px",justifyContent:"center",padding:"0 2rem" }}>
                                        <Divider style={{ width: '50%',border:"1px solid #E2E8F0",marginRight:'1rem' }} />
                                        <Typography style={{fontSize:'14px',fontWeight:700,height:'22px',color:"#64748B"}}>OR</Typography>
                                        <Divider style={{ width: '50%', border:"1px solid #E2E8F0",marginLeft:"1rem"}} />
                                    </Box>
                                    <GoogleLogin
                                     clientId={this.clientData}
                                     data-test-id="googleLogin"
                                     render={renderProps => (
                                    <Button
                                        fullWidth
                                        data-test-id="googleLogin"
                                        type="submit"
                                        className={classes.logingoogleBtn}
                                         onClick={renderProps.onClick}
                                        style={{ textTransform: "initial", marginTop: "37px",marginBottom:"37px",display:"flex",alignItems:"center" }}
                                    >
                                        <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                        <img src={googleImg} style={{ width: "24px", height: "24px"}} />
                                        <Typography style={{"color":"#334155","fontFamily":"Inter","fontSize":"16px",width:"202px","fontStyle":"normal","fontWeight":700,"lineHeight":"24px"}}>Continue with Google</Typography>
                                        </div>

                                    </Button>)}
                                     onSuccess={this.responseGoogle}
                                     onFailure={this.responseGoogle}
                                     cookiePolicy={'single_host_origin'}
                                    />
                                    <Grid item>



                                        <Typography>Don’t have an account &nbsp;
                                            <Redirect to="/EmailAccountRegistration" style={{ textDecoration: "none" }}>
                                                <Link href="#" style={{ color: "#E57727", fontWeight: 700 }}>
                                                    Sign up
                                                </Link>
                                            </Redirect>
                                        </Typography>

                                    </Grid>
                                </Grid>

                            </Box>
                        </Grid>

                        <Grid item xs={false} sm={4} md={6} className={classes.image} >
                            <div className={classes.wlcmDiv} style={{ position: "relative" }}>
                                <img src={Frame} className={classes.imgs}></img>
                                <div className={classes.wlcmImg} style={{ flexDirection: "column", }}>
                                    <h1 style={{ marginBottom: "0px" }}>{this.titleMain}</h1>
                                    <p>{this.titleSub}</p>
                                </div>
                            </div>


                        </Grid>

                    </Grid>

                </Container>
            </ThemeProvider>
            /* Customizable Area End */
        );
    }
}



const useStyles = ({
    root: {
        height: '100vh',
    },
    main: {
        background: "#fff",
    },
    mainBox:{
        display: "flex",
        padding: "10% 25%",
        width: "100%",
        top: "46%",
        left: "50%",
    },
    label:{
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px"
    },
    title: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
        marginBottom: "10px"
    },
    wlcmDiv: {
        display: "grid",
        padding: "13%",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
    },
    wlcmImg: {
        display: "inline-flex",
        alignItems: "center"
    },
    bottomMargin:{marginBottom:"15px !important"},
    image: {

        background: '#F6F2EF',
    },
    img: {
        width: "201px",
        height: "59px",
        marginTop: "7px",
        marginLeft: "27px"
    },
    imgs: {
        width: "auto",
        height: "auto",
        marginLeft: "15%"
    },
    alertMessage: {
        padding: 0,
    },
    alerted:{fontSize: "14px",color:"red",marginLeft:"10px",padding:"8px"},
    loginBtn: {
        display: "flex",
        width: "100%",
        height:"56px",
        color: "white",
        "& .MuiButton-label":{
            width:"44px",
            height:"24px"
        },
        marginTop: "26px",
        fontSize: "16px",
        padding: "10px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flex: "1 0 0",
        alignSelf: "stretch",
        borderRadius: "8px",
        fontWeight: 700,
        background: "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
    },

    logingoogleBtn: {
        display: "flex",
        // width: "360px",
        height: "56px",
        padding: "16px 79px",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        width: "100%",
        background: " #FFF",
        color: "#334155",

        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px"
    }

})

export default withStyles(useStyles)(EmailLogin)