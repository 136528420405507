// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getThemeColor,getSecondaryThemeColor } from "../../../components/src/CommonPlatte";
import React from "react";
export const configJSON = require("./config");

export interface ThemeSelect {
  backgroundColor: string;
  textColors: string;
  fontStyle: "normal" | "italic";
  isBold: boolean;
  underlined: boolean;
  textBackgroundColor: string;
}
export interface Props {
  navigation: any;
  id: string;
  classes:any;
}

interface S {
  theme: ThemeSelect;
  userToken: string | null;
  themeId: string | null;
  themesD: Array<Object>;
  themes: any;
  allThemesModalOpen: boolean;
  isItalic: boolean;
  theme_image:any;
  fileName:string;
  secondary_color:any;
  primary_color:any;
  createData:string;
  description:string;
  title:string;
  fetchedImage:string;
  defaultColor:string;
  showAlertPop:boolean;
  alertMessage:string;
  selectedImage:any;
  fileInput:any;
  isEdit:boolean;
  searchTheme:string;
  themeTypeSelect:boolean;
  primaryColor:string;
  secondaryColor:string;
  searchBar:boolean;
  searchResult:string
  showSearchResult:boolean;
  // isPlatformWeb: booolean;
}

interface SS {
  id: any;

}

const DEFAULT_THEME_COLOR: ThemeSelect = {
  backgroundColor: "#ffffff",
  textColors: "#000000",
  fontStyle: "normal",
  isBold: false,
  underlined: false,
  textBackgroundColor: "#ffffff",
};

export default class ThemeController extends BlockComponent<Props, S, SS> {
  ThemeApiCallId: string = "";
  updateApiCallId: string = "";
  updateThemeApi:string="";
  showThemeApi:string="";
  themeListApi:string="";
  createThemeApi:string="";
  searchThemeApi:string="";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

  
    this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
    ];
    const userData=JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const Token= userData.token
    this.state = {
          theme: DEFAULT_THEME_COLOR,
      userToken: Token,
      themeId: null,
      themesD: [],
      themes: [],
      selectedImage:"",
      theme_image:"",
      secondary_color:"",
      primary_color:"",
      description:"",
      title:"",
      searchResult:"",
      primaryColor:getThemeColor(),
      secondaryColor:getSecondaryThemeColor(),
      showAlertPop:false,
      alertMessage:"",
      fileName:"",
      fetchedImage:"",
      searchTheme:"",
      fileInput:React.createRef(),
      allThemesModalOpen: false,
      showSearchResult:false,
      isItalic: false,
      createData:"",
      searchBar:false,
      defaultColor:"",
      isEdit:false,
      themeTypeSelect:false,
        };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      }

  async componentDidMount() {    
    super.componentDidMount();
      this.themeList()
    }
    async componentDidUpdate() {
      getSecondaryThemeColor()
      if(this.state.showAlertPop){
            setTimeout(()=>{
            this.setState({showAlertPop:false})
          },5000)}
        } 

        async receive(from: string, message: Message) {
          runEngine.debugLog("Message Received", message);
    
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
          );
        if (
          apiRequestCallId === this.themeListApi
        ) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
    
            const themeData=responseJson.data;
            this.setState({themes:themeData,title:themeData[0].attributes.title,description:themeData[0].attributes.description})
            this.parseApiCatchErrorResponse(errorReponse);
        }
        if (
          apiRequestCallId === this.searchThemeApi
        ) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
    
          if (responseJson.data) {
            const themeData=responseJson.data;
            this.setState({themes:themeData,showSearchResult:false})
          }else{
            this.setState({searchResult:responseJson.meta.errors,showSearchResult:true})
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (
          apiRequestCallId === this.showThemeApi
        ) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
    
          if (!responseJson.errors) {  
            const themeData=responseJson.data;
            this.setState({themeId:themeData.id,fetchedImage:themeData.attributes.theme_image.url,
              title:themeData.attributes.title,description:themeData.attributes.description})
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (
          apiRequestCallId === this.updateThemeApi
        ) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
    
          if (!responseJson.errors) {
            this.setState({isEdit:false,showAlertPop:true,alertMessage:"Theme preferences saved"})
            this.themeList()
            window.location.reload()
            const themeData=responseJson.data
            localStorage.setItem("themeColor",JSON.stringify(themeData.attributes))
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (
          apiRequestCallId === this.createThemeApi
        ) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
    
          if (!responseJson.errors) {
            this.setState({isEdit:false,showAlertPop:true,alertMessage:"Theme created successfully"})
            this.themeList()
            this.goToThemes()
            window.location.reload()
            const themeData=responseJson.data
            localStorage.setItem("themeColor",JSON.stringify(themeData.attributes))
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        }


  handleTheme=()=>{
    this.setState({themeTypeSelect:!this.state.themeTypeSelect})
  }
  handlePrimeColorChange=(color:any)=>{
    this.setState({ primary_color: color });
  }

  handleSecondColorChange=(color:any)=>{
    this.setState({ secondary_color: color });
  }

  handleImageChange = (e:any) => {
    const fileInput = this.state.fileInput.current;
    if (fileInput.files.length > 0) {
        const file = fileInput.files[0];
        if (file) {
            const imageURL = (window.URL || window.webkitURL).createObjectURL(file);
            this.setState({ selectedImage: file, theme_image: imageURL, fileName: file.name });
        }
    }
  };
  defaultColor=(value:string)=>{
    this.setState({defaultColor:value})
  }
 handleButtonClick = () => {
    if (this.state.fileInput.current) {
      this.state.fileInput.current.click();
    }
  };
  renderThemeList=()=>{
    return !this.state.isEdit && !this.state.showSearchResult
  }
  editTheme=(id:any)=>{
    this.setState({isEdit:!this.state.isEdit})
    this.showTheme(id)
  }
  themeList=async ()=>{
    this.themeListApi=await this.themeApiCall({
        method: 'GET',
        endPoint: 'bx_block_themes/themes',
        token:this.state.userToken
      })
  }
  getFileName=()=>{
    return this.state.selectedImage ? this.state.fileName : "Upload logo"
  }
  themeFormdata=(values:any)=>{    
    const formData = new FormData();  

    formData.append(`theme[title]`, values.title)
    formData.append(`theme[description]`, values.description)
    values.theme_image && formData.append(`theme[theme_image]`, this.state.selectedImage)

    if(this.state.themeTypeSelect){
      formData.append(`theme[default_theme]`,"true")
      formData.append(`theme[primary_color]`,this.state.defaultColor)
      formData.append(`theme[secondary_color]`,this.state.defaultColor)
    }else{
      formData.append(`theme[default_theme]`,"false")
      formData.append(`theme[primary_color]`,values.primary_color)
      formData.append(`theme[secondary_color]`,values.secondary_color)
    }
    return formData;
  }
  showTheme=async (value:any)=>{
    this.showThemeApi=await this.themeApiCall({
        method: 'GET',
        endPoint: `/bx_block_themes/themes/${value}`,
        token:this.state.userToken
      })
  }
  updateTheme=async (value:any ,id:any)=>{
    const formData= this.themeFormdata(value)
    this.updateThemeApi=await this.themeApiCall({
        method: 'PUT',
        endPoint: `/bx_block_themes/themes/${id}`,
        token:this.state.userToken,
        body:formData
      })
  }
  createTheme=async (value:any)=>{
    const formData= this.themeFormdata(value)
    this.createThemeApi=await this.themeApiCall({
        method: 'POST',
        endPoint: `/bx_block_themes/themes`,
        token:this.state.userToken,
        body:formData
      })
  }
  previewTheme=( a:any ,b:any)=>{    
        this.setState({primaryColor:this.state.themeTypeSelect ? this.state.defaultColor: a ,secondaryColor:this.state.themeTypeSelect ? this.state.defaultColor:b})
  }
  themeSearch=(event:any)=>{
    this.setState({searchTheme:event?.target?.value})
  }
  handleSearchTheme=async()=>{
    this.searchThemeApi=await this.themeApiCall({
      method: 'GET',
      endPoint: `/bx_block_themes/themes/search_themes?theme[title]=${this.state.searchTheme}`,
      token:this.state.userToken,
    })
  }
  showSearchBar=()=>{
    this.setState({searchBar:!this.state.searchBar,searchTheme:""})
  }
  goToThemes=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationThemeMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToCreateTheme=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationCreateThemeMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  themeApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestThemeMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestThemeMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestThemeMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestThemeMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestThemeMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestThemeMessage.id, requestThemeMessage);
    return requestThemeMessage.messageId;
  };
  
}

// Customizable Area End
