// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getThemeColor } from "../../../components/src/CommonPlatte";



export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  ocr :string;
  editableOcr:string;
  imageData:any;
  worker:any;
  showOcrscreen:boolean;
  currentToken:string;
  ocrList:string[];
  showModal:boolean;
  selectedFile:any;
  allFiles:boolean;
  Xml:boolean;
  Csv:boolean;
  alertMessage:string;
  showAlert:boolean;
  primaryColor:string;
}

interface SS {
  id: any;}

export default class OcropticalcharacterrecognizationController extends BlockComponent<
  Props,
  S,
  SS
> {
  OCRListingApi:string="";
  filterOCRApi:string="";
  deleteOCRApi:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    const userData=JSON.parse(localStorage.getItem("loginData") || "{}");
    const userToken= userData.token

    this.state = {
      ocr :"",
      editableOcr:"",
      imageData:null,
      worker:null,
      showOcrscreen:true,
      currentToken:userToken,
      ocrList:[],
      primaryColor:getThemeColor(),
      showModal:false,
      selectedFile:"any",
      allFiles:true,
      alertMessage:"",
      showAlert:false,
      Xml:false,
      Csv:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  async componentDidMount() {
    this.OCRListing()
  }

  async componentDidUpdate() {
    if(this.state.showAlert){
      setTimeout(()=>{
      this.setState({showAlert:false})
    },5000)}
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.OCRListingApi &&
      this.OCRListingApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {

        this.setState({ocrList:responseJson.data});
      }
    } 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.filterOCRApi &&
      this.filterOCRApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {

        this.setState({ocrList:responseJson.data});
      }
    } 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteOCRApi &&
      this.deleteOCRApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.setState({alertMessage:responseJson.message,showAlert:true})
        this.OCRListing()
      }
    } 
  }

  handleFilterClick=(name:any)=>{
    switch(name){
      case "allFiles":
        return this.setState({allFiles:true,Xml:false,Csv:false}, ()=> this.OCRListing())
      case "xml":
        return this.setState({allFiles:false,Xml:true,Csv:false}, ()=>this.filterOCR(false))
      case "csv":
        return this.setState({allFiles:false,Xml:false,Csv:true}, ()=>this.filterOCR(true))
    }

  }


  OCRListing= async()=>{

    this.OCRListingApi=await this.OcrListApiCall({
      method: 'GET',
      endPoint: `/bx_block_optical_character_recognition/ocrs`,
      token:this.state.currentToken,
    })
  }
  filterOCR= async(csv:boolean)=>{

    this.filterOCRApi=await this.OcrListApiCall({
      method: 'GET',
      endPoint: `/bx_block_optical_character_recognition/ocrs/xml_csv_list?csv=${csv}`,
      token:this.state.currentToken,
    })
  }

  handleShowModal=(id:any)=>{
    this.setState({showModal:true,selectedFile:id})
  }

  deleteOCR= async(id:any)=>{

    this.deleteOCRApi=await this.OcrListApiCall({
      method: 'DELETE',
      endPoint: `/bx_block_optical_character_recognition/ocrs/${id}`,
      token:this.state.currentToken,
    })
  }

  OcrListApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const OcrlistApiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    OcrlistApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    OcrlistApiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    OcrlistApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    OcrlistApiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
        );
    runEngine.sendMessage(OcrlistApiRequestMessage.id, OcrlistApiRequestMessage);
    return OcrlistApiRequestMessage.messageId;
  };
}

// Customizable Area End