import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';


const useStyles = makeStyles({
    dialogWrapper: {
        "& [role='dialog']": {
            borderRadius: '8px',
            width: '600px'
        }
    },
    headingStyle: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px", /* 133.333% */
        letterSpacing: "-0.12px",

    },
    paraStyle: {
        textAlign: 'center',
    },
    btnWrapper: {
        justifyContent: 'flex-end',
        padding: "24px 24px 24px 425px",
        borderTop: "1px solid #E2E6F0",
        display: "inline-flex",
        alignItems: "flex-start",
        gap: "20px",
        flexShrink: 0,

        '& button': {
            padding: '16px 36px',
            width: '150px',
            borderColor: '#E57727',
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
        background: "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
        "text-transform": "none",
        "color": "white"
    },
    cancelBtnStyle: {
        background: "linear-gradient(to bottom, red, #C87674)",
        "text-transform": "none",
        "color": "white",
        borderRadius: "10px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        // textAlign: "left",
        // fontSize: "16px",
        // fontWeight: 700,
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        // borderBottom: "1px solid #E2E8F0",
        // padding: "24px 16px 24px 40px",
    },
    subHeading: {
        // fontSize: "16px",
        // margin: "0 4px"
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "26px", /* 144.444% */
        marginBottom: "24px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    reasonsSection: {
        marginBottom: '20px'
    },
    downloaIcon: {
        cursor: "pointer",
        padding: "16px"

    },
    pushBackBtn: {
        border: '1px solid primary'
    },
    errorInputStyle: {
        border: '1px solid red'
    }
})

type EmailUpdateBoxProps = {
    openDialog: boolean,
    headingText: string,
    btnCancelText?: string,
    btnOkText: string,
    btnColor:string,
    handleOk: any;
    handleCancel: any;
    subHeading?: string;
    showDialogBtns?: boolean;
    showCrossBtn?: boolean;
}




const EmailUpdateBox = (props: EmailUpdateBoxProps) => {
    const classes = useStyles();

    return (
        <>
            <Dialog
                open={props?.openDialog}
                onClose={props?.handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                disableBackdropClick={true}
                className={classes.dialogWrapper}
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
                    <div className={classes.dialogTitle}>
                        {props.showCrossBtn ? (<Close
                            className={classes.downloaIcon}
                            onClick={props?.handleCancel}

                        />) : <div></div>}
                    </div>
                </DialogTitle>
                <DialogContent style={{ padding: "0px 40px" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        <span className={classes.headingStyle}>{props?.headingText}</span>
                        <span className={classes.subHeading}>{props.subHeading}</span>
                    </div>
                </DialogContent>

                {props.showDialogBtns && <DialogActions className={classes.btnWrapper}>

                
                    <Button variant="contained"
                        // disabled={reason == "" ? true :false} 
                        style={{ borderRadius: "8px",fontSize:"16px", background:props.btnColor,fontWeight:700,textTransform: "capitalize" }}
                        className={classes.primaryBtnStyle}
                        onClick={() => {
                            props?.handleOk()
                        }}
                        color="primary"
                        // autoFocus
                    >
                        {props.btnOkText}
                    </Button>
                </DialogActions>}

            </Dialog>
        </>
    )
}

export default EmailUpdateBox

