import React from "react";
// Customizable Area Start
import {

    Container,
    Box,
    Button,
    Typography,
    TextField,
    InputAdornment
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { note, search } from "./assets"
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

import SearchController, { Props } from "./SearchController.web";
import { SearchOutlined } from "@material-ui/icons";

export default class Search extends SearchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
    
      const {btnColor}=this.state
        

        return (
            <ThemeProvider theme={theme}>
                {/* <Container maxWidth="md" > */}
                    {/* Customizable Area Start */}
                    <Loader loading={this.state.isLoading}/>
                    <Box sx={{ display: "flex", flexDirection: "column", 
                    width: "calc(100vw - 193px)", position: 'fixed', top: "0px", right: "60px", alignItems:"flex-start" ,justifyContent:'center',height:"100vh"}}>
                        <Box sx={{
                            "display": "flex", "height": "32px", "padding": "30px 1154px 30px 48px", "justifyContent": "space-between",
                            "alignItems": "center", "flexShrink": 0, "borderBottom": "1px solid #CBD5E1", width: "calc(100vw - 193px)", position: "sticky"
                        }}>

                            <Typography style={{ "color": "#0F172A", "fontFamily": "Inter", "fontSize": "24px", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "32px", "letterSpacing": "-0.12px" }}>Search</Typography>
                            <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "gap": "12px", height: "44px", paddingRight: "48px" }}>

                                <TextField
                                    type="search"
                                    id="search"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchOutlined style={{ width: "1.5rem", height: "1.5rem" }} />
                                            </InputAdornment>
                                        ),
                                        style: {
                                            "display": "flex", "width": "31.5rem", "height": "2.75rem", "alignItems": "center",
                                            "flexShrink": 0, "borderRadius": "0.5rem", "background": "#FFF", padding: ".625rem 0.5rem .625rem 0.5rem"
                                        },
                                        inputProps: {
                                            style: {
                                                padding: 0, "fontSize": "16px", "lineHeight": "24px", "fontWeight": 400, "fontFamily": "inter",

                                            } as React.CSSProperties,
                                        },


                                    }}
                                    style={{ fontFamily: "inter" }}
                                    placeholder="Search for documents, people, invoices, projects, tasks"
                                    variant="outlined"
                                    //  className={classes.searchBar}
                                    value={this.state.searchText}
                                    onChange={(searchValue) => this.requestSearch(searchValue)}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '1rem', fontWeight: 400, lineHeight: "1.5rem", fontFamily: "Inter", color: "#64748B",
                                        },
                                    }}
                                />

                                <Button onClick={this.handleShowResult}  data-test-id="searchbtn" style={{
                                    "display": "flex", "height": "2.75rem", "padding": ".625rem 1rem", "justifyContent": "center", "alignItems": "center", "gap": ".5rem", width: 'auto',
                                    "borderRadius": ".5rem", "background": btnColor

                                }}><Typography style={{ "color": "#FFF", "fontFamily": "Inter", "fontSize": "1rem", "fontStyle": "normal", "fontWeight": 700, "lineHeight": "1.5rem", textTransform: "none", }
                                } >Search</Typography></Button>


                            </div>

                        </Box>


                        {this.state.showResults  && this.state.searchList ?
                            <Box sx={webStyle.searchContent}>
                                <Box style={{display:"flex",justifyItems:"flex-start",flexDirection:"column",width:"100%"}}>
                                <Typography style={webStyle.serachHead}>Search results for <span style={webStyle.searchVar}>{this.serachText}</span></Typography>
                                <Typography style={webStyle.item}>{this.state.documentLength} items</Typography>
                                </Box>
                               
                               {this.state.searchList.map((item:any)=>(
                                item.attributes.documents && (
                                <Box sx={webStyle.documntBox} key={item.id}>
                                    <Box sx={{ display: "flex", alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', flexDirection: "column" }}>
                                            <Typography style={webStyle.dochead}>{item.attributes.documents.data.attributes.docs.filename}</Typography>
                                            <Box style={{ display: "flex", gap: "5px", alignItems: 'center' }}>
                                                <img src={note} style={{ width: "1.5rem", height: "1.5rem" }} />
                                                <Typography style={webStyle.docSubText}>{item.attributes.documents.data.attributes.docs.filename}</Typography>
                                            </Box>
                                        </Box>
                                        <ArrowForwardIosIcon id="goToPdfEdit" style={{ width: "1.5rem", height: "1.5rem", cursor:"pointer", position: "absolute", right: "1rem" }} onClick={()=>this.gotoPdfEdit(item.attributes.documents,item.attributes.documents.data.attributes.docs.filename)} />
                                    </Box>
                                </Box>)))}
                            </Box> :
                            <Box sx={webStyle.imgBox} className="imgBOX">
                                <img src={search} style={{ width: "400px", height: "400px" }} />
                                <Typography style={webStyle.imgbelowtext}>Search for documents, people, invoices, projects, tasks</Typography>

                            </Box>}
                    </Box>



                    {/* Customizable End Start */}
                {/* </Container> */}
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: any = {

    searchContent: {
        "display": "flex", 
        "flexDirection": "column", "alignItems": "center", 
        "gap": "20px",
        padding: "30px 0px 30px 48px",width:"101.5%",position:"relative",
        overflow:"auto",scrollBehaviour:"auto",height:"100vh"
    },
    serachHead: {
        "color": "#0F172A", "fontFamily": "Inter",
        "fontSize": "20px", "fontStyle": "normal", 
        "fontWeight": 400, "lineHeight": "28px"
    },
    searchVar: { "color": "#0F172A",
     "fontFamily": "Inter", "fontSize": "20px", "fontStyle": "normal",
      "fontWeight": 700, "lineHeight": "28px" },
    item:
        { "color": "#64748B", "fontFamily": "Inter", 
        "fontSize": "14px",
         "fontStyle": "normal", 
        "fontWeight": 400, "lineHeight": "22px" },
    documntBox: {
        "display": "flex", "padding": "16px", 
        position: 'relative',
        "alignItems": "center", "gap": "20px", "borderRadius": "8px", 
        "border": "1px solid  #CBD5E1", 
        "background": "#FFF",width:"calc(100% - 93px);",marginLeft:"-55px"
    },
    docSubText: { "color": "#64748B", "fontFamily": "Inter", 
    "fontSize": "14px", 
    "fontStyle": "normal", "fontWeight": 400,
     "lineHeight": "22px" },
    dochead: { "color": "#0F172A", "fontFamily": "Inter", "fontSize": "16px", "fontStyle": "normal", 
    "fontWeight": 700, "lineHeight": "28px" },
    imgbelowtext: { "color": "#000", 
    "textAlign": "center", "fontFamily": "Inter", 
    "fontSize": "20px", "fontStyle": "normal",
     "fontWeight": 400, "lineHeight": "28px" },
    imgBox: {
        display: "flex", justifyContent: "center",
         alignItems: "center", flexDirection: "column", gap: "52px",
        position: "relative", overflow: "auto",
         width: "108%", marginBottom: "2rem",height:"100vh"
    }

}
// Customizable Area End
