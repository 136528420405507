import React from "react";
// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CreateProjectController, {
	Props
} from "./CreateProjectController.web";
import { CreateProjectForm } from "../../../components/src/CreateProjectForm";
// Customizable Area End

const theme = createTheme({
	palette: {
		primary: {
			main: "#0000ff",
			contrastText: "#fff"
		}
	}
});

// Customizable Area End

export default class Createproject extends CreateProjectController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	  // Customizable Area Start
	  componentDidMount(): any {
		this.getColumnInCreateProject();
	  }
	  // Customizable Area End

	render() {
		const { model } = this.state;
		// Customizable Area Start
		return (
			<ThemeProvider theme={theme}>
				<CreateProjectForm
					toggleForm={this.state.toggleForm}
					handleClose={this.handleClose}
					createProjects={this.createProjects}
					createSubTask={this.createSubTask}
					model={this.state.model}
					formType={this.state.form}
					namesArray ={this.state.namesArray}
					editProject = {this.editProject}
				/>
			</ThemeProvider>
		);
		// Customizable Area End
	}
}

// Customizable Area Start

// Customizable Area End
