import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
import {getThemeColor,getSecondaryThemeColor} from "../../../components/src/CommonPlatte";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEditable: boolean;
  content: any;
  fileName:string;
  FilesInFolder: any;
  FilteredObject: any;
  pdfBlob: any;
  themePrimaryColor:string;
  themeSecondaryColor:string;
  showDeleteDialog:boolean;
  userType:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PdfEditController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFilesApiCallId: any;
  EditSignatureApiCallId: any;
  deletePdfApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPdfEditWebMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationUserLiberaryMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isEditable: false,
      fileName:"Sign document 1.pdf",
      content: "To run the test, you'll be connected to Measurement Lab (M-Lab) and your IP address will be shared with them and processed by them in accordance with their privacy policy. M-Lab conducts the test and publicly publishes all test results to promote Internet research. Published information includes your IP address and test results, but doesn’t include any other information about you as an Internet user.",
      FilesInFolder: [],
      FilteredObject: {},
      pdfBlob: null,
      themePrimaryColor:getThemeColor(),
      themeSecondaryColor:getSecondaryThemeColor(),
      showDeleteDialog:false,
      userType:""

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPdfEditWebMessage) === message.id) {
      const params = message.getData(
        getName(MessageEnum.NavigationPdfEditWebMessage)
      );
          this.setState({fileName:params})
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getFilesApiCallId) {
        this.getFilesApiCallIdSQ(responseJson)
      }
      if (apiRequestCallId === this.EditSignatureApiCallId) {
        this.EditSignatureApiCallIdSQ(responseJson)
      }
      if (apiRequestCallId === this.deletePdfApiCallId) {
        this.deletePdfApiCallIdSQ(responseJson)
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleEditable = () => {
    this.setState({ isEditable: true })
  }
  NavigationPdfEditMessage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPdfEditMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleNavigateEdit = (id: any) => {
    this.NavigationPdfEditMessage()
  }

  token: string = "";
  pdfId: string = "";
  attributesId: string = ""

  getFilesApiCallIdSQ = (responseJson: any) => {
    if (responseJson.data !== null && responseJson.data !== undefined) {
      this.setState({ FilesInFolder: responseJson.data.attributes.files })
    }
  }
  EditSignatureApiCallIdSQ = (responseJson: any) => {
    if (responseJson.attachment&&responseJson.attachment.data !== null && responseJson.attachment.data !== undefined) {
      localStorage.setItem('pdf_id', responseJson.attachment.data.attributes.files[0].id);
      toast.success(responseJson.message);
    }
  }

  getFilesInFolderlist = async () => {
    let userData = localStorage.getItem("loginData");

    let PdfId = localStorage.getItem("pdf_id")
    this.pdfId = PdfId ? JSON.parse(PdfId) : null

    let AttributesId = localStorage.getItem("attributes_id")
    this.attributesId = AttributesId ? JSON.parse(AttributesId) : null

    let someDataParsed = userData && JSON.parse(userData);

    this.token = someDataParsed?.token;

    this.getFilesApiCallId = await this.signatureAPICall({
      methods: "GET",
      endPoint: `/bx_block_bulk_uploading/attachments/${this.attributesId}?file_id=${this.pdfId}`,
      token: this.token,
    });

  };

  EditFiles = async (pdfFile: any) => {
    let PdfId = localStorage.getItem("pdf_id")
    this.pdfId = PdfId ? JSON.parse(PdfId) : null
    try {
      const formData = new FormData();
      formData.append('pdf_url', pdfFile);
      this.EditSignatureApiCallId = await this.signatureAPICall({
        methods: "PATCH",
        endPoint: `/bx_block_saveaspdf2/saveaspdf2/${this.attributesId}?file_id=${this.pdfId}`,
        token: this.token,
        Body: formData
      });

    } catch (error) {
      console.log('Error updating PDF in database:');
    }
  }

  callChildFunction = (argument: any) => {
    this.setState({ pdfBlob: argument });
  }

  convertBlobToPDF = () => {
    const pdfBlob = this.state.pdfBlob;
    const PDFURL = this.state.FilesInFolder;
    const fileName = PDFURL[0]?.file_name
    if (pdfBlob) {
      const pdfFile = new File([pdfBlob], `${fileName}`, { type: 'application/pdf' });
      return pdfFile;
    }
    return null;
  };

  handleConvertAndUploadPDF = () => {
    const pdfFile = this.convertBlobToPDF();
    if (pdfFile) {

      this.EditFiles(pdfFile);
    }
  };

  deletePdf = async () => {
    this.deletePdfApiCallId = await this.signatureAPICall({
      methods: "Delete",
      endPoint: `/bx_block_bulk_uploading/attachments/${this.attributesId}/delete_file?file_id=${this.pdfId}`,
      token: this.token,
    });
  }

  deletePdfApiCallIdSQ = (responseJson:any) => {
        if(responseJson.message === 'File Destroy Successfully'){
          toast.success(responseJson.messages)
          setTimeout(() => {
            this.navigateToDocuments()
          },2000)
        }else{
          toast.error(responseJson.message);
        }
  }

  deleteOK =()=>{
    this.setState({ showDeleteDialog: false })
    this.deletePdf();
  }


  buttonClicked=()=>{
    this.setState({
      ...this.state,
      showDeleteDialog: true
    });
  }

  cancelLogout=()=>{
    this.setState({
      showDeleteDialog: false,
    })
  }
  
  navigateToDocuments=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationDocumentsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  signatureAPICall = async (data: any) => {
    const { ContentType, methods, endPoint, token, Body } = data;
    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestMessageforSignature = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforSignature.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforSignature.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforSignature.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    Body &&
      apiRequestMessageforSignature.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),

        Body
      );

    runEngine.sendMessage(
      apiRequestMessageforSignature.id,
      apiRequestMessageforSignature
    );
    return apiRequestMessageforSignature.messageId;
  };


  // Customizable Area End
}
