import React from "react";

import {
  Box,
  Button,
  Typography,
  Card,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { imgDelete, imgLogout, imgAlert } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import Settings2Controller, {
  Props,
} from "./Settings2Controller.web";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../../components/src/CustomInput.web";
import Toggle from "../../../components/src/Toggle.web";
import ChangePasswordBox from "../../../components/src/ChangePasswordBox";
import DeleteAccountBox from "../../../components/src/DeleteAccountBox";
import { Alert } from "@material-ui/lab";
import LogoutBox from "../../../components/src/LogoutBox";
import EmailUpdateBox from "../../../components/src/EmailUpdateBox";

export class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const {themePrimaryColor,themeSecondaryColor}= this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div className={classes.container}>
          <div style={{ ...useStyles.formcontainer, flexDirection: "column" }}>
            <Card className={classes.card} data-test-id="card">
              <Typography variant="h6" className={classes.heading}>Personal details</Typography>
              <Formik
                enableReinitialize
                initialValues={{
                  fullName: this.state.fullName,
                  email: this.state.email,
                  password: this.state.password,
                  location: this.state.address ?? "",
                }}
                validationSchema={Yup.object().shape(this.SettingFormSchema())}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                data-testId="SettingsForm"
                onSubmit={(values, actions) => {
                  this.updateUserData(values);
                  actions.setSubmitting(false);
                }}
              >
                {({
                  handleSubmit,
                  touched,
                  errors,
                  values,
                  handleChange,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box component="form" sx={{ mt: 3 }}>
                      <Grid container >
                        <Grid className={classes.outline} item xs={12} >
                          <label aria-controls="fullName" style={useStyles.label} >Full name</label><br />
                          <CustomInput
                            data-test-id="txtInputFullName"
                            autoComplete="given-name"
                            name="fullName"
                            required
                            className={classes.inputText}
                            fullWidth
                            id="fullName"
                            placeholder="Your full name"
                          />

                        </Grid>
                        <Grid className={classes.outline} item xs={12}>
                          <label aria-controls="email" style={useStyles.label} >Email</label><br />
                          <CustomInput
                            data-test-id="txtInputEmail"
                            fullWidth
                            className={classes.inputText}
                            id="email"
                            name="email"
                            autoComplete="email"
                            placeholder="Your email"
                          />
                        </Grid>
                        <Grid className={classes.outline} item xs={12}>
                          <label aria-controls="password" style={useStyles.label} >Location</label><br />
                          <CustomInput
                            data-test-id="txtInputLocation"
                            fullWidth
                            name="location"
                            type="text"
                            id="location"
                            autoComplete="new-password"
                            placeholder="Your location"
                            className={classes.inputText}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <label aria-controls="password" style={useStyles.label} >Password</label><br />
                          <div className={classes.toogleBtn}>
                            <input style={useStyles.backPass} disabled type="password" value="KAMAL@123"></input>
                            <p data-test-id="buttonClicked" onClick={() => this.buttonClicked("change")} style={{...useStyles.changePassword,color:themeSecondaryColor}}>Change password</p>
                          </div>
                        </Grid>

                        <div style={useStyles.partition}></div>

                        <Grid item xs={12} className={classes.actionDiv}>
                          <label aria-controls="password" style={useStyles.label} >Notifications</label>
                          <div className={classes.toogleBtn}>
                            <Typography className={classes.notifyStyle}>Weekly updates</Typography>
                            <Toggle
                              data-test-id="archive-customer"
                              isChecked={this.state.weeklyUpdates}
                              handleToggle={this.handleWeeklyUpdates}
                              color={this.state.themePrimaryColor}
                            />
                          </div>
                          <div className={classes.toogleBtn}>
                            <Typography className={classes.notifyStyle}>Special offers</Typography>
                            <Toggle
                              data-test-id="archive-customer"
                              isChecked={this.state.specialOffers}
                              handleToggle={this.handleSpecialOffer}
                              color={this.state.themePrimaryColor}
                            />
                          </div>
                        </Grid>
                        <div style={useStyles.partition}></div>
                        {this.state.userType != "vincertax_employee" && <Grid item xs={12}>
                          <div className={classes.toogleBtn}>
                          <label aria-controls="password" style={useStyles.label} >Profile theme settings</label>
                            <p data-test-id="updateButtonClicked" onClick={() => this.goToThemes()} style={{...useStyles.changePassword,color:themeSecondaryColor,display: "contents"}}>Update theme</p>
                          </div>
                        </Grid>}
                        {this.state.userType == "vincertax_employee" && <Grid item xs={12}>
                          <div className={classes.toogleBtn}>
                          <label aria-controls="password" style={useStyles.label} >Templates</label>
                            <p data-test-id="updateButtonClicked" onClick={() => this.goToTemplates()} style={{...useStyles.changePassword,color:themeSecondaryColor,display: "contents"}}>Templates</p>
                          </div>
                        </Grid>}
                    
                        <div style={useStyles.partition}></div>

                        <Grid className={classes.actionDiv} item xs={12}>
                          <label aria-controls="password" style={useStyles.label} >Account actions</label>
                          <div style={{ display: "flex" }}>
                            <img data-test-id="imglogoutbutton" src={imgLogout} style={useStyles.marginRight} onClick={() => this.buttonClicked("logout")}></img>
                            <p data-test-id="logoutbutton" style={useStyles.logoutLayout} onClick={() => this.buttonClicked("logout")}>Log out</p>
                          </div>
                          <div style={{ display: "flex" }} >
                            <img data-test-id="imgdeletebuttonClicked" src={imgDelete} style={useStyles.marginRight} onClick={() => this.buttonClicked("delete")}></img>
                            <p data-test-id="deletebuttonClicked" style={useStyles.deleteLayout} onClick={() => this.buttonClicked("delete")}>Delete account</p>
                          </div>
                        </Grid>

                      </Grid>
                      <div style={useStyles.partition}></div>

                      <Button
                        id="btnforUserSignUP"
                        style={{ ...useStyles.signUpBtn, background:themePrimaryColor,textTransform: "initial" }}
                        type="submit"
                        disableElevation
                      >
                        Save changes
                      </Button>

                    </Box>
                  </form>
                )}
              </Formik>

            </Card>

            {this.state.showAlert &&
              <Alert style={{ ...useStyles.alertDiv, position: "absolute" }} icon={false} severity="success">
                <div style={useStyles.messageDiv} >
                  <img src={imgAlert} style={{ verticalAlign: "bottom" }} />
                  <Typography variant="body1">{this.state.alertMessage}</Typography>
                </div>
              </Alert>}
          </div>
          <ChangePasswordBox
            data-test-id="ChangePassword"
            passwordSchema={this.state.passwordSchemaWeb}
            btnCancelText='cancel'
            btnOkText='Change password'
            openDialog={this.state.showChangeDialog}
            headingText={'Change password'}
            handleOk={(value: any) => this.changeOK(value)}
            handleCancel={() => this.ChangePassword()}
            showDialogBtns={true}
            btnColor={themePrimaryColor}
            showCrossBtn={true}
          />
          <DeleteAccountBox
            btnCancelText='Delete account'
            btnOkText='Keep account'
            openDialog={this.state.showDeleteDialog}
            headingText={'Are you sure you want to delete your account?'}
            subHeading={'This action can’t be undone and all the data stored in your account won’t be accessible anymore.'}
            handleOk={() => this.keepOK()}
            handleCancel={() => this.deleteOK()}
            showDialogBtns={true}
            btnColor={themePrimaryColor}
            textColor={themeSecondaryColor}
            showCrossBtn={true}

          />
          <EmailUpdateBox
            btnOkText='Ok'
            openDialog={this.state.showEmailUpdateBox}
            headingText={'Your email address has been updated!'}
            subHeading={'We sent you an email link to confirm your new email. Please open this link to verify the change'}
            handleOk={() => this.closeUpdate()}
            handleCancel={() => this.closeUpdate()}
            showDialogBtns={true}
            showCrossBtn={true}
            btnColor={themePrimaryColor}
          />
          <LogoutBox
            btnCancelText='Cancel'
            btnOkText='Log out'
            openDialog={this.state.showLogoutDialog}
            headingText={'Are you sure you want to log out of your account?'}
            handleOk={() => this.logoutOK()}
            handleCancel={() => this.cancelLogout()}
            showDialogBtns={true}
            showCrossBtn={true}
            btnColor={themePrimaryColor}
            textColor={themeSecondaryColor}
          />
        </div>
        {/* </Container> */}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const useStyles = ({
  label: {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px"
  },
  title: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "36px",
    fontStyle: "normal",
    // fontWeight: "700",
    lineHeight: "44px", /* 122.222% */
    letterSpacing: "-0.36px",
  },
  marginRight: { marginRight: "10px" },
  actionDiv: {
    gap: "15px",
    display: "grid"
  },
  toogleBtn: { display: "flex", justifyContent: "space-between" },
  alertDiv: {
    display: "inline-flex",
    padding: "8px 16px 8px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    marginTop:"10%",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 6px 15px -3px",
  },
  outline: {
    marginBottom: "10px !important"
  },
  startBtn: {
    display: "flex",
    width: "50%",
    color: "white",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "8px",
    flex: "1 0 0",
    borderRadius: "8px",
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
  },
  deleteLayout: {
    color: "#DC2626", 
    margin: "0",
    width: "119px",
    height: "24px",
    cursor:"pointer",
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: 700, 
  },
  logoutLayout:{
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    cursor:"pointer",
    width:"59px",
    margin:"0",
    height:"24px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  inputText: {
    backgroundColor: "#fff", 
  },
  partition: { width: "100%", height: "0px", margin: "40px 0px 40px", border: "1px solid #E2E8F0" },
  formcontainer: { width: "100%", display: "flex", alignItems: "center", justifyContent: "center", margin: "2rem" },
  container: { width: "100%", display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Inter" },
  checkTitle: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  messageDiv: { display: "flex", gap: "8px" },
  termsStyle: {
    marginRight: "15px",
    marginTop: "10px"
  },
  notifyStyle: {
    color: "#000",
    display: "flex",
    alignItems: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px", /* 150% */
    width: "271px",
    flexShrink: 0,
  },
  changePassword:{color: "#E57727", cursor:"pointer",margin: "0", width:"124px",lineHeight:"22px",height:"22px",fontSize:"14px",fontWeight:700},
  backPass:{background: "#F6F6F9", border: "none" },
  card: { width: "629px",maxHeight: "calc(100% - 10px)", backgroundColor: "#F4F6F9", padding: "40px" },
  img: {
    width: "auto",
    height: 50,
    marginTop: "10px",
    marginLeft: "30px"
  },
  alertMessage: {
    padding: 0,
  },
  heading: {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  },
  signUpBtn: {
    display: "flex",
    width: "142px",
    height: "44px",
    color: "white",
    // marginTop: "26px",
    fontSize: "16px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontFamily: "Inter",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "8px",
    // background: "#E57727",
  },
});
export default withStyles(useStyles)(Settings2);
// Customizable Area End
