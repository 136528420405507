import { IBlock } from "../../../framework/src/IBlock";

import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  data: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedDocFile: File | null
  openModal: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class PdfEditController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fileInput: React.RefObject<any>;
  convertPdfApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileInput = React.createRef();
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationCreateProjectMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedDocFile: null,
      openModal: true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }



  // Customizable Area Start

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestId === this.convertPdfApiCallId) {this.convertPdfApiCallIdSQ(responseJson);}
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleDocFileChange = (e: any) => {
    const fileInput = e?.target;
    const selectedDocFile = fileInput?.files && fileInput?.files[0];



    this.setState({ selectedDocFile }, () => { this.apiCall() });

  };
  convertPdfApiCallIdSQ = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const { data: { id, attributes: { files: [{ id: fileId }] } } } = responseJson;
      toast.success(responseJson.messages)
      this.props?.data?.handleShwmodalSavepdf(id, fileId);
    }
    else {
      toast.error(responseJson.messages);
    }
  }

  token: string = "";


  apiCall = async () => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;
    
    const formData = new FormData();
    if (this.state.selectedDocFile) {
      formData.append('file', this.state.selectedDocFile);
    }

    this.convertPdfApiCallId = await this.convertPdfapiCall({
      body: formData,
      methods: "POST",
      endPoint: "/bx_block_saveaspdf2/word_to_pdf",
      token: this.token,
    });
  }

  convertPdfapiCall = async (data: any) => {
    const { methods, endPoint, token, body } = data;
    const Header = {
      contentType: 'application/json',
      token,
    };
    const apiRequestMessageforConvertPdf = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforConvertPdf.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      Header
    );
    apiRequestMessageforConvertPdf.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforConvertPdf.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    body &&
      apiRequestMessageforConvertPdf.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(
      apiRequestMessageforConvertPdf.id,
      apiRequestMessageforConvertPdf
    );
    return apiRequestMessageforConvertPdf.messageId;
  }

  openDocFileInput = () => {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  };


  handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation()

  }

  handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const savePdfFunction = this.props?.data?.handleShwmodalSavepdf;

    this.setState({ selectedDocFile: event.dataTransfer.files[0] }, savePdfFunction);

  };




  // Customizable Area End
}
