import { Card, CardContent, Typography, createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

interface RectangleProps {
  color: string;
  label?: string;
}
const useStyles = makeStyles(theme =>
    createStyles({
      root: {
        display: "flex",
        width: "140px",
        height: "140px",
        paddingBottom: "16px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "8px",
background: "#FFF",
boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        flexShrink: 0,
        "& .MuiCardContent-root": {
          padding: "0px"
        },
        // Add the hover effect dynamically
        "&:hover": {
            cursor: "pointer",
          borderColor: (props: RectangleProps) => props.color,
        },
      },
      selected: {
        border:"2px solid",
        borderColor: (props: RectangleProps) => props.color,
      },
      unselected: {
        // border: "1px solid E2E8F0",
      },
      bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
      tupo: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "32px",
      },
      title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      },
    })
  );

const Rectangle: React.FC<RectangleProps & { selected: boolean; onSelect: () => void }> = ({ color,label,  selected,
    onSelect }) => {
    const cardClasses = useStyles({ color });
    const [colors,selectedColor]= useState(color),

   handleColor=(color:any)=>{
    selectedColor(color)
    console.log("SELECTED COLOR",colors)
    }

  return (
    <div style={{display:"grid",gap:"8px",
    // marginRight:"36px"
    }}>
      <Card className={`${cardClasses.root} ${selected ? cardClasses.selected : cardClasses.unselected}`}
      variant="outlined" onClick={()=>{handleColor(color); onSelect()}} >
      <CardContent>
      <div style={{display: "flex",
// padding: "11.176px 26.667px 2.235px 20px",
alignItems: "center",
margin: "14px 20px",
width: "98px",
height: "62px",
borderRadius: "8px",
background: color,
flex: "1 0 0",
alignSelf: "stretch",}}></div>
      <div style={{display:"grid",gap:"4px"}}>
        <div style={{width: "104px",
height: "20px",
opacity:"0.5",
margin:"0px 18px",
borderRadius: "6px",
background: color,
}}></div>
<div style={{width: "104px",
height: "20px",
opacity:"0.5",
margin:"0px 18px",
borderRadius: "6px",
background: color,
}}></div>
      </div>
      </CardContent>
    </Card>
    <Typography className={cardClasses.tupo}>{label}</Typography>
    </div>
  );
};

interface RectangleRowProps {
  rectangles: RectangleProps[];
  handleColor:any
}

const RectangleRow: React.FC<RectangleRowProps> = ({ rectangles,handleColor }) => {
    const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);    

  const handleCardSelect = (index: number,rectangle:any) => {
    setSelectedCardIndex(index);
    handleColor(rectangle.color);
  };
    return (
    <div className="rectangle-row" style={{display:"flex",flexWrap:"wrap" , gap: "35px",padding: "0 20px"}}>
      {rectangles.map((rectangle, index) => (
        <Rectangle key={index} {...rectangle} selected={index === selectedCardIndex}
        onSelect={() => handleCardSelect(index,rectangle)}/>
      ))}
    </div>
  );
};

export default RectangleRow;
