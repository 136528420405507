import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { getThemeColor, getSecondaryThemeColor } from "../../../components/src/CommonPlatte"
import { Check } from "@material-ui/icons";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  showDialog: boolean;
  selectedDoc: any;
  showCreateModal: boolean;
  createFolder: boolean;
  createSubFolder: boolean;
  pageNumber: number;
  totalDocs: string[];
  primaryColor: string;
  secondaryColor: string;
  userType: string;

  folders: any;
  createNewFolder: any;
  folderName: string;
  subFolderName: string
  folderNameErr: boolean;
  subFolderNameErr: boolean;
  selectedFolderErr: boolean;
  selectedFolder: any;
  showClientFolder: boolean;
  showFinAdvFolder: boolean;
  showFilterOptions: boolean;
  documentspdf: any;
  showREnameBox: boolean;
  renameFolderName: string;
  searchFolder: string;
  selectedSortOption: string;
  errorMessage: any;
  shouldUpdateLibrary: boolean;
  uerDatafor: string;
  showVTempFolder:boolean

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserLibraryController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getFoldersApiCallId: any;
  createNewFolderApiCallId: any;
  getFilesInfolderApiCallId: any;
  searchFoldersApiCallId: any;
  renameFolderApiCallId: any;
  sortApiCallId: any;
  showUserType: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPdfDocumentsMessage),
      getName(MessageEnum.NavigationSubFoldersMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationDocumentsMessage)
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      showDialog: false,
      selectedDoc: null,
      showCreateModal: false,
      createFolder: false,
      createSubFolder: false,
      pageNumber: 1,
      totalDocs: [],
      primaryColor: getThemeColor(),
      secondaryColor: getSecondaryThemeColor(),


      folders: [],
      createNewFolder: [],
      folderName: "",
      selectedFolder: "",
      subFolderName: "",
      showClientFolder: false,
      showFinAdvFolder: false,
      showFilterOptions: false,
      documentspdf: [],
      showREnameBox: false,
      renameFolderName: "",
      searchFolder: "",
      selectedSortOption: "",
      folderNameErr: false,
      subFolderNameErr: false,
      selectedFolderErr: false,
      errorMessage: "",
      shouldUpdateLibrary: false,
      userType: "",
      uerDatafor: "",
      showVTempFolder:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }




  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    let userData = localStorage.getItem("loginData");
    let type = userData && JSON.parse(userData);
    this.setState({ userType: type?.user_type ,uerDatafor:type?.user_type}, () => this.getFolderlist())





    // Customizable Area End
  }


  componentDidUpdate(prevProps: any, prevState: any) {

    if (prevState.shouldUpdateLibrary !== this.state.shouldUpdateLibrary) {
      this.getFolderlist();
      this.setState({ shouldUpdateLibrary: false });
    }
  }


  token: string = "";

  getFolderlist = async () => {
    let userData = localStorage.getItem("loginData");

    let someDataParsed = userData && JSON.parse(userData);
    this.token = someDataParsed?.token;
    let endPoint = `account_block/folders?role_name=${this.state.uerDatafor}`;
  
    this.getFoldersApiCallId = await this.folderAPIcall({
      methods: "GET",
      endPoint: endPoint,

      token: this.token,
    });

    this.updateFolderVisibility()
    

  };


  updateFolderVisibility = () => {
    const { uerDatafor } = this.state;
  
    if (uerDatafor === "white_lable_user") {
      this.setState({
        showFinAdvFolder: true,
        showClientFolder: false,
        showVTempFolder: false
      });
    } else if (uerDatafor === "vincertax_employee") {
      this.setState({
        showVTempFolder: true,
        showClientFolder: false,
        showFinAdvFolder: false
      });
    } else if (uerDatafor === "client") {
      this.setState({
        showVTempFolder: false,
        showClientFolder: true,
        showFinAdvFolder: false
      });
    }
  };
  




  handleClientClick = () => {
    this.setState({ uerDatafor: "client", showClientFolder: true, showFinAdvFolder: false,showVTempFolder:false , shouldUpdateLibrary: true})
  }

  handleVtEmpClick=()=>{
    this.setState({ uerDatafor: "vincertax_employee", showClientFolder: false, showVTempFolder:true, showFinAdvFolder: false, shouldUpdateLibrary: true })
  }

  handleFinAdvisorClick = () => {
    this.setState({ uerDatafor: "white_lable_user", showClientFolder: false, showFinAdvFolder: true,showVTempFolder:false , shouldUpdateLibrary: true},

    )
  }
  sortfolderDoc = async () => {



    this.sortApiCallId = await this.folderAPIcall({
      methods: "GET",
      endPoint: `account_block/folders/sort_folders?sort_option=${this.state.selectedSortOption}`,


      token: this.token,
    });

  };





  handleFilterClick = (filter: string) => {
    this.setState((prevState) => {
      const newSortOption = prevState.selectedSortOption === filter ? '' : filter;

      // Update the state
      return { selectedSortOption: newSortOption };
    }, () => {
      // Callback function is called after the state is updated
      if (this.state.selectedSortOption === '') {
        this.getFolderlist();
      } else {
        this.sortfolderDoc()
      }
    });
  };

  getStartIcon = (filter: string) => {
    return this.state.selectedSortOption === filter ? <Check /> : null;
  };





  formDataCall = () => {
    const formdata = new FormData();

    if (this?.state?.folderName !== null) {
      formdata.append('folder[name]', this.state.folderName);
      return formdata;
    }
  }


  formDataSubFolderCall = () => {
    const formdata = new FormData();

    if (this?.state?.subFolderName !== null) {
      formdata.append('sub_folder[name]', this.state.subFolderName);
      return formdata;
    }
  }


  createNewFolder = async () => {
    const formdata = this.formDataCall();
    this.createNewFolderApiCallId = await this.folderAPIcall({

      methods: 'POST',
      endPoint: `account_block/folders`,
      Body: formdata,
      token: this.token,


    })
    this.setState({ folderName: "", showCreateModal: false, selectedFolderErr: false, subFolderNameErr: false, shouldUpdateLibrary: true })
    await this.getFolderlist()
  }


  createNewSubFolder = async () => {
    const formdata = this.formDataSubFolderCall();
    this.createNewFolderApiCallId = await this.folderAPIcall({

      methods: 'POST',
      endPoint: `account_block/folders/${this.state.selectedFolder}/sub_folders`,
      Body: formdata,
      token: this.token,


    })
    this.setState({ folderName: "", showCreateModal: false, subFolderName: "", selectedFolder: "" })

  }
  formDataFolderRenameCall = () => {
    const formdata = new FormData();

    if (this?.state?.renameFolderName !== null) {
      formdata.append('folder[name]', this.state.renameFolderName);
      return formdata;
    }
  }

  formDataPdfDocRenameCall = () => {
    const formdata = new FormData();

    if (this?.state?.renameFolderName !== null) {
      formdata.append('name', this.state.renameFolderName);
      return formdata;
    }
  }

  RenameFolder = async (id: any) => {

    const formdata = this.formDataFolderRenameCall()

    this.renameFolderApiCallId = await this.folderAPIcall({

      methods: 'PATCH',
      endPoint: `account_block/folders/${id}`,
      Body: formdata,
      token: this.token,


    })
    this.setState({ renameFolderName: "", showREnameBox: false, showDialog: false, shouldUpdateLibrary: true })
    await this.getFolderlist()
  }

  navigateToScannedOcr = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationScannedOcrMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  RenamePdfDoc = async (id: any, attachment_id: any) => {

    const formdata = this.formDataPdfDocRenameCall()

    this.renameFolderApiCallId = await this.folderAPIcall({

      methods: 'PATCH',
      endPoint: `bx_block_bulk_uploading/attachments/${attachment_id}/rename_file?file_id=${id}`,
      Body: formdata,
      token: this.token,


    })
    this.setState({ renameFolderName: "", showREnameBox: false, showDialog: false, shouldUpdateLibrary: true })
    await this.getFolderlist()
  }


  navigateToOcrRecognation = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationOcrRecognationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  DeleteFolder = async (id: any) => {

    this.createNewFolderApiCallId = await this.folderAPIcall({

      methods: 'DELETE',
      endPoint: `account_block/folders/${this.state.selectedDoc.id}`,

      token: this.token,


    })
    this.setState({ shouldUpdateLibrary: true })

    const updatedItems = this.state.folders.filter(
      (item: any) => item.data.id !== id
    );
    this.setState({
      folders: updatedItems,
      showDialog: false
    });

  }


  DeletePdfDoc = async (id: any, attachment_id: any) => {

    this.createNewFolderApiCallId = await this.folderAPIcall({

      methods: 'DELETE',
      endPoint: `bx_block_bulk_uploading/attachments/${attachment_id}/delete_file?file_id=${id}`,

      token: this.token,


    })
    this.setState({ shouldUpdateLibrary: true })

    const updatedItems = this.state.documentspdf.filter(
      (item: any) => item.file_id !== id
    );
    this.setState({
      documentspdf: updatedItems,
      showDialog: false
    });

  }





  folderAPIcall = async (data: any) => {
    const { ContentType, methods, endPoint, token, Body } = data;
    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestMessageforFolderList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforFolderList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforFolderList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforFolderList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    Body &&
      apiRequestMessageforFolderList.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),

        Body
      );

    runEngine.sendMessage(
      apiRequestMessageforFolderList.id,
      apiRequestMessageforFolderList
    );
    return apiRequestMessageforFolderList.messageId;
  };

  handleShowDialogPdfDoc = (item: any) => {


    this.setState({ showDialog: !this.state.showDialog, selectedDoc: item.file_id })
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestIdFolder = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJsonFolder = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestIdFolder === this.getFoldersApiCallId) {
        if (responseJsonFolder.data !== null) {
          this.setState({ folders: responseJsonFolder.folders, documentspdf: responseJsonFolder.documents[0].attachment_data.attributes.files })


        }
      }

      if (apiRequestIdFolder === this.sortApiCallId) {
        if (responseJsonFolder.data !== null) {
          this.setState({ folders: responseJsonFolder.folders, documentspdf: responseJsonFolder.documents[0].attachment_data.attributes.files })

        }
      }



      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (getName(MessageEnum.NavigationDocumentsMessage) === message.id) {
      const params = message.getData(
        getName(MessageEnum.NavigationDocumentsMessage)
      );
    
         this.setState({uerDatafor:params,shouldUpdateLibrary:true})
         
    }


    // Customizable Area End
  }

  handleShowDialog = (item: any) => {

    this.setState({ showDialog: !this.state.showDialog, selectedDoc: item.data })
  }



  handleShowCreateFolder = () => {
    this.setState({ showCreateModal: true, createFolder: true, createSubFolder: false })
  }


  handleShowCreateSubFolder = () => {
    this.setState({ showCreateModal: true, createFolder: false, createSubFolder: true })
  }

  handleHideFolderModal = () => {
    this.setState({ folderName: "", showCreateModal: false, selectedFolder: "", subFolderName: "", folderNameErr: false, subFolderNameErr: false, selectedFolderErr: false })
  }
  handleNavigate = (name: any, id: any) => {

    this.NavigateToPdfDocument(id, name)

  }
  NavigateToPdfDocument(id: any, name: any) {
    localStorage.setItem("FolderName", JSON.stringify(name))
    localStorage.setItem("folderId", JSON.stringify(id))

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubFolder");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationSubFoldersMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationSubFoldersMessage),
      id,


    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }


  handleFolderName = (e: any) => {
    this.setState({ folderName: e.target?.value })
  }

  handleSubFolderName = (e: any) => {
    this.setState({ subFolderName: e.target?.value })
  }


  // NavigationBulkUploadMessage
  handleUploadDoc = () => {
    localStorage.removeItem("folderId");
    localStorage.setItem("defaultState", this.state.uerDatafor);
    const msg: Message = new Message(
      getName(MessageEnum.NavigationBulkUploadMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);

  }
  handleSelectFolder = (event: any) => {
    this.setState({ selectedFolder: event.target.value })
  }
  handleCreateFolderSubFolder = () => {
    this.state.createFolder ? this.createNewFolder() : this.createNewSubFolder()
  }

  handleShowFilterOptions = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions })
  }

  handleopenRenameBox = () => {
    this.setState({ showREnameBox: true })
  }

  handleCloseREnameBox = () => {
    this.setState({ showREnameBox: false, showDialog: false })
  }
  handleRenameFolder = (e: any) => {
    this.setState({ renameFolderName: e.target.value })
  }
  handleCancelRename = () => {
    this.setState({ renameFolderName: "" })
  }




  SerachRequestPdfDoc = (searchedVal: any) => {
    this.setState({ searchFolder: searchedVal.target.value }, () =>
      this.searchResultFolder(this.state.searchFolder)
    );
  };



  searchResultFolder(value: any) {
    let filterFolder = this.state.folders.filter((row: any) => {
      return row.data.attributes.name
        ?.toLowerCase()
        .includes(value.toLowerCase());
    });

    let filterPdfInFolder = this.state.documentspdf.filter((row: any) => {
      return row.file_name
        ?.toLowerCase()
        .includes(value.toLowerCase());
    });

    if (value == "") {
      this.getFolderlist()
    } else {
      this.setState({ folders: filterFolder, documentspdf: filterPdfInFolder });
    }
  }

  getBackgroundColor() {
    return this.state.showClientFolder ? this.state.primaryColor : "#F6F2EF";
  }

  getTextColor() {
    return this.state.showClientFolder ? "#ffffff" : "";
  }

  getFinAdvBackgroundColor() {
    return this.state.showFinAdvFolder ? this.state.primaryColor : "#F6F2EF";
  }

  getVtEmpBackgroundColor() {
    return this.state.showVTempFolder ? this.state.primaryColor : "#F6F2EF";
  }

  getFinAdvTextColor() {
    return this.state.showFinAdvFolder ? "#ffffff" : "";
  }

  getVtEmpTextColor() {
    return this.state.showVTempFolder ? "#ffffff" : "";
  }

  getCreateFolderText() {
    return this.state.createFolder ? "Create new folder" : "Create sub folder";
  }


  handleFieldError = () => {
    const { folderName, subFolderName, selectedFolder, createFolder } = this.state;

    const folderNameErr = folderName?.trim() === "";
    const subFolderNameErr = subFolderName?.trim() === "";
    const selectedFolderErr = selectedFolder?.trim() === "";
    let errorMessage = "";

    if (createFolder) {
      if (folderNameErr) {
        errorMessage = "Folder name is required";
      } else {
        this.createNewFolder();
      }
    } else {
      if (subFolderNameErr) {
        errorMessage = "Subfolder name is required";
      } else if (selectedFolderErr) {
        errorMessage = "Selected folder is required";
      } else {
        this.createNewSubFolder();
      }
    }

    this.setState({
      folderNameErr,
      subFolderNameErr,
      selectedFolderErr,
      errorMessage,
    });
  };


  handleNavigatePdfEdit = (attachment_id: any, file_id: any) => {
    localStorage.setItem('pdf_id', file_id);
    localStorage.setItem('attributes_id', attachment_id);
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPdfEditWebMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);

  }


  calculateContainerHeight = () => {
    const defaultHeight = "11.25rem";
    const folderHeight = "5.13rem";

    return this.state.createFolder ? folderHeight : defaultHeight;
  };



  // Customizable Area End
}
