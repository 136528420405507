// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import * as Yup from "yup";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  classes:any;
}

interface S {
  enablePasswordField: boolean;
  passwordSchemaWeb:any;
  enableConfirmPasswordField:boolean;
  emailGiven:boolean;
  user_identifier:string;
  emailWriten:string;
  alertMessage:string;
  showAlert:boolean;
  showSuccessAlert:boolean;
  alertType:any;
  resendEmail:boolean,
  showRecoveryAlert:boolean,
  newPass:boolean

}

interface SS {
  navigation: any;

}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  resetAPICallId:any;
  forgotAPICallId:any;
  getUserEmailApi:any;
  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
 PasswordRecovery:string=configJSON.PasswordRecovery;
 ResetPassword:string=configJSON.ResetPassword;
 txtEnterNewPass:string=configJSON.txtEnterNewPass;
 labelTextIsSendlinkonEmail:string=configJSON.labelTextIsSendlinkonEmail;
 sublabel:string=configJSON.sublabel;
 txtCheckyouremail:string=configJSON.txtCheckyouremail;
 txtMailtoEmail:string=configJSON.txtMailtoEmail;
 txtResendEmail:string=configJSON.txtResendEmail
 passwordsMustMatch:string=configJSON.passwordsMustMatch;
 firstInputPlaceholder: string = configJSON.firstInputPlaceholder;


  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
  
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    const queryParameter:any =  new URLSearchParams(window.location?.search);
    const token:string = queryParameter?.get('user_identifier')
    const verify:boolean=window.location?.href.includes("NewPassword")

    let passwordSchemaWeb = {
        password: Yup.string()
          .required(configJSON.pleaseEnterAPassword)
          .min(8, "Password must be at least 8 characters")
          .max(20, "Password should not be more than 20 characters")
          .matches(/^(?=.*[a-z])/, "Password must contain lowercase character")
          .matches(/^(?=.*[A-Z])/, "Password must contain uppercase character")
          .matches(/^(?=.*\d)/, "Password must contain at least one number"),
        confirmPassword: Yup.string()
          .required(configJSON.pleaseConfirmYourPassword)
          .when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              this.passwordsMustMatch
            )
          })
      };

    this.state = {
        passwordSchemaWeb:passwordSchemaWeb,
        emailGiven:false,
        newPass:verify,
        emailWriten:"",
        user_identifier:token,
        enablePasswordField: true,
        showRecoveryAlert:false,
        enableConfirmPasswordField:true,
        showAlert:false,
        showSuccessAlert:false,
        resendEmail:false,
        alertMessage:"",
        alertType:"",
    };
  
  }
  handleClickShowNewPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };
  handleCloseAlert=()=>{
    this.setState({showAlert:false,showSuccessAlert:false})
  }
  forgotSchema = () => {
    const schema = {
        email: Yup.string()
          .min(1,"Email is too short")
          .max(50,"Email is too long")
          .email().required('Email is required.'),}
          return schema
    }
    reSendEmail=()=>{
        this.setState({resendEmail:true})
        this.submitForgot(this.state.emailWriten)
    }
    submitForgot=async(values:any)=>{
        !this.state.emailGiven && this.setState({emailWriten:values.email})
        const formData = new FormData();
        !this.state.emailGiven ?formData.append(`confirmation_email`,values.email):formData.append(`confirmation_email`,values);
        

        this.forgotAPICallId = await this.forgotApiCall({
            method: 'POST',
            endPoint: '/account_block/accounts/email_verification/forgot_password',
            body: formData,
          })
    }
    passwordCondition=(cond:any,val1:string,val2:string)=>{
      if(cond){
      return val1
      }else{
      return val2
      }
    }
    resetPassword=async(values:any)=>{
        const formData = new FormData();
        formData.append(`account[password]`,values.password)
        formData.append(`account[password_confirmation]`,values.confirmPassword)
        formData.append(`user_identifier`,this.state.user_identifier)

        this.resetAPICallId = await this.forgotApiCall({
            method: 'POST',
            endPoint: `/account_block/accounts/email_verification/reset_password`,
            body: formData,
          })
    }
    getUserEmail= async ()=>{
      this.getUserEmailApi = await this.forgotApiCall({
        method: 'GET',
        endPoint: `/account_block/accounts/email_verification/retrieve_email?user_identifier=${this.state.user_identifier}`,
      })
    }
    forgotApiCall = async (data: any) => {
        const { contentType, method, endPoint, body, token } = data;
        const header = {
          "Content-Type": contentType,
          token,
        };
        const apiRequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body &&
        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            // JSON.stringify(body)
            body
            );
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
        return apiRequestMessage.messageId;
      };
  async componentDidMount() {
    super.componentDidMount();
  if(this.state.newPass &&this.state.user_identifier!=null){
    this.getUserEmail()
  }
}
  async componentDidUpdate() {
    if(this.state.showAlert || this.state.showSuccessAlert){
      setTimeout(()=>{
        this.handleCloseAlert()
      },10000)
    }
  }

  async receive(from: string, message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.forgotAPICallId &&
      this.forgotAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.meta) {
       this.setState({emailGiven:true})
       this.setState({alertMessage:"Email Sent",showSuccessAlert: true})
      }else{
        this.setState({alertMessage: responseJson.errors.message})
        this.setState({showAlert: true,alertType:"error"})
      }
    } 
    if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.resetAPICallId &&
        this.resetAPICallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.meta) {
         this.setState({emailGiven:true,showRecoveryAlert:true})
        }else{
          this.setState({alertMessage: responseJson.errors.message})
          this.setState({showAlert: true,alertType:"error"})
        }
      } 
      if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getUserEmailApi &&
        this.getUserEmailApi ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
         this.setState({emailWriten:responseJson.data.email})
        }else{
          this.setState({alertMessage: responseJson.meta.message})
          this.setState({showAlert: false,alertType:"error",})
        }
      } 
    }
}
// Customizable Area End