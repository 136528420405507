import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Chip } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Chart } from "react-google-charts";
import DatePicker from 'react-datepicker';
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
     // Customizable Area Start
    const { selectedDate, selectedWeek, selectedMonthYear, selectedView } = this.state;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const clientdata = this.state.visualData?.time_spent_data.client 
    const finadvdata = this.state.visualData?.time_spent_data.white_lable_user 
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const data = [
      ["Task", "Hours per Day", { role: "style" }],
      ["Client", clientdata, "#EC4899"],
      ["Advisors",finadvdata, "#E57727"],

    ];
    const data1 = [
      ["Task", "Hours per Day", { role: "style" }],
      ["Client", 50, "#EC4899"],
      ["Advisors",50, "#E57727"],

    ];
    const options = {
      
      pieHole: 0.6,
      is3D: false,
      colors: ["#EC4899", "#E57727"],
      pieSliceText: "none",
      legend: {
        
        position: 'labeled',
        labeledValueText: 'percentage',
        textStyle: {
          color: "black",
          
        },
      },
    };
 // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Container style={{display: "flex", justifyContent: "center", padding: "20px 32px", flexDirection: "column", gap: "20px",alignItems:'center'}}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: ".5rem" ,width:"100%",justifyContent:"center"}}>
        <div style={{ display: 'flex', gap: "5px" }}>

          <Chip data-test-id="day" size="small" label="Day" style={styleWeb.chip} onClick={() => this.handleViewChange('day')} />
          <Chip data-test-id="week" size="small" label="Week" style={styleWeb.chip} onClick={() => this.handleViewChange('week')} />
          <Chip data-test-id="month" size="small" label="Month" style={styleWeb.chip} onClick={() => this.handleViewChange('month')} />

        </div>
        <div  style={{ paddingRight:"1rem" }}>
          {selectedView === 'day' && (

            <DatePicker
              selected={selectedDate}
              data-test-id="dayview"
              onChange={this.handleDateChange}
              dateFormat="yyyy-MM-dd"
              showWeekNumbers
              startDate={today}
              endDate={today}
              maxDate={today}
              ref={this.state.datePickerRef}
              
            />

          )}

          {selectedView === 'week' && (
            <DatePicker
              selected={selectedWeek}
              onChange={this.handleWeekChange}
              dateFormat="I"
              showWeekNumbers
              startDate={today}
              endDate={today}
              maxDate={today}
              ref={this.state.datePickerRef}
            />
          )}

          {selectedView === 'month' && (
            <DatePicker
              selected={selectedMonthYear}
              onChange={this.handleMonthYearChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              maxDate={lastDayOfMonth}
              ref={this.state.datePickerRef}
            />
          )}
        </div>
      </div>
      {/* <Box sx={styleWeb.mainWrapper}> */}

        <Box sx={styleWeb.subconteiner1} style={{ alignItems: "start" }}>
          <Box sx={styleWeb.multiReadingBox} style={{ padding: "24px 32px 24px 32px", gap: '32px' }}>


            <Box sx={styleWeb.submultireading} >
              <Typography style={{
                "color": "#64748B", "fontFamily": "Inter", "fontSize": "1.25rem",
                 "fontStyle": "normal",
                fontWeight: 400, "lineHeight": "28px"
              }}>Open Invoices</Typography>
              <Typography style={{
                "color": "#0F172A", "fontFamily": "Inter", 
                "fontSize": "3rem", "fontStyle": "normal",
                "fontWeight": 700, "lineHeight": "56px", 
                "letterSpacing": "-0.72px"
              }}>{this.state.visualData?.invoice?.open_invoices}</Typography>




            </Box>

            <Box sx={styleWeb.submultireading}>

              <Typography style={{ display: "flex",
               alignItems: "center", gap: "3px", "color": this.state.visualData?.invoice.open_color, 
               "fontFamily": "Inter", "fontSize": "14px", "fontStyle": "normal",
                "fontWeight": 700, 
                "lineHeight": "20px" }}>
               {this.state.visualData?.invoice?.open_percentage > 0 ?  <ArrowUpward style={{
                height: "14px",
                width: "14px", backgroundColor:this.state.visualData?.invoice.open_background_color, padding: "2px", borderRadius: "61px"
              }} />:<ArrowDownward style={{
                height: "14px",
                width: "14px", backgroundColor:this.state.visualData?.invoice.open_background_color, padding: "2px", borderRadius: "61px"
              }} />}
              {this.state.visualData?.invoice?.open_percentage}%</Typography>



            </Box>

          </Box>

          <Box sx={styleWeb.multiReadingBox} style={{ padding: "24px 32px 24px 32px", gap: '2rem' }}>


            <Box sx={styleWeb.submultireading} style={{ padding: "0px 14px" }}>
              <Typography style={{
                "color": "#64748B", "fontFamily": "Inter", "fontSize": "1.25rem", "fontStyle": "normal",
                fontWeight: 400, "lineHeight": "28px"
              }}>Close Invoices</Typography>
              <Typography style={{
                "color": "#0F172A", "fontFamily": "Inter", "fontSize": "3rem",
                 "fontStyle": "normal",
                "fontWeight": 700, 
                "lineHeight": "56px", 
                "letterSpacing": "-0.72px"
              }}>{this.state.visualData?.invoice?.close_invoices}</Typography>




            </Box>

            <Box sx={styleWeb.submultireading}>

              <Typography style={{
                display: "flex",
                alignItems: "center", gap: "3px",
                "color": this.state.visualData?.invoice.close_color, "fontFamily": "Inter", "fontSize": "14px",
                "fontStyle": "normal", "fontWeight": 700,
                "lineHeight": "20px"
              }}>
              {this.state.visualData?.invoice?.close_percentage > 0 ?  <ArrowUpward style={{
                height: "14px",
                width: "14px", backgroundColor:this.state.visualData?.invoice.close_background_color, padding: "2px", borderRadius: "61px"
              }} />:<ArrowDownward style={{
                height: "14px",
                width: "14px", backgroundColor:this.state.visualData?.invoice.close_background_color, padding: "2px", borderRadius: "61px"
              }} />}{this.state.visualData?.invoice?.close_percentage}%</Typography>



            </Box>

          </Box>



          <Box sx={styleWeb.pieChartBox}>
            <Typography style={{
              "color": "#64748B",
              "fontFamily": "Inter", "fontSize": "20px",
              "fontStyle": "normal", "fontWeight": 400, "lineHeight": "28px",
              padding: "24px 0 0 32px"
            }}>Time spent</Typography>
            <Chart
              chartType="PieChart"
              width="98%"
              height="150px"
              data={(clientdata !== 0 && finadvdata !== 0) ? data : data1}
              options={options}
            />

          </Box>
        </Box>
      {/* </Box> */}
    </Container>
  </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styleWeb = {
  mainWrapper: {
    display: "flex",fontFamily: "Roboto-Medium",
    flexDirection: "column", alignItems: "center",
   background: "#fff", gap: "1.5rem",
 "width": "100vw",
   
  },

  multiReadingBox: {
    "width": "23.5%",
 
    "flexShrink": "0", "borderRadius": "8px",
    "border": "1px solid var(--neutrals-cool-gray-300, #CBD5E1)",
    "background": "#FFF", display: "flex",
    "justifyContent": "space-between",
    "flexWrap":"wrap",

  },

  pieChartBox: {
    "width": "35%",
    // "height": "210px",
    "flexShrink": "0", "borderRadius": "8px",
    "border": "1px solid var(--neutrals-cool-gray-300, #CBD5E1)",
    "background": "#FFF"
  },


  subconteiner1: {
    "display": "flex",
    "justifyContent":"space-between",
    "alignItems": "center", "gap": "1.5rem",
    "width": "calc(100vw - 225px)",
    "flexWrap":"wrap"
  },

  submultireading:
  {
    "display": "inline-flex",
    "flexDirection": "column",
    "justifyContent": "center", "alignItems": "flex-start",
    "gap": "8px",
    maxWidth:"53%"
  },


  chip: {
    "display": "flex",
    width: "4rem",
    "height": "28px",
    "padding": "3px ", "justifyContent": "center",
    "alignItems": "center"
  },

};
// Customizable Area End
