// Customizable Area Start
import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import TermsConditionsController, {
  Props,
} from "./TermsConditionsController.web";

export class PrivacyPolicy extends TermsConditionsController {
  constructor(props: Props) {
    super(props);  
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props?.openDialog}
          onClose={this.props?.handleCancel}
          maxWidth="lg"
          disableBackdropClick={true}
          className={classes.dialogWrapper}
        >
          <DialogTitle style={{ padding: "0" }}>
            <div className={classes.DialgTitle}>
              <div>
                <span className={classes.headingStyle}>{this.props?.headingText}</span>
                <span className={classes.subHeading}>Last modified: {this.props?.subHeadingText}</span>
              </div>
              <Close
                className={classes.downloaIcon}
                onClick={() => {
                  this.props?.handleCancel()
                }}
              />
            </div>
          </DialogTitle>

                <DialogContent style={{ padding: "40px" }}>

                  <Grid className={classes.reasonsSection} item xs={12}>
                    <Typography className={classes.para}>
                    <div className={classes.paragraphs} dangerouslySetInnerHTML={{ __html: this.props.txtPrivacyData || "" }} />
                      </Typography></Grid>
                </DialogContent>

                <Grid className={classes.btnWrapper}>
                  <Button variant="outlined"
                    className={classes.cancelBtnStyle}
                    style={{ textTransform: "none" }}
                    onClick={() => { this.props.handleCancel() }}
                  >
                    {this.props.btnCancelText}
                  </Button>

                  <Button variant="contained"
                    className={classes.primaryBtnStyle}
                    onClick={() => { this.props.handleOk() }}>
                    {this.props.btnOkText}
                  </Button>
                </Grid>
        </Dialog>
      </div>
    );
  }
}


const useStyles = {
  dialogWrapper: {
    "& [role='dialog']": {
      // gap: '40px',
      borderRadius: '8px',
      width: '600px',
      height:"896px"
    }
  },
  headingStyle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  para: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  errorStyle: {
    color: "#DC2626",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: 'end',
    gap: "15px",
    padding: "24px 24px 24px 320px",
    borderTop: "1px solid #E2E6F0",

    '& button': {
      padding: '16px 20px',
      width: '182px',
      borderColor: '#E57727',
      fontWeight: 700,
      borderRadius: '8px',
    }
  },
  checkDetails: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  primaryBtnStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    "text-transform": "none",
    "color": "white",
    cursor: "pointer"
  },
  cancelBtnStyle:{
    border:"none",
    color:"#E57727",
    background: "#F6F2EF",
  },
  textInputStyle: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  paragraphs: {
    '& p:first-child': {
      margin: 0,
    },
    '& p:last-child': {
      margin: 0,
    },
  },
  termsStyle: {
    marginRight: "15px",
    // marginTop: "20px"
  },
  DialgTitle: {
    // textAlign: "left",
    fontSize: "16px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 16px 24px 40px",
  },
  subHeading: {
    margin: "0 10px",
    color:  "#94A3B8",
fontFamily: "Inter",
fontSize: "14px",
fontStyle: "normal",
fontWeight: 500,
lineHeight: "32px",
  },
  confirmBtn: {
    borderRadius: '8px',
  },
  disableBtn: {
    color: "#fff",
    borderRadius: '8px',
    background: '#757575'
  },
  reasonsSection: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "32px"
  },
  downloaIcon: {
    cursor: "pointer"
  },
  pushBackBtn: {
    border: '1px solid primary'
  },
  errorInputStyle: {
    border: '1px solid red'
  }
}
export default withStyles(useStyles)(PrivacyPolicy)
// Customizable Area End