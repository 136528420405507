import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";



// Customizable Area Start
import * as Yup from "yup";
import  {gapi} from "gapi-script"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes:any;
  
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  enablePasswordField: boolean;
  alertMessage:string;
  showAlert:boolean;
  Loading:boolean;
  alertType:string;
  userDetails:{},
  isUserLoggedIn:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginUserAccountAPI:any;
  loginUserAccountAPIgoogle:any;
  titleMain:string;
  titleSub:string;
  title:string;
  clientData:string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      enablePasswordField: true,
      alertMessage:"",
      showAlert:false,
      alertType:"",
      Loading:false,
      userDetails:{},
      isUserLoggedIn:false
    };

    this.titleMain=configJSON.titleMain;
    this.titleSub=configJSON.titleSub;
    this.title=configJSON.title;
    this.clientData=configJSON.Clientdata
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
   
    // Customizable Area Start
    gapi.load("client:auth2",()=>{
      gapi.auth2.init({client_id:this.clientData})
    })
    localStorage.clear();
    // Customizable Area End
  }
  async componentDidUpdate() {
    // Customizable Area Start
    if(this.state.showAlert){
      setTimeout(()=>{
        this.handleCloseAlert()
      },10000)
    }
    // Customizable Area End
  }

  // Customizable Area Start
 

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
    LoginFormSchema = () => {
      const schema = {
          email: Yup.string()
            .min(1,"Email is too short")
            .max(30,"Email is too long")
            .email().required('Email is required.'),
       
          password: Yup.string()
            .required("Please Enter Password")
            .min(8,"Password must be at least 8 characters")
            .max(20,"Password should not be more than 20 characters")
        }
        return schema
  }
  
  loginUser = async (formValues: any) => {
  this.loginUserAccountAPI = await this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: 'login/login',
      body: {
        "data": {
            "type" : "email_account", 
            "attributes": {
                 "email": formValues.email,
                 "password": formValues.password,
            }
         }    
     },
      token:''
    })
  }


   
  loginUserGoogle = async () => {
    this.loginUserAccountAPIgoogle = await this.apiCall({
        contentType: 'application/json',
        method: 'POST',
        endPoint: 'account_block/accounts/create_social_account',
        body: {
          "data": {
              "type" : "social_account", 
              "attributes": {
                  "type":"google",
                  "serverAuthCode" : "",
                  "access_token": this.state.userDetails,
              }
           }    
       }
      })
    }
  handleCloseAlert=()=>{
    this.setState({showAlert:false})
  }
  goToTwoFactorAuthentication() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTwoFactorAuthenticationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  responseGoogle = (response:any) => {
  
    this.setState({ userDetails: response.tokenId, isUserLoggedIn: true });
  
    this.state.isUserLoggedIn && this.loginUserGoogle()
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    apiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

 

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.loginUserAccountAPIgoogle) {
        
        if (responseJson && !responseJson.errors) {
          
          localStorage.setItem("loginData", JSON.stringify(responseJson?.meta))
              window.location.href = '/HomePage';
        
        } else {
          this.setState({alertMessage: responseJson.errors[0].failed_login})
          this.setState({showAlert: true,alertType:"error",Loading:false})
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }

      if (apiRequestCallId === this.loginUserAccountAPI) {
        this.setState({Loading:true})
        if (responseJson && !responseJson.errors) {
          
          localStorage.setItem("loginData", JSON.stringify(responseJson?.meta))
              this.goToTwoFactorAuthentication()
        
        } else {
          this.setState({alertMessage: responseJson.errors[0].failed_login})
          this.setState({showAlert: true,alertType:"error",Loading:false})
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    
    // Customizable Area End
  }
}
