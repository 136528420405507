import React from "react";

// Customizable Area Start
import {
    Container,
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import {Slider, imgLogo,Frame} from "./assets"
import CustomCarousal from "../../../components/src/CustomCarousal.web";
import { Link as Redirect } from "react-router-dom";
// Customizable Area End

import LandingPageController, {
    Props
} from "./LandingPageController.web";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

export class LandingPage extends LandingPageController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes} = this.props;
        const items = [
            {
           
              image: Slider,
            },
            {
                
                  image: Frame,
                },
                {
                   
                      image: Slider,
                    },
                    {
                    
                          image: Frame,
                        },
          ];
          
        return (
            /* Customizable Area Start */
            <ThemeProvider theme={theme}>
                <Container maxWidth={"xl"} style={{padding:"0",}}>
                    <Grid container component="main" className={classes.root}>
                        <CssBaseline />
                        <Grid item xs={8} sm={8} md={6} component={Paper} >
                        <img src={imgLogo}style={useStyles.img}></img>
                            <div className={classes.paper} style={{ padding: "55px",position: "relative",top: "50%",left: "50%",transform: "translate(-50%, -50%)", }}>
                                <Typography className={classes.title}>
                                    {this.titleWelcome}
                                </Typography>
                                <Typography style={useStyles.details} >
                                    {this.details}
                                </Typography>
                                <Redirect to="/EmailAccountRegistration" style={{textDecoration:"none"}}>
                                <Button className={classes.startBtn} style={{textTransform:"initial"}} data-test-id={"goToSignUpButton"} >Get started</Button>
                                </Redirect>
                                <Typography style={{display:"none"}}>LandingPage</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={false} sm={4} md={6} className={classes.image} >
                            <div className={classes.carousal} style={{position:"relative"}}>
                            <CustomCarousal item={items}/>
                            </div>

                        </Grid>

                    </Grid>

                </Container>
            </ThemeProvider>
            /* Customizable Area End */
        );
    }

    async componentDidMount() {
        // Customizable Area Start
        // this.getValidations();
        // Customizable Area End
    }
}

const useStyles = ({
    root: {
        height: '100vh',
    },
    title: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "36px",
        fontStyle: "normal",
        fontWeight:700,
        lineHeight: "44px", /* 122.222% */
        letterSpacing: "-0.36px",
    },
    details:{
        color: "#475569",
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: 1.5,
        marginTop:"35px",
        marginBottom:"24px"
    },
    startBtn: {
        display: "flex",
        width:"121px",
        height:"56px",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        fontWeight:700,
        alignSelf: "stretch",
        borderRadius:"8px",
        color:"#fff",
        background: "#E57727",
        '&:hover': {
            backgroundColor: '#E57727',
        },
    },
    carousal:{
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    padding: "6%",
    },
    image: {
        background: "#F6F2EF",
    },
    img: {
        width:"201px",
        height:"59px",
        marginTop:"7px",
        marginLeft:"27px"
    },
    paper: {
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

export default withStyles(useStyles)(LandingPage)