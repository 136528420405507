// Customizable Area Start
//@ts-nocheck
import React, { useState } from "react";
import { useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";

type ViewerProps = {
  document: string;
  fileName:string;
  callChildFunction:any;
};

const PDFTronWrapper1 = ({ document ,callChildFunction }: ViewerProps) => {
  const viewer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    WebViewer(
      {
        path: "lib",
        initialDoc: document,
      },
      viewer.current as HTMLDivElement
    ).then((instance) => {
      // you can now call WebViewer APIs here...
      instance.UI.enableElements(['contentEditButton']);
      instance.UI.enableFeatures(instance.Feature.Signature);
      instance.annotManager.on("annotationChanged", async () => {
        const xfdfString = await instance.annotManager.exportAnnotations();
        const doc = instance.docViewer.getDocument();
        const data = await doc.getFileData({ xfdfString });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });
        callChildFunction(blob);
      });
    });
  }, []);

  return (
    <div className="PDFTronWrapper" style={{ width: "763px", height: "850px" }}>
      <div ref={viewer} />    
    </div>
  );
};

export default PDFTronWrapper1;
// Customizable Area End
