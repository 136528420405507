import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { Check} from "@material-ui/icons";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  showDialog: boolean;
  selectedDoc: any;
  showCreateModal: boolean;
  createFolder: boolean;
  createSubFolder: boolean;
  folders: any;
  subFolderName: string
  showClientFolder: boolean;
  showFinAdvFolder: boolean;
  showFilterDropdwn: boolean;
  searchedRows: string;
  showRenameDialog: boolean;
  renameSubFolderName: string;
  subFolderId: string;
  Documentspdf:any;
  selectedSortOption:string;
  subfolderNameErr:boolean;
  shouldUpdateSubfolder:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserLibraryController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getFoldersApiCallId: any;
  sortDocFolderApiCallId:any;
  createNewFolderApiCallId: any
  deleteSubFolderApiCallId: any
  renameSubFolderApiCallId: any
  renamedocsubFolderApiCallId:any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      showDialog: false,
      selectedDoc: null,
      showCreateModal: false,
      createFolder: false,
      createSubFolder: false,
      folders: [],
      subFolderName: "",
      showClientFolder: true,
      showFinAdvFolder: false,
      showFilterDropdwn: false,
      searchedRows: "",
      showRenameDialog: false,
      renameSubFolderName: "",
      subFolderId: "",
      Documentspdf:[],
      selectedSortOption:"",
      subfolderNameErr:false,
      shouldUpdateSubfolder:false

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }




  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
   

     
       this.getSubFolderlist();
    
    
      
    
   

    // Customizable Area End
  }


  componentDidUpdate(prevProps:any, prevState:any) {
  
    if (prevState.shouldUpdateSubfolder !== this.state.shouldUpdateSubfolder) {
      this.getSubFolderlist();
      this.setState({ shouldUpdateSubfolder: false });
    }
  }

  token: string = "";
  folderId1: string = ""

  getSubFolderlist = async () => {
    let userData = localStorage.getItem("loginData");


    let someDataParsed = userData && JSON.parse(userData);
    this.token = someDataParsed?.token;

    let idFolder = localStorage.getItem("folderId")
    this.folderId1 = idFolder ? JSON.parse(idFolder) : null


    this.getFoldersApiCallId = await this.subfolderAPIcall({
      methods: "GET",
      endPoint: `account_block/folders/${this.folderId1}/folder_details`,

      token: this.token,
    });

  };



  sortSubfolderDoc = async () => {
    let userData = localStorage.getItem("loginData");


    let someDataParsed = userData && JSON.parse(userData);
    this.token = someDataParsed?.token;

    let idFolder = localStorage.getItem("folderId")
    this.folderId1 = idFolder ? JSON.parse(idFolder) : null


    this.sortDocFolderApiCallId = await this.subfolderAPIcall({
      methods: "GET",
      endPoint: `account_block/folders/${this.folderId1}/folder_details?sort_option=${this.state.selectedSortOption}`,

      token: this.token,
    });

  };




  handleFilterClick = (filter: string) => {
    this.setState((prevState) => {
      const newSortOption = prevState.selectedSortOption === filter ? '' : filter;
  
      // Update the state
      return { selectedSortOption: newSortOption };
    }, () => {
      // Callback function is called after the state is updated
      if (this.state.selectedSortOption === '') {
        this.getSubFolderlist();
      } else {
        this.sortSubfolderDoc();
      }
    });
  };
  
  

  

  getStartIcon = (filter:string) => {
    return this.state.selectedSortOption === filter ? <Check /> : null;
  };

  formDataSubFolderCall = () => {
    const formdata = new FormData();

    if (this?.state?.subFolderName !== null) {
      formdata.append('sub_folder[name]', this.state.subFolderName);
      return formdata;
    }
  }

  formDataSubFolderRenameCall = () => {
    const formdata = new FormData();

    if (this?.state?.renameSubFolderName !== null) {
      formdata.append('sub_folder[name]', this.state.renameSubFolderName);
      return formdata;
    }
  }


  handleCreateSunFolderErr=()=>{
    if(this.state.subFolderName == ""){
      this.setState({subfolderNameErr:true})
    }
    else{
      this.createNewSubFolder()

    }
  }

  createNewSubFolder = async () => {
    const formdata = this.formDataSubFolderCall();
    this.createNewFolderApiCallId = await this.subfolderAPIcall({

      methods: 'POST',
      endPoint: `account_block/folders/${this.folderId1}/sub_folders`,
      Body: formdata,
      token: this.token,


    })
    this.setState({ showCreateModal: false, subFolderName: "" ,shouldUpdateSubfolder:true})
    await this.getSubFolderlist()


  }
  formPdfDocRenameCall = () => {
    const formdata = new FormData();

    if (this?.state?.renameSubFolderName !== null) {
      formdata.append('name', this.state.renameSubFolderName);
      return formdata;
    }
  }
  RenamePdfDocInSubFolder = async (id: any,attachment_id:any) => {

    const formdata = this.formPdfDocRenameCall()

    this.renamedocsubFolderApiCallId = await this.subfolderAPIcall({

      methods: 'PATCH',
      endPoint: `bx_block_bulk_uploading/attachments/${attachment_id}/rename_file?file_id=${id}`,
      Body: formdata,
      token: this.token,


    })
    this.setState({ renameSubFolderName: "", showRenameDialog: false,showDialog:false ,shouldUpdateSubfolder:true})
    await this.getSubFolderlist()
  }

  DeletePdfDocInSubFolder = async (id: any,attachment_id:any) => {

    this.createNewFolderApiCallId = await this.subfolderAPIcall({

      methods: 'DELETE',
      endPoint: `bx_block_bulk_uploading/attachments/${attachment_id}/delete_file?file_id=${id}`,

      token: this.token,


    })
    this.setState({shouldUpdateSubfolder:true})
    const updatedItems = this.state.Documentspdf.filter(
      (item: any) => item.file_id !== id
    );
    this.setState({
      Documentspdf : updatedItems,
      showDialog:false
    });
   
  }

  handleShowDialogPdfDoc1 = (item: any) => {
    

    this.setState({ showDialog: !this.state.showDialog, selectedDoc: item.file_id })
  }


  RenameSubFolder = async (id: any) => {

    const formdata = this.formDataSubFolderRenameCall()

    this.renameSubFolderApiCallId = await this.subfolderAPIcall({

      methods: 'PATCH',
      endPoint: `account_block/folders/${this.folderId1}/sub_folders/${id}`,
      Body: formdata,
      token: this.token,


    })


    this.setState({ renameSubFolderName: "", showRenameDialog: false ,showDialog:false,shouldUpdateSubfolder:true})
    await this.getSubFolderlist()



  }





  DeleteSubFolder = async (id: any) => {

    this.deleteSubFolderApiCallId = await this.subfolderAPIcall({

      methods: 'DELETE',
      endPoint: `account_block/folders/${this.folderId1}/sub_folders/${id}`,

      token: this.token,


    })

    this.setState({shouldUpdateSubfolder:true})
    const updatedItems = this.state.folders.filter(
      (item: any) => item.id !== id
    );
    this.setState({
      folders: updatedItems,
      showDialog:false
    });
  

  }




  subfolderAPIcall = async (data: any) => {
    const { ContentType, methods, endPoint, token, Body } = data;

    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestMessageforSubFolderList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforSubFolderList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageforSubFolderList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforSubFolderList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    Body &&
      apiRequestMessageforSubFolderList.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),

        Body
      );

    runEngine.sendMessage(
      apiRequestMessageforSubFolderList.id,
      apiRequestMessageforSubFolderList
    );
    return apiRequestMessageforSubFolderList.messageId;
  };




  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestIdsubFolder = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJsonFolder = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestIdsubFolder === this.getFoldersApiCallId) {
        if (responseJsonFolder.data !== null) {
       
          this.setState({ folders: responseJsonFolder?.sub_folders , Documentspdf:responseJsonFolder?.documents[0].attachment_data.attributes.files})
           console.log(this?.state?.Documentspdf,"::::::::::::::::::::::")
        } else {
          console.log(responseJsonFolder.data, "error");
        }

        
      }

      if (apiRequestIdsubFolder === this.sortDocFolderApiCallId) {
        if (responseJsonFolder.data !== null) {
          
          this.setState({ folders: responseJsonFolder.sub_folders, Documentspdf:responseJsonFolder.documents[0].attachment_data.attributes.files })

        } else {
          console.log(responseJsonFolder.data, "error");
        }

        
      }



      this.parseApiCatchErrorResponse(errorReponse);
    }

    

    // Customizable Area End
  }

  handleShowDialog = (item: any) => {

    this.setState({ showDialog: !this.state.showDialog, selectedDoc: item.data})
  }



  handleShowCreateSubFolder = () => {
    this.setState({ showCreateModal: true, createFolder: false, createSubFolder: true })
  }

  handleHideFolderModal = () => {
    this.setState({ showCreateModal: false, subFolderName: "" ,subfolderNameErr:false})
  }



  handleSubFolderName = (e: any) => {
    this.setState({ subFolderName: e.target?.value })
  }





  handleShowFilterDropdwn = () => {
    this.setState({ showFilterDropdwn: !this.state.showFilterDropdwn })
  }



  SerachRequest = (searchedVal: any) => {
    this.setState({ searchedRows: searchedVal.target.value }, () =>
      this.searchResult(this.state.searchedRows)
    );
  };

  searchResult(value: any) {
    let filterSubFolder = this.state.folders.filter((row: any) => {
      return row.data.attributes?.name
        ?.toLowerCase()
        .includes(value.toLowerCase());
    });

    let filterPdfInFolder= this.state.Documentspdf.filter((row: any) => {
      return row.file_name
        ?.toLowerCase()
        .includes(value.toLowerCase());
    });
    if (value == "") {
      this.getSubFolderlist()
    } else {
      this.setState({ folders: filterSubFolder ,Documentspdf:filterPdfInFolder});
    }
  }

  handleShowRenameDialog = () => {
    this.setState({ showRenameDialog: true })
  }

  handleRenameSubFolder = (event: any) => {
    this.setState({ renameSubFolderName: event?.target.value })
  }

  handleCancelRename = () => {
    this.setState({ renameSubFolderName: "" })
  }
  handleCloseSubFolderDialog = () => {
    this.setState({ showRenameDialog: false, showDialog: false })
  }

  hadleNavigateToPdf = (name:any,id:any) => {
 
    localStorage.setItem("subFolderName", JSON.stringify(name))
    localStorage.setItem("subFolderId", JSON.stringify(id))

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PdfDocument");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPdfDocumentsMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPdfDocumentsMessage),
      id,


    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

 
  handleUploadDoc = () => {
    localStorage.removeItem("subFolderId");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationBulkUploadMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);

  }

  handleNavigatePdfEdit = (attachment_id:any,file_id:any) => {
    localStorage.setItem('pdf_id', file_id);
    localStorage.setItem('attributes_id', attachment_id);
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPdfEditWebMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);

  }

 
  // Customizable Area End
}
