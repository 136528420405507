import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getThemeColor } from "../../../components/src/CommonPlatte";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  searchText: string;
  searchList: any;
  btnColor:string
   searchDocType:any;
  showResults:boolean;
  changeSearchText:boolean;
  isLoading:boolean;
  documentLength:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      searchText: "",
      searchList: [],
      searchDocType:"",
      btnColor:getThemeColor(),
      showResults:false,
      changeSearchText:false,
      isLoading:false,
      documentLength:0
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }





 

  // Customizable Area Start

  token: string = "";
  serachText:string=""
  getSearchData = async () => {
    let tokenData = localStorage.getItem("loginData");
    let tokenDataParsed = tokenData && JSON.parse(tokenData);
    this.token = tokenDataParsed?.token;

    console.log("hey i am calling create account", this.token);
    this.searchApiCallId = await this.getSearchResultApiCall({
      methods: "GET",
      endPoint: `bx_block_search/searches?search[name]=${this.state?.searchText}`,

      token: this.token,
    });
    this.serachText = this.state.searchText
    this.setState({isLoading:true})
  };

    gotoPdfEdit(id:any,fullName:string) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "PdfEdit");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPdfEditWebMessage)
      );
      raiseMessage.addData(
        getName(MessageEnum.NavigationPdfEditWebMessage),
        fullName
      );
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg);
    }
    documentLength=(data:any)=>{
      const documentsArray:any = [];
      data.forEach((item:any) => {
        if (item.attributes.documents !== null) {
          documentsArray.push(item.attributes.documents.data);
        }
      });
      this.setState({documentLength:documentsArray.length}) 
    }
  getSearchResultApiCall = async (data: any) => {
    const { ContentType, methods, endPoint, token } = data;
    const Header = {
      "Content-Type": ContentType,
      token,
    };
    const apiRequestMessageForSearch = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageForSearch.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    apiRequestMessageForSearch.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageForSearch.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );

    runEngine.sendMessage(
      apiRequestMessageForSearch.id,
      apiRequestMessageForSearch
    );
    return apiRequestMessageForSearch.messageId;
  };


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiSearchRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJsonSearch = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponseSearch = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiSearchRequestId === this.searchApiCallId) {
     
        if (responseJsonSearch.data !== null && this.state.searchText.length>0) {
               this.setState({searchList:responseJsonSearch.data,isLoading:false})
              this.documentLength(this.state.searchList)
             
        } else {
      
          this.setState({searchList:[]})
        }
      }
     
      this.parseApiCatchErrorResponse(errorReponseSearch);
    }

    // Customizable Area End
  }



  txtInputSearchTextProps = {
    onChangeText: (text: string) => {
      this.setState({ searchText: text });
    }
  };

  setSearchText = (e: any) => {
    e.prevent?.default()
    this.setState({ searchText: e });
  };



  handleShowResult=()=>{
    this.getSearchData()
    this.setState({showResults:true})
  }

  requestSearch = (searchedVal: any) => {
    this.setState({ searchText: searchedVal.target.value })
  };



  // Customizable Area End
}
