import React from "react";
// Customizable Area Start
import {
    Divider,
    Dialog,
    Card,
    FormLabel,
    Box,
    FormControl,
    Button,
    Typography,
    TextField,
    InputAdornment
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { Close, Delete, Edit, MoreVert, Search, Sort, ChevronRight, ControlPoint } from "@material-ui/icons";
import { Link as Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#CBD5E1",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

import PdfDocumentController, { Props } from "./PdfDocumentController.web";
import { pdfDOC } from "../../chat/src/assets";
import Pagination from "@material-ui/lab/Pagination";


export default class PdfDocument extends PdfDocumentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { FilesInFolder, showEditDilogue, selectedDocument, showRenameDialog, pdfdocRename, FilterDropdownshow } = this.state


        const startIndex = (this.state.pageNumber - 1) * this.state.dataPerPage;
        const endIndex = startIndex + this.state.dataPerPage;
        const currentData = this.state.FilesInFolder.slice(startIndex, endIndex);
        const FolderName = localStorage.getItem("subFolderName");
        const nameFolder = FolderName ? JSON.parse(FolderName) : null;
        const FolderNmae1 = localStorage.getItem("FolderName")
        const parseSubFolder = FolderNmae1 ? JSON.parse(FolderNmae1) : null;

        return (
            <ThemeProvider theme={theme} >
                {/* <Container maxWidth="md" > */}
                {/* Customizable Area Start */}

                <Box sx={{
                    display: "flex", flexDirection: "column",
                    width: "calc(100vw - 193px)", position: 'fixed',
                    top: "0px", right: "60px", alignItems: "flex-start"
                    , justifyContent: 'flex-start', height: "100%"
                }}>
                    <Box className="Navbox" sx={{
                        "display": "flex", "height": "44px",
                        "padding": "24px 48px 24px 48px", "justifyContent": "flex-start",
                        "alignItems": "center", "borderBottom": "1px solid #CBD5E1",
                        width: "calc(100vw - 252px)", position: "sticky", gridColumnGap: "0.5rem"
                    }}>
                         <Link underline="hover"  color="inherit" >
                            <Redirect to="/UserLibrary" className="bredNav"
                                style={webStyle.userLibraryRedirect}>Documents</Redirect>
                        </Link>


                        < ChevronRight style={{ width: "1.2rem", height: "1.2rem", opacity: "0.8", color: "#64748B" }} />
                        <Link underline="hover" key="1" color="inherit" >
                            <Redirect to="/SubFolder"  className="bredNav"
                                style={webStyle.userLibraryRedirect}>{parseSubFolder}</Redirect>
                        </Link>


                        < ChevronRight style={{ width: "1.2rem", height: "1.2rem", opacity: "0.8", color: "#64748B" }} />
                        <Typography  className="bredNav" style={{
                            "color": "#0F172A", "fontFamily": "Inter",
                            "fontSize": "24px", "fontStyle": "normal",
                            "fontWeight": 700, "lineHeight": "32px", "letterSpacing": "-0.12px"
                        }}>{nameFolder}</Typography>


                    </Box>

                    <Box style={{
                        width: "100%", height: '974px', display: 'flex',
                        flexDirection: "column", gap: "20px", alignItems: 'center', padding: "20px 30px", "overflowY": "auto"
                    }}>
                        <Box sx={webStyle.topbarBox}>
                        <Button  style={webStyle.buttonStyle} onClick={this.handleUploadDoc} data-test-id="uploaddoc" > <ControlPoint className="icon" style={{
              color: "#396178",
              width: "1.5rem", height: '1.5rem'
            }} /><Typography className="uploadbtnText" style={webStyle.btnText}>Upload documents</Typography></Button>
                            <TextField
                                type="search"
                                id="searchPdfDoc"
                                className="searchdiv"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        borderRadius: "1.875rem", height: "44px", padding: '10px 8px'
                                        , display: "flex", justifyContent: "center", alignItems: "center", color: "#64748B"
                                    },
                                }}
                                placeholder="Search"
                                variant="outlined"
                                style={{
                                    width: "600px", borderRadius: "98px",


                                }}
                                value={this.state.searchedDoc}
                                onChange={(searchedRows) => this.SerachRequestPdfDoc(searchedRows)}

                            />

                            <Box sx={{ display: "flex", position: "relative" }}>
                                <Button data-test-id="sort" style={webStyle.sortbtn} onClick={this.handleClickSortBtn}><Sort className="icon" style={{ width: "1.5rem", height: '1.5rem', color: "#64748B", marginRight: "8px" }} /><Typography className="uploadbtnText" style={webStyle.filterSortText}>Sort</Typography></Button>
                                {FilterDropdownshow ?
                                    <Box sx={webStyle.filterBox} >
                                        <Button style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('name')}
                                            startIcon={this.getStartIcon('name')} data-test-id="name"
                                        ><Typography style={webStyle.filterText}>Name</Typography></Button>
                                        <Button data-test-id="open" style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('kind')} startIcon={this.getStartIcon('kind')}><Typography style={webStyle.filterText}>Kind</Typography></Button>
                                        <Button data-test-id="modified" style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('updated_at')} startIcon={this.getStartIcon('updated_at')}><Typography style={webStyle.filterText}>
                                            Date modified</Typography></Button>
                                        <Button data-test-id="createdat" style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('created_at')} startIcon={this.getStartIcon('created_at')}><Typography style={webStyle.filterText}>Date created</Typography></Button>
                                        <Button data-test-id="bysize" style={webStyle.filtrBtn} onClick={() => this.handleFilterClick('size')} startIcon={this.getStartIcon('size')}><Typography style={webStyle.filterText}>Size</Typography></Button>

                                    </Box> : null}
                            </Box>

                        </Box>

                        <Box sx={webStyle.contentBox}>

                            <Box style={{
                                height: "38px", padding: '8px 0px 8px 24px',
                                borderBottom: "1px solid #E2E8F0", display: "flex", alignItems: "center"
                            }} className="files">
                                <Typography style={webStyle.filesHeading}>Files</Typography>
                            </Box>
                            <Box style={{ height: "528", overflowY: "auto" }}>
                                {currentData?.map((item: any ,index:any) => (
                                    <Box className="commonrowBox" style={{ height: "66px", borderBottom: "1px solid #E2E8F0", display: 'flex', justifyContent: "space-between", alignItems: "center", position: "relative"  }} key={item.id}>
                                        <Button className="commonrowBox" style={webStyle.commonRowDocBox} data-test-id={`navigate-${index}`} onClick={()=>{this.handleNavigatePdfEdit(item.id)}}>
                                            <img className="pdfimg" src={pdfDOC} style={{ width: '44px', height: '44px' }} alt="" />
                                            <Typography className="pdfDocName" style={webStyle.docName} data-test-id={`data123-${index}`}>{item.file_name}</Typography>
                                        </Button>
                                        <Box style={{ marginRight: "20px" }}>
                                            
                                            <MoreVert style={{ cursor: 'pointer' }} className="morevertbtn"
                                                onClick={() => this.handleShowDialogPdfDoc(item)}  data-test-id={`rowshow-${index}`}/>
                                        </Box>
                                        {showEditDilogue && selectedDocument == item.id ?

                                            <Box sx={webStyle.modalEditDel}>
                                                <Button data-test-id={`dialoguesRename-${index}`}className="renamedelBtn"
                                                    style={webStyle.renameFolder} onClick={this.handleopenRenameDilog}><Edit style={{ width: '18px', height: "18px" }} /><Typography className="siortbtntext" style={webStyle.editText}>Rename doc</Typography></Button>
                                                <Button style={webStyle.delebtn} data-test-id={`deleteFolder-${index}`}className="renamedelBtn"
                                                    onClick={() => this.DeletePdfDoc1(item.id)}
                                                ><Delete style={{ width: '18px', height: "18px" }} />
                                                    <Typography style={webStyle.editText} className="siortbtntext">Delete doc</Typography></Button>
                                                {showRenameDialog ?
                                                    <Dialog className="dialog"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center", alignItems: "center",
                                                            boxShadow:
                                                                " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
                                                        }}
                                                        data-test-id="renameModal"
                                                        PaperProps={{
                                                            style: {
                                                              minWidth: 200, 
                                                            },
                                                          }}
                                                        open={showRenameDialog} aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Card
                                                            style={{
                                                                maxWidth: "37.5rem",
                                                                borderRadius: "0.5rem", overflowY: "auto"
                                                            }}
                                                        >

                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    height: "2rem", padding: "1.5rem 1rem 1.5rem 2.5rem",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center", alignSelf: "stretch",
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        fontSize: "1.5rem",
                                                                        fontWeight: 700, color: "#000000",
                                                                    }}
                                                                >
                                                                    Rename document

                                                                </p>
                                                                <Close style={webStyle.closeMOdalCross} onClick={this.handleCloseREnameDilog} data-test-id="closefolderrename" />

                                                            </div>
                                                            <Divider />
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    padding: "2.5rem 2.5rem",
                                                                    flexDirection: "column",
                                                                    alignItems: "flex-start", gap: "1rem",
                                                                    alignSelf: "stretch", width: "32.5rem",
                                                                }}
                                                                className="diveFormcontrol"
                                                            >
                                                                <Box>

                                                                    <FormControl  className="textFieldWidth"
                                                                        style={{

                                                                            display: "flex", width: "32.5rem", height: "5.13rem", flexDirection: "column",
                                                                            justifyContent: "center", alignItems: "flex-start",
                                                                        }}
                                                                    >
                                                                        <FormLabel style={{
                                                                            fontWeight: 700,
                                                                            color: "#64748B", fontSize: '14px',
                                                                            lineHeight: '22px'
                                                                        }}

                                                                        >
                                                                            Rename
                                                                        </FormLabel>
                                                                        <TextField type="text" variant="outlined"
                                                                            style={{
                                                                                "borderRadius": "8px",
                                                                                "border": "1px solid #CBD5E1",
                                                                                "background": "#FFF"
                                                                            }}
                                                                            name="sunFolderNmae"
                                                                            color="primary"
                                                                            data-test-id="renameFolder"
                                                                            onChange={this.handlePdfDocRename}
                                                                            value={pdfdocRename}
                                                                            InputProps={{

                                                                                style: {
                                                                                    color: "#64748B", height: '56px', padding: '10px 8px'
                                                                                },
                                                                                placeholder: `${item.file_name}`
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </div>
                                                            <Divider />
                                                            <div
                                                                style={{
                                                                    display: "flex", padding: "1.5rem 1.5rem 1.5rem 20rem",
                                                                    justifyContent: "flex-end",
                                                                    alignItems: "center", alignSelf: "stretch",
                                                                }}
                                                            >
                                                                <Button style={webStyle.cancelbtn}
                                                                 onClick={this.handlecancelRename}
                                                                ><Typography
                                                                    style={webStyle.cancelbtnText}>Cancel</Typography></Button>
                                                                <Button

                                                                    data-test-id="okFolderrename" variant="contained" style={{
                                                                        display: "flex", width: "7.5rem", height: "3.5rem", padding: "1rem 0",
                                                                        borderRadius: "0.5rem", backgroundColor: "#E57727",
                                                                        color: "#FFF", fontWeight: 700,
                                                                        fontSize: "1rem",
                                                                    }}
                                                                    onClick={() => this.RenamePdfDoc1(item.id)}
                                                                >
                                                                    <Typography style={{
                                                                        fontSize: '16px', fontWeight: 700, fontFamily: 'Inter', textTransform: 'none'
                                                                    }}>Save</Typography>
                                                                </Button>
                                                            </div>

                                                        </Card>
                                                    </Dialog> : null}
                                            </Box> : null}
                                    </Box>))}
                            </Box>

                            <Box style={{
                                borderTop: '1px solid #E2E8F0', background: '#fff', height: "4rem", position: "absolute", bottom: 0,
                                width: "100%", display: "flex", justifyContent: "center", flexDirection: "column"
                            }}>
                                <Box sx={webStyle.paginatnBox}>

                                    <select className="paginatnText"  value={this.state.dataPerPage} onChange={this.handleItemsPerPageChange} style={{ border: "none", background: "#fff" }} data-test-id="selectDocPerPage"   >
                                        {this.dataPerPageOptions.map((option: any) => (
                                            <option key={option} value={option}>
                                                {option} Per Page
                                            </option>
                                        ))}
                                    </select>
                                    <Pagination style={webStyle.paginatnText} size="small" count={Math.ceil(FilesInFolder.length / this.state.dataPerPage)}
                                        onChange={this.handlePageChange} page={this.state.pageNumber} data-test-id="pagination" />
                                    <Typography className="RightPagintnText" style={webStyle.RightPagintnText}>{startIndex + 1} - {endIndex} of {FilesInFolder.length} results</Typography>
                                </Box>
                            </Box>

                        </Box>

                    </Box>
                </Box>



                {/* Customizable End Start */}
                {/* </Container> */}
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: any = {
    topbarBox: {
        "display": "flex",
        "width": "calc(100% - 60px)",
        maxHeight: "1066px",
        "padding": "16px 24px",

        "alignItems": "center",
        "borderRadius": "8px",
        "border": "0.5px solid  #94A3B8",
        "background": "#FFF",
        height: "44px",
        "zIndex": "2",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
            width : "calc(100% - 20px)",
            "flexWrap": "wrap", 
            "fontSize": "50%", 
            height:"auto"
          },
         

    },
    contentBox: {
        "display": "flex",
        "width": "calc(100% - 12px)",
        "height": "684px",
        // "justifyContent": "center",
        // "alignItems": "center",
        paddingTop: '1rem',
        "flexShrink": "0",

        "borderRadius": "8px",
        "border": "0.5px solid  #94A3B8",
        "position": "relative", // Set position to relative
        "zIndex": "1",
        flexDirection: "column",
       
        "@media (max-width: 768px)": {
            width : "calc(100% - -22px)"
          
          },
    },
    filterSortText: {
        "color": "#64748B",
        "fontFamily": "Inter",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "700",
        "lineHeight": "24px",
        textTransform: "none"
    },
    filterbtn: {
        "display": "flex",
        "width": "95px",
        "height": "44px",
        "padding": "10px 12px 10px 10px",
        "justifyContent": "center",
        "alignItems": "flex-start",
        "gap": "8px",
        "borderRadius": "8px",

    },
    sortbtn: {
        "display": "flex",
        "width": "88px",
        "height": "44px",
        "padding": "10px 12px 10px 10px",
        "justifyContent": "center",
        // "alignItems": "flex-start",
        "gap": "8px",
        "borderRadius": "8px"
    },
    paginatnBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '0px 16px',
        "@media (max-width: 768px)": {
     
            "flexWrap": "wrap", 
           padding:"0px 0px",
            height:"auto"
          },
    },
    paginatnText: {
        "display": "flex",

        "height": "32px",
        "padding": "5px 0px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "4px",
        "flexShrink": "0",
        "borderRadius": "100px",
        "background": "#FFF",

    },
    RightPagintnText: {
        "color": "#64748B",
        "textAlign": "right",
        "fontFamily": "Inter",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "400",
        "lineHeight": "18px"
    },
    filesHeading: {
        "color": "#64748B",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "700",
        "lineHeight": "22px"
    },
    commonRowDocBox: {
        "display": "flex",
        // "width": "880px",
        "height": "66px",
        "padding": "12px 24px 10px 24px",
        "alignItems": "center",
        "flexShrink": "0",
        justifyContent: 'flex-start',
        width: "50%",
     
         

    },
    docName: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "400",
        // "lineHeight": "22px",
        textTransform: 'none',
        marginLeft: "12px",
        
          
          
    },
    
      
      
 
    modalEditDel: {
        "display": "flex",
        "width": "194px",
        "padding": "5px",
        "flexDirection": "column",
        "alignItems": "flex-start",
        "gap": "5px",
        "borderRadius": "8px",
        "background": "#FFF",
        "boxShadow": "4px 8px 32px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08)",
        position: "absolute",
        top: '1rem',
        right: "2.5rem",
        zIndex: 1,
        "@media (max-width: 768px)": {
            maxWidth:"110px"
             
           },
    },
    delebtn: {
        "display": "flex",
        "width": "184px",
        "height": "36px",
        // "padding": "7px 56px 7px 16px",
        "alignItems": "center",
        "gap": "8px",
        "borderRadius": "8px",
        "background": "#FFF"
    },
    renameFolder: {
        "display": "flex",
        "width": "184px",
        "height": "36px",

        "alignItems": "center", "gap": "8px",
        "borderRadius": "8px",
        "background": "#F6F2EF"
    },
    editText: {
        "color": "#0F172A",
        "fontFamily": "Inter",
        "fontSize": "14px", "fontStyle": "normal",
        "fontWeight": "400",
        "lineHeight": "22px",
        textTransform: "none"
    },
    closeMOdalCross: {
        "display": "flex",
        "width": "32px",
        "height": "32px",
        "padding": "4px", "justifyContent": "center",
        "alignItems": "center",
        "flexShrink": "0",
        cursor: 'pointer'
    },
    cancelbtn: {
        "display": "flex",
        "width": "120px",
        "height": "56px",
        "padding": "16px 24.5px 16px 40.5px",
        "justifyContent": "flex-end",
        "alignItems": "center",
        "flexShrink": "0",
        "borderRadius": "8px",
        "background": "#F6F2EF",
        marginRight: "1rem"
    },
    cancelbtnText: {
        "color": "var(--Orange, #E57727)", "textAlign": "center",
        "fontFamily": "Inter",
        "fontSize": "16px", "fontStyle": "normal",
        "fontWeight": "700",
        "lineHeight": "24px",
        textTransform: "none"
    },
    userLibraryRedirect: {
        "color": "#0F172A",
        "fontFamily": "Inter", "fontSize": "24px",
        "fontStyle": "normal",
        "fontWeight": 400, 
        "letterSpacing": "-0.12px",
        textDecoration: "none",
    },
    filterBox: {
        "display": "flex",
        "width": "11.25rem",
        "paddingTop": "0.75rem", "flexDirection": "column",
        "alignItems": "flex-start",
        "gap": "0.625rem", "flexShrink": "0",
        position: "absolute", top: "3rem", right: '0.625rem',
        "zIndex": "2",
        "background": "#FFFFFF",
        "boxShadow": "4px 8px 32px 0px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.08)"
    },
    filterText: {
        "color": "#0F172A",
        "fontFamily": "Inter", "fontSize": "0.875rem",
        "fontStyle": "normal",
        "fontWeight": 400, "lineHeight": "1.375rem",
        textTransform: "none"
    },
    filtrBtn: {
        "display": "flex",
        "width": "180px", "padding": "10px 0px 10px 25px",
        "alignItems": "center", "gap": "8px",
        justifyContent: 'flex-start'
    },
    buttonStyle: {
        "display": "flex",
        "height": "44px",
        "justifyContent": "center", "alignItems": "center",
        "gap": "8px",
        "borderRadius": "8px"
      },
      btnText: {
        "fontFamily": "Inter",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": 700, 
        textTransform: "none",
        color: "#396178",
        marginLeft: "8px"
      },
}
// Customizable Area End
