//@ts-nocheck
import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, CircularProgress, OutlinedInput, Grid, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, TextField, Typography, Switch, Box, } from '@material-ui/core';
// import LabelWithIcon from './LabelWithIcon';
import Select from "react-select";
import { Close,Add } from '@material-ui/icons';
import CustomInput from './CustomInput.web';
import { Formik,useField,useFormik } from 'formik';
import * as Yup from "yup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            margin: '0',
            // borderRadius: '8px',
            width: '901px',
            maxHeight: "calc(100% - 55px)",
        },
        "& [role='none presentation']": {
          // alignItems: "start",
          // justifyContent: "end",
      },
    },
    headingStyle: {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
    },
    paraStyle:{
        textAlign: 'center',
    },
    btnWrapper:{
      width:"94%",
        justifyContent: 'flex-end',
        padding: "24px 24px 24px",
        borderTop: "1px solid #E2E6F0",
        display: "inline-flex",
        alignItems: "flex-start",
        gap: "16px",
    
        '& button': {
            padding: '16px',
            width: '118px',
            fontWeight:700,
            borderRadius: '8px',
        }
    },
    primaryBtnStyle: {
      background: "#E57727",
      '&:hover': {
        backgroundColor: '#E57727',
    },
      "text-transform": "none",
      "color": "white"
    },
    cancelBtn: {
      color: '#E57727',
      background: "#F6F2EF",
      width:"105px !important",
      height:"56px",
      borderColor: '#F6F2EF',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      textTransform: "capitalize"
  },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    textInputStyle: {
        margin: "0 !important",
        "& .MuiOutlinedInput-input": {
          textTransform:"uppercase",
        },
        "& .css-yk16xz-control":{
          color: "#000",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "24px", 
          height:"56px"
        },
        "& .css-1pahdxg-control":{
          height:"56px",
          color: "#000",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "24px", 
        },
        "&:hover .css-1pahdxg-control":{
          height:"56px",
          borderColor:"#2d6f8f"
        }
    },
    labelStyle:{fontSize:"14px",fontWeight:700,lineHeight:"22px",color:"#334155"},
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 40px",
    },
    radioH:{
      "& .MuiTypography-body1": {
        fontSize: "14px !important",
        color: "#0F172A",
        fontFamily: "Inter",
        lineHeight: "24px",     
    },
    '& .MuiRadio-root.Mui-checked': {
      color: '#e87242',
      fontWeight:700
    },
    '& .MuiRadio-root:not(.Mui-checked)': {
      color: 'grey !important',
    },
  },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    reasonsSection: {
        // marginBottom: '25px !important',
        display:"flex",
        flexDirection:"column",
        gap:"10px",
    },
    editor:{
      display:"grid",
      "& .ql-toolbar.ql-snow": {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
      "& .ql-container.ql-snow":{
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      },
      "& .ql-toolbar.ql-snow + .ql-container.ql-snow":{
        height:"256px"
      }
    },
    downloaIcon: {
        cursor: "pointer"
    },
    pushBackBtn: {
      border: '1px solid primary'
    },
    errorInputStyle:{
      border: '1px solid red'
    },
    toggleBtnss:{
     "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
        background: "#E57727",
    },
  },
  toggle:{
    width: '53px',
    height: '41px',
    "& .MuiSwitch-input":{
      width:"48px",
      height:"28px",
      left:"-41%"
    },
    "& .Mui-checked":{
      transform: "translateX(13px)",
      color:'#fff',
    },
    "& .MuiSwitch-track":{
      backgroundColor:"#989898"
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track":{
      background:'#E57727',
      opacity:"1"
    },
    "&>span":{
      top: '4px',
      left: '4px',
      "&>span":{
        "&>span":{
        width: '15px'
,
        height: '15px'
        }
      }
    }
},
tempName:{
  color: "#000",
fontFamily: "Inter",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "24px",
}


})
const enumMode = {
  Contact: "use_contact_short_code",
  Account: "use_account_short_code"
}
const enumType = {
  WYSIWYG: "wysiwyg",
  HTML: "html"
}
const initialValues={ template_name: "",to:"",description:"", subject: "",format:"html",mode:"use_account_short_code" }
const validationSchema = Yup.object().shape({
  template_name: Yup.string().min(1, "Template name is too short")
  .max(20,"Template name is too long")
  .matches(/^[a-zA-Z][a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Template name is invalid')
  .required("Template name is required."),
  subject: Yup.string().required('Subject is required'),
  to:Yup.string()
  .required('This field is required'),
  description: Yup.string().required("Message is required")
});
type EmailTemplateProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText?: string,
    btnOkText: string,
    handleOk: any;
    handleCancel:any;
    vtEmailId:any;
    subHeading?: string;
    showDialogBtns?: boolean;
    modeTypeSelect:boolean;
    showCrossBtn?: boolean;
    handleEmailType?:any;
    handleMode?:any;
    emailTypeSelect?:any;
}




const EmailTemplate = (props:EmailTemplateProps) => {
    const classes = useStyles();
    
    const modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        [{ undo: true, redo: true }], // Redo/Undo buttons
        ['clean'], // Remove formatting button
        ['copy'], // Copy button
      ],
    };
  
    const formats = [
      'header',
      'bold', 'italic', 'underline', 'strike',
      'align',
      'blockquote', 'code-block',
      'list', 'bullet',
      'link', 'image', 'video',
    ];
  
    
      
  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                    <span className={classes.subHeading}>{props.subHeading}</span>
                </div>
                <Close
                    className={classes.downloaIcon}
                    // onClick={props?.handleCancel}
                    onClick={() => {
                      props?.handleCancel()
                    }} 
                />
           </div>
        </DialogTitle>
        <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                  console.log("VALUES",values);
                  
                props.handleOk(values);
                actions.setSubmitting(false)
                actions.resetForm();
                }}
              >
                {({
                  handleSubmit,
                  touched,
                  handleBlur,
                  errors,
                  values,
                  setFieldValue,
                  handleChange,
                }) => (
                  <form style={{margin:"0"}}onSubmit={handleSubmit}>

       <DialogContent>

<div style={{display:"grid",padding: "24px 40px 8px",gap:"16px"}}>
          <Grid className={classes.reasonsSection} item xs={12}>
          
               <label className={classes.labelStyle}>Template name</label>
               <OutlinedInput
                    className={classes.tempName}
                    fullWidth
                    name="template_name"
                    id="template_name"
                    value={values.template_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter your template name"
                />
                    {touched.template_name && errors.template_name && (
                    <Typography color="error" >{errors.template_name}</Typography>
                    )}
                
          </Grid>
          <Grid className={classes.reasonsSection} item xs={12} >
          <label className={classes.labelStyle}>Mode:</label>
                          <FormControl>
                            <RadioGroup aria-labelledby="demo-row-radiobutton-group-label" name={"mode"}
                            className={classes.radioH}
                            data-test-id="radioBtn"
                              defaultValue={props.modeTypeSelect ? enumMode.Contact : enumMode.Account}
                              value={props.modeTypeSelect ? enumMode.Contact : enumMode.Account}
                              onChange={(event: any) => {
                                handleChange(event);
                                props.handleMode()
                              }}
                            >
                              <FormControlLabel checked={props.modeTypeSelect === true} value={enumMode.Contact} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1" style={{ fontWeight: props.modeTypeSelect ? 700 : 400 }}>
              Use contact short code
            </Typography>
          } />
                              <FormControlLabel checked={props.modeTypeSelect === false} value={enumMode.Account} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1" style={{ fontWeight: props.modeTypeSelect===false ? 700 : 400 }}>
              Use account short code
            </Typography>
          } />
                            </RadioGroup>
                          </FormControl>

                        </Grid>
                        <Grid item xs={12}>
            <label  className={classes.labelStyle}>To</label>
            <Select
              name="to"
              data-test-id="to"
              className={classes.textInputStyle}
              options={props.vtEmailId}
              onBlur={handleBlur}
              value={props.vtEmailId.find((option: any) => option.value === Number(values.to))}
              onChange={(option: any) =>
                setFieldValue('to', option?.labelValue)
              }
            />
            {touched.to && errors.to && (
              <Typography color="error" >{errors.to}</Typography>
            )}
          </Grid>
          
     <Grid className={classes.reasonsSection} item xs={12}>
    
          <label className={classes.labelStyle}>Email Subject</label>
          <OutlinedInput
              
               fullWidth
               name="subject"
               id="subject"
               onChange={handleChange}
               onBlur={handleBlur}
               value={values.subject}
               placeholder="Enter your subject ID"
               error={touched.subject && Boolean(errors.subject)}
           />
               {touched.subject && errors.subject && (
               <Typography color="error" >{errors.subject}</Typography>
               )}
           
     </Grid>
     <Grid className={classes.reasonsSection} item xs={12} >

<FormControl>
  <RadioGroup aria-labelledby="demo-row-radiobutton-group-label" name={"format"}
  className={classes.radioH}
  data-test-id="radioBtn"
    defaultValue={props.emailTypeSelect ? enumType.WYSIWYG : enumType.HTML}
    value={props.emailTypeSelect ? enumType.WYSIWYG : enumType.HTML}
    onChange={(event: any) => {
      handleChange(event);
      props.handleEmailType()
    }}
  >
    <FormControlLabel checked={props.emailTypeSelect === true} value={enumType.WYSIWYG} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1" style={{ fontWeight: props.emailTypeSelect ? 700 : 400 }}>
              WYSIWYG
            </Typography>
          } />
    <FormControlLabel checked={props.emailTypeSelect === false} value={enumType.HTML} control={<Radio style={{ color: "#e87424" }} />} label={
            <Typography variant="body1" style={{ fontWeight: props.emailTypeSelect===false ? 700 : 400 }}>
              HTML
            </Typography>
          } />
  </RadioGroup>
</FormControl>

</Grid>
{props.emailTypeSelect ?  <div>
                                <ReactQuill
                                  className={classes.editor}
                                  theme="snow"
                                  value={values.description}
                                  modules={modules}
                                  formats={formats}
                                  onChange={(content) => {
                                    // Use setFieldValue to update the form state for ReactQuill
                                    setFieldValue("description", content);
                                  }}
                                />
                            </div>: <Grid>
                                <OutlinedInput
                                fullWidth
                                multiline
                                rows={5}
                                name="description"
                                id="description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.description}
                                placeholder="Type here"
                                error={touched.description && Boolean(errors.description)}
                              />
                              {touched.description && errors.description && (
                                <Typography color="error">{errors.description}</Typography>
                              )}
                              </Grid>}
     
</div>
       </DialogContent>
        
    {/* <DialogActions className={classes.btnWrapper}> */}

<Grid className={classes.btnWrapper}>
<Button variant="contained"  className={classes.primaryBtnStyle} type='submit'>
                            {props.btnOkText}
                        </Button>
    </Grid>          
        
{/* 
        </DialogActions> */}
        </form>
                )}
        </Formik>

      </Dialog>
    </div>
  )
}

export default EmailTemplate

