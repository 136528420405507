import React,{ChangeEvent} from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  DialogActions,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import InsertDialogBox from "./Insertdialogbox.web";
import ColumnDialogBox from "./Columndialogbox.web"
import { Close, GetApp } from "@material-ui/icons";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import XmlcsvtemplatebuilderController, {
  Props,
  configJSON,
} from "./XmlcsvtemplatebuilderController";
const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'end',
    '& button': {
        padding: '15px 0px',
        width: '150px',
        borderColor: '#3b8da2',
        borderRadius: '15px',
    }
});
const StyledButton = styled(Button)({
  '&.primaryBtnStyle': {
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform": "none",
    "color": "white"
  },
  '&.disableBtns': {
    color: "#fff",
    borderRadius: '8px',
    background: '#757575',
  },
});
const StyledDialog = styled(Dialog)({
  '& [role="dialog"]': {
    padding: '30px',
    borderRadius: '16px',
    width: '300px',
  },
});

export default class Xmlcsvtemplatebuilder extends XmlcsvtemplatebuilderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {



    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <React.Fragment>

          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Grid style={{display: "flex", width: "30%",flexDirection: "column"}}>
              <Box>
                <Button id={"createbuttonClicked"} variant="contained" style={{...webStyle.buttonStyle,textTransform:"capitalize"}}
                 onClick={() => this.buttonClicked("create")} >
                  Create
                </Button>
              </Box>
              <Box>
                <Button id={"findbuttonClicked"} variant="contained" style={{...webStyle.buttonStyle,textTransform:"capitalize"}}
                  onClick={() => this.buttonClicked("find")}
                  component="span">
                  Find Your files
                </Button>
              </Box>
            </Grid>

            {this.state.xmlFileName &&
              <Grid style={webStyle.box} item>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p style={{ margin: "0", fontSize: "18px", fontWeight: 800, }}>Your File:-</p>
                  <Close style={{ cursor: "pointer" }} onClick={this.closePopup} />
                </div>
                <div style={webStyle.fileName}>
                  <p style={{ margin: "11px", fontSize: "20px", fontWeight: 500, }}>{this.state.xmlFileName}</p>
                  <GetApp style={{ margin: "11px 18px 0 0" }} id="downloadbuttonClicked"
                    onClick={()=>this.handleSaveButtonClick()}
                  />
                  <Box>
                    <Button id={"insertbuttonClicked"} variant="contained" style={{...webStyle.insertbuttonStyle,textTransform:"capitalize"}}
                      onClick={() => this.buttonClicked("insert")}
                      component="span">
                      Insert
                    </Button>
                  </Box>
                </div>
              </Grid>
            }

            {this.state.errors &&
              <Grid style={webStyle.box} item>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <Close id="closeBtn" style={{ cursor: "pointer" }} onClick={this.closePopup} />
                </div>
                <div style={webStyle.fileName}>
                  <p style={{ margin: "2px", textTransform:"capitalize", fontSize: "20px", fontWeight: 500, }}>{this.state.errors}</p>
                </div>
              </Grid>
            }
          </Box>
          <ColumnDialogBox
          data-test-id="Dialogbox"
            btnCancelText='cancel'
            btnOkText='submit'
            openDialog={this.state.showCreateDialog}
            inputValue={this.state.inputValues}
            typeSelected={this.state.typeSelected}
            handleRadio={this.handleRadio}
            headingText={'Enter number of columns'}
            handleOk={this.changeOK}
            handleInputChange={this.handleInputChange}
            handleCancel={this.handleCreateDialog} 
            navigation={undefined} id={""}
          />
          <InsertDialogBox
          data-test-id="InsertDialogbox"
            btnCancelText='cancel'
            btnOkText='submit'
            openDialog={this.state.showInsertDialog}
            columnNames={this.state.columnNames}
            insertValues={this.state.insertValues}
            headingText={'Enter the columns value'}
            typeSelected={this.state.typeSelected}
            handleOk={this.insertChangeOK}
            handleInputChange={this.handleInsertChange}
            handleCancel={this.handleInsertDialog} 
            navigation={undefined} id={""}         
          />
          <FindDialog
          data-test-id="FindDialogbox"
            btnOkText='search'
            openDialog={this.state.showFindDialog}
            headingText={'Enter the file name'}
            dialogReasons={this.state.dialogFind}
            handleFindText={this.handleFindName}
            handleOk={this.findOK}
            handleCancel={this.handleFindDialog}
            id={""} navigation={undefined}
            />
        </React.Fragment>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const FindDialog=(props: Props)=>{
  
  return(
    <div>
      <StyledDialog
        open={props.openDialog}
        onClose={()=>props.handleCancel("backdropClick")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
      >
        <DialogTitle id="alert-dialog-title">
           <div style={webStyle.dialogTitle}>
                <div>
                    <span style={webStyle.headingStyle}>Find your existing file</span>
                </div>
                <Close
                    style={webStyle.downloaIcon}
                    onClick={()=>props.handleCancel()} 
                />
           </div>
        </DialogTitle>
       <DialogContent>
          
          <Grid style={webStyle.reasonsSection} item xs={12}>
               <label style={{fontSize:"16px",fontWeight:700,textAlign: "center",}}>{props.headingText}</label>
               <OutlinedInput
                   style={webStyle.textInputStyle}
                   value={props.dialogReasons}
                   type="text"
                   fullWidth
                   placeholder='Enter your file name'
                   color='primary'
                  onChange={(event:ChangeEvent<HTMLInputElement>) => {
                     props.handleFindText?.(event)
                  }} 
               />
          </Grid>      
       </DialogContent>
        
       <StyledDialogActions >          
          <StyledButton variant="contained" 
            disabled={props.dialogReasons == ""} 
            style={{borderRadius: "8px",textTransform:"capitalize"}} 
            className={props.dialogReasons !="" ? "primaryBtnStyle":"disableBtn"}
            onClick={() => {
              props.handleOk()
            }} 
            color="primary" 
            autoFocus
          >
            {props.btnOkText}
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  )
}
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column" as const,
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  fileName: {
    display: "flex", marginTop: "20px", justifyContent: "center", width: "100%"
  },
  reasonsSection: {
    margin: '10px 0'
},
downloaIcon: {
    cursor: "pointer"
},
  box:{
    border: "1px solid gray",
    borderRadius: "14px",
    marginTop: "60px", 
    boxShadow: "20px 20px 50px gray", 
    padding: "26px"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "rgb(98, 0, 238)",
  },
dialogTitle: {
  // textAlign: "left",
  fontSize: "16px",
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
},
headingStyle: {
    
    '& h2': {
        fontWeight: 700,
        
    },
    fontSize: "16px"
},
  insertbuttonStyle: {
    border: "none",
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "rgb(98, 0, 238)",
  },
cancelBtnStyle: {
  background: "linear-gradient(to bottom, red, #C87674)",
  "text-transform": "none",
  "color": "white",
  borderRadius: "10px"
},
textInputStyle: {
    marginTop: "10px",
    marginBottom: "10px"
},
};
// Customizable Area End