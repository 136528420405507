import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
export const configJSON = require("./config");
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { toast } from 'react-toastify';
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  handleDrawer:any;
  isDrawerOpen:any;
  Task_Id: any;
  projectId: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  value:any;
  isDrawerOpen:boolean;
  formData: any;
  taskFormToggle: any;
  inputValue: any,
  mentionedUsers: any;
  anchorEl: any;
  TaskData: any;
  ProjectNoteMention:any
  error: any;
  users: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AddSubTaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskApiCallId: string = "";
  updateTaskApiCallId: string = "";
  getVtEmpListApiCallId: string = "";
  editProjectNoteApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationCreateProjectMessage)
    ];
    this.state = {
      isDrawerOpen: false,
      value: '',
      formData: {
        taskAssignName: '',
        due_date: '',
        status: '',
        company_name: '',
        description: '',
        assignees: ''
      },
      taskFormToggle: false,
      inputValue: '',
      mentionedUsers: [],
      anchorEl: null,
      TaskData: {},
      error: "",
      users: [],
      ProjectNoteMention:[]
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responsejson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getTaskApiCallId) {
        this.getTaskApiCallIdSQ(responsejson);
      }
      if (apiRequestCallId === this.getVtEmpListApiCallId) {
        this.getVtEmpListApiCallIdSQ(responsejson);
      }
      if (apiRequestCallId === this.editProjectNoteApiCallId) {
        if (responsejson.data) {
          this.showTask(this.props.Task_Id);
        }
      }
      if (apiRequestCallId === this.updateTaskApiCallId) {
        if (responsejson.data) {
          this.showTask(this.props.Task_Id);
        }else{
          this.props.handleDrawer();
          toast.error(responsejson.message);
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  token: string = "";
  gotoAuditTrail = (projectId: any) => {
    if (projectId) {
       localStorage.setItem('projectId', projectId);
        window.location.href = '/Audittrail';
    } 
}

getVtEmpListApiCallIdSQ = (responsejson: any) => {
  if (responsejson.data) {
    const users = responsejson.data.map((item: any) => ({
      id: item.id,
      display: item.attributes.full_name,
      email: item.attributes.email
    }));
    this.setState({ users });
  }
}

handleInputChange = (e: any) => {
  this.setState({ value: e.target.value });
};

  handleMentionAdd = (id: any, display: any) => {
    this.setState(prevState => ({
      mentionedUsers: [...prevState.mentionedUsers, { id, display }]
    }));
  };

  toggleTaskForm = () => {
    this.editTask(this.props.Task_Id, this.props.projectId);
  }


  handleClick =  (event:any)=> {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose =  () => {
    this.setState({ anchorEl: null });
  };

  handleSubmit = () => {
    const { inputValue, mentionedUsers } = this.state;
    const postData = {
      text: inputValue,
      mentionedUsers: mentionedUsers.map((user: any) => user.display)
    };
    console.log(postData);
  }
  
  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));
  }

  getTaskApiCallIdSQ = (responsejson: any) => {
    const { data } = responsejson;
    if (data && data.attributes) {
      const { attributes } = data;
      this.setState({ TaskData: attributes ,ProjectNoteMention:responsejson.note });
      let statusLabel = '';
      switch (attributes.status) {
        case 'to_do':
          statusLabel = 'To Do';
          break;
        case 'in_progress':
          statusLabel = 'In Progress';
          break;
        case 'complete':
          statusLabel = 'Complete';
          break;
        case 'deleted':
          statusLabel = 'Deleted';
          break;
        default:
          statusLabel = '';
          break;
      }
      this.setState({
        formData: {
          ...this.state.formData,
          title: attributes.title || '',
          taskAssignName: '',
          due_date: attributes.due_date || '',
          status: statusLabel || '',
          company_name: attributes.account.company_name,
          description: attributes.description || '',
          assignees: attributes.assignees || ''
        }
      });
    }
  }


  editProjectNote = async (e: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.editProjectNoteApiCallId = await this.taskApiCall({
      contentType: 'application/json',
      methods: "PATCH",
      endPoint: `/bx_block_tasks/tasks/${this.props.Task_Id}/create_note`,
      token: this.token,
      body: {
        task: {
          project_id: this.props.Task_Id,
          project_note: this.state.value
        }
      }
    });
  }

  getVtEmpList = async () => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.getVtEmpListApiCallId = await this.taskApiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: `account_block/accounts/vt_emp_list`,
      token: this.token,
    });
  }

  editTask = async (Task_id: any, ProjectId: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;
    this.setState({ taskFormToggle: false })
    console.log(this.state.formData)
    this.updateTaskApiCallId = await this.taskApiCall({
      contentType: 'application/json',
      methods: "PATCH",
      endPoint: `bx_block_tasks/tasks/${Task_id}`,
      token: this.token,
      body: {
        task: {
          project_id: ProjectId,
          ...this.state.formData

        }
      }
    });
  }
  
  showTask = async (Task_id: any) => {
    let someData = localStorage.getItem("loginData");
    let Parsed = someData && JSON.parse(someData);
    this.token = Parsed?.token;

    this.getTaskApiCallId = await this.taskApiCall({
      contentType: 'application/json',
      methods: "GET",
      endPoint: `bx_block_tasks/tasks/${Task_id}`,
      token: this.token,
    });
  };


  taskApiCall = async (data: any) => {
    const { methods, endPoint, token, body } = data;
    const Header = {
      "Content-Type": "application/json",
      token,
    };
    const apiRequestMessageforAddSubTask = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageforAddSubTask.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageforAddSubTask.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    body &&
      apiRequestMessageforAddSubTask.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    apiRequestMessageforAddSubTask.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    runEngine.sendMessage(
      apiRequestMessageforAddSubTask.id,
      apiRequestMessageforAddSubTask
    );
    return apiRequestMessageforAddSubTask.messageId;
  }

  // Customizable Area End
}
