import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

interface CategoryMapping {
  yourCategory: string;
  ourCategory: string;
  options: string[];
}

interface ImportDialogProps {
  openDialog:boolean,
  headingText: string,
  handleCancel:any
}

interface ImportDialogState {
  mappings: CategoryMapping[];
  errors: { [key: number]: boolean };
}
const useStyles = makeStyles({
  dialogWrapper:{
      "& [role='dialog']":{
          borderRadius: '8px',
          width: '100%'
      }
  },
  headingStyle: {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
  },
  paraStyle:{
      textAlign: 'center',
  },
  btnWrapper:{
      justifyContent: 'end',
      padding: "24px 37px 24px 381px",
      borderTop: "1px solid #E2E6F0",
  
      '& button': {
          padding: '16px 20px',
          width: '182px',
          borderColor: "#E57727",
          fontWeight: 700,
          borderRadius: '8px',
      }
  },
  primaryBtnStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: "#E57727",
  },
    width: "85px",
    fontWeight: 700,
    fontFamily: "Inter",
    "text-transform": "none",
    "color": "white",
    cursor: "pointer",
    display: "flex",
    height: "44px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  cancelBtnStyle: {
    background: "linear-gradient(to bottom, red, #C87674)",
    "text-transform": "none",
    "color": "white",
    borderRadius: "10px"
  },
  textInputStyle: {
      marginTop: "10px",
      marginBottom: "10px"
  },
  dialogTitle: {
      textAlign: "left",
      fontSize: "16px",
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: "1px solid #E2E8F0",
      padding: "24px 16px 24px 40px",
  },
  imageContainer: {
    width: "18%",
    position: "relative",
    // max-width: 600px,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  
  textOverlay: {
    position: "absolute",
    top: "3px",
    left:"50px",
    // width: "100%",
    color: "#000", 
    backgroundColor: "#fff", 
    textAlign: "center", 
    padding: "0px 10px",
  },
  subHeading: {
      fontSize: "16px",
      margin: "0 4px"
  },
  confirmBtn: {
      borderRadius: '8px',
  },
  disableBtn: {
      color: "#fff",
      borderRadius: '8px',
      background: '#757575'
  },
  reasonsSection: {
      marginBottom: '25px !important',
      display:"flex",
      flexDirection:"column",
      gap:"10px"
  },
  downloaIcon: {
      cursor: "pointer"
  },
  pushBackBtn: {
    border: '1px solid primary'
  },
  errorInputStyle:{
    border: '1px solid red'
  }
})

const ImportDialog = (props: ImportDialogProps) => {
  const classes = useStyles();

    const initialMappings: CategoryMapping[] = [
      { yourCategory: 'First name', ourCategory: 'First name', options: ['First name', 'Surname', 'Company', 'Phone number', 'Birthday'] },
      { yourCategory: 'Last name', ourCategory: 'Surname', options: ['First name', 'Surname', 'Company', 'Phone number', 'Birthday'] },
      { yourCategory: 'Company name', ourCategory: 'Company', options: ['First name', 'Surname', 'Company', 'Phone number', 'Birthday'] },
      { yourCategory: 'Phone number', ourCategory: 'Phone number', options: ['First name', 'Surname', 'Company', 'Phone number', 'Birthday'] },
      { yourCategory: 'Date of birth', ourCategory: 'Birthday', options: ['First name', 'Surname', 'Company', 'Phone number', 'Birthday'] },
      { yourCategory: 'City', ourCategory: '', options: ['First name', 'Surname', 'Company', 'Phone number', 'Birthday'] },
    ];

    const [mappings, setMappings] = useState<CategoryMapping[]>(initialMappings);
    const [errors, setErrors] = useState<{ [key: number]: boolean }>({});
  
    const handleCategoryChange = (index: number, value: any) => {
      const newMappings = [...mappings];
      newMappings[index].ourCategory = value;
      setMappings(newMappings);
    };
  
    const handleImport = () => {
      let isValid = true;
      const newErrors: { [key: number]: boolean } = {};
  
      mappings.forEach((mapping, index) => {
        if (mapping.ourCategory === '') {
          isValid = false;
          newErrors[index] = true;
        }
      });
  
      if (isValid) {
        console.log('Importing with mappings:', mappings);
      } else {
        setErrors(newErrors);
      }
    };
  
    return (
      <Dialog
        open={props.openDialog}
        onClose={props.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        disableBackdropClick={true}
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
          <div className={classes.dialogTitle}>
            <div>
              <span className={classes.headingStyle}>{props.headingText}</span>
            </div>
            <Close className={classes.downloaIcon} onClick={props.handleCancel} />
          </div>
        </DialogTitle>
        <DialogContent style={{padding:"40px",display:"flex",flexDirection:"column",gap:"40px"}}>
          <div style={{display:"flex",gap:"40px"}}>
          <Typography style={{color: "#000",
fontFamily: "Inter",
fontSize: "20px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "normal",}}>Yours categories</Typography>

          <div className={classes.imageContainer}>
          <img src={require("../../web/public/assets/view_line.svg")}></img>
          <div className={classes.textOverlay}>Match me</div>
    </div>
          <Typography style={{color: "#000",
fontFamily: "Inter",
fontSize: "20px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "normal",}}>Ours categories</Typography>
</div>
          <Table>
            <TableBody>
              {mappings.map((mapping, index) => (
                <TableRow key={index}>
                  <TableCell>{mapping.yourCategory}</TableCell>
                  <TableCell>
                    <FormControl fullWidth error={!!errors[index]}>
                      <InputLabel>{mapping.ourCategory || 'Select a category'}</InputLabel>
                      <Select
                        value={mapping.ourCategory}
                        onChange={(event) => handleCategoryChange(index, event?.target?.value)}
                      >
                        {mapping.options.map((option, i) => (
                          <MenuItem key={i} value={option}>{option}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {Object.keys(errors).length > 0 && (
            <div style={{ color: 'red' }}>1 field label must be assigned</div>
          )}

          <Button onClick={handleImport} className={classes.primaryBtnStyle}>
            Import
          </Button>
        </DialogContent>
        <DialogActions className={classes.btnWrapper}>
          <Button onClick={props.handleCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ImportDialog;