import React from "react";
// Customizable Area Start
import {
    Container,
    Button,

    TextField,
    FormControl,
    FormLabel,
    Card,
    Dialog,
    Divider,
    Typography,

} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { DeleteOutlineOutlined, AddCircleOutlineRounded, CloseOutlined } from "@material-ui/icons";

import "react-datepicker/dist/react-datepicker.css";
import { uploadImage } from "./assets"
const theme1 = createTheme({
    palette: {
        primary: {
            main: "#CBD5E1",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

import CustomisableUserProfilesController, {
    Props,

} from "./CustomisableUserProfilesController.web";

// Customizable Area End

export default class CustomisableUserProfile extends CustomisableUserProfilesController{
    // constructor(props: Props) {
    //     super(props);
    //     // Customizable Area Start
    //     // Customizable Area End
    // }



    render() {
        // Customizable Area Start
       
        const { userProfileData} = this.state
       
   
        return (
            <ThemeProvider theme={theme1}>
                <Container maxWidth='md' >
                    {/* Customizable Area Start */}

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Card
                            style={{
                                width: "39.3rem",
                                backgroundColor: "#F8FAFC",
                                padding: "2.5rem",
                                minHeight: "49.8rem",
                                borderRadius: "0.625rem",

                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "2.5rem",
                                marginTop: "2.5rem",
                                marginBottom: "2.5rem",
                            }}
                            data-test-id="card"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2.5rem",
                                    alignSelf: "stretch",
                                    height: "9.75rem",
                                    width: "100%",
                                    position: "relative"
                                }}
                            >
                                <img
                                    src={
                                        this.selectProfile()
                                    }
                                    alt="profile"
                                    width="156"
                                    height="156"
                                    style={{
                                        borderRadius: "8.5rem",
                                        border: "4px solid #F0E5FF",

                                        background: "#FFF",
                                    }}
                                    data-test-id="uploadProfile"
                                    onClick={this.handleshowUploadCard}
                                />
                                {this.state.showUploadCard &&
                                    <Card style={{ width: "184px", height: "77px", padding: '5px', display: "inline-flex", flexDirection: "column", gap: "5px", borderRadius: "8px", background: "#FFF", boxShadow: " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)", position: "absolute", left: "130px", top: "74px" }}>
                                        <div style={{ "display": "flex", "width": "184px", "height": "36px", "padding": "7px 52px 7px 16px", "alignItems": "center", "gap": "8px" }}>
                                            <label onClick={this.openFileInput}
                                                style={{ display: "flex", alignItems: "center" }}><img src={uploadImage} style={{ width: "18px", height: "18px", marginRight: "8px" }} />Upload Photo</label>
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={this.fileInput}
                                                onChange={this.handleFileChange}
                                                name="profile"
                                                data-test-id="fileupload"
                                                accept=".jpg, .jpeg, .png"
                                            />

                                        </div>
                                        <div style={{ "display": "flex", "width": "184px", "height": "36px", "padding": "7px 46px 7px 16px", "alignItems": "center", "gap": "8px" }}>
                                            <label onClick={this.removeFile} style={{ display: "flex", alignItems: "center" }} > <DeleteOutlineOutlined style={{ width: "18px", height: "18px", marginRight: "8px" }} />Remove Photo</label>
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={this.fileInput}
                                                onChange={this.handleFileChange}
                                                name="profile"
                                                data-test-id="removeFile"
                                            />

                                        </div>





                                    </Card>}
                            </div>
                            <form
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "#F8FAFC",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "0px"
                                }}
                                // onSubmit={(e) => this.handleSubmit(e)}
                                data-test-id="form"
                            >
                                <FormControl
                                    style={{
                                        marginBottom: "0.94rem",
                                        display: "flex",
                                        width: "39.3125rem",
                                        alignItems: "flex-start",
                                        height: '1.5rem'
                                    }}
                                >
                                    <FormLabel
                                        style={{
                                            paddingBottom: "10px",
                                            fontWeight: 700,
                                            color: "#64748B",
                                        }}
                                    >
                                        Personal Information
                                    </FormLabel>
                                </FormControl>
                                <FormControl
                                    style={{
                                        marginBottom: "0.94rem",
                                        display: "flex",
                                        width: "39.3125rem",
                                        height: "5.125rem",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        // gap: "0.25rem",
                                    }}
                                >
                                    <FormLabel
                                        style={{
                                            paddingBottom: "10px",
                                            fontWeight: 700,
                                            color: "#64748B",
                                            fontSize: '14px',
                                            lineHeight: '22px'
                                        }}
                                    >
                                        Full Name
                                    </FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF", }}
                                        name="full_name"
                                        color="primary"
                                        value={userProfileData?.attributes?.full_name}
                                        data-test-id="txtInputname"
                                        onChange={this.handleChange}
                                        fullWidth
                                        InputProps={{

                                            style: { color: "#64748B" },

                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    style={{
                                        marginBottom: "0.94rem",
                                        display: "flex",
                                        width: "39.3125rem",
                                        height: "5.125rem",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        // gap: "0.25rem",
                                    }}
                                >
                                    <FormLabel
                                        style={{
                                            paddingBottom: "10px",
                                            fontWeight: 700,
                                            color: "#64748B",
                                            fontSize: '14px',
                                            lineHeight: '22px'
                                        }}
                                    >
                                        Email
                                    </FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        name="email"
                                        onChange={this.handleChange}
                                        value={userProfileData?.attributes?.email}
                                        InputProps={{

                                            style: { color: "#64748B" },
                                        }}
                                        color="primary"
                                        style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
                                        fullWidth
                                        data-test-id="txtInputemail"

                                    />
                                </FormControl>
                                <FormControl
                                    style={{
                                        marginBottom: "0.94rem",
                                        display: "flex",
                                        width: "39.3125rem",
                                        height: "5.125rem",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        // gap: "0.25rem",
                                    }}
                                >
                                    <FormLabel
                                        style={{
                                            paddingBottom: "10px",
                                            fontWeight: 700,
                                            color: "#64748B",
                                            fontSize: '14px',
                                            lineHeight: '22px'
                                        }}
                                    >
                                        Location
                                    </FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        name="address"
                                        color="primary"
                                        value={userProfileData?.attributes?.address}
                                        onChange={this.handleChange}
                                        InputProps={{

                                            style: { color: "#64748B" },
                                        }}
                                        style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
                                        fullWidth
                                    />
                                </FormControl>
                          
                                <FormControl
                                    style={{
                                        // marginBottom: "0.94rem",
                                        display: "flex",
                                        width: "39.3125rem",
                                        height: "5.125rem",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        // gap: "0.25rem",
                                    }}
                                >
                                    <FormLabel
                                        style={{
                                            paddingBottom: "10px",
                                            fontWeight: 700,
                                            color: "#64748B",
                                            fontSize: '14px',
                                            lineHeight: '22px'
                                        }}
                                    >
                                        Bio
                                    </FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
                                        name="bio"
                                        color="primary"
                                        value={userProfileData?.attributes?.bio}
                                        onChange={this.handleChange}
                                        InputProps={{

                                            style: { color: "#64748B" },
                                        }}
                                        fullWidth
                                    />
                                </FormControl>
                            
                                {this.state.customField && Object.entries(this.state.customField).map(([key, value], index) =>(
    <FormControl
                                    style={{
                                        marginTop: "0.94rem",
                                        display: "flex",
                                        width: "39.3125rem",
                                        height: "5.125rem",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        // gap: "0.25rem",
                                    }}key={index}
                                >
                                    <FormLabel
                                        style={{
                                            paddingBottom: "10px",
                                            fontWeight: 700,
                                            color: "#64748B",
                                            fontSize: '14px',
                                            lineHeight: '22px'
                                        }}
                                    >
                                        {key}
                                    </FormLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
                                        name={key}
                                        color="primary"
                                        value={value}
                                        data-test-id={`addfield-${index}`}
                                        onChange={(e) => this.handleInputChange(key, e.target.value)}
                                        InputProps={{

                                            style: { color: "#64748B" },
                                        }}
                                        fullWidth
                                    />
                                </FormControl>))}  

                          
                            </form>

                            <Divider
                                style={{
                                    border: "1px solid #E2E8F0",
                                    height: "0.0625rem",
                                    backgroundColor: "#E2E8F0",
                                    width: "100%",
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    width: "520px",
                                    alignItems: "center",
                                    gap: "24px",
                                    height: "1.5rem"
                                }}
                            >

                                <Button
                                    style={{
                                        color: "#64748B",
                                        textTransform: "none",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    data-test-id="fieldModalOpen"
                                    onClick={this.handleaddFieldModal}
                                >
                                    <AddCircleOutlineRounded
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "0.5rem",
                                        }}
                                    />
                                    Add Field
                                </Button>

                                <Dialog
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",

                                        boxShadow:
                                            " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
                                    }}
                                    open={this.state.addFieildModal}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Card
                                        style={{
                                            maxWidth: "37.5rem",

                                            borderRadius: "0.5rem",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                height: "2rem",
                                                padding: "1.5rem 1rem 1.5rem 2.5rem",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                alignSelf: "stretch",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontSize: "1.5rem",
                                                    fontWeight: 700,
                                                    color: "#000000",
                                                }}
                                            >
                                                {" "}
                                                Add Field
                                            </p>
                                            <CloseOutlined onClick={this.handleaddFieldModal1} />
                                        </div>
                                        <Divider />
                                        <div
                                            style={{
                                                display: "flex",
                                                padding: "2.5rem 2.5rem",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                gap: "1rem",
                                                alignSelf: "stretch",
                                                width: "32.5rem",
                                                // height: "11.3rem",
                                            }}
                                        >
                                            <FormControl
                                                style={{
                                                    // marginBottom: "0.94rem",
                                                    display: "flex",
                                                    width: "32.5rem",
                                                    height: "5.13rem",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                    // gap: "0.25rem",
                                                }}
                                            >
                                                <FormLabel
                                                    style={{
                                                        paddingBottom: "10px",
                                                        fontWeight: 700,
                                                        color: "#64748B",
                                                        fontSize: '14px',
                                                        lineHeight: '22px'
                                                    }}
                                                >
                                                    Field Name
                                                </FormLabel>
                                                <TextField
                                                    type="text"
                                                    variant="outlined"
                                                    style={{ "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF" }}
                                                    name="new_column_name"
                                                    data-test-id="newColumn"
                                                    value={this.state.addfieldName}
                                                    // color="primary"
                                                    onChange={this.handleChangeAddFieldname}
                                                    InputProps={{
                                                        placeholder: "Enter Field Name",
                                                        style: { color: "#64748B" },
                                                    }}
                                                    fullWidth
                                                    error={this.state.addfieldError}
                                                />
                                                {this.state.addfieldError ? <Typography style={{ color: "red", fontSize: '12px', fontFamily: "inter", paddingBottom: "2rem" }}>Field name is required</Typography> : null}

                                            </FormControl>

                                            <FormControl
                                                style={{
                                                    // marginBottom: "0.94rem",
                                                    display: "flex",
                                                    width: "32.5rem",
                                                    height: "5.13rem",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                    // gap: "0.25rem",
                                                }}
                                            >
                                                <FormLabel
                                                    style={{
                                                        paddingBottom: "10px",
                                                        fontWeight: 700,
                                                        color: "#64748B",
                                                        fontSize: '14px',
                                                        lineHeight: '22px'
                                                    }}
                                                >
                                                    Description
                                                </FormLabel>
                                                <TextField
                                                    type="text"
                                                    variant="outlined"
                                                    style={{
                                                        "borderRadius": "8px", "border": "1px solid #CBD5E1", "background": "#FFF",


                                                    }}
                                                    name="new_data"
                                                    data-test-id="newColumnDesc"
                                                    value={this.state.addfieldDescr}
                                                    onChange={this.handleChangeAddFieldDescr}
                                                    color="primary"
                                                    InputProps={{
                                                        placeholder: "Enter Description",
                                                        style: { color: "#64748B" },
                                                    }}
                                                    fullWidth
                                                    error={this.state.addfieldDesError}

                                                />
                                                {this.state.addfieldDesError ? <Typography style={{ color: "red", fontSize: '12px', fontFamily: "inter" }}>Field description is required</Typography> : null}

                                            </FormControl>

                                        </div>
                                        <Divider />
                                        <div
                                            style={{
                                                display: "flex",
                                                // height: "6.5rem",
                                                padding: "1.5rem 1.5rem 1.5rem 28.5rem",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                alignSelf: "stretch",
                                            }}
                                        >
                                            <Button
                                                // onClick={this.accountProfileStatusModalClose}
                                                data-test-id="addnewfieldapi"
                                                variant="contained"
                                                style={{
                                                    display: "flex",
                                                    width: "7.5rem",
                                                    height: "3.5rem",
                                                    padding: "1rem 2.59375rem 1rem 2.46875rem",
                                                    borderRadius: "0.5rem",
                                                    backgroundColor: this.state.primaryColor,
                                                    color: "#FFF",
                                                    fontWeight: 700,
                                                    fontSize: "1rem",
                                                }}
                                                onClick={this.handleFieldError}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Card>
                                </Dialog>


                            </div>
                            <div
                                style={{
                                    display: "flex", alignItems: "flex-start",
                                    gap: "2.5rem",
                                }}
                            >
                                <Button
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        color: this.state.secondaryColor,
                                        backgroundColor: "#F6F2EF",
                                        textTransform: "none", borderRadius: "0.5rem",
                                        display: "flex",
                                        height: "44px", padding: "10px 16px",
                                        justifyContent: "center", alignItems: "center", gap: "8px",
                                    }}
                                    data-test-id="cancelchanges"
                                    onClick={this.handelCancelChanges}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        color: "#FFFFFF", backgroundColor: this.state.primaryColor,
                                        textTransform: "none", borderRadius: "0.5rem",
                                        display: "flex", height: "44px",
                                        padding: "10px 16px", justifyContent: "center", alignItems: "center",
                                        gap: "8px",
                                    }}
                                    data-test-id="updateProfile"
                                    onClick={this.updateProfile}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </Card>
                    </div>

                    {/* Customizable End Start */}
                </Container>
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start





// Customizable Area End
