// Customizable Area Start
import React from "react";
import { array, object } from 'yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import { Close } from "@material-ui/icons";

const initialValues = { termCondition: false }

import { MultipleFileUploadField } from "./fileupoad.web";
import FileUploadPop from "./FileUploadPop.web";


type BulkUploadProps = {
    openDialog: boolean,
    headingText: string,
    btnCancelText: string,
    btnOkText: string,
    btnColor:string;
    textColor:string;
    handleOk: any;
    handleCancel: any;
    subHeading: string;
    showDialogBtns?: boolean;
    showCrossBtn?: boolean;
    showPopUp:boolean;
    filesStatus:string;
    heading:string;
    btnText:string;
    uploadDone:any;
}

const BulkUpload = (props: BulkUploadProps) => {
    const classes = useStyles();

//   render() {
//     const { classes } = this.props;
//     const {btnColor,textColor} = this.state;
    return (
      <div>
        <Dialog
          // open={props?.openDialog}
          open={props.openDialog}
          onClose={props.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          disableBackdropClick={true}
          className={classes.dialogWrapper}
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
            <div className={classes.dialogTitle}>
              <div>
                <span className={classes.headingStyle}>{props.headingText}</span>
              </div>
              <Close
                className={classes.downloaIcon}
                onClick={() => props.handleCancel()}
              />
            </div>
          </DialogTitle>


          <Formik
            data-testId="UploadForm"
            initialValues={{ files: [] }}
            validationSchema={object({
              files: array().min(1, 'At least one file is required'),
            })}
            onSubmit={async (values) => {
              props.handleOk(values.files);
            }}
          >
            {({ values, errors, isValid, handleSubmit }) => {
              return (
                <form style={{ margin: "0" }} onSubmit={handleSubmit}>
                  <DialogContent style={{ padding: "40px" }}>
                    <Grid container spacing={2} direction="column">
                      <MultipleFileUploadField name="files" textColor={props.textColor} />
                    </Grid>
                  </DialogContent>

                  <DialogActions className={classes.btnWrapper}>
                      <Button variant="contained" onClick={props.handleCancel} style={{color:props.textColor}} className={classes.cancelBtnStyle}>
                        {props.btnCancelText}
                      </Button>
                      <Button variant="contained" id="btnUpload" style={{background:props.btnColor}} className={classes.primaryBtnStyle} type='submit'>
                        {props.btnOkText}
                      </Button>
                  </DialogActions>

                </form>
              );
            }}
          </Formik>

        </Dialog>

        <FileUploadPop
          openDialog={props.showPopUp}
          fileStatus={props.filesStatus}
          headingText={props.heading}
          subHeading={props.subHeading}
          btnOkText={props.btnText}
          btnColor={props.btnColor}
          handleOk={(e:any) => {props.uploadDone(e)}}
        />
      </div>
    );


//   }
}

const useStyles = makeStyles({
  dialogWrapper: {
    "& [role='dialog']": {
      borderRadius: '8px',
      width: '600px'
    }
  },
  headingStyle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  paraStyle: {
    textAlign: 'center' as const,
  },
  btnWrapper: {
    display: "flex",
    gap: "16px",
    margin:"0",
    justifyContent: 'end',
    padding: "24px 24px 24px 320px",
    borderTop: "1px solid #E2E6F0",
  "& .MuiDialogActions-spacing > :not(:first-child)": {
    marginLeft: "0",
  },
    '& button': {
      padding: '16px 32px',
      width: '120px',
      borderColor: '#E57727',
      borderRadius: '8px',
    }
  },
  primaryBtnStyle: {
    background: "#E57727",
    '&:hover': {
      backgroundColor: '#E57727',
    },
    "text-transform": "none",
    "color": "white"
  },
  cancelBtnStyle: {
    color: '#E57727',
    background: "#F6F2EF",
    display: "flex",
    padding: "16px 21px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    width: "159px",
    gap: "10px", borderRadius: "8px", textTransform: "none" as const
  },
  textInputStyle: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  dialogTitle: {
    textAlign: "left" as const,
    fontSize: "16px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 16px 24px 40px",
  },
  subHeading: {
    fontSize: "16px",
    margin: "0 4px"
  },
  confirmBtn: {
    borderRadius: '8px',
  },
  disableBtn: {
    color: "#fff",
    borderRadius: '8px',
    background: '#757575'
  },
  reasonsSection: {
    marginBottom: '25px !important',
    display: "flex",
    flexDirection: "column" as const,
    gap: "10px"
  },
  downloaIcon: {
    cursor: "pointer"
  },
  pushBackBtn: {
    border: '1px solid primary'
  },
  errorInputStyle: {
    border: '1px solid red'
  }
})
export default BulkUpload
// Customizable Area End



