import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingCSV: boolean;
  loadingJson: boolean;
  token: string;
  files:File[];
  importData:boolean;
  exportData:boolean;
  mapping:boolean;
  selectedFile:any;
  exportAsType:boolean,
  exportPageType:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImportExportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  jsonDataApiCallId: string = "";
  currentId = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loadingCSV: false,
      loadingJson: false,
      token: "",
      selectedFile:null,
      exportData:false,
      importData:false,
      exportAsType:false,
      exportPageType:false,
      mapping:false,
      files:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.jsonDataApiCallId !== "" &&
      this.jsonDataApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.errors) {
        console.log(responseJson.errors);
        alert(configJSON.errorMsg);
        this.setState({ loadingJson: false });
      } else {
        responseJson && this.exportFile(JSON.stringify(responseJson), ".json");
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isJSON = (text: any) => {
    try {
      var json = JSON.parse(text);
      return typeof json === "object";
    } catch (error) {
      return false;
    }
  };

  exportFile = (data: any, type: string) => {
    const blob = new Blob([data], {
      type: type === ".json" ? "application/json" : "text/csv"
    });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `${Date.now()}${type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ loadingCSV: false, loadingJson: false });
  };

  downloadCSVData = async () => {
    // This function uses fetch rather than RunEngine for the network request by design
    // It is done because the RestApiClientBlock does not handle CSV responses

    this.setState({ loadingCSV: true });
    try {
      const response = await fetch(
        `${configJSON.baseURL}/${configJSON.exportDataEndpoint}`,
        {
          headers: {
            "Content-Type": configJSON.csvApiContentType,
            token: this.state.token
          }
        }
      );
      const res = await response.text();
      let result = res && this.isJSON(res) ? JSON.parse(res) : null;
      if (result?.errors) {
        console.log(result.errors);
        this.showAlert('Error downloading CSV', configJSON.errorMsg);
        this.setState({ loadingCSV: false });
      } else {
        this.exportFile(res, ".csv");
      }
    } catch (e) {
      console.log("error: ", e);
      this.setState({ loadingCSV: false });
    }
  };

  openImortData=()=>{
    this.setState({importData:!this.state.importData})
  }
  openExportData=()=>{
    this.setState({exportData:!this.state.exportData})
  }
  handleExportPage=()=>{
    this.setState({exportPageType:!this.state.exportPageType})
  }
  handleExportAs=()=>{
    this.setState({exportAsType:!this.state.exportAsType})
  }
  handleMapping=()=>{
    this.setState({mapping:!this.state.mapping})
  }

   handleFileChange = (event:any) => {
    const file = event.target.files[0] || event.dataTransfer.files[0];
    this.setState({selectedFile:file});
  };

   handleDragOver = (event:any) => {
      event.preventDefault();
  };
    
  
   handleDrop = (event:any) => {
    event.preventDefault();
   if (event.dataTransfer.items) {
    for (let i = 0; i < event.dataTransfer.items.length; i++) {
      if (event.dataTransfer.items[i].kind === 'file') {
        let file = event.dataTransfer.items[i].getAsFile();
        console.log('... file[' + i + '].name = ' + file.name);
        this.setState({selectedFile:file});
      }
    }
  } else {
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      console.log('... file[' + i + '].name = ' + event.dataTransfer.files[i].name);
      this.setState({selectedFile:event.dataTransfer.files[i]})
    }
  }
  

  };

  handleFileUpload(selectedFiles:any) {
    this.setState({ files: selectedFiles });
  }
  handleImportFileUpload = (files: File[]) => {
    console.log(files);
    this.handleMapping()
  };
  getNewId=()=> {
    return ++this.currentId;
  }
  onUpload=(file:any, url:any)=> {
    this.setState((prevState:any) => ({
      files: prevState.files.map((fw:any) => {
        if (fw.file === file) {
          return { ...fw, url };
        }
        return fw;
      }),
    }));
  }

  onDelete=(file:any)=> {
    this.setState((prevState) => ({
      files: prevState.files.filter((fw:any) => fw.file !== file),
    }));
  }

  downloadJsonData = () => {
    this.setState({ loadingJson: true });
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.state.token
    };

    const getJsonMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jsonDataApiCallId = getJsonMessage.messageId;

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exportDataEndpoint
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exportDataMethod
    );

    runEngine.sendMessage(getJsonMessage.id, getJsonMessage);
    return true;
  };
  // Customizable Area End
}
