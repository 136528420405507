// Customizable Area Start
import React from "react";
import { Box, TextField, Typography, TableRow, TableCell, TableHead, Table, Grid, TableBody, MenuItem, withStyles, FormControl, RadioGroup, FormControlLabel, Radio, OutlinedInput, Button, Link, Breadcrumbs, Popover } from "@material-ui/core";
import InvoiceDetailsController, {
  Props,
} from "./InvoiceDetailsController.web";
import Toggle from "../../../components/src/Toggle.web";
import { AddCircleOutline, NavigateNext } from "@material-ui/icons";
import { cards } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link as Redirect } from 'react-router-dom'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ManualPaymentForm from "../../../components/src/ManualPaymentForm";

export class InvoiceDetails extends InvoiceDetailsController {
  constructor(props: Props) {
    super(props);

  }


  render() {
    const {classes}= this.props;

  const itemmsss=this.state.showInvoiceData.attributes.invoice_items;
  const due_Date = new Date(this.state.showInvoiceData.attributes.due_date);
    const subtotal = itemmsss.reduce((sum:any, item:any) => sum + item.price, 0);
  const taxAmount = (subtotal * this.state.taxRate) / 100;
  const discountAmount = (subtotal * this.state.discountRate) / 100;
  const total = subtotal + taxAmount - discountAmount;
  const open = Boolean(this.state.anchorEl);
  const breadcrumbsInvoicePayment = [
    <Link underline="hover" key="1" color="inherit">
      <Redirect to="/InvoiceBilling" className={classes.redirection} style={{color: "#475569",fontWeight: 400}}>Invoices</Redirect>
    </Link>,
  
    <Typography key="3" className={classes.currentPage} style={{color:"#0F172A"}}>
      {itemmsss[0].name}
    </Typography>
  ];
  const breadcrumbsInvoiceCard = [
    <Link underline="hover" key="1" color="inherit">
      <Typography onClick={this.handleCreditPay} className={classes.redirection} style={{color: "#475569",fontWeight: 400}}>Invoices</Typography>
    </Link>,
  
    <Typography key="3" className={classes.currentPage} style={{color:"#0F172A"}}>
      {itemmsss[0].name}
    </Typography>
  ];
 
    return (
      <Box sx={{ display: "flex", flexDirection: "column", 
      width: "calc(100vw - 135px)", position: 'fixed', top: "0px", right: "0px", alignItems:"flex-start" ,justifyContent:'center',height:"100vh"}}>
          <Box sx={{
              "display": "flex", "height": "32px", "padding": "20px 1154px 20px 30px", "justifyContent": "space-between",
              "alignItems": "center", "flexShrink": 0, "borderBottom": "1px solid #CBD5E1", width: "calc(100vw - 193px)", position: "sticky"
          }}>
        {!this.state.payOnline &&<><Breadcrumbs
                 style={{display:"flex"}}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbsInvoicePayment}
        </Breadcrumbs>
              <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "gap": "12px", height: "44px" }}>

              <Button className={classes.btnAddColumn} style={{color:"#E57727",width:"140px"}}>Send to client</Button>
                <Button className={classes.btnAddAccount} style={{width:"98px"}} onClick={this.handleOpen}>Pay now</Button>
                <Popover
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  className={classes.customPopover}
                >
        <Button id="manualForm" className={classes.btnText} onClick={this.handleManualForm}>Manual Payment</Button>
        <Button id="creditForm" className={classes.btnText} onClick={this.handleCreditPay}>Card Payment</Button>
      </Popover>

              </div></>}

              {this.state.payOnline && <Breadcrumbs
                 style={{display:"flex"}}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbsInvoiceCard}
        </Breadcrumbs>}

          </Box>
      <Box style={{
        width: "100%", height: '90%', display: 'flex',
        flexDirection: "row", gap: "43px",overflowY:"auto" 
    }}>
        {!this.state.payOnline &&<Box style={{width: "calc(100% - 40%)",height: "778px",paddingLeft: "43px"}}>
            <label className={classes.labelStyle}>Bill to</label>
            <Box style={{display: "flex",
padding: "16px",
alignItems: "flex-start",
gap: "8px",
alignSelf: "stretch",
borderRadius: "8px",
border: "1px solid #CBD5E1",
background: "#FFF",}}>
    <div style={{display: "flex",
width: "44px",
height: "26px",
padding: "9px 0px",
color:"#E57727",
justifyContent: "center",
alignItems: "center",
borderRadius: "100px",
background:"#F6F2EF",}}>{this.state.showInvoiceData.attributes.client_name.charAt(0).toUpperCase()}</div>
    <div style={{flexDirection: "column"}}>
    <Typography style={{color: "#0F172A",
fontFamily: "Inter",
fontSize: "14px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "22px",textTransform:"capitalize"}}>{this.state.showInvoiceData.attributes.client_name}</Typography>
    <Typography>{this.state.showInvoiceData.attributes.account_data}</Typography>
    </div>

        </Box>
        <label className={classes.labelStyle}>Items</label>
            <Box style={{display: "flex",
padding: "16px",
alignItems: "flex-start",
gap: "8px",
alignSelf: "stretch",
borderRadius: "8px",
border: "1px solid #CBD5E1",
background: "#FFF",}}>
    <div style={{display:"flex",flexDirection: "column",width:"100%"}}>
<Table style={invoiceStyle.table}>
        <TableHead>
          <TableRow>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {itemmsss.map((item:any, index:any) => (
            <TableRow key={index}>
            <TableCell className={classes.tableDescrip}>{item.name}</TableCell>
            <TableCell className={classes.tableAmount}align="center">${item.price.toFixed(2)}</TableCell>
            <TableCell className={classes.tableDescrip} align="right" >${item.price.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography style={{color:"#E57727",
fontFamily: "Inter",
fontSize: "14px",
fontStyle: "normal",
display:"flex",
alignItems:"center",
fontWeight: 700,
marginTop:"4px",
lineHeight: "22px"}}><AddCircleOutline style={{marginRight: "4px",width: "16px",height: "16px"}}/>Line items</Typography>

      <Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
<Typography className={classes.subTitle}>Subtotal</Typography>
<Typography className={classes.tableDescrip}>${subtotal.toFixed(2)}</Typography>
</Grid>
<Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
<Typography className={classes.subTitle} style={{display:"flex",alignItems:"center",gap:"15px"}}>Tax
<TextField
                select
                value={this.state.taxRate}
                onChange={this.handleTaxChange}
                className={classes.input}
                SelectProps={{ MenuProps: { classes: { paper: classes.inputMenu } } }}
                >
                {[5, 12, 18, 20, 25].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}%
                  </MenuItem>
                ))}
              </TextField>
</Typography>
<Typography className={classes.tableDescrip}>${taxAmount.toFixed(2)}</Typography>
</Grid>
<Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
<Typography className={classes.subTitle} style={{display:"flex",alignItems:"center",gap:"15px"}}>Discount
<TextField
                select
                value={this.state.discountRate}
                onChange={this.handleDiscountChange}
                className={classes.input}
                SelectProps={{ MenuProps: { classes: { paper: classes.inputMenu } } }}
                >
                {[0, 5, 10, 15, 20].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}%
                  </MenuItem>
                ))}
              </TextField>
</Typography>
<Typography className={classes.tableDescrip}>${discountAmount.toFixed(2)}</Typography>
</Grid>
<Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
<Typography className={classes.subTitle}>Total</Typography>
<Typography className={classes.tableDescrip}>${total.toFixed(2)}</Typography>
</Grid>
    </div>
</Box>
<label className={classes.labelStyle}>Payments</label>
            <Box style={{display: "flex",
padding: "16px",
flexDirection:"column",
gap: "8px",
alignSelf: "stretch",
borderRadius: "8px",
marginBottom:"30px",
border: "1px solid #CBD5E1",
background: "#FFF",}}>
    <Grid item xs={12} style={{display: "flex",alignItems:"center",justifyContent:"space-between"}}>
    <Typography style={{
          color: "#000",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}>Include payment link</Typography>
        <Toggle
          name="sendMail"
          data-test-id="archive-customer"
          style={invoiceStyle.toggleBtnss}
          isChecked={this.state.paymentToggle}
          handleToggle={this.handleChangeToggle}
        />
                 
    </Grid>
    <Grid item xs={12} style={{display: "flex",alignItems:"center",justifyContent:"space-between"}}>
    <Typography style={{
          color: "#000",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}>Recurring <Typography style={{
          color: "#0F172A",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
        }}>Repeat every year</Typography></Typography>
        <Toggle
          name="sendMail"
          data-test-id="archive-customer"
          style={invoiceStyle.toggleBtnss}
          isChecked={this.state.recurring}
          handleToggle={this.handleChangeRecurring}
        />
                 
    </Grid>
    <Grid item xs={12} style={{display: "flex",alignItems:"center",justifyContent:"space-between"}}>
    <Typography style={{
          color: "#000",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        }}>Due date <Typography style={{
          color: "#0F172A",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
        }}>March 31, 2023</Typography></Typography>
        <Toggle
          name="sendMail"
          data-test-id="archive-customer"
          style={invoiceStyle.toggleBtnss}
          isChecked={this.state.dueDate}
          handleToggle={this.handleChangeDuedate}
        />
                 
    </Grid>

        </Box>
        
    </Box>}
    {this.state.payOnline && <Box style={{width: "calc(100% - 40%)",height: "778px",paddingLeft: "43px"}}>
        <label className={classes.labelStyle}>Payment</label>
            <Box style={{display: "flex",
                        padding: "16px",
                        // alignItems: "flex-start",
                        flexDirection:"column",
                        gap: "8px",
                        alignSelf: "stretch",
                        borderRadius: "8px",
                        border: "1px solid #CBD5E1",
                        background: "#FFF",}}>
  
    <label className={classes.labelStyle} style={{color:"#334155",fontWeight:700}}>Payment method</label>
    <div style={{display:"flex",gap:"10px"}}>
    <FormControl style={{width:"100%"}} className={classes.paper1}>
        <RadioGroup name="payment" className={classes.radioBtn}>
        {this.state.cardsData.map((card, index) => (
            <div style={{display: "flex",
                padding: "10px 20px",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flex: "1 0 0",
                borderRadius: "6px",
                border: "1px solid #CBD5E1"}}>
                    <div style={{display:"flex",flexDirection:"column"}}>
          <FormControlLabel
            id={`cardLabel-${card.id}`}
            key={card?.id} 
            value={card?.id}
            control={<Radio style={{ color: "#e87424" }} />}
            label={`**** ${card?.last4Digits}`}
          />
          <Typography style={{color:"#64748B",
fontFamily: "Inter",
fontSize: "12px",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "18px",
display:"flex",
gap:"3px" }}>Visa
            <Typography style={{color:"#64748B",
fontFamily: "Inter",
fontSize: "12px",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "18px", }}> <FiberManualRecordIcon style={{width: "6px",height: "6px"}}/> Edit</Typography>
          </Typography>
          
          </div>
          <img src={cards}></img>
          </div>
        ))}
        </RadioGroup>
      </FormControl>

    <Box
                                                    
                                                    id="addCard"
                                                    className={classes.addCardBtn}
                                                    onClick={this.addCard}
                                                >
                                                    <AddCircleOutline/>
                                                    New
                                                </Box>
        </div>
                               {this.state.addCard && <Formik
                                    data-test-id="cardForm"
                                    enableReinitialize
                                    initialValues={{
                                      cardNumber:"",
                                      nameOnCard:"",
                                      date:"",
                                      cvv:"",
                                    }}
                                    validationSchema={Yup.object().shape(this.cardDetailsSchema())}
                                    validateOnChange={false}
                                    validateOnMount={true}
                                    validateOnBlur={true}
                                    onSubmit={(values, actions) => {
                                      this.manualPayment(values)
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        touched,
                                        handleBlur,
                                        errors,
                                        values,
                                        handleChange,
                                        setFieldValue
                                    }) => (

                                    <form onSubmit={handleSubmit} style={{margin:"0"}}>
                                        <div style={{ width:"100%",height:"100%"}}>
                                           <Grid container style={{gap:"20px"}}>
                                       
                                              <Grid className={classes.bottomMargin} item xs={12}>
                                                    <label aria-controls="cardNumber" className={classes.label} >Card number</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtcardNumber"}
                                                        id={"txtcardNumber"}
                                                        fullWidth
                                                        className={classes.allowed}
                                                        name="cardNumber"
                                                        onChange={(e) => {
                                                            const newValue = e.target.value.replace(/\s/g, '');
                                                            const formattedValue = newValue.replace(/(.{4})/g, '$1 ').trim();
                                                            handleChange({
                                                                target: {
                                                                    name: 'cardNumber',
                                                                    value: formattedValue
                                                                }
                                                            });
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values?.cardNumber}
                                                        autoComplete="cardNumber"
                                                        placeholder="XXXX XXXX XXXX XXXX"
                                                        inputProps={{
                                                            maxLength: 19
                                                        }}
                                                        error={touched.cardNumber && Boolean(errors.cardNumber)}
                                                    />
                                                      {touched.cardNumber && errors.cardNumber && (
                                                        <Typography color="error" style={{fontSize:"13px"}} >{errors.cardNumber}</Typography>
                                                        )}
                                                </Grid>
                                                <Grid className={classes.bottomMargin} item xs={12}>
                                                    <label aria-controls="nameOnCard" className={classes.label} >Name on card</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputName"}
                                                        id={"txtInputName"}
                                                        fullWidth
                                                        className={classes.allowed}
                                                        onChange={handleChange}
                                                        name="nameOnCard"
                                                        onBlur={handleBlur}
                                                        value={values?.nameOnCard}
                                                        autoComplete="nameOnCard"
                                                        placeholder="Ex John Doe"
                                                        error={touched.nameOnCard && Boolean(errors.nameOnCard)}
                                                        />
                                                        {touched.nameOnCard && errors.nameOnCard && (
                                                        <Typography color="error" style={{fontSize:"13px"}} >{errors.nameOnCard}</Typography>
                                                        )}
                                                </Grid>
                                             
                                            <Grid style={{display:"flex",gap:"12px"}} item xs={12}>
                                                <Grid className={classes.bottomMargin} item xs={6}>
                                                    <label aria-controls="date" className={classes.label} >Date</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputDate"}
                                                        id={"txtInputDate"}
                                                        className={classes.allowed}
                                                        fullWidth
                                                        name="date"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            const cleanedValue = value.replace(/\D/g, ''); // Remove non-digit characters
                                                            let formattedValue = cleanedValue.slice(0, 4); // Limit to DDYY format
                                                            if (cleanedValue.length > 2) {
                                                                formattedValue = cleanedValue.slice(0, 2) + '/' + cleanedValue.slice(2, 4); // Insert "/" after DD
                                                            }
                                                            handleChange({
                                                                target: {
                                                                    name: 'date',
                                                                    value: formattedValue
                                                                }
                                                            });
                                                        }}
                                                        inputProps={{
                                                            maxLength: 5
                                                        }}
                                                        value={values?.date}
                                                        autoComplete="date"
                                                        placeholder="DD/YY"
                                                        error={touched.date && Boolean(errors.date)}
                                                        />
                                                          {touched.date && errors.date && (
                                                            <Typography color="error" style={{fontSize:"13px"}} >{errors.date}</Typography>
                                                            )}
                                                </Grid>
                                                <Grid className={classes.bottomMargin} item xs={6}>
                                                    <label aria-controls="cvv" className={classes.label} >Cvv</label><br />
                                                    <OutlinedInput
                                                        data-test-id={"txtInputCvv"}
                                                        id={"txtInputCvv"}
                                                        className={classes.allowed}
                                                        fullWidth
                                                        name="cvv"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            const numericValue = value.replace(/\D/g, '');
                                                            handleChange({
                                                                target: {
                                                                    name: 'cvv',
                                                                    value: numericValue
                                                                }
                                                            });
                                                        }}
                                                        value={values?.cvv}
                                                        autoComplete="cvv"
                                                        placeholder="123"
                                                        error={touched.cvv && Boolean(errors.cvv)}
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*',
                                                            maxLength: 3
                                                        }}
                                                        />
                                                        {touched.cvv && errors.cvv && (
                                                        <Typography color="error" style={{fontSize:"13px"}} >{errors.cvv}</Typography>
                                                        )}
                                                </Grid>
                                                </Grid>   
                                        </Grid>

                                    </div>
                                    <Button
                                                    fullWidth
                                                    data-test-id={"btnPayment"}
                                                    type="submit"
                                                    className={classes.Btn}
                                                    style={{ textTransform: "initial" }}
                                                >
                                                    Make payment
                                                </Button>
                                </form>
                                )}
                            </Formik>}
                          {!this.state.addCard && <Button
                                                    fullWidth
                                                    data-test-id={"btnPayment"}
                                                    type="submit"
                                                    className={classes.Btn}
                                                    style={{ textTransform: "initial" }}
                                                >
                                                    Make payment
                                                </Button>}

        </Box>     
    </Box>}
    <Box style={{width: "calc(100% - 60%)"}}>
      <label className={classes.labelStyle}>Preview</label>
    <Box style={{display: "flex",
width: "418px",
padding: "21px 20px",
flexDirection: "column",
// alignItems: "flex-start",
gap: "10px",
borderRadius: "8px",
border: "1px solid #CBD5E1",
background: "#FFF"}}>
  <Typography style={{color: "#0F172A",
fontFamily: "Roboto",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 600,
lineHeight: "24px"}}>Invoices</Typography>

<Grid style={{display:"flex",flexDirection:"column",gap:"20px"}}>
  <div>
    <div style={{display:"flex",gap:"20px"}}>
    <Typography style={{color: "#0F172A",
fontFamily: "Inter",
fontSize: "14px",
width:"102px",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "22px"}}>Invoice number</Typography>
<Typography style={{color: "#0F172A",
fontFamily: "Inter",
fontSize: "14px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "22px"}}>{this.state.showInvoiceData.attributes.invoice_number}</Typography>
</div>
<div style={{display:"flex",gap:"20px"}}>
    <Typography style={{color: "#0F172A",
fontFamily: "Inter",
width:"102px",
fontSize: "14px",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "22px"}}>Due date</Typography>
<Typography style={{color: "#0F172A",
fontFamily: "Inter",
fontSize: "14px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "22px"}}>{due_Date.toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' })}</Typography>
  </div>
</div>
<Typography style={{color: "#0F172A",
fontFamily: "Inter",
fontSize: "14px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "22px"}}>${total.toFixed(2)} due to {due_Date.toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' })}</Typography>
<div>
<Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.previewHeading}>Description</TableCell>
            <TableCell className={classes.previewHeading}>Qty</TableCell>
            <TableCell className={classes.previewHeading} align="center">Price</TableCell>
            <TableCell className={classes.previewHeading} align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          {itemmsss.map((item:any, index:any) => (
           <TableRow key={index}>
            <TableCell className={classes.previewHeading2} style={{borderBottom: index === itemmsss.length - 1 ? "0.5px solid #073F59" : "none",}}>{item.name}</TableCell>
            <TableCell className={classes.previewHeading2} style={{borderBottom: index === itemmsss.length - 1 ? "0.5px solid #073F59" : "none",}}>1</TableCell>
            <TableCell className={classes.previewHeading2} style={{borderBottom: index === itemmsss.length - 1 ? "0.5px solid #073F59" : "none",}} align="center">${item.price.toFixed(2)}</TableCell>
            <TableCell className={classes.previewHeading2} style={{borderBottom: index === itemmsss.length - 1 ? "0.5px solid #073F59" : "none",}} align="right" >${item.price.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
</div>
<div style={{display:"grid"}}>
<Grid className={classes.previewTypes}>
<Typography className={classes.previewText}>Subtotal</Typography>
<Typography className={classes.previewText} style={{fontWeight:500}}>${subtotal.toFixed(2)}</Typography>
</Grid>
<div className={classes.diversion}></div>
<Grid className={classes.previewTypes}>
<Typography className={classes.previewText} style={{display:"flex",alignItems:"center",gap:"5px"}}>Tax
<Typography className={classes.previewText}>({this.state.taxRate}%)</Typography>
</Typography>
<Typography className={classes.previewText} style={{fontWeight:500}}>${taxAmount.toFixed(2)}</Typography>
</Grid>
<div className={classes.diversion}></div>
<Grid className={classes.previewTypes}>
<Typography className={classes.previewText} style={{display:"flex",alignItems:"center",gap:"5px"}}>Discount
<Typography className={classes.previewText}>({this.state.discountRate}%)</Typography>
</Typography>
<Typography className={classes.previewText} style={{fontWeight:500}}>${discountAmount.toFixed(2)}</Typography>
</Grid>
<div className={classes.diversion}></div>
<Grid className={classes.previewTypes}>
<Typography className={classes.previewText} style={{fontSize:"12px",fontWeight:700}}>Amount due</Typography>
<Typography className={classes.previewText}  style={{fontSize:"12px",fontWeight:700}}>${total.toFixed(2)}</Typography>
</Grid>
</div>
</Grid>
  
  </Box>
    
    </Box>

    </Box>
    <ManualPaymentForm
      openDialog= {this.state.openManualForm}
      headingText= "Create manual payment"
      btnCancelText= "Cancel"
      btnOkText= "Create"
      handleOk= {this.handleManualForm}
      handleCancel= {this.handleManualForm}
      TypeSelect={this.state.TypeSelect}
      handleType={this.handleType}
    />
    </Box>

    );
  }
}

const invoiceStyle: any = {
  pagination: {
      "display": "flex",

      "height": "32px",
      "padding": "5px 0px",
      "justifyContent": "center",
      "alignItems": "center",
      "gap": "4px",
      "flexShrink": "0",
      "borderRadius": "100px",
      "background": "#FFF",
   
  },
  RightPagintnText:{
      "color": "#64748B",
      "textAlign": "right",
      "fontFamily": "Inter",
      "fontSize": "12px",
      "fontStyle": "normal",
      "fontWeight": "400",
      "lineHeight": "18px"
    },
    table:{
      "& .MuiTableCell-root":{
        borderBottom:"0.5px solid #073F59",
        padding:"16px 0px"
    }
    },
    previewTypes:{display:"flex",marginBottom:"5px",justifyContent:"space-between",alignItems:"center",height:"18px"},
    diversion:{width:"100%",height:"0.3px",background:"CBD5E1",marginTop:"5px"},
    tableDescrip:{color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    padding:"12px 0px",
    lineHeight: "22px"},
    tableAmount:{
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px"
    },
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(2),
    },
    customPopover: {
      transform: "translateX(-50px) !important",
      "& .MuiPopover-paper":{
      display:"flex",
      flexDirection:"column" as const,
      padding:"5px",
      alignItems:"start",
      justifyContent:"center"
      }
    },
    previewText:{
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
    },
    btnText:{
    textTransform:"capitalize" as const,
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
    },
    labelStyle:{display: "flex",
    height: "18px",
    color:"#64748B",
    padding: "24px 24px 8px 0px",
    alignItems: "center",
    alignSelf: "stretch",},
    subTitle:{
      color: "#334155",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px"
    },
    redirection: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      textDecoration: "none",
      letterSpacing: "-0.12px",
      '&:hover': {
        textDecoration: "none",
      },
    },
    currentPage: {
      color: "#E57727",
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      textDecoration: "none",
      letterSpacing: "-0.12px"
    },
    btnAddColumn: {
      display: "flex",
      textTransform: "none",
      height: "44px",
      padding: "10px 16px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      width: "140px",
      background: "#F6F2EF",
      color: "#E57727",
      gap: "8px"
    },
    btnAddAccount: {
      display: "flex",
      height: "44px",
      padding: "10px 16px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      width: "185px",
      textTransform: "capitalize",
      background: "#E57727",
      color: "#fff",
      gap: "8px",
      '&:hover': {
        backgroundColor: "#E57727",
      },
    },
    addCardBtn:{ 
    textTransform: "initial" as const,
    display: "flex",
    width: "62px",
    padding: "11px 10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #E57727",
    background: "#FFF",
    color:"#E57727",
    cursor:"pointer",
    fontSize:"16px",
    fontWeight:700,
    alignSelf: "end", },
    input: {
      "& .MuiInputBase-input": {
    color: "#E57727",
    background: "#F6F2EF",
    fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Inter",
        borderRadius: "8px",
        display: "flex",
        height: "28px",
        padding: "4px 20px 4px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
    },
    "& .MuiInput-underline:before":{
        borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
        borderBottom: "none",
    },
    "& .MuiInput-underline:hover": {
      borderBottom: "none",
    },
    "& .MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
        color:"#000"
    },
    "& .MuiSelect-icon":{
      top:"calc(50% - 8px)"
    },
    
  },
  toggleBtnss: {
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      background: "#E57727",
    },
  },
  inputMenu:{
    "& .MuiMenuItem-root":{
      fontSize:"14px",
      color:"#E57727",
      background:"#F6F2EF",
      },
      "& .MuiList-padding": {
           paddingTop: 0, 
           paddingBottom: 0, 
      }
  },
  paper1: {
    "& .MuiSvgIcon-root": {
      width: "16px",
      height: "16px"
  },
  "& .MuiTypography-body1": {
      color:"#111729",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px", 
  },
  "& .MuiFormControl-root": {
      width: "100%", 
    },
  "& .MuiFormGroup-root":{
      display: "flex",
      flexWrap: "wrap",
      gap:"10px",
      flexDirection:"row" as const,
  }
  },

  radioBtn:{
    '& .MuiRadio-root.Mui-checked': {
      color: '#e87242',
    },
    '& .MuiRadio-root:not(.Mui-checked)': {
      color: 'grey !important',
    },
  },

  label:{
      color: "#334155",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
  },

  Btn: {
      display: "flex",
      width: "148px",
      height:"44px",
      color: "white",
      marginTop: "20px",
      fontSize: "16px",
      padding: "10px 16px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      flex: "1 0 0",
      alignSelf: "stretch",
      borderRadius: "8px",
      fontFamily: "Inter",
      fontStyle: "normal",
      lineHeight: "24px",
      fontWeight: 700,
      background: "#E57727",
      '&:hover': {
          backgroundColor: '#E57727',
      },
  },
  previewHeading:{
    color: "#0F172A",
fontFamily: "Inter",
fontStyle: "normal",
fontWeight: 400,
lineHeight: "18px",
fontSize:"8px",
  },
  previewHeading2:{
    color: "#0F172A",
fontFamily: "Inter",
fontStyle: "normal",
fontWeight: 400,
fontSize:"9px",
lineHeight:" 9.682px",
  }

}
export default withStyles(invoiceStyle)(InvoiceDetails)
// Customizable Area End
