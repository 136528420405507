//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  classes:any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  value: string,
  isDrawerOpen: boolean
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserGroupController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.state = {
      value: 'Profile',
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  
  tabtypes = ["Profile", "Progress Tracker", "Documents", "Communication", "Invoices", "Notes", "Projects"];

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  // Customizable Area End
}
